import React from 'react';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { Box, Divider, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';

/**
 * This is a React component that renders a popover with two options, "View" and "Edit", when a trigger
 * element is clicked.
 * @returns A React functional component called `ActionPopOver` is being returned. It renders a
 * `PopupState` component from the `material-ui-popup-state` library, which manages the state of the
 * popover. Inside the `PopupState` component, there is a `Box` component that contains a `Typography`
 * component with a `pointerCursor` class and a `Popover` component. The `Popover`
 */
export default function DetailReportPopup({ row, pageRoute }) {
    return (
        <Box>
            <PopupState variant="popover" popupId="demo-popup-popover">
                {(popupState) => (
                    <Box>
                        <Typography fontWeight={"bold"} variant="h5" {...bindTrigger(popupState)} className="pointerCursor" component={'span'}>
                            ...
                        </Typography>
                        <Popover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <Box sx={{ width: "8rem" }}>
                                <List>
                                    <ListItem >
                                        {pageRoute === 'mcasummaryreport' &&
                                            <Link to={`/client/clientdetails/mcasummaryreport/${row}`} target="_blank" rel="noreferrer" className='m-auto' style={{ textDecoration: "none" }}>
                                                <Typography variant='subtitle1' fontWeight="semiBold">Download</Typography>
                                            </Link>

                                        }
                                        {pageRoute === 'mcadetaliedreport' &&
                                            <Link to={`/client/clientdetails/mcadetailedreport/${row}`} target="_blank" rel="noreferrer" className='m-auto' style={{ textDecoration: "none" }}>
                                                <Typography variant='subtitle1' fontWeight="semiBold">Download</Typography>
                                            </Link>
                                        }

                                        {pageRoute === 'careerAssessmentVoucher' &&
                                            <Link to={`/client/clientdetails/careervoucherreport/${row}`} target="_blank" rel="noreferrer" className='m-auto' style={{ textDecoration: "none" }}>
                                                <Typography variant='subtitle1' fontWeight="semiBold">Download</Typography>
                                            </Link>

                                        }

                                        {pageRoute === 'careerAssessmentReport' &&
                                            <Link to={`/client/clientdetails/careerassessmentreport/${row}`} target="_blank" rel="noreferrer" className='m-auto' style={{ textDecoration: "none" }}>
                                                <Typography variant='subtitle1' fontWeight="semiBold">Download</Typography>
                                            </Link>
                                        }

                                        {pageRoute === 'homeassessmentvoucher' &&
                                            <Link to={`/client/clientdetails/homeassessmentvoucher/${row}`} target="_blank" rel="noreferrer" className='m-auto' style={{ textDecoration: "none" }}>
                                                <Typography variant='subtitle1' fontWeight="semiBold">Download</Typography>
                                            </Link>

                                        }

                                        {pageRoute === 'homeassessmentreport' &&
                                            <Link to={`/client/clientdetails/homeassessmentreport/${row}`} target="_blank" rel="noreferrer" className='m-auto' style={{ textDecoration: "none" }}>
                                                <Typography variant='subtitle1' fontWeight="semiBold">Download</Typography>
                                            </Link>
                                        }

                                        {pageRoute === 'mentalhealthvoucher' &&
                                            <Link to={`/client/clientdetails/mentalhealthvoucher/${row}`} target="_blank" rel="noreferrer" className='m-auto' style={{ textDecoration: "none" }}>
                                                <Typography variant='subtitle1' fontWeight="semiBold">Download</Typography>
                                            </Link>

                                        }

                                        {pageRoute === 'mentalhealthdetailedreport' &&
                                            <Link to={`/client/clientdetails/mentalhealthdetailedreport/${row}`} target="_blank" rel="noreferrer" className='m-auto' style={{ textDecoration: "none" }}>
                                                <Typography variant='subtitle1' fontWeight="semiBold">Download</Typography>
                                            </Link>
                                        }

                                        {pageRoute === 'benefitBudgetAssessmentvoucher' &&
                                            <Link to={`/client/clientdetails/benefitbudgetvoucher/${row}`} target="_blank" rel="noreferrer" className='m-auto' style={{ textDecoration: "none" }}>
                                                <Typography variant='subtitle1' fontWeight="semiBold">Download</Typography>
                                            </Link>

                                        }

                                        {pageRoute === 'benefitBudgetAssessment' &&
                                            <Link to={`/client/clientdetails/benefitbudgetdetailedreport/${row}`} target="_blank" rel="noreferrer" className='m-auto' style={{ textDecoration: "none" }}>
                                                <Typography variant='subtitle1' fontWeight="semiBold">Download</Typography>
                                            </Link>
                                        }

                                        {pageRoute === 'mastercomprehensivereport' &&
                                            <Link to={`/client/clientdetails/mastercomprehensivereport/${row}`} target="_blank" rel="noreferrer" className='m-auto' style={{ textDecoration: "none" }}>
                                                <Typography variant='subtitle1' fontWeight="semiBold">Download</Typography>
                                            </Link>

                                        }

                                        {/* {pageRoute === 'mastercomprehensivereport' &&
                                            <Link to={`/client/clientdetails/mastercomprehensivereport/${row}`} target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}>
                                                <Typography variant='subtitle1' fontWeight="semiBold">Download</Typography>
                                            </Link>
                                        } */}
                                    </ListItem>
                                    <Divider />
                                    <ListItem >
                                        <ListItemButton
                                            onClick={() => {
                                                popupState.close()
                                            }}
                                        >
                                            <ListItemText primary={<Typography variant='subtitle1' fontWeight="semiBold" textAlign="center">Share</Typography>} />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </Box>
                        </Popover>
                    </Box>
                )}
            </PopupState>
        </Box>
    );
}
