import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const mentalHealthDiagnosticApi = createApi({
    reducerPath: 'mentalHealthDiagnosticApi',
    refetchOnMountOrArgChange: true,
    keepUnusedDataFor: 0,
    refetchOnReconnect: true,
    baseQuery: AxiosBaseQuery({
        baseUrl: `${developmentApi}/api`,
    }),
    tagTypes: ['getMentalStatusSummary', 'getDiagnosticImpression', 'getEmploymentSummary', 'getRecommendationMedical', 'getRecommendationCommunity', 'getBarriers', 'getRecommendationVocational',
        'getRecommendationMentalHealth'],
    endpoints: (builder) => ({
        addMentalStatusSummary: builder.mutation({
            query: ({ payload }) => ({
                url: `/company/add-MentalStatus-Summary`,
                method: 'POST',
                payload: payload,
            }),
            invalidatesTags: ['getMentalStatusSummary'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateMentalStatusSummary: builder.mutation({
            query: ({ id ,payload }) => ({
                url: `/company/update-MentalStatusSummary?id=${id}`,
                method: 'PUT',
                payload: payload,
             
            }),
            invalidatesTags: ['getMentalStatusSummary'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getMentalStatusSummary: builder.query({
            query: ({ ClientId }) => ({
                url: `/company/get-MentalStatus-Summary?ClientId=${ClientId}`,
                method: 'GET',
            }),
            providesTags: ['getMentalStatusSummary'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addDiagnosticImpression: builder.mutation({
            query: ({ payload }) => ({
                url: `/company/add-DiagnosticImpressions`,
                method: 'POST',
                payload: payload,
            }),
            invalidatesTags: ['getDiagnosticImpression'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateDiagnosticImpression: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-DiagnosticImpressions?id=${id}`,
                method: 'PUT',
                payload: payload,
            }),
            invalidatesTags: ['getDiagnosticImpression'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getDiagnosticImpressionById: builder.query({
            query: ({ id }) => ({
                url: `/company/get-DiagnosticImpressions?id=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ['getDiagnosticImpression'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getDiagnosticImpression: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-all-DiagnosticImpressions?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getDiagnosticImpression'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        DeleteDiagnosticImpression: builder.mutation({
            query: ({ id }) => ({
                url: `/company/delete-DiagnosticImpressions?id=${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['getDiagnosticImpression'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addEmploymentSummary: builder.mutation({
            query: ({ payload }) => ({
                url: `/company/add-EmploymentSummary`,
                method: 'POST',
                payload: payload,
            }),
            invalidatesTags: ['getMentalStatusSummary'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateEmploymentSummary: builder.mutation({
            query: ({ payload }) => ({
                url: `/company/update-EmploymentSummary`,
                method: 'PUT',
                payload: payload,
            }),
            invalidatesTags: ['getEmploymentSummary'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getEmploymentSummary: builder.query({
            query: ({ ClientId }) => ({
                url: `/company/get-EmploymentSummary?ClientId=${ClientId}`,
                method: 'GET',
            }),
            providesTags: ['getEmploymentSummary'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addRecommendationMedical: builder.mutation({
            query: ({ payload }) => ({
                url: `/company/add-Recommendations-Medical-Treatment`,
                method: 'POST',
                payload: payload,
            }),
            invalidatesTags: ['getRecommendationMedical'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateRecommendationMedical: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-Recommendations-Medical-Treatment?id=${id}`,
                method: 'PUT',
                payload: payload,
            }),
            invalidatesTags: ['getRecommendationMedical'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getRecommendationMedicalById: builder.query({
            query: ({ id }) => ({
                url: `/company/get-Recommendations-Medical-Treatment?id=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ['getRecommendationMedical'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getRecommendationMedical: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-all-Recommendations-Medical-Treatment?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getRecommendationMedical'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        DeleteRecommendationMedical: builder.mutation({
            query: ({ id }) => ({
                url: `/company/delete-Recommendations-Medical-Treatment?id=${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['getRecommendationMedical'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addRecommendationCommunity: builder.mutation({
            query: ({ payload }) => ({
                url: `/company/add-Recommendations-Community-Service`,
                method: 'POST',
                payload: payload,
            }),
            invalidatesTags: ['getRecommendationCommunity'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateRecommendationCommunity: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-Recommendations-Community-Service?id=${id}`,
                method: 'PUT',
                payload: payload,
            }),
            invalidatesTags: ['getRecommendationCommunity'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getRecommendationCommunityById: builder.query({
            query: ({ id }) => ({
                url: `/company/get-Recommendations-Community-Service?id=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ['getRecommendationCommunity'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getRecommendationCommunity: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-all-Recommendations-Community-Service?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getRecommendationCommunity'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        DeleteRecommendationCommunity: builder.mutation({
            query: ({ id }) => ({
                url: `/company/delete-Recommendations-Community-Service?id=${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['getRecommendationCommunity'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addBarriers: builder.mutation({
            query: ({ payload, ClientId }) => ({
                url: `/company/add-Barriers-And-Challenges?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
            }),
            invalidatesTags: ['getBarriers'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateBarriers: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-Barriers-And-Challenges?id=${id}`,
                method: 'PUT',
                payload: payload,
            }),
            invalidatesTags: ['getBarriers'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getBarriersById: builder.query({
            query: ({ id }) => ({
                url: `/company/get-Barriers-And-Challenges?id=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ['getBarriers'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getBarriers: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-All-Barriers-And-Challenges?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getBarriers'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        DeleteBarriers: builder.mutation({
            query: ({ id }) => ({
                url: `/company/delete-Barriers-And-Challenges?id=${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['getBarriers'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addRecommendationVocational: builder.mutation({
            query: ({ payload, ClientId }) => ({
                url: `/company/add-Recommendation-Vocational-Services?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
            }),
            invalidatesTags: ['getRecommendationVocational'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateRecommendationVocational: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-Recommendation-Vocational-Services?id=${id}`,
                method: 'PUT',
                payload: payload,
            }),
            invalidatesTags: ['getRecommendationVocational'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getRecommendationVocationalById: builder.query({
            query: ({ id }) => ({
                url: `/company/get-Recommendation-Vocational-Services?id=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ['getRecommendationVocational'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getRecommendationVocational: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-All-Recommendation-Vocational-Services?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getRecommendationVocational'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        DeleteRecommendationVocational: builder.mutation({
            query: ({ id }) => ({
                url: `/company/delete-Recommendation-Vocational-Services?id=${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['getRecommendationVocational'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addRecommendationMentalHealth: builder.mutation({
            query: ({ payload, ClientId }) => ({
                url: `/company/add-Recommendation-Mental-Health?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
            }),
            invalidatesTags: ['getRecommendationMentalHealth'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateRecommendationMentalHealth: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-Recommendation-Mental-Health?id=${id}`,
                method: 'PUT',
                payload: payload,
            }),
            invalidatesTags: ['getRecommendationMentalHealth'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getRecommendationMentalHealthById: builder.query({
            query: ({ id }) => ({
                url: `/company/get-Recommendation-Mental-Health?id=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ['getRecommendationMentalHealth'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getRecommendationMentalHealth: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-All-Recommendation-Mental-Health?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getRecommendationMentalHealth'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        DeleteRecommendationMentalHealth: builder.mutation({
            query: ({ id }) => ({
                url: `/company/delete-Recommendation-Mental-Health?id=${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['getRecommendationMentalHealth'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
    }),
})

export const { useAddMentalStatusSummaryMutation, useUpdateMentalStatusSummaryMutation, useGetMentalStatusSummaryQuery,
    useAddDiagnosticImpressionMutation, useUpdateDiagnosticImpressionMutation, useGetDiagnosticImpressionByIdQuery, useGetDiagnosticImpressionQuery, useDeleteDiagnosticImpressionMutation,
    useAddEmploymentSummaryMutation, useUpdateEmploymentSummaryMutation, useGetEmploymentSummaryQuery,
    useAddRecommendationMedicalMutation, useUpdateRecommendationMedicalMutation, useGetRecommendationMedicalByIdQuery, useGetRecommendationMedicalQuery, useDeleteRecommendationMedicalMutation,
    useAddRecommendationCommunityMutation, useUpdateRecommendationCommunityMutation, useGetRecommendationCommunityByIdQuery, useGetRecommendationCommunityQuery, useDeleteRecommendationCommunityMutation,
    useAddBarriersMutation, useUpdateBarriersMutation, useGetBarriersByIdQuery, useGetBarriersQuery, useDeleteBarriersMutation,
    useAddRecommendationVocationalMutation, useUpdateRecommendationVocationalMutation, useGetRecommendationVocationalByIdQuery, useGetRecommendationVocationalQuery, useDeleteRecommendationVocationalMutation,
    useAddRecommendationMentalHealthMutation, useUpdateRecommendationMentalHealthMutation, useGetRecommendationMentalHealthByIdQuery, useGetRecommendationMentalHealthQuery, useDeleteRecommendationMentalHealthMutation } = mentalHealthDiagnosticApi;
