import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import DropZone from "../../../../../components/DropZone/DropZone";
import Scrollbars from "react-custom-scrollbars-2";
import locationData from '../../../../../locationData.json';
import cityLocationData from "../../../../../cityLocationData.json"
import browse from "../../../../../assets/Images/browse.png";
import browseUpload from "../../../../../assets/Images/browseUpload.png";
import { useNavigate } from "react-router-dom";
import palette from "../../../../../theme/palette";

export default function FirstRegisterForm({ formData, setFormData, setUserWq, userWq, setUserlogo, userLogo, handleFormChange, setValue, logoFileError, setLogoFileError, w9FileError, setW9FileError }) {
    const navigate = useNavigate();
    const [cityOptions, setCityOptions] = useState();


    /* The above code is defining an object `customStyles` with three properties: `dropdownIndicator`,
    `indicatorSeparator`, and `container`. Each property is a function that returns an object with
    CSS styles. */
    const customStyles = {
        dropdownIndicator: (base) => ({
            ...base, color: palette.text.light, flex: "1", width: "100%",
        }),
        indicatorSeparator: () => null,
        container: base => ({ ...base, flex: "1", width: "100%" }),
    };

    /* The above code is defining an object called `acceptLogo` which contains two properties, each of
    which is an array of file extensions. The first property is `"image/png"` and its value is an
    array containing only the extension `.png`. The second property is `"image/jpg"` and its value
    is an array containing the extensions `.jpg` and `.jpeg`. This object is likely used to validate
    file types when accepting logo files in a web application. */
    const acceptLogo = {
        "image/png": [".png"],
        "image/jpg": [".jpg", ".jpeg"],
    }

    /* The above code is defining an object called `acceptW9` which contains two properties,
    "document/pdf" and "document/doc". Each property is an array of file extensions that are
    accepted for that document type. For example, the "document/pdf" property accepts only files
    with the ".pdf" extension, while the "document/doc" property accepts files with either the
    ".doc" or ".docx" extension. This code is likely used for file validation or filtering purposes. */
    const acceptW9 = {
        "document/pdf": [".pdf"],
        "document/doc": [".doc", ".docx"],
    }

    /**
     * This function handles changes in form data and validates email and phone number inputs.
     * @param e - The parameter `e` is an event object that is passed to the `handleChange` function as
     * an argument. It represents the event that triggered the function, such as a change in the input
     * field value.
     */
    const handleChange = (e) => {
        const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        if (e.target.name === "emailAddress") {
            setFormData({
                ...formData,
                values: {
                    ...formData?.values,
                    [e.target.name]: e.target.value,
                },
                error: {
                    ...formData?.error,
                    emailAddressError: (emailPattern.test(e?.target?.value) ? "" : "Email is not valid"),
                }
            })
        }
        else {
            setFormData({
                ...formData,
                values: {
                    ...formData?.values,
                    [e.target.name]: e.target.value,
                },
                error: {
                    ...formData?.error,
                    emailAddressError: "",
                }
            });
        }
    };

    /**
     * The function prevents certain characters from being typed in an input field.
     * @param e - The parameter "e" is an event object that is passed to the function "handlevalidate".
     * It contains information about the event that triggered the function, such as the type of event
     * (e.g. keydown), the target element, and any additional data related to the event (e.g. the
     */
    const handlevalidate = (e) => {


        if (e.keyCode === 69 || e.keyCode === 189 || e.keyCode === 187 || e.key === ".") {
            e.preventDefault();
        }
    };

    /* The above code is defining an array of objects called `sizeOptions`. Each object has two
    properties: `label` and `value`. The `label` property is a string that describes the size
    option, and the `value` property is a string that represents the size option. These size options
    are likely intended to be used in a dropdown or selection menu for a user to choose from. */
    const sizeOptions = [
        { label: "Startup (1-10)", value: "Startup (1-10)" },
        { label: "Mid-sized (11-50)", value: "Mid-sized (11-50)" },
        { label: "Large (51-100)", value: "Large (51-100)" },
        { label: "Enterprise (100+)", value: "Enterprise (100+)" },
    ]

    /* The above code is defining an array of objects called `hereAboutOptions`. Each object has two
    properties: `label` and `value`. These objects represent options for a form field where the user
    can select how they heard about something. The options include "Through search engines (like
    google,being)", "Word of mouth", "Recommended by friend", "Social Media", and "Other". */
    const hereAboutOptions = [
        { label: "Search engines (e.g., Bing, Google, Yahoo)", value: "Search engines (e.g., Bing, Google, Yahoo)" },
        { label: "Word of mouth", value: "Word of mouth" },
        { label: "Recommended by a friend", value: "Recommended by a friend" },
        { label: "Social media platforms", value: "Social media platforms" },
        { label: "Other", value: "Other" },
    ]

    /* The above code is written in JavaScript and it is creating a new array called `locationState`
    using the optional chaining operator `?.`. It is mapping over an array called `locationData` and
    for each element in the array, it is returning an object with two properties: `label` and
    `value`. The `label` property is set to the `state` property of the current element in the
    `locationData` array, and the `value` property is also set to the `state` property of the
    current element in the `locationData` array. If the `state` property */
    const locationState = locationData?.map((data) => {
        return { label: data?.state, value: data?.state }
    })
    /* The above code is creating an array of unique state options by mapping over an array called
    `locationState`, converting each object to a string using `JSON.stringify`, creating a new Set
    from the resulting array of strings to remove duplicates, and then mapping over the Set to
    convert each string back to an object using `JSON.parse`. The resulting array contains all
    unique state options from the original `locationState` array. */
    const stateOptions = [...new Set(locationState.map(o => JSON.stringify(o)))].map(s => JSON.parse(s))
    /* The above code is a React useEffect hook that is triggered when the value of
    `formData?.values?.selectState` changes. It filters the `locationData` array to get all the
    cities that belong to the selected state and maps them to an array of objects with `label` and
    `value` properties. It then removes any duplicate objects from the array and sets the resulting
    array as the options for a select input. */
    useEffect(() => {
        if (formData?.values?.selectState !== "") {
            const locationCity = cityLocationData?.filter((data) => data?.state === formData?.values?.selectState?.value)
            const city = locationCity?.map((data) => ({ label: data?.city, value: data?.city }))
            setCityOptions([...new Set(city.map(o => JSON.stringify(o)))].map(s => JSON.parse(s)))
        }
    }, [formData?.values?.selectState])


    /* The above code is a React useEffect hook that runs when the `formData.values.zipCode` changes.
    It filters the `locationData` array to find the data that matches the `zipCode` value. If there
    is a match, it updates the `formData` state with the corresponding city and state values from
    the `autofieldData` array. If there is no match, it sets the `formData` state to the previous
    values of `selectCity` and `selectState`. */
    useEffect(() => {
        if (formData?.values?.zipCode) {
            const autofieldData = locationData?.filter((data) => data?.zip_code.toString() === formData?.values?.zipCode)
            {
                autofieldData?.length !== 0 ? setFormData({
                    ...formData,
                    values: {
                        ...formData?.values,
                        selectCity: { label: autofieldData[0]?.city, value: autofieldData[0]?.city },
                        selectState: { label: autofieldData[0]?.state, value: autofieldData[0]?.state }
                    }
                }) : setFormData({
                    ...formData,
                    values: {
                        ...formData?.values,
                        selectCity: formData?.values?.selectCity,
                        selectState: formData?.values?.selectState
                    }
                })
            }

        }
    }, [formData?.values?.zipCode])

    /* The above code is checking if all the required fields in a form (companyName, emailAddress,
    officeNumber, displayName, einNumber, companyAddress, zipCode, selectState, selectCity) have
    been filled out and if there are no errors in the emailAddress field. If all conditions are met,
    the next button is enabled. */
    const nextButtonEnable = (formData?.values?.companyName && formData?.values?.emailAddress && formData?.values?.officeNumber && formData?.values?.displayName &&
        formData?.values?.einNumber && formData?.values?.companyAddress && formData?.values?.zipCode && formData?.values?.selectState &&
        formData?.values?.selectCity && formData?.error?.emailAddressError === "" && logoFileError === "" && w9FileError === "" && formData?.values?.selectCompanySize)
    return (
        <Box>
            <Scrollbars autoHeight autoHeightMax={"calc(100vh - 260px)"}>
                <Grid container spacing={2} sx={{ pr: '15px', pl: '1px' }}>
                    <Grid item xs={12}><Typography variant="h4" sx={{ m: "1.3rem 0 .6rem 0" }}>Company Details</Typography></Grid>
                    <Grid item xs={12}>
                        <Typography variant="formLabel" component='div'>Company name*</Typography>
                        <TextField
                            required
                            fullWidth
                            id="outlined-error-helper-text"
                            size="small"
                            name="companyName"
                            value={formData?.values?.companyName || ""}
                            onChange={(e) => handleChange(e)}
                        /></Grid>
                    <Grid item xs={12}>
                        <Typography variant="formLabel" component='div'>Email address*</Typography>
                        <TextField
                            error={formData?.values?.emailAddress === undefined || formData?.values?.emailAddress === "" || !formData?.error?.emailAddressError ? false : true}
                            required
                            fullWidth
                            size="small"
                            name="emailAddress"
                            autoComplete="off"
                            helperText={formData?.values?.emailAddress === undefined || formData?.values?.emailAddress === "" ? "" : formData?.error?.emailAddressError}
                            value={formData?.values?.emailAddress}
                            onChange={(e) => handleChange(e)}
                        /></Grid>
                    <Grid item xs={12}>
                        <Typography variant="formLabel" component='div'>Office number*</Typography>
                        <TextField
                            error={formData?.values?.officeNumber?.length === 10 || formData?.values?.officeNumber?.length === 0 || formData?.values?.officeNumber === undefined ? false : true}
                            required
                            fullWidth
                            type={'number'}
                            size="small"
                            name="officeNumber"
                            helperText={formData?.values?.officeNumber?.length === 10 || formData?.values?.officeNumber?.length === 0 || formData?.values?.officeNumber === undefined ? "" : "Phone Number should be of 10 digits"}
                            value={formData?.values?.officeNumber}
                            onChange={(e) => handleChange(e)}
                            onKeyDown={handlevalidate}
                            onInput={(e) => {
                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                            }}
                        /></Grid>
                    <Grid item xs={12}>
                        <Typography variant="formLabel" component='div'>Display name of your business*</Typography>
                        <TextField
                            required
                            fullWidth
                            size="small"
                            name="displayName"
                            value={formData?.values?.displayName}
                            onChange={(e) => handleChange(e)}
                        /></Grid>
                    <Grid item xs={12}>
                        <Typography variant="formLabel" component='div'>EIN*</Typography>
                        <TextField
                            required
                            fullWidth
                            size="small"
                            name="einNumber"
                            value={formData?.values?.einNumber}
                            onChange={(e) => handleChange(e)}
                        // onKeyDown={handlevalidate}
                        /></Grid>
                    <Grid item xs={12}>
                        <Typography variant="formLabel" component='div'>Company address*</Typography>
                        <TextField
                            required
                            fullWidth
                            size="small"
                            name="companyAddress"
                            value={formData?.values?.companyAddress}
                            onChange={(e) => handleChange(e)}
                        /></Grid>
                    <Grid item xs={12}>
                        <Typography variant="formLabel" component='div'>Select state*</Typography>
                        <Select
                            maxMenuHeight={250}
                            styles={customStyles}
                            options={stateOptions}
                            required
                            classNamePrefix="standardSelect"
                            placeholder={<Typography variant="formLabel" >Select state</Typography>}
                            isSearchable={true}
                            isClearable={true}
                            name="selectState"
                            value={formData?.values?.selectState}
                            onChange={(e) => setFormData({
                                ...formData,
                                values: {
                                    ...formData?.values,
                                    selectState: e,
                                },
                            })}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="formLabel" component='div'>Select city*</Typography>
                        <Select
                            maxMenuHeight={250}
                            styles={customStyles}
                            options={cityOptions}
                            required
                            classNamePrefix="standardSelect"
                            placeholder={<Typography variant="formLabel">Select city</Typography>}
                            isSearchable={true}
                            isClearable={true}
                            name="selectCity"
                            value={formData?.values?.selectCity}
                            onChange={(e) => setFormData({
                                ...formData,
                                values: {
                                    ...formData?.values,
                                    selectCity: e,
                                },
                            })}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="formLabel" component='div'>Enter ZIP*</Typography>
                        <TextField
                            error={formData?.values?.zipCode?.length === 5 || formData?.values?.zipCode?.length === 0 || formData?.values?.zipCode === undefined ? false : true}
                            required
                            fullWidth
                            type="number"
                            size="small"
                            name="zipCode"
                            helperText={formData?.values?.zipCode?.length === 5 || formData?.values?.zipCode?.length === 0 || formData?.values?.zipCode === undefined ? "" : "Zip Code should be of 5 digits"}
                            value={formData?.values?.zipCode}
                            onChange={(e) => handleChange(e)}
                            onKeyDown={handlevalidate}
                            onInput={(e) => {
                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 5)
                            }}
                        /></Grid>
                    <Grid item xs={12}>
                        <DropZone btnText={"Upload company logo (Optional)"} setFiles={setUserlogo} files={userLogo} icon={browse} accept={acceptLogo} setFileError={setLogoFileError} />
                        {logoFileError && <Typography variant="errorMessage">{logoFileError}</Typography>}
                    </Grid>
                    <Grid item xs={12}>
                        <DropZone btnText={"W9 (Optional)"} setFiles={setUserWq} files={userWq} icon={browseUpload} accept={acceptW9} setFileError={setW9FileError} />
                        {w9FileError && <Typography variant="errorMessage">{w9FileError}</Typography>}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="formLabel" component='div'>Website link (Optional)</Typography>
                        <TextField
                            required
                            size="small"
                            fullWidth
                            name="websiteLink"
                            value={formData?.values?.websiteLink}
                            onChange={(e) => handleChange(e)}
                        /></Grid>
                    <Grid item xs={12}>
                        <Typography variant="formLabel" component='div'>What is the size of your company?*</Typography>
                        <Select
                            maxMenuHeight={250}
                            styles={customStyles}
                            options={sizeOptions}
                            required
                            classNamePrefix="standardSelect"
                            placeholder={<Typography variant="formLabel">Select size</Typography>}
                            isSearchable={false}
                            isClearable={true}
                            name="selectCompanySize"
                            value={formData?.values?.selectCompanySize}
                            onChange={(e) => setFormData({
                                ...formData,
                                values: {
                                    ...formData?.values,
                                    selectCompanySize: e,
                                },
                            })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="formLabel" component='div'>Where did you hear about CollabSetGo?</Typography>
                        <Select
                            maxMenuHeight={250}
                            styles={customStyles}
                            options={hereAboutOptions}
                            required
                            classNamePrefix="standardSelect"
                            placeholder={<Typography variant="formLabel">Please select</Typography>}
                            isSearchable={false}
                            isClearable={true}
                            name="selectHearAbout"
                            value={formData?.values?.selectHearAbout}
                            onChange={(e) => setFormData({
                                ...formData,
                                values: {
                                    ...formData?.values,
                                    selectHearAbout: e,
                                },
                            })}
                        />
                    </Grid>
                    {formData?.values?.selectHearAbout?.value === "Other" &&
                        <Grid item xs={12}>
                            <Typography variant="formLabel" component='div'>Enter where did you hear about CollabSetGo</Typography>
                            <TextField
                                required
                                size="small"
                                fullWidth
                                name="userHearAbout"
                                value={formData?.values?.userHearAbout}
                                onChange={(e) => handleChange(e)}
                            /></Grid>}
                    <Grid item xs={12} sx={{ mt: '20px' }}>
                        <Button disabled={!nextButtonEnable} variant="contained" fullWidth onClick={() => {
                            handleFormChange()
                            setValue(1)
                        }}>Next</Button>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', mt: "40px", alignItems: "center" }}>
                        <Typography variant="body1" sx={{ mr: "8px" }}>Already have an account? </Typography>
                        <Typography variant="subtitle2" ><span onClick={() => navigate("/")} style={{ textDecoration: "underline", cursor: "pointer" }}>Click here</span> <span style={{ color: palette.text.main }}>to Login</span></Typography>
                    </Grid>
                </Grid>
            </Scrollbars>
        </Box>
    )
}