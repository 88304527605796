import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const comprehensiveAssessmentApi = createApi({
  reducerPath: 'comprehensiveAssessmentApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: `${developmentApi}/api`,
  }),
  tagTypes: ['getExecutiveSummary', 'getAllActionPlan', 'getDemoCompSummary', 'getHomeCompSummary',
    'getBBCompSummary', 'getCareerCompSummary', 'getMentalCompSummary', 'getAllDiagnoComp'],
  endpoints: (builder) => ({
    addExecutiveSummary: builder.mutation({
      query: ({ payload }) => ({
        url: `/company/add-Executive-Summary`,
        method: 'POST',
        payload: payload
      }),
      invalidatesTags: ['getExecutiveSummary'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updateExecutiveSummary: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/update-Executive-Summary`,
        method: 'PUT',
        payload: payload
      }),
      invalidatesTags: ['getExecutiveSummary'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getExecutiveSummary: builder.query({
      query: ({ ClientId }) => ({
        url: `/company/get-Executive-Summary?ClientId=${ClientId}`,
        method: 'GET',
      }),
      providesTags: ['getExecutiveSummary'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    addActionPlan: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-Action-Plans?ClientId=${ClientId}`,
        method: 'POST',
        payload: payload,
      }),
      invalidatesTags: ['getAllActionPlan'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updateActionPlan: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/update-Action-Plans?id=${id}`,
        method: 'PUT',
        payload: payload,
      }),
      invalidatesTags: ['getAllActionPlan'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    getActionPlan: builder.query({
      query: ({ id }) => ({
        url: `/company/get-Action-Plans?id=${id}`,
        method: 'GET',
      }),
      providesTags: ['getAllActionPlan'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    getAllActionPlan: builder.query({
      query: ({ clientId }) => ({
        url: `/company/get-all-Action-Plans?clientId=${clientId}`,
        method: 'GET',
      }),
      providesTags: ['getAllActionPlan'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    deleteActionPlan: builder.mutation({
      query: ({ id }) => ({
        url: `/company/delete-Action-Plans-ById?id=${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['getAllActionPlan'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    addDemoCompSummary: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-demographic-summary-comprehensive?ClientId=${ClientId}`,
        method: 'POST',
        payload: payload
      }),
      invalidatesTags: ['getDemoCompSummary'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updateDemoCompSummary: builder.mutation({
      query: ({ params, payload }) => ({
        url: `/company/update-demographic-summary-comprehensive`,
        method: 'PUT',
        payload: payload,
        params: params
      }),
      invalidatesTags: ['getDemoCompSummary'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getDemoCompSummary: builder.query({
      query: ({ ClientId }) => ({
        url: `/company/get-demographic-summary-comprehensive?ClientId=${ClientId}`,
        method: 'GET',
      }),
      providesTags: ['getDemoCompSummary'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    addHomeCompSummary: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-home-summary-comprehensive?ClientId=${ClientId}`,
        method: 'POST',
        payload: payload
      }),
      invalidatesTags: ['getHomeCompSummary'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updateHomeCompSummary: builder.mutation({
      query: ({ params, payload }) => ({
        url: `/company/update-home-summary-comprehensive`,
        method: 'PUT',
        payload: payload,
        params: params
      }),
      invalidatesTags: ['getHomeCompSummary'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getHomeCompSummary: builder.query({
      query: ({ ClientId }) => ({
        url: `/company/get-home-summary-comprehensive?ClientId=${ClientId}`,
        method: 'GET',
      }),
      providesTags: ['getHomeCompSummary'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    addBBCompSummary: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-bb-summary-comprehensive?ClientId=${ClientId}`,
        method: 'POST',
        payload: payload
      }),
      invalidatesTags: ['getBBCompSummary'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updateBBCompSummary: builder.mutation({
      query: ({ params, payload }) => ({
        url: `/company/update-bb-summary-comprehensive`,
        method: 'PUT',
        payload: payload,
        params: params
      }),
      invalidatesTags: ['getBBCompSummary'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getBBCompSummary: builder.query({
      query: ({ clientId }) => ({
        url: `/company/get-bb-summary-comprehensive?clientId=${clientId}`,
        method: 'GET',
      }),
      providesTags: ['getBBCompSummary'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    addCareerCompSummary: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-career-summary-comprehensive?ClientId=${ClientId}`,
        method: 'POST',
        payload: payload
      }),
      invalidatesTags: ['getCareerCompSummary'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updateCareerCompSummary: builder.mutation({
      query: ({ params, payload }) => ({
        url: `/company/update-career-summary-comprehensive`,
        method: 'PUT',
        payload: payload,
        params: params
      }),
      invalidatesTags: ['getCareerCompSummary'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getCareerCompSummary: builder.query({
      query: ({ clientId }) => ({
        url: `/company/get-career-summary-comprehensive?clientId=${clientId}`,
        method: 'GET',
      }),
      providesTags: ['getCareerCompSummary'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    addMentalCompSummary: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-mental-summary-comprehensive?ClientId=${ClientId}`,
        method: 'POST',
        payload: payload
      }),
      invalidatesTags: ['getMentalCompSummary'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updateMentalCompSummary: builder.mutation({
      query: ({ params, payload }) => ({
        url: `/company/update-mental-summary-comprehensive`,
        method: 'PUT',
        payload: payload,
        params: params
      }),
      invalidatesTags: ['getMentalCompSummary'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getMentalCompSummary: builder.query({
      query: ({ ClientId }) => ({
        url: `/company/get-mental-summary-comprehensive?ClientId=${ClientId}`,
        method: 'GET',
      }),
      providesTags: ['getMentalCompSummary'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    addEmploymentCompSummary: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-employment-comprehensive?ClientId=${ClientId}`,
        method: 'POST',
        payload: payload
      }),
      invalidatesTags: ['getEmploymentCompSummary'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updateEmploymentCompSummary: builder.mutation({
      query: ({ params, payload }) => ({
        url: `/company/update-employment-comprehensive`,
        method: 'PUT',
        payload: payload,
        params: params
      }),
      invalidatesTags: ['getEmploymentCompSummary'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getEmploymentCompSummary: builder.query({
      query: ({ clientId }) => ({
        url: `/company/get-employment-comprehensive?clientId=${clientId}`,
        method: 'GET',
      }),
      providesTags: ['getEmploymentCompSummary'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    addDiagnoComp: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-Diagnostic-Impressions-Comprehensive?ClientId=${ClientId}`,
        method: 'POST',
        payload: payload,
      }),
      invalidatesTags: ['getAllDiagnoComp'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updateDiagnoComp: builder.mutation({
      query: ({ params, payload }) => ({
        url: `/company/update-Diagnostic-Impressions-Comprehensive`,
        method: 'PUT',
        payload: payload,
        params: params
      }),
      invalidatesTags: ['getAllDiagnoComp'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    getDiagnoComp: builder.query({
      query: (params) => ({
        url: `/company/get-Diagnostic-Impressions-Comprehensive`,
        method: 'GET',
        params: params
      }),
      providesTags: ['getAllDiagnoComp'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    getAllDiagnoComp: builder.query({
      query: ({ clientId }) => ({
        url: `/company/get-all-Diagnostic-Impressions-Comprehensive?clientId=${clientId}`,
        method: 'GET',
      }),
      providesTags: ['getAllDiagnoComp'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    deleteDiagnoComp: builder.mutation({
      query: (params) => ({
        url: `/company/delete-Diagnostic-Impressions-Comprehensive`,
        method: 'DELETE',
        params: params
      }),
      invalidatesTags: ['getAllDiagnoComp'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
  }),
})

export const { useAddExecutiveSummaryMutation, useUpdateExecutiveSummaryMutation, useGetExecutiveSummaryQuery,
  useAddActionPlanMutation, useUpdateActionPlanMutation, useGetActionPlanQuery, useGetAllActionPlanQuery, useDeleteActionPlanMutation,
  useAddDemoCompSummaryMutation, useUpdateDemoCompSummaryMutation, useGetDemoCompSummaryQuery,
  useAddHomeCompSummaryMutation, useUpdateHomeCompSummaryMutation, useGetHomeCompSummaryQuery,
  useAddBBCompSummaryMutation, useUpdateBBCompSummaryMutation, useGetBBCompSummaryQuery,
  useAddCareerCompSummaryMutation, useUpdateCareerCompSummaryMutation, useGetCareerCompSummaryQuery,
  useAddMentalCompSummaryMutation, useUpdateMentalCompSummaryMutation, useGetMentalCompSummaryQuery,
  useAddEmploymentCompSummaryMutation, useUpdateEmploymentCompSummaryMutation, useGetEmploymentCompSummaryQuery,
  useAddDiagnoCompMutation, useUpdateDiagnoCompMutation, useGetDiagnoCompQuery, useGetAllDiagnoCompQuery, useDeleteDiagnoCompMutation } = comprehensiveAssessmentApi;
