import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const clientApi = createApi({
  reducerPath: 'clientApiReducer1',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: `${developmentApi}/api`,
  }),
  tagTypes: ['geClientList', 'getServicesById'],
  endpoints: (builder) => ({
    addClient: builder.mutation({
      query: ({ payload }) => ({
        url: `/company/add-client`,
        method: 'POST',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ["geClientList"],
      transformResponse: (response) => JSON.parse(response)
    }),
    getClientList: builder.query({
      query: (params) => ({
        url: `/company/get-clients`,
        method: 'GET',
        params: params
      }),
      providesTags: ["geClientList"],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    getClientBy: builder.query({
      query: ({ id }, params) => ({
        url: `/company/get-client-by-id?id=${id}`,
        method: 'GET', params: params
      }),
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    getServicesById: builder.query({
      query: (params) => ({
        url: `/company/get-client-services`,
        method: 'GET',
        params: params

      }),
      providesTags: ['getServicesById'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    updateClient: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/update-client-info?id=${id}`,
        method: 'PUT',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ["geClientList"],
      transformResponse: (response) => JSON.parse(response)
    }),
    clientSetPassword: builder.mutation({
      query: ({ payload }) => ({
        url: `/client/setpassword`,
        method: 'POST',
        payload: payload
      }),
      transformResponse: (response) => JSON.parse(response)
    }),
    addCustomerServices: builder.mutation({
      query: ({ payload }) => ({
        url: `/company/add-customer-required-service`,
        method: 'POST',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ['getServicesById'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updateCustomerServices: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/edit-customer-required-service?id=${id}`,
        method: 'POST',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ['getServicesById'],
      transformResponse: (response) => JSON.parse(response)
    }),
    searchClient: builder.mutation({
      query: ({ payload }) => ({
        url: `/company/search-clients`,
        method: 'POST',
        payload: payload,
      }),
      transformResponse: (response) => JSON.parse(response)
    }),

    getServices: builder.query({
      query: (params) => ({
        url: `/company/get-services`,
        method: 'GET',
        params: params

      }),
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    getclientProfile: builder.query({
      query: ({ clientid }) => ({
        url: `/Client/GetClientProfile/${clientid}`,
        method: 'GET',

      }),
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    resendInviteLink: builder.mutation({
      query: ({ payload }) => ({
        url: `/Client/ResendInvite`,
        method: 'POST',
        payload: payload,
      }),

      transformResponse: (response) => JSON.parse(response)
    }),
    updateClientAssignment: builder.mutation({
      query: ({ payload }) => ({
        url: `/Client/UpdateAssignedToIntakeStatus/v1`,
        method: 'POST',
        payload: payload
      }),
      transformResponse: (response) => JSON.parse(response)
    }),
    getStaffListAssignment: builder.query({
      query: (params) => ({
        url: `/company/get-staff-members/v1`,
        method: 'GET',
        params: params
      }),
      transformResponse: (response) => JSON.parse(response)?.data
    }),



  }),
})

export const { useAddClientMutation, useGetClientByQuery, useGetClientListQuery, useGetServicesQuery, useUpdateClientMutation, useClientSetPasswordMutation,
  useAddCustomerServicesMutation, useUpdateCustomerServicesMutation, useSearchClientMutation,
  useGetServicesByIdQuery, useGetclientProfileQuery, useResendInviteLinkMutation, useUpdateClientAssignmentMutation
  , useGetStaffListAssignmentQuery } = clientApi;
