import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const careerEmploymentApi = createApi({
    reducerPath: 'careerEmploymentApi',
    refetchOnMountOrArgChange: true,
    keepUnusedDataFor: 0,
    refetchOnReconnect: true,
    baseQuery: AxiosBaseQuery({
        baseUrl: `${developmentApi}/api`,
    }),
    tagTypes: ['getCareerInterestInfo', 'getAllTopThreeJob', 'getCareerScope', 'getResume', 'getAdditonalInfo'],
    endpoints: (builder) => ({
        addCareerInterest: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-career-interest-info?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getCareerInterestInfo'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateCareerInterest: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-career-interest-info?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getCareerInterestInfo'],
            transformResponse: (response) => JSON.parse(response)
        }),
        getCareerInterestInfo: builder.query({
            query: ({ id }) => ({
                url: `/company/get-career-interest-info?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['getCareerInterestInfo'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addTopThreeJob: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-top-three-job-interest?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getAllTopThreeJob'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateTopThreeJob: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-top-three-job-interest?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getAllTopThreeJob'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getTopThreeJob: builder.query({
            query: ({ id }) => ({
                url: `/company/get-top-three-job-interest?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['getAllTopThreeJob'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getAllTopThreeJob: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-all-top-three-job-interest?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getAllTopThreeJob'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        deleteTopThreeJob: builder.mutation({
            query: ({ id }) => ({
                url: `/company/delete-top-three-job-interest?id=${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['getAllTopThreeJob'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addCareerScope: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-career-scope-testing?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getCareerScope'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateCareerScope: builder.mutation({
            query: ({ id, clientId, payload }) => ({
                url: `/company/update-career-scope-testing?id=${id}&clientId=${clientId}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getCareerScope'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getCareerScope: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-career-scope-testing?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getCareerScope'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addResume: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-resume?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getResume'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateResume: builder.mutation({
            query: ({ id,clientId, payload }) => ({
                url: `/company/update-resume?id=${id}&clientId=${clientId}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getResume'],
            transformResponse: (response) => JSON.parse(response)
        }),
        getResume: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-resume?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getResume'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addCareerEmploymentAdditionalInfo: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-career-scope-additional-info?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getAdditonalInfo'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateCareerEmploymentAdditionalInfo: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-career-scope-additional-info?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getAdditonalInfo'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getCareerEmploymentAdditionalInfo: builder.query({
            query: ({ id }) => ({
                url: `/company/get-career-scope-additional-info?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['getAdditonalInfo'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addEmploymentSupport: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-employment-supports?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getAdditonalInfo'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateEmploymentSupport: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-employment-supports?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getAdditonalInfo'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getEmploymentSupport: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-employment-supports?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getAdditonalInfo'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
    }),
})

export const { useAddCareerInterestMutation, useUpdateCareerInterestMutation, useGetCareerInterestInfoQuery,
    useAddTopThreeJobMutation, useUpdateTopThreeJobMutation, useGetTopThreeJobQuery, useGetAllTopThreeJobQuery, useDeleteTopThreeJobMutation,
    useAddCareerScopeMutation, useUpdateCareerScopeMutation, useGetCareerScopeQuery,
    useAddResumeMutation, useUpdateResumeMutation, useGetResumeQuery,
    useAddCareerEmploymentAdditionalInfoMutation, useUpdateCareerEmploymentAdditionalInfoMutation, useGetCareerEmploymentAdditionalInfoQuery,
    useAddEmploymentSupportMutation, useUpdateEmploymentSupportMutation, useGetEmploymentSupportQuery } = careerEmploymentApi;
