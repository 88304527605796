import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const mentalHealthReportApi = createApi({
  reducerPath: 'mentalHealthReportApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: `${developmentApi}/api`,
  }),
  tagTypes: ['getAssignedToIntakeStatus', 'getFinalReport', 'getClearHomeHousing'],
  endpoints: (builder) => ({
    getMentalHealthVoucher: builder.query({
      query: ({ clientId }) => ({
        url: `/company/mental-health-voucher-report?clientId=${clientId}`,
        method: 'GET',
      }),
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    getMentalHealthDetailed: builder.query({
      query: ({ clientId }) => ({
        url: `/company/mental-health-detailed-report?clientId=${clientId}`,
        method: 'GET',
      }),
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    getMasterComprehensive: builder.query({
      query: ({ clientId }) => ({
        url: `/company/Master-Comprehensive-Report?clientId=${clientId}`,
        method: 'GET',
      }),
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    getServiceElementStatus: builder.query({
      query: ({masterServiceId, clientId}) => ({
        url: `/Client/GetServiceElementStatus/${masterServiceId}/${clientId}`,
        method: 'GET',
      }),
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    updateAssigmentStatus: builder.mutation({
      query: ({ payload }) => ({
        url: `/Client/UpdateAssignedToIntakeStatus`,
        method: 'POST',
        payload: payload
      }),
      invalidatesTags: ['getAssignedToIntakeStatus'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getAssignedToIntakeStatus: builder.query({
      query: (params) => ({
        url: `/client/GetAssignedToIntakeStatus`,
        method: 'GET',
        params: params
      }),
      providesTags: ['getAssignedToIntakeStatus'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    addFinalReport: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-Final-Submitted-Report?ClientId=${ClientId}`,
        method: 'POST',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ['getFinalReport'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getFinalReport: builder.query({
      query: ({ ClientId }) => ({
        url: `/company/get-Final-Submitted-Report?ClientId=${ClientId}`,
        method: 'GET',
      }),
      providesTags: ['getFinalReport'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    updateFinalReport: builder.mutation({
      query: ({ id, ClientId, payload }) => ({
        url: `/company/update-Final-Submitted-Report?id=${id}&ClientId=${ClientId}`,
        method: 'PUT',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ['getFinalReport'],
      transformResponse: (response) => JSON.parse(response)
    }),
    addClearHomeHousing: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-Home-Housing-Assessment?ClientId=${ClientId}`,
        method: 'POST',
        payload: payload,
      }),
      invalidatesTags: ['getClearHomeHousing'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getClearHomeHousing: builder.query({
      query: ({ ClientId }) => ({
        url: `/company/get-Home-Housing-Assessment?ClientId=${ClientId}`,
        method: 'GET',
      }),
      providesTags: ['getClearHomeHousing'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    updateClearHomeHousing: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/update-Home-Housing-Assessment?id=${id}`,
        method: 'PUT',
        payload: payload,
      }),
      invalidatesTags: ['getClearHomeHousing'],
      transformResponse: (response) => JSON.parse(response)
    }),
  }),
})

export const { useGetMentalHealthVoucherQuery, useGetMentalHealthDetailedQuery, useGetMasterComprehensiveQuery, useGetServiceElementStatusQuery, useUpdateAssigmentStatusMutation,
  useGetAssignedToIntakeStatusQuery, useAddFinalReportMutation, useGetFinalReportQuery, useUpdateFinalReportMutation,
  useGetClearHomeHousingQuery, useAddClearHomeHousingMutation, useUpdateClearHomeHousingMutation } = mentalHealthReportApi;
