import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const clientInterviewApi = createApi({
  reducerPath: 'clientInterviewApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: `${developmentApi}/api`,
  }),
  tagTypes: ['getHousingSituation', 'getHousingReadliness','getAccessibility'],
  endpoints: (builder) => ({
    addHousingSituation: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-current-housing-situation-info?ClientId=${ClientId}`,
        method: 'POST',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ['getHousingSituation'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updateHousingSituation: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/update-current-housing-situation-info?id=${id}`,
        method: 'PUT',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ['getHousingSituation'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getHousingSituation: builder.query({
      query: ({ clientId }) => ({
        url: `/company/get-current-housing-situation-info?clientId=${clientId}`,
        method: 'GET',
      }),
      providesTags: ['getHousingSituation'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    addHousingReadliness: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-housing-readiness-question?ClientId=${ClientId}`,
        method: 'POST',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ['getHousingReadliness'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updateHousingReadliness: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/update-housing-readiness-question?id=${id}`,
        method: 'PUT',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ['getHousingReadliness'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getHousingReadliness: builder.query({
      query: ({ clientId }) => ({
        url: `/company/get-housing-readiness-question?clientId=${clientId}`,
        method: 'GET',
      }),
      providesTags: ['getHousingReadliness'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    addAccessibility: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-accessibility?ClientId=${ClientId}`,
        method: 'POST',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ['getAccessibility'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updateAccessibility: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/update-accessibility?id=${id}`,
        method: 'PUT',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ['getAccessibility'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getAccessibility: builder.query({
      query: ({ clientId }) => ({
        url: `/company/get-accessibility?clientId=${clientId}`,
        method: 'GET',
      }),
      providesTags: ['getAccessibility'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
  }),
})

export const { useAddHousingSituationMutation, useUpdateHousingSituationMutation, useGetHousingSituationQuery,
  useAddHousingReadlinessMutation, useUpdateHousingReadlinessMutation, useGetHousingReadlinessQuery,
  useAddAccessibilityMutation, useUpdateAccessibilityMutation, useGetAccessibilityQuery} = clientInterviewApi;
