import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const financialApi = createApi({
    reducerPath: 'financialApi',
    refetchOnMountOrArgChange: true,
    keepUnusedDataFor: 0,
    refetchOnReconnect: true,
    baseQuery: AxiosBaseQuery({
        baseUrl: `${developmentApi}/api`,
    }),
    tagTypes: ['getAllclientIncome'],
    endpoints: (builder) => ({
        addClientIncome: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-client-income?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ["getAllclientIncome"],
            transformResponse: (response) => JSON.parse(response)
        }),
        EditClientIncome: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-client-income?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ["getAllclientIncome"],
            transformResponse: (response) => JSON.parse(response)
        }),
        getAllClientIncome: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-all-client-income?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ["getAllclientIncome"],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getClientIncomeById: builder.query({
            query: ({ id }) => ({
                url: `/company/get-client-income?id=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ["getAllclientIncome"],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        deleteClientIncome: builder.mutation({
            query: ({ id }) => ({
                url: `/company/delete-client-income?id=${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["getAllclientIncome"],
            transformResponse: (response) => JSON.parse(response)
        }),
        addBenefits: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-benefit-detail?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ["getAllbenefits"],
            transformResponse: (response) => JSON.parse(response)
        }),
        EditBenefits: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-benefit-detail?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ["getAllbenefits"],
            transformResponse: (response) => JSON.parse(response)
        }),
        getAllBenefits: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-all-benefit-detail?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ["getAllbenefits"],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getBenefitsById: builder.query({
            query: ({ id }) => ({
                url: `/company/get-benefit-detail?id=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ["getAllbenefits"],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        deleteBenefits: builder.mutation({
            query: ({ id }) => ({
                url: `/company/delete-benefit-detail?id=${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["getAllbenefits"],
            transformResponse: (response) => JSON.parse(response)
        }),
        addHealthInsurance: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-health-insurance-type?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ["getAllHealthInsurance"],
            transformResponse: (response) => JSON.parse(response)
        }),
        EditHealthInsurance: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-health-insurance-type?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ["getAllHealthInsurance"],
            transformResponse: (response) => JSON.parse(response)
        }),
        getAllHealthInsurance: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-all-health-insurance-type?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ["getAllHealthInsurance"],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getHealthInsuranceById: builder.query({
            query: ({ id }) => ({
                url: `/company/get-health-insurance-type?id=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ["getAllHealthInsurance"],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        deleteHealthInsurance: builder.mutation({
            query: ({ id }) => ({
                url: `/company/delete-health-insurance-type?id=${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["getAllHealthInsurance"],
            transformResponse: (response) => JSON.parse(response)
        }),
    }),
})

export const { useAddClientIncomeMutation, useEditClientIncomeMutation, useGetAllClientIncomeQuery, useGetClientIncomeByIdQuery, useDeleteClientIncomeMutation,
    useAddBenefitsMutation, useEditBenefitsMutation, useGetAllBenefitsQuery, useGetBenefitsByIdQuery, useDeleteBenefitsMutation,
    useAddHealthInsuranceMutation, useEditHealthInsuranceMutation, useGetAllHealthInsuranceQuery, useGetHealthInsuranceByIdQuery, useDeleteHealthInsuranceMutation } = financialApi;
