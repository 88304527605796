import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const careerComprehensiveApi = createApi({
  reducerPath: 'careerComprehensiveApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: `${developmentApi}/api`,
  }),
  tagTypes:['getComprehensive'],
  endpoints: (builder) => ({
    addComprehensive: builder.mutation({
      query: ({ClientId, payload})=>({
        url:`/company/add-career-comprehensive-report-summery?ClientId=${ClientId}`,
        method:'POST',
        fileUpload:true,
        payload:payload
      }),
      invalidatesTags:['getComprehensive'],
      transformResponse:(response)=> JSON.parse(response)?.data
    }),
    updateComprehensive: builder.mutation({
      query: ({id, payload})=>({
        url:`/company/update-career-comprehensive-report-summery?id=${id}`,
        method:'PUT',
        fileUpload:true,
        payload:payload
      }),
      invalidatesTags:['getComprehensive'],
      transformResponse:(response)=> JSON.parse(response)?.data
    }),
    getComprehensive: builder.query({
        query: ({id})=>({
          url:`/company/get-career-comprehensive-report-summery?id=${id}`,
          method:'GET',
        }),
        providesTags:['getComprehensive'],
        transformResponse:(response)=> JSON.parse(response)?.data
      }),
  }),
})

export const {useAddComprehensiveMutation, useUpdateComprehensiveMutation, useGetComprehensiveQuery}=careerComprehensiveApi;
