import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const benefitsApi = createApi({
  reducerPath: 'benefitsApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: `${developmentApi}/api`,
  }),
  endpoints: (builder) => ({
    getBenefitVoucher: builder.query({
      query: ({clientId}) => ({
        url: `/company/benefits-budget-summery-report?clientId=${clientId}`,
        method: 'GET',
      }),
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    getBenefitDetailed: builder.query({
      query: ({ clientId }) => ({
        url: `/company/benefits-budget-detail-report?clientId=${clientId}`,
        method: 'GET',
      }),
      transformResponse: (response) => JSON.parse(response)?.data
    }),
  }),
})

export const { useGetBenefitVoucherQuery, useGetBenefitDetailedQuery } = benefitsApi;
