import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const observationApi = createApi({
  reducerPath: 'observationApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: `${developmentApi}/api`,
  }),
  tagTypes:['getObservation'],
  endpoints: (builder) => ({
    addObservation: builder.mutation({
        query: ({ ClientId, payload }) => ({
            url: `/company/add-observation?ClientId=${ClientId}`,
            method: 'POST',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags: ['getObservation'],
        transformResponse: (response) => JSON.parse(response)
    }),
    updateObservation: builder.mutation({
        query: ({ id, payload }) => ({
            url: `/company/update-observation?id=${id}`,
            method: 'PUT',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags: ['getObservation'],
        transformResponse: (response) => JSON.parse(response)
    }),
    getObservation: builder.query({
        query: ({ id }) => ({
            url: `/company/get-observation?id=${id}`,
            method: 'GET',
        }),
        providesTags: ['getObservation'],
        transformResponse: (response) => JSON.parse(response)?.data
    }),
  }),
})

export const {useAddObservationMutation, useUpdateObservationMutation,useGetObservationQuery}=observationApi;
