import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const casenotesApi = createApi({
  reducerPath: 'casenotesApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: `${developmentApi}/api`,
  }),
  tagTypes: ['caseNotesList'],
  endpoints: (builder) => ({
    getCaseNotes: builder.query({
      query: ({ id, params }) => ({
        url: `/CaseNotes/casenotes/${id}`,
        method: 'GET',
        params: params
      }),
      providesTags: ['caseNotesList'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getCaseNoteById: builder.query({
      query: ({ clientId, caseNoteId }) => ({
        url: `/CaseNotes/casenotes/${clientId}/${caseNoteId}`,
        method: 'GET',
      }),
      invalidatesTags: ['caseNotesList'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    AddCaseNotes: builder.mutation({
      query: ({ payload }) => ({
        url: `/CaseNotes/casenotes`,
        method: 'POST',
        payload: payload
      }), invalidatesTags: ['caseNotesList'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    deleteCaseNotes: builder.mutation({
      query: ({ id }) => ({
        url: `/CaseNotes/delete-case-notes?id=${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['caseNotesList'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    deleteSubCaseNote: builder.mutation({
      query: ({ CaseNoteId, SubCaseNoteId }) => ({
        url: `/CaseNotes/delete-Sub-Case-note?CaseNoteId=${CaseNoteId}&SubCaseNoteId=${SubCaseNoteId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['caseNotesList'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    getGoals: builder.query({
      query: ({ params}) => ({
        url: `/company/get-Service-Goals`,
        method: 'GET',
        params: params
      }),
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    getAssignments: builder.query({
      query: ({ params }) => ({
        url: `/company/get-Service-Assignments`,
        method: 'GET',
        params: params
      }),
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    getGoalsByCompanyID: builder.query({
      query: ({ params}) => ({
        url: `/company/get-Service-Goals-By-Company`,
        method: 'GET',
        params: params
      }),
      transformResponse: (response) => JSON.parse(response)?.data
    }),
  }),
})

export const { useGetCaseNotesQuery, useGetCaseNoteByIdQuery, useAddCaseNotesMutation, useDeleteCaseNotesMutation, useDeleteSubCaseNoteMutation,
  useGetGoalsQuery, useGetAssignmentsQuery, useGetGoalsByCompanyIDQuery } = casenotesApi;
