import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const summaryApi = createApi({
  reducerPath: 'summaryApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: `${developmentApi}/api`,
  }),
  tagTypes:['getSummary'],
  endpoints: (builder) => ({
    addSummary: builder.mutation({
        query: ({ ClientId, payload }) => ({
            url: `/company/add-demographic-report-summery?ClientId=${ClientId}`,
            method: 'POST',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags: ['getSummary'],
        transformResponse: (response) => JSON.parse(response)
    }),
    updateSummary: builder.mutation({
        query: ({ id, payload }) => ({
            url: `/company/update-demographic-report-summery?id=${id}`,
            method: 'PUT',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags: ['getSummary'],
        transformResponse: (response) => JSON.parse(response)
    }),
    getSummary: builder.query({
        query: ({ id }) => ({
            url: `/company/get-demographic-report-summery?id=${id}`,
            method: 'GET',
        }),
        providesTags: ['getSummary'],
        transformResponse: (response) => JSON.parse(response)?.data
    }),
  }),
})

export const {useAddSummaryMutation, useUpdateSummaryMutation,useGetSummaryQuery}=summaryApi;
