import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  CircularProgress
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Scrollbars from "react-custom-scrollbars-2";
import { useNavigate } from "react-router-dom";
import palette from "../../../../../theme/palette";
import { useRegisterMutation } from "../../../../../store/services/authServices";
import { toast } from 'react-toastify';
import { positionOptions } from "../../../../../constant";
export default function SecondRegisterForm({
  formData,
  setFormData,
  userWq,
  userLogo,
  // setValue
}) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  /* The above code is using the `useRegisterMutation` hook to create a `userRegister` variable and a
  `isLoading` variable. The `userRegister` variable is a function that can be called to register a
  user, while the `isLoading` variable is a boolean that indicates whether the registration process
  is currently loading. */
  const [userRegister, { isLoading }] = useRegisterMutation({});

  /**
   * The function toggles the visibility of either the user password or the confirm password based on
   * the parameter passed.
   * @param param - The parameter `param` is a string that is used to determine which password field to
   * toggle the visibility of. If `param` is equal to "userPassword", then the visibility of the user's
   * password will be toggled. If `param` is equal to "RePassword", then the visibility
   */

  const handleClickShowPassword = (param) => {
    param === "userPassword" &&
      setShowPassword({
        password: !showPassword.password,
        confirmPassword: showPassword.confirmPassword,
      });
    param === "RePassword" &&
      setShowPassword({
        password: showPassword.password,
        confirmPassword: !showPassword.confirmPassword,
      });
  };

  /* The above code is defining an object called `customStyles` which contains three properties:
  `dropdownIndicator`, `indicatorSeparator`, and `container`. */
  const customStyles = {
    dropdownIndicator: (base) => ({
      ...base,
      color: palette.text.light,
      flex: "1",
      width: "100%",
    }),
    indicatorSeparator: () => null,
    container: (base) => ({ ...base, flex: "1", width: "100%" }),
  };

  /**
   * This function handles changes in form input values and updates the form data and error messages
   * accordingly.
   * @param e - The parameter `e` is an event object that is passed to the `handleChange` function as
   * an argument. It contains information about the event that triggered the function, such as the
   * target element, the type of event, and the value of the element.
   */
  const handleChange = (e) => {

    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()\-=^]).{8,20}$/;

    let { name, value } = e.target;
    if (e.target.type === "checkbox") {
      name = e.target.name;
      value = e.target.checked;
      setFormData({
        ...formData,
        values: {
          ...formData?.values,
          [name]: value,
        },
      });
    }
    else if (e.target.name === "userPassword") {
      setFormData({
        ...formData,
        values: {
          ...formData?.values,
          [name]: value,
        },
        error: {
          ...formData?.error,
          userPasswordError: (passwordRegex.test(e.target.value) ? "" : "Password should have 8-20 characters, 1 digit, 1 uppercase letter, 1 lowercase letter, 1 special character (!@#$%&*()-+=^), no white space."),
        },
      });
    }
    else {
      setFormData({
        ...formData,
        values: {
          ...formData?.values,
          [name]: value,
        },
      });
    }
  };
  /**
   * The function prevents the input of certain characters (E, - and +) in a text field.
   * @param e - The parameter "e" is an event object that is passed to the function "handlevalidate".
   * It contains information about the event that triggered the function, such as the type of event
   * (e.g. keydown), the target element, and any additional data related to the event (e.g. the
   */
  const handlevalidate = (e) => {
    if (e.keyCode === 69 || e.keyCode === 189 || e.keyCode === 187) {
      e.preventDefault();
    }
  };

  const handlevalidateSpace = (e) => {
    if (e.keyCode === 32) {
      e.preventDefault();
    }
  };

  /* The above code is a React `useEffect` hook that is triggered whenever the
  `formData.values.userPassword` or `formData.values.RePassword` values change. It checks if the
  `userPassword` value matches a specific regular expression pattern for a strong password (minimum
  eight characters, at least one letter, one number, and one special character) and sets an error
  message if it doesn't match. It also checks if the `RePassword` value matches the `userPassword`
  value and sets an error message if it doesn't match. If either of these values is not present, it
  clears the */

  useEffect(() => {
    if (formData?.values?.RePassword !== "") {
      setFormData({
        ...formData,
        error: {
          ...formData?.error,
          RePasswordError: (formData?.values?.userPassword === formData?.values?.RePassword ? "" : "Confirm Password not matched")
        }
      })
    }
    else {
      setFormData({
        ...formData,
        error: {
          ...formData?.error,
          RePasswordError: "",
        },
      });
    }
  }, [formData?.values?.userPassword, formData?.values?.RePassword]);

  /* The above code is checking if all the required fields in a form are filled out correctly and if
  the user has agreed to the terms and conditions. If all the conditions are met, then the submit
  button is enabled. */
  const submitButtomEnable =
    formData?.values?.contactPersonName &&
    formData?.values?.mobileNumber &&
    formData?.values?.officePhoneNumber &&
    formData?.values?.selectPosition &&
    formData?.values?.userPassword &&
    formData?.values?.RePassword &&
    formData?.error?.RePasswordError === "" &&
    formData?.values?.userAgreeCheck === true &&
    formData?.values?.companyName && formData?.values?.emailAddress && formData?.values?.officeNumber && formData?.values?.displayName &&
    formData?.values?.einNumber && formData?.values?.companyAddress && formData?.values?.zipCode && formData?.values?.selectState &&
    formData?.values?.selectCity && formData?.error?.emailAddressError === "" && formData?.error?.userPasswordError === ""

  /**
   * This function handles the submission of a form with various input fields and sends the data as a
   * payload to an API for user registration.
   */
  const handleSubmit = () => {
    const payload = {
      Role: "Company",
      Email: formData?.values?.emailAddress || null,
      Password: formData?.values?.userPassword || null,
      CompanyName: formData?.values?.companyName || null,
      OfficeNumber: formData?.values?.officeNumber || null,
      BusinessName: formData?.values?.displayName || null,
      EIN: formData?.values?.einNumber || null,
      CompanyAddress: formData?.values?.companyAddress || null,
      CompanyState: formData?.values?.selectState?.value || null,
      CompanyCity: formData?.values?.selectCity?.value || null,
      CompanyZip: formData?.values?.zipCode || null,
      CompanyLogoImage: userLogo?.length > 0 ? userLogo[0] : null,
      W9File: userWq?.length > 0 ? userWq[0] : null,
      WebsiteLink: formData?.values?.websiteLink || null,
      CompanySize: formData?.values?.selectCompanySize?.value || null,
      HearAboutCollebsetgo: formData?.values?.selectHearAbout?.value || null,
      ContactPersonDetail: formData?.values?.contactPersonName || null,
      ContactPersonMobile: formData?.values?.mobileNumber || null,
      ContactPersonOfficePhoneNumber: formData?.values?.officePhoneNumber || null,
      ContactPersonPosition: formData?.values?.selectPosition?.value || null,
      OtherContactPersonPosition: formData?.values?.enterPosition,
      SecContactPersonDetail: formData?.values?.secondaryContactPersonName || null,
      SecContactPersonMobile: formData?.values?.secondaryMobileNumber || null,
      SecContactPersonOfficePhoneNumber: formData?.values?.SecondaryOfficePhoneNumber || null,
      SecContactPersonPosition: formData?.values?.SecondarySelectPosition?.value || null,
      OtherSecContactPersonPosition: formData?.values?.enterSecondaryPosition,
      OtherHearAboutCollebsetgo: formData?.values?.userHearAbout || null
    }
    userRegister({ payload }).unwrap()
      .then((response) => {
        toast.success(response?.message)
        navigate("/emailverify", { state: { data: formData?.values?.emailAddress } })
      })
      .catch((error) => {
        console.log(error?.message)
      })
  }
  return (
    <Box>
      <Scrollbars autoHeight autoHeightMax={"calc(100vh - 260px)"}>
        <Grid container spacing={2} sx={{ pr: "15px", pl: '1px' }}>
          <Grid item xs={12}>
            <Typography variant="h4" sx={{ m: "1.3rem 0 .6rem 0" }}>Contact Person's Details</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="formLabel" component="div">
              Contact person's name*
            </Typography>
            <TextField
              required
              fullWidth
              id="outlined-error-helper-text"
              size="small"
              name="contactPersonName"
              value={formData?.values?.contactPersonName}
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="formLabel" component="div">
              Mobile number*
            </Typography>
            <TextField
              error={formData?.values?.mobileNumber?.length === 10 || formData?.values?.mobileNumber?.length === 0 || formData?.values?.mobileNumber === undefined ? false : true}
              required
              fullWidth
              type={'number'}
              id="outlined-error-helper-text"
              size="small"
              name="mobileNumber"
              helperText={formData?.values?.mobileNumber?.length === 10 || formData?.values?.mobileNumber?.length === 0 || formData?.values?.mobileNumber === undefined ? "" : "Mobile Number Should be of 10 digits"}
              value={formData?.values?.mobileNumber}
              onChange={(e) => handleChange(e)}
              onKeyDown={handlevalidate}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="formLabel" component="div">
              Office phone number (of contact person)*
            </Typography>
            <TextField
              error={formData?.values?.officePhoneNumber?.length === 10 || formData?.values?.officePhoneNumber?.length === 0 || formData?.values?.officePhoneNumber === undefined ? false : true}
              required
              fullWidth
              type='number'
              id="outlined-error-helper-text"
              size="small"
              name="officePhoneNumber"
              helperText={formData?.values?.officePhoneNumber?.length === 10 || formData?.values?.officePhoneNumber?.length === 0 || formData?.values?.officePhoneNumber === undefined ? "" : "Phone Number Should be of 10 digits"}
              value={formData?.values?.officePhoneNumber}
              onChange={(e) => handleChange(e)}
              onKeyDown={handlevalidate}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="formLabel" component="div">
              Select your position in the company*
            </Typography>
            <Select
              styles={customStyles}
              options={positionOptions}
              required
              classNamePrefix="standardSelect"
              placeholder={
                <Typography variant="formLabel">
                  Select your position in the company
                </Typography>
              }
              isSearchable={false}
              isClearable={true}
              name="selectPosition"
              value={formData?.values?.selectPosition}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  values: {
                    ...formData?.values,
                    selectPosition: e,
                  },
                })
              }
            />
          </Grid>
          {formData?.values?.selectPosition?.value === "OTHER" &&
            <Grid item xs={12}>
              <Typography variant="formLabel" component="div" >Enter position*</Typography>
              <TextField fullWidth id="outlined-error-helper-text"
                size="small"
                name="enterPosition"
                value={formData?.values?.enterPosition}
                onChange={(e) => handleChange(e)} />
            </Grid>}
          <Grid item xs={12}>
            <Typography variant="formLabel" component="div">
              Password*
            </Typography>
            <TextField
              error={formData?.values?.userPassword !== "" && formData?.error?.userPasswordError ? true : false}
              type={showPassword?.password ? 'text' : 'password'}
              fullWidth
              id="outlined-error-helper-text"
              size="small"
              name="userPassword"
              onKeyDown={handlevalidateSpace}
              helperText={<Typography variant="errorMessage">{formData?.values?.userPassword !== "" && formData?.error?.userPasswordError ? formData?.error?.userPasswordError : ""}</Typography>}
              value={
                formData?.values?.userPassword
                  ? formData?.values?.userPassword
                  : ""
              }
              autoComplete="new-password"
              onChange={(e) => handleChange(e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleClickShowPassword("userPassword")}
                    >
                      {showPassword?.password ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="formLabel" component="div">
              Re-enter password*
            </Typography>
            <TextField
              error={formData?.error?.RePasswordError ? true : false}
              type={showPassword?.confirmPassword ? 'text' : 'password'}
              name="RePassword"
              helperText={<Typography variant="errorMessage">{formData?.error?.RePasswordError ? formData?.error?.RePasswordError : ""}</Typography>}
              value={formData?.values?.RePassword}
              onChange={(e) => handleChange(e)}
              fullWidth
              onKeyDown={handlevalidateSpace}
              id="outlined-error-helper-text"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleClickShowPassword("RePassword")}
                    >
                      {showPassword?.confirmPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: "13px" }}>
            <FormControlLabel
              control={
                <Checkbox sx={{ pt: 0, pb: 0 }}
                  name="secondaryContactCheck"
                  checked={formData?.values?.secondaryContactCheck}
                  onChange={(e) => handleChange(e)}
                />
              }
              label={<Typography variant="formLabel" sx={{ pt: 1 }}>Enter details of secondary contact person-Optional</Typography>}
            />
          </Grid>
          {formData?.values?.secondaryContactCheck === true && (
            <>
              <Grid item xs={12}>
                <Typography variant="formLabel" component="div">
                  Contact person's name
                </Typography>
                <TextField
                  required
                  fullWidth
                  id="outlined-error-helper-text"
                  size="small"
                  name="secondaryContactPersonName"
                  value={formData?.values?.secondaryContactPersonName}
                  onChange={(e) => handleChange(e)}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="formLabel" component="div">
                  Mobile number
                </Typography>
                <TextField
                  error={formData?.values?.secondaryMobileNumber?.length === 10 || formData?.values?.secondaryMobileNumber?.length === 0 || formData?.values?.secondaryMobileNumber === undefined ? false : true}
                  required
                  fullWidth
                  id="outlined-error-helper-text"
                  size="small"
                  type="number"
                  name="secondaryMobileNumber"
                  helperText={formData?.values?.secondaryMobileNumber?.length === 10 || formData?.values?.secondaryMobileNumber?.length === 0 || formData?.values?.secondaryMobileNumber === undefined ? "" : "Mobile Number Should be of 10 digits"}
                  value={formData?.values?.secondaryMobileNumber}
                  onChange={(e) => handleChange(e)}
                  onKeyDown={handlevalidate}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="formLabel" component="div">
                  Office phone number
                </Typography>
                <TextField
                  error={formData?.values?.SecondaryOfficePhoneNumber?.length === 10 || formData?.values?.SecondaryOfficePhoneNumber?.length === 0 || formData?.values?.SecondaryOfficePhoneNumber === undefined ? false : true}
                  required
                  fullWidth
                  id="outlined-error-helper-text"
                  size="small"
                  type='number'
                  name="SecondaryOfficePhoneNumber"
                  helperText={formData?.values?.SecondaryOfficePhoneNumber?.length === 10 || formData?.values?.SecondaryOfficePhoneNumber?.length === 0 || formData?.values?.SecondaryOfficePhoneNumber === undefined ? "" : "Phone Number Should be of 10 digits"}
                  value={formData?.values?.SecondaryOfficePhoneNumber}
                  onChange={(e) => handleChange(e)}
                  onKeyDown={handlevalidate}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="formLabel" component="div">
                  Select your position in the company
                </Typography>
                <Select
                  styles={customStyles}
                  options={positionOptions}
                  required
                  classNamePrefix="standardSelect"
                  placeholder={
                    <Typography variant="formLabel">
                      Select your position in the company
                    </Typography>
                  }
                  isSearchable={false}
                  isClearable={true}
                  name="SecondarySelectPosition"
                  value={formData?.values?.SecondarySelectPosition}
                  onChange={(e) => setFormData({
                    ...formData,
                    values: {
                      ...formData?.values,
                      SecondarySelectPosition: e,
                    },
                  })}
                />
              </Grid>
              {formData?.values?.SecondarySelectPosition?.value === "OTHER" &&
                <Grid item xs={12}>
                  <Typography variant="formLabel" component="div" >Enter Position</Typography>
                  <TextField fullWidth id="outlined-error-helper-text"
                    size="small"
                    name="enterSecondaryPosition"
                    value={formData?.values?.enterSecondaryPosition}
                    onChange={(e) => handleChange(e)} />
                </Grid>}
            </>
          )}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox sx={{ pt: 0, pb: 0 }}
                  name="userAgreeCheck"
                  checked={formData?.values?.userAgreeCheck}
                  onChange={(e) => handleChange(e)}
                />
              }
              label={<Typography variant="formLabel" sx={{ pt: 1 }}>I agree with
                <span style={{ textDecoration: "underline", color: palette.secondary.main }}
                  onClick={() => navigate("/terms&condition", { state: { data: formData?.values, w9: userWq, logo: userLogo } })}> Terms & Conditions</span> and
                <span onClick={() => navigate("/privacypolicy", { state: { data: formData?.values, w9: userWq, logo: userLogo } })}
                  style={{ textDecoration: "underline", color: palette.secondary.main }}> Privacy Policy*</span></Typography>}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: "20px" }}>
            <Button
              disabled={!submitButtomEnable || isLoading}
              variant="contained"
              fullWidth
              onClick={handleSubmit}
            >
              Submit Registration Request
              {isLoading && <CircularProgress size={20} sx={{ ml: 2, color: palette.secondary.main }} />}
            </Button>
          </Grid>
          {/* <Grid item lg={12}>
                        <Button variant="contained" fullWidth onClick={() => setValue(0)}>Back</Button>
                        </Grid> */}
          <Grid item xs={12} sx={{ display: "flex", mt: "40px", alignItems: "center" }}>
            <Typography variant="body1" sx={{ mr: "8px" }}>
              Alreay have an account?{" "}
            </Typography>
            <Typography variant="subtitle2" ><span onClick={() => navigate("/")} style={{ textDecoration: "underline", cursor: "pointer" }}>Click here</span> <span style={{ color: palette.text.main, textDecoration: "none" }}>to Login</span></Typography>
          </Grid>
        </Grid>
      </Scrollbars>
    </Box>
  );
}
