import {
    Box,
    Button,
    CircularProgress,
    Divider,
    Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import palette from "../../../theme/palette";
import moment from "moment";
import { useGetCaseNoteByIdQuery } from "../../../store/services/caseNotesServices";
import { getTime } from "../../../utils/util";
import html2pdf from "html2pdf.js/dist/html2pdf.js";


export default function CaseNoteView() {
    const { id } = useParams();
    const location = useLocation()
    const dataCaseNote = location?.state?.data
    const navigate = useNavigate();
    const [totalCalculatedTime, setTotalCalculatedTime] = useState("00");
    const [isExporting, setIsExporting] = useState(false)
    const { data: data, isSuccess, isLoading } = useGetCaseNoteByIdQuery({ clientId: id, caseNoteId: dataCaseNote?.id }, { skip: !id, skip: !dataCaseNote?.id })

    const reportTemplateRef = useRef(null);;


    function download() {
        setIsExporting(true);
        var element = document.getElementById("element-to-print");
        html2pdf()
            .from(element)
            .set({
                filename: "Casenotes" + ".pdf",
                pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
                image: { type: 'jpeg', quality: 1 },
                enableLinks: true,
                jsPDF: {
                    orientation: "portrait",
                    unit: "pt",
                    format: "a4",
                    // compressPDF: true,
                },
            })
            .save()
            .then(() => {
                setIsExporting(false);
            });
    }

    useEffect(() => {
        const startTime = {
            hours: { label: getTime(data?.startTime)?.hour, value: getTime(data?.startTime)?.hour === 0 ? "00" : getTime(data?.startTime)?.hour },
            minutes: { label: getTime(data?.startTime)?.minute === 0 ? "00" : getTime(data?.startTime)?.minute, value: getTime(data?.startTime)?.minute === 0 ? "00" : getTime(data?.startTime)?.minute },
            typeTime: { label: data?.startTimeType, value: data?.startTimeType }
        }
        const endTime = {
            hours: { label: getTime(data?.endTime)?.hour, value: getTime(data?.endTime)?.hour === 0 ? "00" : getTime(data?.endTime)?.hour },
            minutes: { label: getTime(data?.endTime)?.minute === 0 ? "00" : getTime(data?.endTime)?.minute, value: getTime(data?.endTime)?.minute === 0 ? "00" : getTime(data?.endTime)?.minute },
            typeTime: { label: data?.endTimeType, value: data?.endTimeType }
        }
        if (
            startTime?.hours !== null &&
            startTime?.minutes !== null &&
            endTime?.hours !== null &&
            endTime?.minutes !== null
        ) {
            let startHours = startTime?.hours?.value || 0;
            let endHours = endTime?.hours?.value || 0;
            let startMinutes = startTime?.minutes?.value || 0;
            let endMinutes = endTime?.minutes?.value || 0;
            if (startTime?.typeTime?.value === "pm" && startHours !== 12) {
                startHours += 12;
            } else if (startTime?.typeTime?.value === "am" && startHours === 12) {
                startHours = 0;
            }
            if (endTime?.typeTime?.value === "pm" && endHours !== 12) {
                endHours += 12;
            } else if (endTime?.typeTime?.value === "am" && endHours === 12) {
                endHours = 0;
            }
            let startTotalMinutes = Number(startHours) * 60 + Number(startMinutes);
            const endTotalMinutes = Number(endHours) * 60 + Number(endMinutes);

            if (endTotalMinutes < startTotalMinutes) {
                startTotalMinutes -= 24 * 60;
            }
            const totalMinutes = endTotalMinutes - startTotalMinutes;
            const hours = Math.floor(Math.abs(totalMinutes) / 60);
            const minutes = Math.abs(totalMinutes) % 60;

            setTotalCalculatedTime(`${hours} hours ${minutes} minutes`);
        }
    }, [data, isSuccess]);

    return (
        <Box className="casenoteTopContainer" ref={reportTemplateRef}>
            <Grid container justifyContent={"flex-end"} gap={2}>
                <Button
                    variant="contained"
                    onClick={() => navigate("/casenotes")}
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    onClick={download} disabled={isLoading || isExporting}
                >
                    Generate PDF
                    {isExporting && <CircularProgress color="primary" disableShrink size={20} sx={{ ml: 1 }} />}
                </Button>
            </Grid>
            <Box id="element-to-print" p={2} >
                <Grid container spacing={2} >
                    <Grid xs={12} sm={12} md={12} lg={12} display='flex' justifyContent={'center'}>
                        <Typography variant="h1">Case Notes Details</Typography>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} lg={3} >
                        <Typography variant="subtitle2" color={palette.text.dark} fontSize="18px" fontWeight={600} component='div'>Client Name </Typography>
                        <Typography variant="body2" fontWeight="regular">{data?.clientName || ""}</Typography>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} lg={3}>
                        <Typography variant="subtitle2" color={palette.text.dark} fontSize="18px" fontWeight={600} component='div'>Service</Typography>
                        <Typography variant="body2" fontWeight="regular">{data?.serviceName || ""}</Typography>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} lg={3}>
                        <Typography variant="subtitle2" color={palette.text.dark} fontSize="18px" fontWeight={600} component='div'>Date</Typography>
                        <Typography variant="body2" fontWeight="regular">{moment(data?.startDate).format("DD-MM-YYYY")}</Typography>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} lg={3}>
                        <Typography variant="subtitle2" color={palette.text.dark} fontSize="18px" fontWeight={600} component='div'>Start Time</Typography>
                        <Typography variant="body2" fontWeight="regular">{`${data?.startTime}${data?.startTimeType ? data?.startTimeType : ""}`}</Typography>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} lg={3}>
                        <Typography variant="subtitle2" color={palette.text.dark} fontSize="18px" fontWeight={600} component='div'>End Time</Typography>
                        <Typography variant="body2" fontWeight="regular">{`${data?.endTime}${data?.endTimeType ? data?.endTimeType : ""}`}</Typography>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} lg={3}>
                        <Typography variant="subtitle2" color={palette.text.dark} fontSize="18px" fontWeight={600} component='div'>Total Time</Typography>
                        <Typography variant="body2" fontWeight="regular">{totalCalculatedTime}</Typography>
                    </Grid>
                </Grid>
                <Box border={`2px solid ${palette.background.dark}`} p={2} mt={2}>
                    {data?.notes?.map((row, i) => (
                        <Grid key={i} container spacing={2} >
                            {i !== 0 && <Grid xs={12} sm={12} md={12} lg={12}>
                                <Divider sx={{ bgcolor: palette.text.dark }} />
                            </Grid>}
                            <Grid xs={12} sm={12} md={12} lg={6}>
                                <Typography variant="subtitle2" color={palette.text.dark} fontSize="18px" fontWeight={600} component='div'>Goals</Typography>
                                <Typography variant="body2" fontWeight="regular">{row?.serviceGoals?.map(item => item?.goalName)?.includes("Other") ? row?.otherGoal : row?.serviceGoals?.map(item => item?.goalName)}</Typography>
                            </Grid>
                            <Grid xs={12} sm={12} md={12} lg={6}>
                                <Typography variant="subtitle2" color={palette.text.dark} fontSize="18px" fontWeight={600} component='div'>Assignments</Typography>
                                <Typography variant="body2" fontWeight="regular">{row?.serviceAssignments?.map(item => item?.assignmentName)?.includes("Other") ? row?.otherAssignment : row?.serviceAssignments?.map(item => item?.assignmentName)}</Typography>
                            </Grid>
                            <Grid xs={12} sm={12} md={12} lg={6}>
                                <Typography variant="subtitle2" color={palette.text.dark} fontSize="18px" fontWeight={600} component='div'>Service Type</Typography>
                                <Typography variant="body2" fontWeight="regular">{row?.meetingTypeName}</Typography>
                            </Grid>
                            <Grid xs={12} sm={12} md={12} lg={6}>
                                <Typography variant="subtitle2" color={palette.text.dark} fontSize="18px" fontWeight={600} component='div'>Meeting With</Typography>
                                <Typography variant="body2" fontWeight="regular">{row?.meetingWithName}</Typography>
                            </Grid>
                            <Grid xs={12} sm={12} md={12} lg={12}>
                                <Typography variant="subtitle2" color={palette.text.dark} fontSize="18px" fontWeight={600} component='div'>Case Notes</Typography>
                                <Typography variant="body2" textAlign={'justify'} fontWeight="regular">{row?.caseNotesInfo}</Typography>
                            </Grid>
                            <Grid xs={12} sm={12} md={12} lg={12}>
                                <Typography variant="subtitle2" color={palette.text.dark} fontSize="18px" fontWeight={600} component='div'>Partinent Information</Typography>
                                <Typography variant="body2" textAlign={'justify'} fontWeight="regular">{row?.pertinentInfo}</Typography>
                            </Grid>
                            <Grid xs={12} sm={12} md={12} lg={6}>
                                <Typography variant="subtitle2" color={palette.text.dark} fontSize="18px" fontWeight={600} component='div'>Intervention Time</Typography>
                                <Typography variant="body2" fontWeight="regular">{row?.interventionTime}</Typography>
                            </Grid>
                            <Grid xs={12} sm={12} md={12} lg={6}>
                                <Typography variant="subtitle2" color={palette.text.dark} fontSize="18px" fontWeight={600} component='div'>Travel Time</Typography>
                                <Typography variant="body2" fontWeight="regular">{row?.travelTime}</Typography>
                            </Grid>
                            <Grid xs={12} sm={12} md={12} lg={6}>
                                <Typography variant="subtitle2" color={palette.text.dark} fontSize="18px" fontWeight={600} component='div'>Documentation Time</Typography>
                                <Typography variant="body2" fontWeight="regular">{row?.documentationTime}</Typography>
                            </Grid>
                        </Grid>
                    ))
                    }
                </Box>
            </Box>

        </Box>
    );
}
