import {
    Box,
    Button,
    CircularProgress,
    Divider,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import palette from "../../../theme/palette";
import CustomDatepicker from "../../../components/CustomDatepicker";
import CustomTimePicker from "../../../components/CustomTimePicker";
import AddCaseNotesSection from "../AddCaseNotesSection";
import {
    useGetClientListQuery,
    useGetServicesQuery,
} from "../../../store/services/clientServices";
import moment from "moment";
import { useAddCaseNotesMutation, useDeleteSubCaseNoteMutation, useGetAssignmentsQuery, useGetGoalsQuery } from "../../../store/services/caseNotesServices";
import { toast } from "react-toastify";
import { addOneDay, getTime } from "../../../utils/util";
import { useGetCaseNoteByIdQuery } from "../../../store/services/caseNotesServices";
import { useCompanyProfileQuery } from "../../../store/services/companyServices";

const customStyles = {
    dropdownIndicator: (base) => ({
        ...base,
        color: palette.text.light,
        flex: "1",
        width: "100%",
    }),
    indicatorSeparator: () => null,
    container: (base) => ({ ...base, flex: "1", width: "100%" }),
};
export default function EditCaseNotes() {
    const { id } = useParams();
    const location = useLocation()
    const dataCaseNote = location?.state?.data
    const navigate = useNavigate();
    const [caseDate, setCaseDate] = useState({});
    const [finalTotalTime, setFinalTotalTime] = useState();
    const [startTime, setStartTime] = useState([]);
    const [showTotalTime, setShowTotalTime] = useState();
    const [endTime, setEndTime] = useState([]);
    const [goalsOptions, setGoalsOptions] = useState([]);
    const [assignmentOptions, setAssignmentOptions] = useState([]);
    const [serviceType, setServiceType] = useState([]);
    const [caseNotesData, setCaseNotesData] = useState([]);
    const [clientDetails, setClientDetails] = useState({ values: {} });
    const { data: companyData = {} } = useCompanyProfileQuery({});
    const { data: getServices, isSuccess } = useGetServicesQuery({});
    const [clientList, setClientList] = useState([]);
    const [addcasenote, { isLoading }] = useAddCaseNotesMutation({});
    const [totalCalculatedTime, setTotalCalculatedTime] = useState("00");
    const [deleteSubCaseNote] = useDeleteSubCaseNoteMutation({})
    const goalParams = {
        CompanyId: companyData?.id,
        ServiceId: clientDetails?.values?.service?.value
    }
    const { data: goalData = [], isSuccess: SuccessIS } = useGetGoalsQuery({ params: goalParams }, { skip: !goalParams });
    const { data: assignmentData = [], isSuccess: SuccessOn } = useGetAssignmentsQuery({ params: goalParams }, { skip: !goalParams })
    const { data: editData, isSuccess: success } = useGetCaseNoteByIdQuery({ clientId: id, caseNoteId: dataCaseNote?.id }, { skip: !id, skip: !dataCaseNote?.id })


    useEffect(() => {
        let goalArr = []
        goalData?.map((row) => {
            goalArr.push({
                label: row?.goalName,
                value: row?.goalId
            })
        })
        SuccessIS &&
            setGoalsOptions(goalArr)
    }, [SuccessIS, goalData])

    useEffect(() => {
        let assignmentArr = []
        assignmentData?.map((row) => {
            assignmentArr.push({
                label: row?.assignmentName,
                value: row?.assignmentId
            })
        })
        SuccessOn &&
            setAssignmentOptions(assignmentArr)
    }, [SuccessOn, assignmentData])

    useEffect(() => {
        if (editData) {

            setClientDetails({
                ...clientDetails,
                values: {
                    ...clientDetails?.values,
                    client: { label: editData?.clientName, value: editData?.clientId },
                    service: { label: editData?.serviceName, value: editData?.serviceId },
                }
            })
            setCaseDate({
                dayName: { label: moment(editData?.startDate).format("DD"), value: moment(editData?.startDate).format("DD") },
                monthName: { label: moment(editData?.startDate).format("MM"), value: moment(editData?.startDate).format("MM") },
                yearName: { label: moment(editData?.startDate).format("YYYY"), value: moment(editData?.startDate).format("YYYY") },
            })
            setStartTime({
                hours: { label: getTime(editData?.startTime)?.hour, value: getTime(editData?.startTime)?.hour === 0 ? "00" : getTime(editData?.startTime)?.hour },
                minutes: { label: getTime(editData?.startTime)?.minute === 0 ? "00" : getTime(editData?.startTime)?.minute, value: getTime(editData?.startTime)?.minute === 0 ? "00" : getTime(editData?.startTime)?.minute },
                typeTime: { label: editData?.startTimeType, value: editData?.startTimeType }
            })
            setEndTime({
                hours: { label: getTime(editData?.endTime)?.hour, value: getTime(editData?.endTime)?.hour === 0 ? "00" : getTime(editData?.endTime)?.hour },
                minutes: { label: getTime(editData?.endTime)?.minute === 0 ? "00" : getTime(editData?.endTime)?.minute, value: getTime(editData?.endTime)?.minute === 0 ? "00" : getTime(editData?.endTime)?.minute },
                typeTime: { label: editData?.endTimeType, value: editData?.endTimeType }
            })

            editData?.notes?.map(row => {
                const valueAssignment = []
                const assignmentValue = row?.serviceAssignments
                assignmentValue?.map(item => {
                    return valueAssignment?.push({
                        label: item?.assignmentName,
                        value: item?.assignmentId

                    })
                })
                const valueGoal = []
                const goalValue = row?.serviceGoals
                goalValue?.map(item => {
                    return valueGoal?.push({
                        label: item?.goalName,
                        value: item?.goalId

                    })
                })
                setCaseNotesData((prev) => {
                    return [
                        ...prev,
                        {
                            id: row?.id ?? null,
                            serviceGoalId: valueGoal || null,
                            Assignments: valueAssignment,
                            meetingType: { label: row?.meetingTypeName, value: row?.meetingType },
                            meetingWith: { label: row?.meetingWithName, value: row?.meetingWith },
                            caseNotesInfo: row?.caseNotesInfo,
                            pertinentInfo: row?.pertinentInfo,
                            interventionTime: {
                                hours: { label: getTime(row?.interventionTime)?.hour, value: getTime(row?.interventionTime)?.hour === 0 ? "00" : getTime(row?.interventionTime)?.hour },
                                minutes: { label: getTime(row?.interventionTime)?.minute === 0 ? "00" : getTime(row?.interventionTime)?.minute, value: getTime(row?.interventionTime)?.minute === 0 ? "00" : getTime(row?.interventionTime)?.minute },
                            },
                            travelTime: {
                                hours: { label: getTime(row?.travelTime)?.hour, value: getTime(row?.travelTime)?.hour === 0 ? "00" : getTime(row?.travelTime)?.hour },
                                minutes: { label: getTime(row?.travelTime)?.minute === 0 ? "00" : getTime(row?.travelTime)?.minute, value: getTime(row?.travelTime)?.minute === 0 ? "00" : getTime(row?.travelTime)?.minute },
                            },
                            documentationTime: {
                                hours: { label: getTime(row?.documentationTime)?.hour, value: getTime(row?.documentationTime)?.hour === 0 ? "00" : getTime(row?.documentationTime)?.hour },
                                minutes: { label: getTime(row?.documentationTime)?.minute === 0 ? "00" : getTime(row?.documentationTime)?.minute, value: getTime(row?.documentationTime)?.minute === 0 ? "00" : getTime(row?.documentationTime)?.minute },
                            },
                            totalTime: [],
                            otherAssignment: row?.otherAssignment,
                            otherGoal: row?.otherGoal
                        },
                    ];
                });
            })

        }
    }, [editData, success])

    useEffect(() => {
        const serviceOptions = getServices?.map((data) => {
            return { label: data?.serviceName, value: data?.serviceId };
        });
        setServiceType(serviceOptions);
    }, [isSuccess]);
    const params = {
        status: true,
    }
    const { data: DataClient, isSuccess: onSuccess } = useGetClientListQuery(params, { skip: !params });

    const handleChange = (e) => {
        let { name, value } = e.target;
        setClientDetails({
            ...clientDetails,
            values: {
                ...clientDetails?.values,
                [name]: value,
            },
        });
    };
    useEffect(() => {
        let DataArr = [];
        const listArr = DataClient?.map((data) => {
            DataArr.push({
                label: data?.firstName + " " + data?.lastName,
                value: data?.clientId,
            });
        });
        onSuccess && setClientList(DataArr);
    }, [onSuccess, DataClient]);

    const handleAddCaseNotes = () => {
        setCaseNotesData((prev) => {
            return [
                ...prev,
                {
                    serviceGoalId: [],
                    Assignments: [],
                    meetingType: [],
                    meetingWith: [],
                    caseNotesInfo: [],
                    pertinentInfo: [],
                    interventionTime: null,
                    travelTime: null,
                    documentationTime: null,
                    totalTime: [],
                    otherAssignment: null,
                    otherGoal: null
                },
            ];
        });
    };

    const onChange = (e, i) => {
        const { name, value } = e.target;
        setCaseNotesData((prev) => {
            prev[i][name] = value;
            return [...prev];
        });
    };

    const setPartinentInformation = (e, i) => {
        setCaseNotesData((prev) => {
            prev[i].pertinentInfo = e;
            return [...prev];
        });
    };

    const setTotalTime = (e, i) => {
        setCaseNotesData((prev) => {
            prev[i].totalTime = e;
            return [...prev];
        });
    };

    function setInterventionTime(e, i) {
        setCaseNotesData((prev) => {
            prev[i].interventionTime = e;
            return [...prev];
        });
    }

    function setTravelTime(e, i) {
        setCaseNotesData((prev) => {
            prev[i].travelTime = e;
            return [...prev];
        });
    }

    function setDocumentationTime(e, i) {
        setCaseNotesData((prev) => {
            prev[i].documentationTime = e;
            return [...prev];
        });
    }

    const handleRemoveCaseNotes = (e, i) => {
        setCaseNotesData((prev) => {
            prev.splice(i, 1);
            return [...prev];
        });
        deleteSubCaseNote({ CaseNoteId: editData?.id, SubCaseNoteId: e?.id }).unwrap()
            .then((response) => { console.log(response) })
            .catch((error) => console.log(error))
    };
    const setSelectGoal = (e, i) => {
        setCaseNotesData((prev) => {
            prev[i].serviceGoalId = e;
            return [...prev];
        });
    };

    const setSelectAssignment = (e, i) => {
        setCaseNotesData((prev) => {
            prev[i].Assignments = e;
            return [...prev];
        });
    };

    const setSelectServiceType = (e, i) => {
        setCaseNotesData((prev) => {
            prev[i].meetingType = e;
            return [...prev];
        });
    };

    const setSelectMeeting = (e, i) => {
        setCaseNotesData((prev) => {
            prev[i].meetingWith = e;
            return [...prev];
        });
    };

    const setCaseNotes = (e, i) => {
        setCaseNotesData((prev) => {
            prev[i].caseNotesInfo = e;
            return [...prev];
        });
    };

    useEffect(() => {
        const firstTime = showTotalTime?.split(":") || [];
        const secondTime = totalCalculatedTime?.split(" ") || [];

        let hours = Number(firstTime[0] || 0) + Number(secondTime[0] || 0);
        let minutes = Number(firstTime[1] || 0) + Number(secondTime[2] || 0);

        if (minutes >= 60) {
            hours += Math.floor(minutes / 60);
            minutes %= 60;
        }
        setFinalTotalTime(`${hours} hours ${minutes} minutes`);
    }, [showTotalTime, totalCalculatedTime]);

    useEffect(() => {
        if (
            startTime?.hours !== null &&
            startTime?.minutes !== null &&
            endTime?.hours !== null &&
            endTime?.minutes !== null
        ) {
            let startHours = startTime?.hours?.value || 0;
            let endHours = endTime?.hours?.value || 0;
            let startMinutes = startTime?.minutes?.value || 0;
            let endMinutes = endTime?.minutes?.value || 0;

            if (startTime?.typeTime?.value === "pm" && startHours !== 12) {
                startHours += 12;
            } else if (startTime?.typeTime?.value === "am" && startHours === 12) {
                startHours = 0;
            }

            if (endTime?.typeTime?.value === "pm" && endHours !== 12) {
                endHours += 12;
            } else if (endTime?.typeTime?.value === "am" && endHours === 12) {
                endHours = 0;
            }

            let startTotalMinutes = Number(startHours) * 60 + Number(startMinutes);
            const endTotalMinutes = Number(endHours) * 60 + Number(endMinutes);

            if (endTotalMinutes < startTotalMinutes) {
                startTotalMinutes -= 24 * 60;
            }

            const totalMinutes = endTotalMinutes - startTotalMinutes;
            const hours = Math.floor(Math.abs(totalMinutes) / 60);
            const minutes = Math.abs(totalMinutes) % 60;

            setTotalCalculatedTime(`${hours} hours ${minutes} minutes`);
        }
    }, [startTime, endTime]);

    const handleSave = (e) => {
        e.preventDefault();
        const CaseNotesDataArr = caseNotesData?.map((item, i) => {
            return {
                ...item,
                serviceGoalId: item?.serviceGoalId?.map((item) => item?.value),
                Assignments: item?.Assignments?.map((data) => data?.value),
                meetingType: item?.meetingType?.value,
                meetingWith: item?.meetingWith?.value,
                otherInfo: null,
                interventionTime: item?.interventionTime?.hours?.value === '00' ?
                    (item?.interventionTime?.hours?.value + ':' + item?.interventionTime?.minutes?.value + ':' + '00') : moment(
                        item?.interventionTime?.hours?.value +
                        ":" +
                        item?.interventionTime?.minutes?.value,
                        "h:mm"
                    ).format("hh:mm:ss"),
                travelTime: item?.travelTime?.hours?.value === '00' ?
                    (item?.travelTime?.hours?.value + ':' + item?.travelTime?.minutes?.value + ':' + '00') : moment(
                        item?.travelTime?.hours?.value +
                        ":" +
                        item?.travelTime?.minutes?.value,
                        "h:mm"
                    ).format("hh:mm:ss"),
                documentationTime: item?.documentationTime?.hours?.value === '00' ?
                    (item?.documentationTime?.hours?.value + ':' + item?.documentationTime?.minutes?.value + ':' + '00') : moment(
                        item?.documentationTime?.hours?.value +
                        ":" +
                        item?.documentationTime?.minutes?.value,
                        "h:mm"
                    ).format("hh:mm:ss"),
            };
        });
        const payload = {
            id: editData?.id,
            clientId: clientDetails?.values?.client?.value,
            serviceId: clientDetails?.values?.service?.value,
            startDate: addOneDay(
                new Date(
                    `${caseDate?.yearName?.value}-${caseDate?.monthName?.value}-${caseDate?.dayName?.value}`
                )
            ).toISOString(),
            startTime: startTime?.hours?.value === '00' ?
                (startTime?.hours?.value + ':' + startTime?.minutes?.value + ':' + '00') : moment(
                    startTime?.hours?.value + ":" + startTime?.minutes?.value,
                    "h:mm"
                ).format("hh:mm:ss"),
            startTimeType: startTime?.typeTime?.value,
            endTime: endTime?.hours?.value === '00' ?
                (endTime?.hours?.value + ':' + endTime?.minutes?.value + ':' + '00') : moment(
                    endTime?.hours?.value + ":" + endTime?.minutes?.value,
                    "h:mm"
                ).format("hh:mm:ss"),
            endTimeType: endTime?.typeTime?.value,
            notes: CaseNotesDataArr,
        };
        addcasenote({ payload })
            .unwrap()
            .then((response) => {
                toast.success(response?.message);
                navigate("/casenotes");
            })
            .catch((error) => console.log(error?.message));
    };
    return (
        <form onSubmit={(e) => handleSave(e)}>
            <Box>
                <Grid container spacing={2}>
                    <Grid xs={12} sm={12} md={12} lg={6}>
                        <Typography variant="h1">Edit Case Notes</Typography>
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={6}>
                        <Grid container justifyContent={"flex-end"} gap={2}>
                            <Button
                                variant="contained"
                                onClick={() => navigate("/casenotes")}
                            >
                                Back
                            </Button>
                            <Button disabled={isLoading} variant="contained" type="submit">
                                Save
                                {isLoading && (
                                    <CircularProgress
                                        size={20}
                                        sx={{ ml: 2, color: palette.secondary.main }}
                                    />
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={6}>
                        <Typography variant="formLabel" component={"div"}>
                            Please Select Client
                        </Typography>
                        <Select
                            styles={customStyles}
                            options={clientList}
                            required
                            classNamePrefix="standardSelect"
                            placeholder={
                                <Typography variant="formLabel">
                                    Show Client name with ID
                                </Typography>
                            }
                            isSearchable={false}
                            isClearable={true}
                            name="client"
                            value={clientDetails?.values?.client}
                            onChange={(e) =>
                                setClientDetails({
                                    ...clientDetails,
                                    values: {
                                        ...clientDetails?.values,
                                        client: e,
                                    },
                                })
                            }
                        />
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={6}>
                        <Typography variant="formLabel" component={"div"}>
                            Please Select Service
                        </Typography>
                        <Select
                            styles={customStyles}
                            options={serviceType}
                            required
                            classNamePrefix="standardSelect"
                            placeholder={
                                <Typography variant="formLabel">Show Service</Typography>
                            }
                            isSearchable={false}
                            isClearable={true}
                            name="service"
                            value={clientDetails?.values?.service}
                            onChange={(e) =>
                                setClientDetails({
                                    ...clientDetails,
                                    values: {
                                        ...clientDetails?.values,
                                        service: e,
                                    },
                                })
                            }
                        />
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="formLabel" component={"div"}>
                            Select Date
                        </Typography>
                        <CustomDatepicker
                            setValue={setCaseDate}
                            value={caseDate}
                            DOBYear={2023}
                        />
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="formLabel" component={"div"}>
                            Start Time
                        </Typography>
                        <CustomTimePicker setValue={setStartTime} value={startTime} />
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="formLabel" component={"div"}>
                            End Time
                        </Typography>
                        <CustomTimePicker setValue={setEndTime} value={endTime} />
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="formLabel" component={"div"}>
                            Total Calculated Time
                        </Typography>
                        <TextField
                            fullWidth
                            inputProps={{ readOnly: true }}
                            value={totalCalculatedTime}
                            sx={{ backgroundColor: palette.primary.main }}
                        />
                    </Grid>
                </Grid>
                <Box sx={{ backgroundColor: palette.primary.main }} mt={5} p={3}>
                    <Grid container justifyContent={"flex-end"}>
                        <Button
                            onClick={(e, i) => handleAddCaseNotes(e, i)}
                            variant="contained"
                        >
                            Add Case Note
                        </Button>
                    </Grid>
                    {caseNotesData?.map((data, i) => {
                        const {
                            serviceGoalId,
                            Assignments,
                            meetingType,
                            meetingWith,
                            caseNotesInfo,
                            pertinentInfo,
                            interventionTime,
                            travelTime,
                            documentationTime,
                            totalTime,
                            otherAssignment,
                            otherGoal
                        } = data;
                        return (
                            <Box key={i}>
                                {i !== 0 && (
                                    <Box pt={7} pb={5}>
                                        {" "}
                                        <Divider />
                                    </Box>
                                )}
                                <AddCaseNotesSection
                                    i={i}
                                    showTotalTime={showTotalTime}
                                    setShowTotalTime={setShowTotalTime}
                                    serviceGoalId={serviceGoalId}
                                    Assignments={Assignments}
                                    otherAssignment={otherAssignment}
                                    otherGoal={otherGoal}
                                    meetingType={meetingType}
                                    meetingWith={meetingWith}
                                    caseNotesInfo={caseNotesInfo}
                                    pertinentInfo={pertinentInfo}
                                    interventionTime={interventionTime}
                                    travelTime={travelTime}
                                    documentationTime={documentationTime}
                                    totalTime={totalTime}
                                    setSelectGoal={setSelectGoal}
                                    setSelectAssignment={setSelectAssignment}
                                    setSelectServiceType={setSelectServiceType}
                                    setSelectMeeting={setSelectMeeting}
                                    setCaseNotes={setCaseNotes}
                                    setPartinentInformation={setPartinentInformation}
                                    setInterventionTime={setInterventionTime}
                                    setTravelTime={setTravelTime}
                                    setDocumentationTime={setDocumentationTime}
                                    setTotalTime={setTotalTime}
                                    onChange={onChange}
                                    goalsOptions={goalsOptions}
                                    assignmentOptions={assignmentOptions}
                                />
                                <Grid container mt={2} justifyContent={"flex-end"}>
                                    {i !== 0 && (
                                        <Button
                                            onClick={(e) => handleRemoveCaseNotes(data, i)}
                                            variant="contained"
                                        >
                                            Remove Case Note
                                        </Button>
                                    )}
                                </Grid>
                            </Box>
                        );
                    })}
                </Box>
                <Grid xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="formLabel" component={"div"}>
                        Total Calculated Time
                    </Typography>
                    <Typography variant="formLabel" component={"div"}>
                        {finalTotalTime || ""}
                    </Typography>
                </Grid>
            </Box>
        </form>
    );
}
