export const AllRoles = [
  { label: "Manager", value: "Manager" },
  { label: "Professional writer", value: "Professional writer" },
  { label: "Supervisor", value: "Supervisor" },
  { label: "Consultant", value: "Consultant" },
  { label: "Consultant Supervisor", value: "Consultant Supervisor" },
  { label: "Service staff", value: "Service staff" },
  { label: "Office staff", value: "Office staff" },
  { label: "Accountant", value: "Accountant" },
  { label: "Intern", value: "Intern" },
];

export const RolesForManager = [
  { label: "Professional writer", value: "Professional writer" },
  { label: "Supervisor", value: "Supervisor" },
  { label: "Intern", value: "Intern" },
  { label: "Consultant", value: "Consultant" },
  { label: "Consultant Supervisor", value: "Consultant Supervisor" },
  { label: "Service staff", value: "Service staff" },
  { label: "Office staff", value: "Office staff" },
]

export const RolesForSupervisor = [
  { label: "Professional writer", value: "Professional writer" },
  { label: "Supervisor", value: "Supervisor" },
  { label: "Intern", value: "Intern" },
  { label: "Consultant", value: "Consultant" },
  { label: "Consultant Supervisor", value: "Consultant Supervisor" },
  { label: "Service staff", value: "Service staff" },
  { label: "Office staff", value: "Office staff" },
]

export const RolesForSatff = [
  { label: "Professional writer", value: "Professional writer" },
  { label: "Service staff", value: "Service staff" },
  { label: "Office staff", value: "Office staff" },
]

export const positionOptions = [
  { label: "Director", value: "Director" },
  { label: "Manager", value: "Manager" },
  { label: "Operations head", value: "Operations head" },
  { label: "Sales manager", value: "Sales manager" },
  { label: "Sales staff", value: "Sales staff" },
  { label: "Other", value: "OTHER" },
];

export const positionStatusAll = [
  { label: "Active", value: 0 },
  { label: "Inactive", value: 1 },
];

export const positionStatus = [
  { label: "Active", value: 0 },
  { label: "Inactive", value: 1 },
];
export const clientStatus = [
  { label: "Active", value: true },
  { label: "Inactive", value: false },
];
export const positionPrefix = [
  { label: "Mr.", value: "Mr." },
  { label: "Mrs.", value: "Mrs." },
  { label: "Miss", value: "Miss" },
  { label: "Ms.", value: "Ms." },
  { label: "Dr.", value: "Dr." },
  { label: "Prof", value: "Prof" },
  { label: "Master", value: "Master" },
  { label: "Other", value: "OTHER" },
];
export const IntakeStatusOptions = [
  { label: "Pending", value: "Pending" },
  { label: "In progress", value: "In progress" },
  { label: "On hold", value: "On hold" },
  { label: "Completed", value: "Completed" },
  { label: "Canceled by client", value: "Canceled By client" },
  { label: "Canceled by agency", value: "Canceled By agency" },
  { label: "Canceled by company", value: "Canceled By company" },
];

export const RelationOptions = [
  { label: "Husband", value: "Husband" },
  { label: "Wife", value: "Wife" },
  { label: "Son", value: "Son" },
  { label: "Daughter", value: "Daughter" },
  { label: "Mother", value: "Mother" },
  { label: "Father", value: "Father" },
  { label: "Grandmother", value: "Grandmother" },
  { label: "Grandfather", value: "Grandfather" },
  { label: "Aunt", value: "Aunt" },
  { label: "Uncle", value: "Uncle" },
  { label: "Boyfriend", value: "Boyfriend" },
  { label: "Girlfriend", value: "Girlfriend" },
  { label: "Other", value: "OTHER" },
];

export const HousingType = [
  { label: "Apartment", value: "Apartment" },
  { label: "Duplex", value: "Duplex" },
  { label: "Hotel", value: "Hotel" },
  { label: "Town house", value: "Town house" },
  { label: "Row house", value: "Row house" },
  { label: "Housing shelter", value: "Housing shelter" },
];

export const HousingAssistance = [
  { label: "Section 8", value: "Section 8" },
  { label: "Public housing", value: "Public housing" },
  { label: "Non applicable", value: "Non applicable" },
];

export const SafeInNeighborhoodOptions = [
  { label: "Safe", value: "Safe" },
  { label: "Unsafe", value: "Unsafe" },
];
export const HousingRecommendationsOptions = [
  // { label: "Tamishia,will provide the list", value: "SAFE" },
  { label: "Other", value: "OTHER" },
];

export const MedicalRecommendations = [
  // { label: "Tamishia,will provide the list", value: "SAFE" },
  { label: "Human Services", value: "Human Services" },
  { label: " Department of Aging and Rehabilitation Services", value: " Department of Aging and Rehabilitation Services" },
  { label: "Community Services Board", value: "Community Services Board" },
  { label: "Department of Housing", value: "Department of Housing" },
  { label: "Transportation", value: "Transportation" },
  { label: "Health Insurance", value: "Health Insurance" },
  { label: "Social Security Disability Benefits", value: "Social Security Disability Benefits" },
  { label: "Unemployment Benefits", value: "Unemployment Benefits" },
  { label: "Supplemental Nutrition Assistance Program (SNAP)", value: "Supplemental Nutrition Assistance Program (SNAP)" },
  { label: "Other", value: "Other" },
];

export const BarriersOptions = [
  { label: "Other", value: "Other" },
]

export const MentalMedicalRecommendations = [
  { label: "Other", value: "Other" },
]

export const MentalMedicalGoals = [
  { label: "Other", value: "Other" },
]

export const MentalHealthRecommendation = [
  { label: "Other", value: "Other" },
]


export const MentalHealthGoals = [
  { label: "Other", value: "Other" },
]


export const vocationalRecommendations = [
  { label: "Other", value: "Other" },
]


export const vocationalGoals = [
  { label: "Other", value: "Other" },
]


export const CommunityServiceRecommendation = [
  { label: "Other", value: "Other" },
]


export const CommunityServiceGoals = [
  { label: "Other", value: "Other" },
]

export const HospitalizationOptions = [
  { label: "Medical hospitalization", value: "Medical hospitalization" },
  {
    label: "Medical health hospitalization",
    value: "Medical health hospitalization",
  },
];

export const MEDICALALIMENT = [
  { label: "Diabetes", value: "Diabetes" },
  { label: "Asthma", value: "Asthma" },
  { label: "Other", value: "OTHER" },
];

export const SYMPTOMS = [
  { label: "Drowsy", value: "Drowsy" },
  { label: "Other ", value: "OTHER" },
];

export const EVENTTYPE = [
  { label: "Surgery", value: "Surgery" },
  { label: "Appointment", value: "Appointment" },
];

export const AllergyOptions = [
  { label: "Food", value: "Food" },
  { label: "Environment", value: "Environment" },
  { label: "Medications", value: "Medications" },
  { label: "Latex", value: "Latex" },
  { label: "Nickel", value: "Nickel" },
];

export const FrequencyOptions = [
  { label: "Occasionally", value: "Occasionally" },
  { label: "1 Day a week", value: "1 Day a week" },
  { label: "2 Days a week", value: "2 Days a week" },
  { label: "3 Days a week", value: "3 Days a week" },
  { label: "4 Days a week", value: "4 Days a week" },
  { label: "5 Days a week", value: "5 Days a week" },
  { label: "6 Days a week", value: "6 Days a week" },
  { label: "Everyday", value: "Everyday" },
  { label: "Not at all", value: "Not at all" },
];

export const TabaccoOptions = [
  { label: "Chew", value: "Chew" },
  { label: "Cigars", value: "Cigars" },
  { label: "Cigarette", value: "Cigarette" },
  { label: "Pipe", value: "Pipe" },
  { label: "Vape", value: "Vape" },
  { label: "Other", value: "OTHER" },
];

export const AlcoholOptions = [
  { label: "Beer", value: "Beer" },
  { label: "Liquor", value: "Liquor" },
  { label: "Wine", value: "Wine" },
  { label: "Other", value: "OTHER" },
];
export const TimeOptions = [
  { label: "Afternoon", value: "Afternoon" },
  { label: "Evening", value: "Evening" },
  { label: "Bedtime", value: "Bedtime" },
];
export const IncomeOptions = [
  { label: "Employment", value: "Employment" },
  { label: "Hobby", value: "Hobby" },
  { label: "Child support", value: "Child support" },
  { label: "Other", value: "OTHER" },
];

export const benefitsOptions = [
  {
    label: "Supplemental Security Income (SSI)",
    value: "Suplemental Security Income (SSI)",
  },
  {
    label: "Social Security Disability Insurance(SSDI)",
    value: "Social Security Disability Insurance(SSDI)",
  },
  {
    label: "Temporary Assistance for Needed Families (TANF)",
    value: "Temporary Assistance for Needed Families (TANF)",
  },
  {
    label: "Supplemental Nutrition Assistance Program Virginia (SNAP)",
    value: "Supplemental Nutrition Assistance Program Virginia (SNAP)",
  },
  { label: "Unemployment insurance", value: "Unemployment insurance" },
  { label: "Veterans benefits", value: "Veterans benefits" },
  { label: "Workers compensation", value: "Workers compensation" },
  { label: "Auxiliary income", value: "Auxiliary income" },
  { label: "General relief", value: "General relief" },
  { label: "Railroad benefits", value: "HoRailroad benefits" },
];

export const insuranceOptions = [
  { label: "Medicaid Health Insurance", value: "Medicaid Health Insurance" },
  { label: "Medicare health insurance", value: "Medicare health insurance" },
  { label: "Medicaid waiver", value: "Medicaid waiver" },
  { label: "Other", value: "OTHER" },
];

export const HoursPerMonthOptions = [
  { label: "January", value: 'January' },
  { label: "February", value: 'February' },
  { label: "March", value: 'March' },
  { label: "April", value: 'April' },
  { label: "May", value: 'May' },
  { label: "June", value: 'June' },
  { label: "July", value: 'July' },
  { label: "August", value: 'August' },
  { label: "September", value: 'September' },
  { label: "October", value: 'October' },
  { label: "November", value: 'November' },
  { label: "December", value: 'December' },
];


export const EmploymentOptions = [
  { label: "Seeking employment", value: "Seeking employment" },
  { label: "Employment pending", value: "Employment pending" },
  { label: "Employed", value: "Employed" },
  { label: "Other", value: "OTHER" },
];

export const CONSENTOPTIONS = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const MATTERSTATUS = [
  { label: "Ongoing", value: "Ongoing" },
  { label: "Pending", value: "Pending" },
  { label: "Dismissed", value: "Dismissed" },
  { label: "Convicted", value: "Convicted" },
  { label: "Other", value: "OTHER" },
];

export const CLIENTPUNCTUALOPTIONS = [
  { label: "Early", value: "Early" },
  { label: "On time", value: "On time" },
  { label: "Tardy", value: "Tardy" },
];

export const CLIENTAPPARANCEPTIONS = [
  { label: "Professional", value: "Professional" },
  { label: "Well-dressed", value: "Well-dressed" },
  { label: "Disarrayed", value: "Disarrayed" },
];

export const CLIENTBEHAVIOREPTIONS = [
  { label: "Engaged", value: "Engaged" },
  { label: "Good eye-contact", value: "Good eye-contact" },
  { label: "Cooperative", value: "Cooperative" },
  { label: "Evasive", value: "Evasive" },
  { label: "Defensive", value: "Defensive" },
  { label: "Inappropriate language", value: "Inappropriate language" },
  { label: "Disengaged", value: "Disengaged" },
];

export const EDUCATIONLEVEL = [
  {
    label: "Less Then High School Diploma",
    value: "Less Then Hight School Diploma",
  },
  {
    label: "General Education Diploma (G.E.D.)",
    value: "General Education Diploma (G.E.D.)",
  },
  { label: "High School Diploma", value: "High School Diploma" },
  {
    label: "Post Secondary/Technical Certificate",
    value: "Post Secondary/Technical Certificate",
  },
  { label: "Associate's Degree", value: "Associate's Degree" },
  { label: "Bachelor's Degree", value: "Bachelor's Degree" },
  { label: "Master's Degree", value: "Master's Degree" },
  { label: "Doctorate Degree (PhD)", value: "Doctorate Degree (PhD)" },
];

export const hoursOptions = [
  { label: 0, value: '00' },
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 9, value: 9 },
  { label: 10, value: 10 },
  { label: 11, value: 11 },
  { label: 12, value: 12 },
];

export const minutesOptions = [
  { label: "00", value: "00" },
  { label: 15, value: 15 },
  { label: 30, value: 30 },
  { label: 45, value: 45 },
];

export const timecaseOptions = [
  { label: "am", value: "am" },
  { label: "pm", value: "pm" },
];

export const goalOptions = [
  { label: "Demographic assessment", value: 0 },
  { label: "Home assessment", value: 1 },
  { label: "Mental health assessment", value: 2 },
  { label: "Career assessment", value: 3 },
  { label: "Benefits/Budget assessment", value: 4 },
  { label: "Comprehensive report development", value: 5 },
  { label: "Edit reports", value: 6 },
  { label: "Supervisor case", value: 7 },
];

export const assignmentOptions = [
  { label: "Demographic assessment", value: 0 },
  { label: "Report development", value: 1 },
  { label: "Communication/Meetings", value: 2 },
  { label: "TAPT team meeting", value: 3 },
  { label: "Other", value: 4 },
];

export const serviceTypeOptions = [
  { label: "In person", value: 0 },
  { label: "Virtual", value: 1 },
  { label: "Phone", value: 2 },
  { label: "Text", value: 3 },
  { label: "Email", value: 4 },
  { label: "Case work", value: 5 },
];

export const meetingOptions = [
  { label: "Customer", value: 0 },
  { label: "Agency", value: 1 },
  { label: "Employer", value: 2 },
  { label: "Internal meeting", value: 3 },
  { label: "Other", value: 4 },
];

export const amnitiesOptions = [
  { label: "Playground", value: "Playground" },
  { label: "Pool", value: "Pool" },
  { label: "Clubhouse", value: "Clubhouse" },
  { label: "Gazebo", value: "Gazebo" },
  { label: "Tennis court", value: "Tennis court" },
  { label: "Neighborhood watch program", value: "Neighborhood watch program" },
  { label: "Basketball court", value: "Basketball court" },
  { label: "Bus stop", value: "Bus stop" },
  { label: "Bike trail", value: "Bike trail" },
  { label: "Walking trail", value: "Walking trail" },
  { label: "Other", value: "OTHER" },
];

export const neighborhoodConditionOptions = [
  { label: "Well maintained landscape", value: "Well maintained landscape" },
  { label: "Loiters", value: "Loiters" },
  { label: "Evidence of crime", value: "Evidence of crime" },
  { label: "Sense of unsafety", value: "Sense of unsafe" },
  {
    label: "Elevated noise in the neighborhood",
    value: "Elevated noise in the neighborhood",
  },
  { label: "Paper and debris", value: "Paper and debris" },
  { label: "Roads well maintained", value: "Roads well maintained" },
  { label: "Roads damaged", value: "Roads damaged" },
  { label: "Quiet neighborhood", value: "Quiet neighborhood" },
  { label: "Graffiti", value: "Graffiti" },
  { label: "Property damage", value: "Property damage" },
  { label: "Well lit entryway", value: "Well lit entryway" },
  { label: "Other", value: "OTHER" },
];

export const frontHomeCondition = [
  { label: "No front yard", value: "No front yard" },
  { label: "Grass and yard neat", value: "Grass and yard neat" },
  { label: "Yard area unkept", value: "Yard area unkept" },
  { label: "Debris / garbage / clutter", value: "Debris / garbage / clutter" },
  { label: "Broken windows", value: "Broken windows" },
  { label: "Damaged doors", value: "Damaged doors" },
  {
    label: "Front of home in good condition",
    value: "Front of home in good condition",
  },
  { label: "Other", value: "OTHER" },
];

export const backHomeCondition = [
  { label: "No backyard", value: "No backyard" },
  { label: "Grass and yard neat", value: "Grass and yard neat" },
  { label: "Yard area unkept", value: "Yard area unkept" },
  { label: "Debris / garbage / clutter", value: "Debris / garbage / clutter" },
  { label: "Broken windows", value: "Broken windows" },
  { label: "Damaged doors", value: "Damaged doors" },
  {
    label: "Back of home in good condition",
    value: "Back of home in good condition",
  },
  { label: "Other", value: "OTHER" },
];

export const outdoorResources = [
  { label: "Shed", value: "Shed" },
  { label: "Lawn furniture", value: "Lawn furniture" },
  { label: "Playset", value: "Playset" },
  { label: "Fenced in backyard", value: "Fenced in backyard" },
  { label: "Fenced in frontyard", value: "Fenced in frontyard" },
  { label: "No backyard", value: "No backyard" },
  { label: "Other", value: "OTHER" },
];

export const dwellingTypeOptions = [
  { label: "Apartment", value: "Apartment" },
  { label: "Duplex", value: "Duplex" },
  { label: "Townhouse", value: "Townhouse" },
  { label: "Rowhouse", value: "Rowhouse" },
  { label: "Single story home", value: "Single story home" },
  { label: "Two story home", value: "Two story home" },
  { label: "Three story home", value: "Three story home" },
  { label: "Hotel", value: "Hotel" },
  { label: "Shelter", value: "Shelter" },
  { label: "Housing deprived", value: "Housing deprived" },
  { label: "Boarder", value: "Boarder" },
  { label: "Other", value: "OTHER" },
];

export const livingRoomOptions = [
  { label: "Excellent", value: "Excellent" },
  { label: "Above average", value: "Above average" },
  { label: "Average", value: "Average" },
  { label: "Below average", value: "Below average" },
  { label: "Poor", value: "Poor" },
];

export const kitchenTypeOptions = [
  { label: "Full kitchen", value: "Full kitchen" },
  { label: "Partial kitchen", value: "Partial kitchen" },
  { label: "Shared kitchen", value: "Shared kitchen" },
  { label: "No kitchen", value: "No kitchen" },
  { label: "Other", value: "OTHER" },
];

export const kitchenOptions = [
  { label: "Excellent", value: "Excellent" },
  { label: "Above average", value: "Above average" },
  { label: "Average", value: "Average" },
  { label: "Below average", value: "Below average" },
  { label: "Poor", value: "Poor" },
  { label: "Other", value: "OTHER" },
];

export const wallConditionOptions = [
  { label: "Good condition", value: "Good condition" },
  { label: "Holes in the wall", value: "Holes in the wall" },
  { label: "Wire exposed", value: "Wire exposed" },
  { label: "Other", value: "OTHER" },
];

export const appliancesOptions = [
  { label: "Working condition", value: "Working condition" },
  { label: "Needs maintenance", value: "Needs maintenance" },
  { label: "Other", value: "OTHER" },
];

export const bedroomLocationOptions = [
  { label: "Largest bedroom", value: "Largest bedroom" },
  { label: "Downstairs hallway", value: "Downstairs hallway" },
  { label: "Upstairs hallway", value: "Upstairs hallway" },
  { label: "Ensuite bedroom", value: "Ensuite bedroom" },
  { label: "Other", value: "OTHER" },
];

export const bedTypeOptions = [
  { label: "King size", value: "King size" },
  { label: "Queen size", value: "Queen size" },
  { label: "Twin bed", value: "Twin bed" },
  { label: "Bunk bed", value: "Bunk bed" },
  { label: "Other", value: "OTHER" },
];
export const BathroomLocationOptions = [
  { label: "Downstairs", value: "Downstairs" },
  { label: "Upstairs", value: "Upstairs" },
  { label: "Ensuite bathroom", value: "Ensuite bathroom" },
  { label: "Other", value: "OTHER" },
];

export const bathroomTypeOptions = [
  { label: "Operational single sink", value: "Operational single sink" },
  { label: "Operational double sink", value: "Operational double sink" },
  { label: "Operational toilet", value: "Operational toilet" },
  { label: "Operational bathtub", value: "Operational bathtub" },
  { label: "Operational shower", value: "Operational shower" },
  { label: "Other", value: "OTHER" },
];

export const washerOptions = [
  { label: "Washer unit", value: "Washer unit" },
  { label: "Washer connection", value: "Washer connection" },
  { label: "None", value: "None" },
  { label: "Other", value: "OTHER" },
];

export const dryerOptions = [
  { label: "Dryer unit", value: "Dryer unit" },
  { label: "Dryer connection", value: "Dryer connection" },
  { label: "None", value: "None" },
  { label: "Other", value: "OTHER" },
];

export const housingGoalOptions = [
  { label: "Obtain housing", value: "Obtain housing" },
  { label: "Obtain affordable housing", value: "Obtain affordable housing" },
  { label: "Improve current housing", value: "Improve current housing" },
  { label: "Move to safe area", value: "Move to safe area" },
  { label: "Public housing", value: "Public housing" },
  { label: "Other", value: "OTHER" },
];

export const ADAComplaintOptions = [
  { label: "Hearing impaired doorbell", value: "Hearing impaired doorbell" },
  { label: "Visually impaired doorbell", value: "Visually impaired doorbell" },
  {
    label: "Visually impaired lighting system",
    value: "Visually impaired lighting system",
  },
  { label: "Braille apartment signage", value: "Braille apartment signage" },
  { label: "Other", value: "OTHER" },
];

export const wheelchairOptions = [
  { label: "Wheelchair accessible ramp", value: "Wheelchair accessible ramp" },
  { label: "Elevator", value: "Elevator" },
  {
    label: "Wheelchair accessible kitchen countertops",
    value: "Wheelchair accessible kitchen countertops",
  },
  { label: "Accessible stove", value: "Accessible stove" },
  { label: "Modified bathroom sink", value: "Modified bathroom sink" },
  { label: "Modified bathroom shower", value: "Modified bathroom shower" },
  { label: "Other", value: "OTHER" },
];

export const ordrHomeOptions = [
  { label: "Unclean smell", value: "Unclean smell" },
  { label: "Mold", value: "Mold" },
  { label: "Urine", value: "Urine" },
  { label: "Cigarette or cigar smoke", value: "Cigarette or cigar smoke" },
  { label: "Animal odor", value: "Animal Odor" },
  { label: "Evidence of alcohol use", value: "Evidence of alcohol use" },
  { label: "Marijuana odor", value: "Marijuana odor" },
  { label: "Other", value: "OTHER" },
];

export const entryToHomeOptions = [
  { label: "Good condition", value: "Good condition" },
  { label: "Fair condition", value: "Fair condition" },
  { label: "Poor condition", value: "Poor condition" },
  { label: "Other", value: "OTHER" },
];

export const VentilationOptions = [
  { label: "Central air", value: "Central air" },
  { label: "Window air conditioner", value: "Window air conditioner" },
  { label: "Central heat", value: "Central heat" },
  { label: "Portable heating units", value: "Portable heating units" },
  { label: "Other", value: "OTHER" },
];

export const heatingSystemType = [
  { label: "Electric", value: "Electric" },
  { label: "Gas", value: "Gas" },
  { label: "Other", value: "OTHER" },
];

export const teamPlayerOptions = [
  { label: "Team player", value: "Team player" },
  { label: "Work alone", value: "Work alone" },
];

export const educationRequirementOptions = [
  { label: "No educational requirment", value: "No educational requirement" },
  { label: "A high school diploma", value: "A high school diploma" },
  { label: "A certificate", value: "A certificate" },
  { label: "An associate's degree", value: "An associate's degree" },
  { label: "A bachelor's degree", value: "A bachelor's degree" },
  { label: "A master's degree", value: "A master's degree" },
  { label: "A doctoral degree", value: "A doctoral degree" },
  { label: "Other", value: "OTHER" },
];

export const hearingOptions = [
  { label: "Hearing aid", value: "Hearing aid" },
  { label: "Reading lips", value: "Reading lips" },
  { label: "Interpreter", value: "Interpreter" },
  { label: "Other", value: "OTHER" },
];

export const visionOptions = [
  { label: "Magnifying glasses", value: "Magnifying glasses" },
  { label: "Hand magnifiers", value: "Hand magnifiers" },
  { label: "Closed-circuit televisio", value: "Closed-circuit televisio" },
  { label: "Reading prisms", value: "Reading prisms" },
  { label: "Other", value: "OTHER" },
];

export const dailyActivityOptions = [
  { label: "No assistance", value: "No assistance" },
  { label: "Some assistance", value: "Some assistance" },
  { label: "Much assistance", value: "Much assistance" },
  { label: "Unable to perform", value: "Unable to perform" },
];

export const medicalConsent = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
  { label: "Not sure", value: "Not sure" },
  { label: "Other", value: "Other" },
];

export const menstrualProblems = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
  { label: "Not sure", value: "Not sure" },
  { label: "Other", value: "Other" },
  { label: "N/A", value: "N/A" },
];

export const publicHouseType = [
  { label: "Longley village", value: "Longley village" },
  { label: "North phoebus housing", value: "North phoebus housing" },
];

export const childRelationOptions = [
  { label: "Son", value: "Son" },
  { label: "Daughter", value: "Daughter" },
];

export const medicalIllnessOptions = [
  { label: "Diabetes", value: "Diabetes" },
  { label: "Asthma", value: "Asthma" },
  { label: "Other", value: "OTHER" },
];

export const mentalHealthOptions = [
  { label: "Anxiety", value: "Anxiety" },
  {
    label: "Attention deficit disorder(ADD)",
    value: "Attention deficit disorder(ADD)",
  },
  { label: "Autism", value: "Autism" },
  { label: "Bipolar Disorder", value: "Bipolar Disoder" },
  { label: "Depression", value: "Depression" },
  { label: "Drug Addition", value: "Drug Addition" },
  { label: "Substance Abuse", value: "Substance Abuse" },
  { label: "Schizophrenia", value: "Schizophrenia" },
];

export const childGradeOptions = [
  { label: "3k", value: "3k" },
  { label: "4k", value: "4k" },
  { label: "Kindergarten", value: "Kindergarten" },
  { label: "1st grade", value: "1st grade" },
  { label: "2nd grade", value: "2nd grade" },
  { label: "3rd grade", value: "3rd grade" },
  { label: "4th grade", value: "4th grade" },
  { label: "5th grade", value: "5th grade" },
  { label: "6th grade", value: "6th grade" },
  { label: "7th grade", value: "7th grade" },
  { label: "8th grade", value: "8th grade" },
  { label: "9th grade", value: "9th grade" },
  { label: "10th grade", value: "10th grade" },
  { label: "11th grade", value: "11th grade" },
  { label: "12th grade", value: "12th grade" },
  { label: "College", value: "Collage" },
];

export const insuranceType = [{ label: "Other", value: "OTHER" }];

export const amountType = [
  { label: "Monthly", value: "Monthly" },
  { label: "Bi-monthly", value: "Bi-monthly" },
  { label: "Quarterly", value: "Quarterly" },
  { label: "Inconsistent", value: "Inconsistent" },
];

export const numberOfJobs = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
];

export const DebtExpenseStatus = [
  { label: "Other", value: "Other" },
];

export const RefferedByOptions = [
  { label: "Agency 1", value: "Agency 1" },
  { label: "Agency 2", value: "Agency 2" },
];

export const AgencyDepartmentOptions = [
  { label: "Department 1", value: "Department 1" },
  { label: "Department 2", value: "Department 2" },
];

export const AgencyWorkerOptions = [
  { label: "Worker 1", value: "Worker 1" },
  { label: "Worker 2", value: "Worker 2" },
];

export const fatherOptions = [
  { label: "Remarried", value: "Remarried" },
  {
    label: "Married Another Person",
    value: "Married Another Person",
  },
  { label: "Never Married", value: "Never Married" },
  { label: "Remained Separated", value: "Remained Separated" },
  // { label: "Separated", value: "Separated" },
  { label: "Separated from another person", value: "Separated from another person" },

  { label: "Abandoned by another person", value: "Abandoned by another person" },
  { label: "Father Deceased", value: "Father Deceased" },
  { label: "Widowed from another marriage", value: "Widowed from another marriage" },
  { label: "In a relationship with another person", value: "In a relationship with another person" },
  // { label: "Other", value: "Other" },
];

export const motherOptions = [
  { label: "Remarried", value: "Remarried" },
  {
    label: "Married Another Person",
    value: "Married Another Person",
  },
  { label: "Never Married", value: "Never Married" },
  { label: "Remained Separated", value: "Remained Separated" },
  // { label: "Separated", value: "Separated" },
  { label: "Separated from another person", value: "Separated from another person" },

  { label: "Abandoned by another person", value: "Abandoned by another person" },
  { label: "Mother Deceased ", value: "Mother Deceased " },
  { label: "Widowed from another marriage", value: "Widowed from another marriage" },
  { label: "In a relationship with another person", value: "In a relationship with another person" },
  // { label: "Other", value: "Other" },
];


export const parentOptions = [
  { label: "Married", value: "Married" },
  { label: "Separated", value: "Separated" },
  { label: "Divorced", value: "Divorced" },
  { label: "Never Married", value: "Never Married" },
  { label: "Parent Deceased", value: "Parent Deceased" },
  { label: "Both Parents Deceased", value: "Both Parents Deceased" },
];

export const growingUpOptions = [
  { label: "Parents", value: "Parents" },
  { label: "Mother", value: "Mother" },
  { label: "Father", value: "Father" },
  { label: "Step-mother", value: "Step-mother" },
  { label: "Step-father", value: "Step-father" },
  { label: "Adoptive parent(s)", value: "Adoptive parent(s)" },
  { label: "Foster parent(s)", value: "Foster parent(s)" },
  { label: "Grandparents", value: "Grandparents" },
  { label: "Grandmother", value: "Grandmother" },
  { label: "Grandfather", value: "Grandfather" },
  { label: "Aunt(s)", value: "Aunt(s)" },
  { label: "Uncles(s)", value: "Uncles(s)" },
  { label: "Sister(s)", value: "Sister(s)" },
  { label: "Brother(s)", value: "Brother(s)" },
  { label: "Cousin(s)", value: "Cousin(s)" },
  { label: "Other", value: "Other" },
]

export const bornOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const siblingsOptions = [
  { label: "Bio-Sister", value: "Bio-Sister" },
  { label: "Bio-Brother", value: "Bio-Brother" },
  { label: "Step-Sister", value: "Step-Brother" },
  { label: "Half-Sister", value: "Half-Sister" },
  { label: "Half-Brother", value: "Half-Brother" },
  { label: "Adopted-Sister", value: "Adopted-Sister" },
  { label: "Adopted-Brother", value: "Adopted-Brother" },
  // { label: "Other", value: "Other" },
];

export const relationshipOptions = [
  { label: "Sister", value: "Sister" },
  { label: "Brother", value: "Brother" },
  { label: "Step-sister", value: "Step-sister" },
  { label: "Step-brother", value: "Step-brother" },
  { label: "Half-sister", value: "Half-sister" },
  { label: "Half-brother", value: "Half-brother" },
  { label: "Other", value: "Other" },
]

export const significantRelationshipOptions = [
  { label: "Father", value: "Father" },
  { label: "Sister", value: "Sister" },
  { label: "Brother", value: "Brother" },
  { label: "Aunt", value: "Aunt" },
  { label: "Uncle", value: "Uncle" },
  { label: "Grandmother", value: "Grandmother" },
  { label: "Grandfather", value: "Grandfather" },
  { label: "Friend", value: "Friend" },
  { label: "Boyfriend", value: "Boyfriend" },
  { label: "Girlfriend", value: "Girlfriend" },
  { label: "Other", value: "Other" },
]

export const therapyOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const answerOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
  { value: "Not Sure", label: "Not Sure" },
  { value: "Other", label: "Other" }
];

export const recevingTherapyOptions = [
  { value: "Individual outpatient therapy", label: "Individual outpatient therapy" },
  { value: "Group outpatient therapy", label: "Group outpatient therapy" },
]

export const recevingTherapyOptionsWith = [
  { value: "Individual outpatient therapy", label: "Individual outpatient therapy" },
  { value: "Group outpatient therapy", label: "Group outpatient therapy" },
]

export const recevingTherapyOptionsWithout = [
  { value: "None", label: "None" },
]

export const daysOptions = [
  { value: "Weeks", label: "Weeks" },
  { value: "Months", label: "Months" },
  { value: "Years", label: "Years" },
]

export const problemsOptions = [
  { value: "Mental Illness", label: "Mental Illness" },
  { value: "Impacted by suicidal individual", label: "Impacted by suicidal individual" },
  { value: "Alcoholism", label: "Alcoholism" },
  { value: "Drug abuser", label: "Drug abuser" },
  { value: "Other", label: "Other" },
  { value: "None", label: "None" },
]

export const personRelationOptions = [
  { label: "Mother", value: "Mother" },
  { label: "Father", value: "Father" },
  { label: "Sister", value: "Sister" },
  { label: "Brother", value: "Brother" },
  { label: "Uncle", value: "Uncle" },
  { label: "Grandmother", value: "Grandmother" },
  { label: "Grandfather", value: "Grandfather" },
  { label: "Friend", value: "Friend" },
  { label: "Spouse", value: "Spouse" },
  { label: "Boyfriend", value: "Boyfriend" },
  { label: "Girlfriend", value: "Girlfriend" },
  { label: "Other", value: "Other" },
];

export const mentallyProblems = [
  { label: "Mentally ill", value: "Mentally ill" },
  { label: "Suicidal", value: "Suicidal" },
  { label: "An alcoholic", value: "An alcoholic" },
  { label: "Abused drugs", value: "Abused drugs" },
  { label: "Other", value: "Other" },
  { label: "None", value: "None" },

]

export const emotionalEventOptions = [
  { label: "Cursed at", value: "Cursed at" },
  { label: "Insulted", value: "Insulted" },
  { label: "Made to feel less than adequate", value: "Made to feel less than adequate" },
  { label: "Other", value: "Other" },
  { label: "None", value: "None" },
]

export const timeOptions = [
  { label: "Past", value: "Past" },
  { label: "Present", value: "Present" },
]

export const eventOptions = [
  { label: "Beaten", value: "Beaten" },
  { label: "Punched", value: "Punched" },
  { label: "Other", value: "Other" },
  { label: "None", value: "None" },
]
export const sexualEventOptions = [
  { label: "Fondling", value: "Fondling" },
  { label: "Oral Sex", value: "Oral Sex" },
  { label: "Anal penetration", value: "Anal penetration" },
  { label: "Vaginal intercourse", value: "Vaginal intercourse" },
  { label: "Other", value: "Other" },
  { label: "None", value: "None" },
]

export const genderOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Transgender Male", value: "transgender male" },
  { label: "Transgender Female", value: "transgender female" },
  { label: "Transgender (as non-binary)", value: "transgender (as non-binary)" },
  { label: "Non-binary", value: "non-binary" },
  { label: "Gender-queer", value: "gender-queer" },
  { label: "Two-spirit", value: "two-spirit" },
  { label: "Other", value: "other" },
]

export const resultOptions = [
  { label: "Good", value: "Good" },
  { label: "Adequate", value: "Adequate" },
  { label: "Impaired", value: "Impaired" },
  { label: "Poor", value: "Poor" },
]

export const notExistOptions = [
  { label: "Auditory hallucinations reported", value: "Auditory hallucinations reported" },
  { label: "No auditory hallucinations reported", value: "No auditory hallucinations reported " },
]


export const voiceOptions = [
  { label: "Verbal hallucinations reported", value: "Verbal hallucinations reported" },
  { label: "No verbal hallucinations reported", value: "No verbal hallucinations reported " },
]

export const bodyOptions = [
  { label: "Tactile hallucinations reported", value: "Tactile hallucinations reported" },
  { label: "No tactile hallucinations reported", value: "No tactile hallucinations reported " },
  { label: "Oldfactory hallucinations reported", value: "Oldfactory hallucinations reported" },
  { label: "No oldfactory hallucinations reported", value: "No oldfactory hallucinations reported " }
]

export const harmingYourselfOptions = [
  { label: "Denied Suicidal Thoughts", value: "Denied Suicidal Thoughts" },
  { label: "Reported Suicidal Ideation", value: "Reported Suicidal Ideation" },
  { label: "Reported Suicidal Attempt", value: "Reported Suicidal Attempt" }
]

export const harmingOtherOptions = [
  { label: "Denied Homicidal Thoughts", value: "Denied Homicidal Thoughts" },
  { label: "Reported Homicidal Ideation", value: "Reported Homicidal Ideation" },
  { label: "Reported Homicidal Attempt", value: "Reported Homicidal Attempt" }
]

export const observationOptions = [
  { label: "Good", value: "Good" },
  { label: "Adequate", value: "Adequate" },
  { label: "Poor", value: "Poor" },
  { label: "Distracted", value: "Distracted" },
  { label: "Impaired", value: "Impaired" },
  { label: "Other", value: "Other" },
]

export const thoughtProcessOptions = [
  { label: "Organized", value: "Organized" },
  { label: "Processes Simplistic", value: "Processes Simplistic" },
  { label: "Preoccupied (distracted)", value: "Preoccupied (distracted)" },
  { label: "Confused", value: "Confused" },
  { label: "Pressure (intense responses)", value: "Pressure (intense responses)" },
  { label: "Other", value: "Other" },
]

export const groomingOption = [
  { label: "Good", value: "Good" },
  { label: "Adequate", value: "Adequate" },
  { label: "Poor", value: "Poor" },
]

export const delusionOptions = [
  { label: "No Delusion", value: "No Delusion" },
  { label: "Persecutory delusion", value: "Persecutory delusion" },
  { label: "Delusion of grandeur", value: "Delusion of grandeur" },
  { label: "Delusion jealousy", value: "Delusion jealousy" },
  { label: "Erotomania or delusion of love", value: "Erotomania or delusion of love" },
  { label: "Scmotic delusional disorder", value: "Scmotic delusional disorder" },
  { label: "Induced delusional disorder or folie a' deux", value: "Induced delusional disorder or folie a' deux" },
  { label: "Other", value: "Other" },
]

export const orientationOptions = [
  { label: "Oriented", value: "Oriented" },
  { label: "Disoriented", value: "Disoriented" },
  { label: "Unable to answer questions", value: "Unable to answer questions" },
]

export const provrebsOptions = [
  { label: "Good Understanding", value: "Good Understanding" },
  { label: "Average Understanding", value: "Average Understanding" },
  { label: "Poor Understanding", value: "Poor Understanding" },
]

export const judgmentOptions = [
  { label: "Good", value: "Good" },
  { label: "Adequate", value: "Adequate" },
  { label: "Poor", value: "Poor" },
]

export const assignmentGoalOptions = [
  { label: 'Demographic Assessment', value: 1 },
  { label: 'Home Assessment', value: 2 },
  { label: 'Mental Health Assessment', value: 3 },
  { label: 'Career Assessment', value: 4 },
  { label: 'Benefits/Budget Assessment', value: 5 },
  { label: 'Comprehensive Report', value: 6 },
]

export const raceIdentify = [
  { label: 'American Indian or Alaska Native', value: 'American Indian or Alaska Native' },
  { label: 'Asian', value: 'Asian' },
  { label: 'Black/African American', value: 'Black/African American' },
  { label: 'Hispanic or Latino', value: 'Hispanic or Latino' },
  { label: 'Native Hawaiian or Other Pacific Islander', value: 'Native Hawaiian or Other Pacific Islander' },
  { label: 'White', value: 'White' },
]


export const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];