import { createApi } from "@reduxjs/toolkit/query/react";
import AxiosBaseQuery from "../axiosBaseQuery";
import { developmentApi } from "../../config";

export const benefitsReportApi = createApi({
  reducerPath: "benefitsReportApi",
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: `${developmentApi}/api`,
  }),
  tagTypes: ["getBBreportSummary", "getAllBBCriticalConcern"],
  endpoints: (builder) => ({
    addBBReportSummary: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-bbreport-summary?ClientId=${ClientId}`,
        method: "POST",
        fileUpload: true,
        payload: payload,
      }),
      invalidatesTags: ["getBBreportSummary"],
      transformResponse: (response) => JSON.parse(response),
    }),
    updateBBReportSummary: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/update-bbreport-summary?id=${id}`,
        method: "PUT",
        fileUpload: true,
        payload: payload,
      }),
      invalidatesTags: ["getBBreportSummary"],
      transformResponse: (response) => JSON.parse(response),
    }),
    getBBReportSummary: builder.query({
      query: ({ id }) => ({
        url: `/company/get-bbreport-summary?id=${id}`,
        method: "GET",
      }),
      providesTags: ["getBBreportSummary"],
      transformResponse: (response) => JSON.parse(response)?.data,
    }),
    addBBCriticalConcern: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-Benefits-Critical-Concerns-FollowUps?ClientId=${ClientId}`,
        method: "POST",
        payload: payload,
      }),
      invalidatesTags: ["getAllBBCriticalConcern"],
      transformResponse: (response) => JSON.parse(response),
    }),
    updateBBCriticalConcern: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/update-Benefits-Critical-Concerns-FollowUps?id=${id}`,
        method: "PUT",
        payload: payload,
      }),
      invalidatesTags: ["getAllBBCriticalConcern"],
      transformResponse: (response) => JSON.parse(response)?.data,
    }),
    getBBCriticalConcernById: builder.query({
      query: ({ id }) => ({
        url: `/company/get-Benefits-Critical-Concerns-FollowUps?id=${id}`,
        method: "GET",
      }),
      providesTags: ["getAllBBCriticalConcern"],
      transformResponse: (response) => JSON.parse(response)?.data,
    }),
    getAllBBCriticalConcern: builder.query({
      query: ({ clientId }) => ({
        url: `/company/get-Benefits-All-Critical-Concerns-FollowUps?clientId=${clientId}`,
        method: "GET",
      }),
      providesTags: ["getAllBBCriticalConcern"],
      transformResponse: (response) => JSON.parse(response)?.data,
    }),
    deleteBBCriticalConcern: builder.mutation({
      query: ({ id }) => ({
        url: `/company/delete-Benefits-Critical-Concerns-FollowUps?id=${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["getAllBBCriticalConcern"],
      transformResponse: (response) => JSON.parse(response)?.data,
    }),
  }),
});

export const {
  useAddBBReportSummaryMutation,
  useUpdateBBReportSummaryMutation,
  useGetBBReportSummaryQuery,
  useAddBBCriticalConcernMutation,
  useUpdateBBCriticalConcernMutation,
  useGetBBCriticalConcernByIdQuery,
  useGetAllBBCriticalConcernQuery,
  useDeleteBBCriticalConcernMutation,
} = benefitsReportApi;
