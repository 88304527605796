import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const mentalHealthCognitiveApi = createApi({
    reducerPath: 'mentalHealthCognitiveApi',
    refetchOnMountOrArgChange: true,
    keepUnusedDataFor: 0,
    refetchOnReconnect: true,
    baseQuery: AxiosBaseQuery({
        baseUrl: `${developmentApi}/api`,
    }),
    tagTypes: ['getCalculationTask','getMemoryTask','getForwardReverseTask', 'getCognitiveIdentifyTask'],
    endpoints: (builder) => ({
        addCalculationTask: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-calculation-task?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getCalculationTask'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateCalculationTask: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-calculation-task?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getCalculationTask'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getCalculationTask: builder.query({
            query: ({ id }) => ({
                url: `/company/get-calculation-task?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['getCalculationTask'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addForwardReverseTask: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-forward-reverse-task?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getForwardReverseTask'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateForwardReverseTask: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-forward-reverse-task?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getForwardReverseTask'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getForwardReverseTask: builder.query({
            query: ({ id }) => ({
                url: `/company/get-forward-reverse-task?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['getForwardReverseTask'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addMemoryTask: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-memory-task?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getMemoryTask'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateMemoryTask: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-memory-task?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getMemoryTask'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getMemoryTask: builder.query({
            query: ({ id }) => ({
                url: `/company/get-memory-task?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['getMemoryTask'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addCognitiveIdentifyTask: builder.mutation({
            query: ({payload }) => ({
                url: `/company/add-Cognitive-Identify-Task`,
                method: 'POST',
                payload: payload,
            }),
            invalidatesTags: ['getCognitiveIdentifyTask'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateCognitiveIdentifyTask: builder.mutation({
            query: ({ payload }) => ({
                url: `/company/update-Cognitive-Identify-Task`,
                method: 'PUT',
                payload: payload,
            }),
            invalidatesTags: ['getCognitiveIdentifyTask'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getCognitiveIdentifyTask: builder.query({
            query: (clientId) => ({
                url: `/company/get-Cognitive-Identify-Task/${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getCognitiveIdentifyTask'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
    }),
})

export const { useAddCalculationTaskMutation, useUpdateCalculationTaskMutation, useGetCalculationTaskQuery, 
    useAddForwardReverseTaskMutation, useUpdateForwardReverseTaskMutation, useGetForwardReverseTaskQuery, 
    useAddMemoryTaskMutation, useUpdateMemoryTaskMutation, useGetMemoryTaskQuery, 
    useAddCognitiveIdentifyTaskMutation, useUpdateCognitiveIdentifyTaskMutation, useGetCognitiveIdentifyTaskQuery} = mentalHealthCognitiveApi;
