import { Box, Typography,Grid, Button } from "@mui/material";
import React ,{useState, useEffect} from 'react';
import { theme } from "../../theme/theme";
import registerCreative from "../../assets/Images/register-creative.png";
import Logo from "../../assets/Images/Logo.png"
import { useNavigate } from "react-router-dom/dist";
export default function Page404(){
    const navigate = useNavigate()
    return (
        <Grid container sx={{ height: "100vh" }}>
        <Grid item xs={12} md={6} backgroundColor="#FFF8EE" padding="30px">
            <Grid item lg={12}><img src={Logo} alt="Logo" /></Grid>
            <Grid container justifyContent={"center"} paddingTop="5vh">
            <Grid container justifyContent={"center"} >
                    <img src={registerCreative} alt="registerCreative" className="authCreative" />
                </Grid>
                <Grid sx={{ mt: 3 }}>
                    <Typography variant="h1" textAlign="center">Lorem ipsum dolor sit amet</Typography>
                    <Typography variant="h5" align="center">Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit</Typography>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12}  md={6} container alignItems={"center"} justifyContent="center"  sx={{
            p: "0 3rem",flexDirection:"column",
            [theme.breakpoints.down('md')]: {
                p: '1.5rem',
            },
        }} >
            <Typography sx={{textAlign:'center'}} variant="h2">404 <br /> Page not found!</Typography><br /> 
            <Button variant="contained" onClick={()=> navigate(-1)}>Back</Button>

        </Grid>
    </Grid>
    )
}