import { Grid, Typography } from "@mui/material";
import React ,{useState, useEffect} from 'react';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/Images/Logo.png"
import registerCreative from "../../assets/Images/register-creative.png";
import loginCreative from "../../assets/Images/login-creative.png";
import verificationCreative from "../../assets/Images/verification-creative.png";
import forgotPasswordcreative from "../../assets/Images/forgotPassword-creative.png";
import resetPasswordCreative from "../../assets/Images/resetPassword-creative.png";
import underConstruction from "../../assets/Images/underConstruction.jpg";
import { theme } from "../../theme/theme";
export default function AuthenicationOutlet() {
    const navigate=useNavigate()
    /* `const location = useLocation();` is a React hook that is used to get the current location of
    the application. It is imported from the `react-router-dom` library. It returns an object that
    contains information about the current URL path and query parameters. In this code, it is used
    to conditionally render different images based on the current URL path. */
    const location = useLocation();
    return (
        <Grid container sx={{ height: "100vh" }}>
            <Grid item xs={12} md={6} backgroundColor="#FFF8EE" padding="30px">
                <Grid item lg={12}><img src={Logo} alt="Logo" /></Grid>
                <Grid container justifyContent={"center"} paddingTop="5vh" sx={{display: 'flex', height: '85%',alignItems: 'center'}}>
                    {(location?.pathname === "/register" || location?.pathname === "/emailverify" || location?.pathname.includes("/confirmemail")) && <Grid container justifyContent={"center"} >
                        <img src={registerCreative} alt="registerCreative" className="authCreative" />
                    </Grid>}
                    {location?.pathname === "/" && <Grid container justifyContent={"center"} >
                        <img src={loginCreative} alt="loginCreative" className="authCreative" />
                    </Grid>}
                    {location?.pathname === "/otpverify" && <Grid container justifyContent={"center"} >
                        <img src={verificationCreative} alt="loginCreative" className="authCreative" />
                    </Grid>}
                    {(location?.pathname === "/forgotpasswordlink" || location?.pathname === "/forgotpassword" || location?.pathname?.includes("/clientsetpassword" ) || location?.pathname?.includes("/staffsetpassword")) && <Grid container justifyContent={"center"} >
                        <img src={forgotPasswordcreative} alt="loginCreative" className="authCreative" />
                    </Grid>}
                    {(location?.pathname.includes('/forgetpassword')) && <Grid container justifyContent={"center"} >
                        <img src={resetPasswordCreative} alt="loginCreative" className="authCreative" />
                    </Grid>}
                    {(location?.pathname === "/privacypolicy" || location?.pathname==="/terms&condition") && <Grid container justifyContent={"center"} >
                        <img src={loginCreative} alt="loginCreative" className="authCreative" />
                    </Grid>}
                    {/* <Grid sx={{ mt: 3 }}>
                        <Typography variant="h1" textAlign="center">Welcome to CollabSetGo</Typography>
                        <Typography variant="h5" align="center">Collaborating to Success</Typography>
                        <Typography variant="subtitle2" align="center"><span className="pointerCursor" onClick={()=>navigate('/')}>Login</span> or <span className="pointerCursor" onClick={()=>navigate('/register')}>Register</span></Typography>
                    </Grid> */}
                </Grid>
            </Grid>
            <Grid item xs={12}  md={6} container alignItems={"center"} sx={{
                p: "0 3rem",
                [theme.breakpoints.down('md')]: {
                    p: '1.5rem',
                },
            }} >
                <Outlet />
            </Grid>
        </Grid>
    )
}