import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const mentalHealthMedicalApi = createApi({
    reducerPath: 'mentalHealthMedicalApi',
    refetchOnMountOrArgChange: true,
    keepUnusedDataFor: 0,
    refetchOnReconnect: true,
    baseQuery: AxiosBaseQuery({
        baseUrl: `${developmentApi}/api`,
    }),
    tagTypes: ['getTherapy', 'getSubstance', 'getReportMHS', 'getComprehensiveReport', 'getReportCriticalReport'],
    endpoints: (builder) => ({
        addTherapy: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-therapy-psy-treatment?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getTherapy'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateTherapy: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-therapy-psy-treatment?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getTherapy'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getTherapy: builder.query({
            query: ({ id }) => ({
                url: `/company/get-therapy-psy-treatment?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['getTherapy'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addSubstance: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-substance-abuse-history?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getSubstance'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateSubstance: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-substance-abuse-history?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getSubstance'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getSubstance: builder.query({
            query: ({ id }) => ({
                url: `/company/get-substance-abuse-history?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['getSubstance'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addReportMHS: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-report-comment-mhs?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getReportMHS'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateReportMHS: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-report-comment-mhs?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getReportMHS'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getReportMHS: builder.query({
            query: ({ id }) => ({
                url: `/company/get-report-comment-mhs?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['getReportMHS'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addComprehensiveReport: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-report-summery-mental-health?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getComprehensiveReport'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateComprehensiveReport: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-report-summery-mental-health?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getComprehensiveReport'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getComprehensiveReport: builder.query({
            query: ({ id }) => ({
                url: `/company/get-report-summery-mental-health?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['getComprehensiveReport'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addReportCritical: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-report-critical-concerns-mha?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getReportCriticalReport'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateReportCritical: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-report-critical-concerns-mha?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getReportCriticalReport'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getReportCriticalById: builder.query({
            query: ({ id }) => ({
                url: `/company/get-report-critical-concerns-mha?id=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ['getReportCriticalReport'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getReportCritical: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-all-report-critical-concerns-mha?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getReportCriticalReport'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        DeleteReportCritical: builder.mutation({
            query: ({ id }) => ({
                url: `/company/delete-report-critical-concerns-mha?id=${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['getReportCriticalReport'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
    }),
})

export const { useAddTherapyMutation, useUpdateTherapyMutation, useGetTherapyQuery, 
    useAddSubstanceMutation, useUpdateSubstanceMutation, useGetSubstanceQuery, 
    useAddReportMHSMutation, useUpdateReportMHSMutation, useGetReportMHSQuery, 
    useAddComprehensiveReportMutation, useUpdateComprehensiveReportMutation, useGetComprehensiveReportQuery, 
    useAddReportCriticalMutation, useUpdateReportCriticalMutation, useGetReportCriticalByIdQuery, useGetReportCriticalQuery, useDeleteReportCriticalMutation } = mentalHealthMedicalApi;
