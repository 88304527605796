import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const careerObservationApi = createApi({
  reducerPath: 'careerObservationApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: `${developmentApi}/api`,
  }),
  tagTypes:['getCareerObservation'],
  endpoints: (builder) => ({
    addCareerObservation: builder.mutation({
      query: ({ClientId, payload})=>({
        url:`/company/add-career-observation?ClientId=${ClientId}`,
        method:'POST',
        fileUpload:true,
        payload:payload
      }),
      invalidatesTags:['getCareerObservation'],
      transformResponse:(response)=> JSON.parse(response)?.data
    }),
    updateCareerObservation: builder.mutation({
      query: ({id, payload})=>({
        url:`/company/update-career-observation?id=${id}`,
        method:'PUT',
        fileUpload:true,
        payload:payload
      }),
      invalidatesTags:['getCareerObservation'],
      transformResponse:(response)=> JSON.parse(response)?.data
    }),
    getCareerObservation: builder.query({
        query: ({id})=>({
          url:`/company/get-career-observation?id=${id}`,
          method:'GET',
        }),
        providesTags:['getCareerObservation'],
        transformResponse:(response)=> JSON.parse(response)?.data
      }),
  }),
})

export const {useAddCareerObservationMutation, useUpdateCareerObservationMutation, useGetCareerObservationQuery}=careerObservationApi;
