import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const benefitsInerviewApi = createApi({
    reducerPath: 'benefitsInerviewApi',
    refetchOnMountOrArgChange: true,
    keepUnusedDataFor: 0,
    refetchOnReconnect: true,
    baseQuery: AxiosBaseQuery({
        baseUrl: `${developmentApi}/api`,
    }),
    tagTypes: ['getDisability', 'getHealthInsurance', 'getOtherBenefits', 'getHousingBenefits', 'getChild', 'getChildBenefits', 'getBenefitInterviewSummary', 'getBBReportSummary'],
    endpoints: (builder) => ({
        addDisability: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-disability-cash-benefits?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getDisability'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateDisability: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-disability-cash-benefits?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getDisability'],
            transformResponse: (response) => JSON.parse(response)
        }),
        getDisability: builder.query({
            query: ({ id }) => ({
                url: `/company/get-disability-cash-benefits?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['getDisability'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addHealthInsurance: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-health-insurance?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getHealthInsurance'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateHealthInsurance: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-health-insurance?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getHealthInsurance'],
            transformResponse: (response) => JSON.parse(response)
        }),
        getHealthInsurance: builder.query({
            query: ({ id }) => ({
                url: `/company/get-health-insurance?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['getHealthInsurance'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addOtherBenefits: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-other-benefit?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getOtherBenefits'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateOtherBenefits: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-other-benefit?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getOtherBenefits'],
            transformResponse: (response) => JSON.parse(response)
        }),
        getOtherBenefits: builder.query({
            query: ({ id }) => ({
                url: `/company/get-other-benefit?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['getOtherBenefits'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addHousingBenefits: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-housing-benefit?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getHousingBenefits'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateHousingBenefits: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-housing-benefit?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getHousingBenefits'],
            transformResponse: (response) => JSON.parse(response)
        }),
        getHousingBenefits: builder.query({
            query: ({ id }) => ({
                url: `/company/get-housing-benefit?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['getHousingBenefits'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addChild: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-child?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getChild'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateChild: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-child?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getChild'],
            transformResponse: (response) => JSON.parse(response)
        }),
        getChild: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-all-child?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getChild'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getChildById: builder.query({
            query: ({ id }) => ({
                url: `/company/get-child?id=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ['getChild'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        deleteChild: builder.mutation({
            query: ({ id }) => ({
                url: `/company/delete-child?id=${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['getChild'],
            transformResponse: (response) => JSON.parse(response)
        }),
        addChildBenefits: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-child-benefit?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getChildBenefits'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateChildBenefits: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-child-benefit?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getChildBenefits'],
            transformResponse: (response) => JSON.parse(response)
        }),
        getChildBenefits: builder.query({
            query: ({ id }) => ({
                url: `/company/get-child-benefit?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['getChildBenefits'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addBenefitInterviewSummary: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-benefit-interview-additional?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getBenefitInterviewSummary'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateBenefitInterviewSummary: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-benefit-interview-additional?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getBenefitInterviewSummary'],
            transformResponse: (response) => JSON.parse(response)
        }),
        getBenefitInterviewSummary: builder.query({
            query: ({ id }) => ({
                url: `/company/get-benefit-interview-additional?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['getBenefitInterviewSummary'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addBBReportSummary: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-report-comment-benefit-budget?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
            }),
            invalidatesTags: ['getBBReportSummary'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateBBReportSummary: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-report-comment-benefit-budget?id=${id}`,
                method: 'PUT',
                payload: payload,
            }),
            invalidatesTags: ['getBBReportSummary'],
            transformResponse: (response) => JSON.parse(response)
        }),
        getBBReportSummary: builder.query({
            query: ({ ClientId }) => ({
                url: `/company/get-report-comment-benefit-budget?ClientId=${ClientId}`,
                method: 'GET',
            }),
            providesTags: ['getBBReportSummary'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
    }),
})

export const { useAddDisabilityMutation, useUpdateDisabilityMutation, useGetDisabilityQuery,
    useAddHealthInsuranceMutation, useUpdateHealthInsuranceMutation, useGetHealthInsuranceQuery,
    useAddOtherBenefitsMutation, useUpdateOtherBenefitsMutation, useGetOtherBenefitsQuery,
    useAddHousingBenefitsMutation, useUpdateHousingBenefitsMutation, useGetHousingBenefitsQuery,
    useAddChildMutation, useUpdateChildMutation, useGetChildQuery, useGetChildByIdQuery, useDeleteChildMutation,
    useAddChildBenefitsMutation, useUpdateChildBenefitsMutation, useGetChildBenefitsQuery,
    useAddBenefitInterviewSummaryMutation, useUpdateBenefitInterviewSummaryMutation, useGetBenefitInterviewSummaryQuery, 
    useAddBBReportSummaryMutation, useUpdateBBReportSummaryMutation, useGetBBReportSummaryQuery } = benefitsInerviewApi;
