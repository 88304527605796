import {
  Box,
  Typography,
  Container,
  Tooltip,
  IconButton,
  AppBar,
  Button,
  Divider,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CardContent from "@mui/material/CardContent";
import vectorImage from "../../../assets/Images/Vector.png";
import logoImage from "../../../assets/Images/cover-logo.png";
import abstractImage from "../../../assets/Images/abstractt.png";
import palette from "../../../theme/palette";
import { useCompanyProfileQuery } from "../../../store/services/companyServices";

export default function ReportHeader({ data }) {
  const { data: companyData = {} } = useCompanyProfileQuery({});

  const customerData = data?.clientProfile || [];
  return (
    <Container
      disableGutters
      style={{ maxWidth: "100%", position: "relative" }}
    >
      <AppBar position="static" style={{ border: "none", padding: "80px" }}>
        <Box className="logo-right">
          <Box>
            <img
              src={logoImage}
              alt="vectorImage"
              style={{ width: "400px", maxWidth: "100%" }}
            />
          </Box>
        </Box>
      </AppBar>
      <Box className="left-line">
        <img
          src={vectorImage}
          alt="vectorImage"
          style={{ height: "100%", maxWidth: "100%" }}
        />
      </Box>
      <Box className="bottomleft-line">
        <img
          src={abstractImage}
          alt="vectorImage"
          style={{ height: "100%", maxWidth: "100%" }}
        />
      </Box>
      <Grid spacing={2}>
        <Card className="content-part" sx={{ minWidth: 275, mt: "150px" }}>
          <CardContent sx={{ paddingLeft: 14 }}>
            <Typography color="text.secondary" gutterBottom>
              <span style={{ color: palette.secondary.main }}>Case Note</span>{" "}
              Report
            </Typography>
            <List className="listing-content" sx={{ mt: "177px" }}>
              <h5 style={{ color: palette.text.light }}>
                Customer Information
              </h5>
              <ListItem
                sx={{
                  alignItems: "flex-start",
                  flexDirection: "column",
                  padding: "0",
                }}
              >
                <ListItemText>
                  <p style={{ color: palette.text.dark }}>
                    <span style={{ color: palette.text.light }}>
                      Customer Name:
                    </span>{" "}
                    {customerData?.firstName} {customerData?.lastName}
                  </p>
                </ListItemText>
                <ListItemText>
                  <p style={{ color: palette.text.dark }}>
                    <span style={{ color: palette.text.light }}>
                      Customer ID:
                    </span>{" "}
                    {data?.agencyClientId}
                  </p>
                </ListItemText>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Grid>
      <Box className="bottom-section">
        <Grid spacing={2}>
          <Card className="bottom-part" sx={{ minWidth: 275 }}>
            <CardContent sx={{ paddingLeft: 0 }}>
              <List className="listing-content">
                <ListItem
                  sx={{
                    alignItems: "flex-start",
                    flexDirection: "row",
                    padding: "0",
                    mt: "80px",
                  }}
                >
                  <ListItemText sx={{ paddingLeft: 14, width: "50%" }}>
                    <p style={{ color: palette.text.light }}>
                      <span
                        style={{
                          color: palette.secondary.main,
                          paddingBottom: "20px",
                        }}
                      >
                        Company
                      </span>
                      {companyData?.companyName}
                    </p>
                  </ListItemText>
                  <ListItemText sx={{ width: "50%" }}>
                    <p style={{ color: palette.text.light }}>
                      <span
                        style={{
                          color: palette.secondary.main,
                          paddingBottom: "20px",
                        }}
                      >
                        Address
                      </span>
                      {companyData?.companyAddress}, {companyData?.companyState}
                      , {companyData?.companyCity}, {companyData?.companyZip}
                    </p>
                  </ListItemText>
                </ListItem>
              </List>
            </CardContent>
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "0",
                mt: "79px",
                visibility: "hidden",
                opacity: 0,
              }}
            >
              <Typography color="text.secondary" gutterBottom>
                <p style={{ color: palette.text.light }}>
                  <span
                    style={{
                      color: palette.secondary.main,
                      display: "inline-block",
                    }}
                  >
                    Company Vendor ID:
                  </span>{" "}
                  {companyData?.id}
                </p>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid spacing={2}>
          <Card
            className="footer-part"
            sx={{
              bgcolor: palette.background.light,
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-around",
              padding: "0",
            }}
          >
            <CardContent
              className="card-content"
              sx={{ width: "100%", padding: "0" }}
            >
              <List className="listing-content">
                <ListItem>
                  <ListItemText className="border-right">
                    <p style={{ color: palette.primary.main }}>
                      <span style={{ color: palette.secondary.main }}>
                        Office No:
                      </span>{" "}
                      (757) 315-3150{" "}
                    </p>
                  </ListItemText>
                  <Divider
                    sx={{ borderColor: `1px solid ${palette.primary.main}` }}
                    orientation="vertical"
                    variant="middle"
                    flexItem
                  />
                  <ListItemText>
                    <p style={{ color: palette.primary.main }}>
                      <span style={{ color: palette.secondary.main }}>
                        Fax No:
                      </span>{" "}
                      (757) 320-5659
                    </p>
                  </ListItemText>
                  <Divider
                    sx={{ borderColor: `1px solid ${palette.primary.main}` }}
                    orientation="vertical"
                    variant="middle"
                    flexItem
                  />
                  <ListItemText>
                    <p style={{ color: palette.primary.main }}>
                      <span style={{ color: palette.secondary.main }}>
                        Email:
                      </span>{" "}
                      succeed@goodstewardbbc.com
                    </p>
                  </ListItemText>
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Box>
    </Container>
  );
}
