import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import palette from '../../../../../theme/palette';
import registerThanks from '../../../../../assets/Images/registerThanks.png';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
/* `const style` is an object that defines the styling properties for the modal component. It sets the
position of the modal to be in the center of the screen, with a width of 650px, a background color
of the primary color from the theme palette, a border radius of 5px, and a box shadow. It also sets
the padding and text alignment of the modal content. */
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 650,
  bgcolor: palette.primary.main,
  borderRadius: '5px',
  boxShadow: 24,
  p: 8,
  textAlign: 'center'
};

export default function ThankYouModal({ open, setOpen }) {
  const navigate = useNavigate();
  /**
   * The function handleClose sets the state of "open" to false and navigates to the homepage.
   */
  const handleClose = () => {
    setOpen(false)
    navigate("/")
  };
  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h3">
            Thank you!
          </Typography>
          <Typography id="modal-modal-description" variant='body2' sx={{ mt: 2 }}>
          Congratulations on register with Collab Set Go! Our team is thrilled to receive your request and will carefully review it. Expect an email from us shortly with the next steps. We can't wait to begin this collaborative journey with you!
          </Typography>
          <Grid pt="20px">
            <Button variant='contained' onClick={handleClose}>Ok</Button>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
