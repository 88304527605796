import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const medicalApi = createApi({
    reducerPath: 'medicalApi',
    refetchOnMountOrArgChange: true,
    keepUnusedDataFor: 0,
    refetchOnReconnect: true,
    baseQuery: AxiosBaseQuery({
        baseUrl: `${developmentApi}/api`,
    }),
    tagTypes: ['getAllHospitalizationHistory', 'getAllAppointment', 'getAllMedicalHistory', 'getAllPrescription', 'getAllAllery', 'getMedicalRecommendation', 'getAllNicotineUse', 'getAllAlcoholUse', 'getAllRecreationalDrug'],
    endpoints: (builder) => ({
        addHospitalization: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-hospitalization-history?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getAllHospitalizationHistory'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateHospitalization: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-hospitalization-history?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getAllHospitalizationHistory'],
            transformResponse: (response) => JSON.parse(response)
        }),
        getHospitalizationById: builder.query({
            query: ({ id }) => ({
                url: `/company/get-hospitalization-history?id=${id}`,
                method: 'GET',
            }),
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getAllHospitalization: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-all-hospitalization-history?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getAllHospitalizationHistory'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        deleteHospitalization: builder.mutation({
            query: ({ id }) => ({
                url: `/company/delete-hospitalization-history?id=${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['getAllHospitalizationHistory'],
            transformResponse: (response) => JSON.parse(response)
        }),
        addMedicalHistory: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-medical-history?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getAllMedicalHistory'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateMedicalHistory: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-medical-history?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getAllMedicalHistory'],
            transformResponse: (response) => JSON.parse(response)
        }),
        getMedicalHistoryById: builder.query({
            query: ({ id }) => ({
                url: `/company/get-medical-history?id=${id}`,
                method: 'GET',
            }),
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getAllMedicalHistory: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-all-medical-history?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getAllMedicalHistory'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        deleteMedicalHistory: builder.mutation({
            query: ({ id }) => ({
                url: `/company/delete-medical-history?id=${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['getAllMedicalHistory'],
            transformResponse: (response) => JSON.parse(response)
        }),
        addAppointment: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-upcoming-appointment-surgery?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getAllAppointment'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateAppointment: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-upcoming-appointment-surgery?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getAllAppointment'],
            transformResponse: (response) => JSON.parse(response)
        }),
        getAppointmentById: builder.query({
            query: ({ id }) => ({
                url: `/company/get-upcoming-appointment-surgery?id=${id}`,
                method: 'GET',
            }),
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getAllAppointment: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-all-upcoming-appointment-surgery?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getAllAppointment'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        deleteAppointment: builder.mutation({
            query: ({ id }) => ({
                url: `/company/delete-upcoming-appointment-surgery?id=${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['getAllAppointment'],
            transformResponse: (response) => JSON.parse(response)
        }),
        addPrescription: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-prescription?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getAllPrescription'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updatePrescription: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-prescription?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getAllPrescription'],
            transformResponse: (response) => JSON.parse(response)
        }),
        getPrescriptionById: builder.query({
            query: ({ id }) => ({
                url: `/company/get-prescription?id=${id}`,
                method: 'GET',
            }),
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getAllPrescription: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-all-prescriptions?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getAllPrescription'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        deletePrescription: builder.mutation({
            query: ({ id }) => ({
                url: `/company/delete-prescription?id=${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['getAllPrescription'],
            transformResponse: (response) => JSON.parse(response)
        }),
        addAllergy: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-allergy?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getAllAllery'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateAllergy: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-allergy?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getAllAllery'],
            transformResponse: (response) => JSON.parse(response)
        }),
        getAllergyById: builder.query({
            query: ({ id }) => ({
                url: `/company/get-allergy?id=${id}`,
                method: 'GET',
            }),
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getAllAllergy: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-all-allergies?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getAllAllery'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        deleteAllergy: builder.mutation({
            query: ({ id }) => ({
                url: `/company/delete-allergy?id=${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['getAllAllery'],
            transformResponse: (response) => JSON.parse(response)
        }),
        addMedicalRecommendation: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-medical-recomendation?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getMedicalRecommendation'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateMedicalRecommendation: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-medical-recomendation?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getMedicalRecommendation'],
            transformResponse: (response) => JSON.parse(response)
        }),
        getMedicalRecommendation: builder.query({
            query: ({ id }) => ({
                url: `/company/get-medical-recomendation?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['getMedicalRecommendation'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addNicotine: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-nicotine-use?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getAllNicotineUse'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateNicotine: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-nicotine-use?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getAllNicotineUse'],
            transformResponse: (response) => JSON.parse(response)
        }),
        getNicotineById: builder.query({
            query: ({ id }) => ({
                url: `/company/get-nicotine-use?id=${id}`,
                method: 'GET',
            }),
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getAllNicotine: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-all-nicotine-use?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getAllNicotineUse'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        deleteNicotine: builder.mutation({
            query: ({ id }) => ({
                url: `/company/delete-nicotine-use?id=${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['getAllNicotineUse'],
            transformResponse: (response) => JSON.parse(response)
        }),
        addAlcohol: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-alcohol-use?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getAllAlcoholUse'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateAlcohol: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-alcohol-use?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getAllAlcoholUse'],
            transformResponse: (response) => JSON.parse(response)
        }),
        getAlcoholById: builder.query({
            query: ({ id }) => ({
                url: `/company/get-alcohol-use?id=${id}`,
                method: 'GET',
            }),
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getAllAlcohol: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-all-alcohol-use?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getAllAlcoholUse'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        deleteAlcohol: builder.mutation({
            query: ({ id }) => ({
                url: `/company/delete-alcohol-use?id=${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['getAllAlcoholUse'],
            transformResponse: (response) => JSON.parse(response)
        }),
        addRecreationalDrug: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-recreational-drug-use?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getAllRecreationalDrug'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateRecreationalDrug: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-recreational-drug-use?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getAllRecreationalDrug'],
            transformResponse: (response) => JSON.parse(response)
        }),
        getRecreationalDrugById: builder.query({
            query: ({ id }) => ({
                url: `/company/get-recreational-drug-use?id=${id}`,
                method: 'GET',
            }),
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getAllRecreationalDrug: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-all-recreational-drug-use?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getAllRecreationalDrug'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        deleteRecreationalDrug: builder.mutation({
            query: ({ id }) => ({
                url: `/company/delete-recreational-drug-use?id=${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['getAllRecreationalDrug'],
            transformResponse: (response) => JSON.parse(response)
        }),
    }),
})

export const { useAddHospitalizationMutation, useUpdateHospitalizationMutation, useGetHospitalizationByIdQuery, useGetAllHospitalizationQuery, useDeleteHospitalizationMutation,
    useAddMedicalHistoryMutation, useUpdateMedicalHistoryMutation, useGetMedicalHistoryByIdQuery, useGetAllMedicalHistoryQuery, useDeleteMedicalHistoryMutation,
    useAddAppointmentMutation, useUpdateAppointmentMutation, useGetAppointmentByIdQuery, useGetAllAppointmentQuery, useDeleteAppointmentMutation,
    useAddPrescriptionMutation, useUpdatePrescriptionMutation, useGetPrescriptionByIdQuery, useGetAllPrescriptionQuery, useDeletePrescriptionMutation,
    useAddAllergyMutation, useUpdateAllergyMutation, useGetAllergyByIdQuery, useGetAllAllergyQuery, useDeleteAllergyMutation,
    useAddMedicalRecommendationMutation, useUpdateMedicalRecommendationMutation, useGetMedicalRecommendationQuery,
    useAddNicotineMutation, useUpdateNicotineMutation, useGetNicotineByIdQuery, useGetAllNicotineQuery, useDeleteNicotineMutation,
    useAddAlcoholMutation, useUpdateAlcoholMutation, useGetAlcoholByIdQuery, useGetAllAlcoholQuery, useDeleteAlcoholMutation,
    useAddRecreationalDrugMutation, useUpdateRecreationalDrugMutation, useGetRecreationalDrugByIdQuery, useGetAllRecreationalDrugQuery, useDeleteRecreationalDrugMutation } = medicalApi;
