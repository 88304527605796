import moment from "moment";
import  secureLocalStorage  from  "react-secure-storage";


export const getCaseNoteData = () => {
  return secureLocalStorage.getItem("CaseNotFilter")
};

export const getFilterParams = () => {

  return secureLocalStorage.getItem("filterParams")
};

export const setCaseNoteData = (data) => {
  
  secureLocalStorage.setItem("CaseNotFilter", data);
};
export const setFilterParams = (filterParams) => {
 
  secureLocalStorage.setItem('filterParams', filterParams);
};
export const getUserId = () => {
  return localStorage.getItem("Admintoken")
};

export const setUserId = (Admintoken) => {
  localStorage.setItem("Admintoken", Admintoken);
};

export const getCompanyId = () => {
  return localStorage.getItem("companyId")
};

export const setCompanyId = (companyId) => {
  localStorage.setItem("companyId", companyId);
};


export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function addOneDay(date) {
  date.setDate(date.getDate() + 1);
  return date;
}

export function isBefore(date1, date2) {
  return date1 < date2;
}

export function parseJwt(token) {
  var base64Url = token.split(".")[1]
  var base64 = base64Url?.replace(/-/g, "+").replace(/_/g, "/")
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join("")
  )
  return JSON.parse(jsonPayload)
}

export function grossSituation1(rate, perWeek) {
  return rate * perWeek * 4.33
}

export function netSituation1(rate, perWeek) {
  return (rate * perWeek * 4.33) - ((rate * perWeek * 4.33) * 30 / 100)
}

export function TotalSumOfValues(arr) {
  const summedvalue = arr?.reduce((accumulator, currentValue) => {
    return parseInt(accumulator) + parseInt(currentValue)
  }, 0);
  return summedvalue
}

export function maskedEmail(email) {
  if (!email) {
    return ''; // or any other appropriate default value
  }
  return email.replace(/^(.{2})[^@]*/, "$1*****");
}

export function formatPhoneNumber(phoneNumber) {
  // Remove any non-numeric characters from the input
  const numericOnly = phoneNumber?.replace(/\D/g, '');

  // Check if the numericOnly string is a valid length
  if (numericOnly?.length !== 10) {
    // Handle invalid phone number length
    return "";
  }

  // Format the phone number as (XXX) XXX-XXXX
  const areaCode = numericOnly?.substring(0, 3);
  const firstPart = numericOnly?.substring(3, 6);
  const secondPart = numericOnly?.substring(6);

  return `(${areaCode}) ${firstPart}-${secondPart}`;
}


// Date format function
export function formatDate(inputDate) {
  const momentDate = moment.utc(inputDate);
  return momentDate.isValid() ? momentDate.format('MMMM DD, YYYY') : "";
}


// add and beefore last element

export function addAndBeforeLastElement(arr) {
  let lastIndex = arr?.length - 1;
  if (lastIndex >= 0 && arr?.length > 1) {
    arr[lastIndex] = "and " + arr[lastIndex];
  }
  return arr;
}

// remove dot from last index

export function removeDot(inputString) {
  return inputString?.replace(/\.$/, '') || '';
}

export function isDataEmpty(data) {

  if (data) {
    const ignoreKeysArray = ["id", "CreatedBy", "addedBy", "clientId", "added", "createdAt", "clientProfile", "createdBy", "status", "updatedAt", "auId", "cofohId", "haiId"]
    const filteredData = Object.keys(data)
      ?.filter(key => !ignoreKeysArray?.includes(key))
      ?.reduce((result, key) => {
        result[key] = data[key];
        return result;
      }, {});
    return Object.values(filteredData).every(value => value === null || value === "" || (Array.isArray(value) && value.length === 0));
  }
  else {
    return true
  }
}


export function formatCurrency(number) {
  const formattedNumber = '$' + parseInt(number)?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  return formattedNumber;
}

export function convertTo24HourFormat(timeString) {
  const [time, period] = timeString.split(' ');
  const [hour, minute] = time.split(':');
  let formattedHour = parseInt(hour);

  if (period === 'PM') {
    formattedHour += 12;
  }

  return `${formattedHour}:${minute}`;
}


export function getTime(timeZone) {
  const d = new Date()
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const date = d.getDate();
  const Format = year + "-" + month + "-" + date + "," + timeZone
  const dd = new Date(Format)
  return { hour: dd?.getHours(), minute: dd?.getMinutes() }
}

export function stringMatch(string) {
  const result = string?.split(" ")?.join("")?.toLowerCase()
  return result
}

export function totalTimeCalculator(notesData) {
  const finalTotalTime = []
  const totalHoursArr = []
  const totalHours = [getTime(notesData?.map(item => item?.interventionTime))?.hour, getTime(notesData?.map(item => item?.travelTime))?.hour, getTime(notesData?.map(item => item?.documentationTime))?.hour]
    .reduce((sum, hours) => sum + hours, 0);
  totalHoursArr?.push(totalHours)

  const totalMinutesArr = []
  const totalMinutes = [getTime(notesData?.map(item => item?.interventionTime))?.minute, getTime(notesData?.map(item => item?.travelTime))?.minute, getTime(notesData?.map(item => item?.documentationTime))?.minute]
    .map((time) => (time || 0))
    .reduce((sum, minutes) => sum + minutes, 0,);

  totalMinutesArr?.push(totalMinutes)
  const extrahourArr = []
  const extraHours = Math.floor(totalMinutesArr?.map(min => min) / 60);
  extrahourArr.push(extraHours)
  const minutesArr = []
  const minutes = totalMinutesArr?.map(min => min) % 60;
  minutesArr?.push(minutes)
  const arr = totalHoursArr;
  const sum = arr.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const finalHours = parseInt(sum) + parseInt(extrahourArr?.map(m => m));

  minutesArr?.map(v => {
    finalTotalTime?.push({
      hour: finalHours,
      minute: v
    })
  })
  return finalTotalTime
}

export function getTotalTime(data, keyName) {
  let result
  const arrhour = []
  const arrMinute = []
  data?.map(item => {
    item[keyName]?.map((item) => {
      arrhour?.push(item?.hour || 0)
    })
    const sumHhour = arrhour.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    item[keyName]?.map((item) => {
      arrMinute?.push(item?.minute || 0)
    })
    const sumMinute = arrMinute.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const minIntoHour = Math.floor(sumMinute / 60);
    const ExtraMinutes = sumMinute % 60;

    const finalHours = sumHhour + minIntoHour
    result = `${finalHours}:${ExtraMinutes}`
  })
  return result
}