import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const careerEducationApi = createApi({
  reducerPath: 'careerEducationApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: `${developmentApi}/api`,
  }),
  tagTypes:['getInterview','getInterviewEduAdditionalInfo'],
  endpoints: (builder) => ({
    addInterview: builder.mutation({
      query: ({ClientId, payload})=>({
        url:`/company/add-interview?ClientId=${ClientId}`,
        method:'POST',
        payload:payload,
        fileUpload:true
      }),
      invalidatesTags:['getInterview'],
      transformResponse:(response)=> JSON.parse(response)?.data
    }),
    updateInterview: builder.mutation({
      query: ({id, payload})=>({
        url:`/company/update-interview?id=${id}`,
        method:'PUT',
        payload:payload,
        fileUpload:true
      }),
      invalidatesTags:['getInterview'],
      transformResponse:(response)=> JSON.parse(response)?.data
    }),
    getInterview: builder.query({
        query: ({clientId})=>({
          url:`/company/get-interview?clientId=${clientId}`,
          method:'GET',
        }),
        providesTags:['getInterview'],
        transformResponse:(response)=> JSON.parse(response)?.data
      }),
      addInterviewEduAdditionalInfo: builder.mutation({
        query: ({ClientId, payload})=>({
          url:`/company/add-edu-additional-information?ClientId=${ClientId}`,
          method:'POST',
          payload:payload,
          fileUpload:true
        }),
        invalidatesTags:['getInterviewEduAdditionalInfo'],
        transformResponse:(response)=> JSON.parse(response)?.data
      }),
      updateInterviewEduAdditionalInfo: builder.mutation({
        query: ({id, payload})=>({
          url:`/company/update-edu-additional-information?id=${id}`,
          method:'PUT',
          payload:payload,
          fileUpload:true
        }),
        invalidatesTags:['getInterviewEduAdditionalInfo'],
        transformResponse:(response)=> JSON.parse(response)?.data
      }),
      getInterviewEduAdditionalInfo: builder.query({
          query: ({clientId})=>({
            url:`/company/get-edu-additional-information?clientId=${clientId}`,
            method:'GET',
          }),
          providesTags:['getInterviewEduAdditionalInfo'],
          transformResponse:(response)=> JSON.parse(response)?.data
        }),
  }),
})

export const {useAddInterviewMutation, useUpdateInterviewMutation, useGetInterviewQuery,
    useAddInterviewEduAdditionalInfoMutation, useUpdateInterviewEduAdditionalInfoMutation, useGetInterviewEduAdditionalInfoQuery}=careerEducationApi;
