import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const careerSummaryApi = createApi({
  reducerPath: 'careerSummaryApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: `${developmentApi}/api`,
  }),
  tagTypes: ['getCareerReports', 'getAllCareerCriticalConcern'],
  endpoints: (builder) => ({
    addCareerReportSummary: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-career-report-summery?ClientId=${ClientId}`,
        method: 'POST',
        fileUpload: true,
        payload: payload
      }),
      invalidatesTags: ['getCareerReports'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    updateCareerReportSummary: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/update-career-report-summery?id=${id}`,
        method: 'PUT',
        fileUpload: true,
        payload: payload
      }),
      invalidatesTags: ['getCareerReports'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    getCareerReports: builder.query({
      query: ({ id }) => ({
        url: `/company/get-career-report-summery?id=${id}`,
        method: 'GET',
      }),
      providesTags: ['getCareerReports'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    addCareerCriticalConcern: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-Career-Critical-Concerns-FollowUps?ClientId=${ClientId}`,
        method: 'POST',
        payload: payload,
      }),
      invalidatesTags: ['getAllCareerCriticalConcern'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updateCareerCriticalConcern: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/update-Career-Critical-Concerns-FollowUps?id=${id}`,
        method: 'PUT',
        payload: payload,
      }),
      invalidatesTags: ['getAllCareerCriticalConcern'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    getCareerCriticalConcernById: builder.query({
      query: ({ id }) => ({
        url: `/company/get-Career-Critical-Concerns-FollowUps?id=${id}`,
        method: 'GET',
      }),
      providesTags: ['getAllCareerCriticalConcern'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    getAllCareerCriticalConcern: builder.query({
      query: ({ clientId }) => ({
        url: `/company/get-All-Career-Critical-Concerns-FollowUps?clientId=${clientId}`,
        method: 'GET',
      }),
      providesTags: ['getAllCareerCriticalConcern'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    deleteCareerCriticalConcern: builder.mutation({
      query: ({ id }) => ({
        url: `/company/delete-Career-Critical-Concerns-FollowUps?id=${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['getAllCareerCriticalConcern'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
  }),
})

export const { useAddCareerReportSummaryMutation, useUpdateCareerReportSummaryMutation, useGetCareerReportsQuery,
  useAddCareerCriticalConcernMutation, useUpdateCareerCriticalConcernMutation, useGetCareerCriticalConcernByIdQuery, useGetAllCareerCriticalConcernQuery, useDeleteCareerCriticalConcernMutation } = careerSummaryApi;
