import axios from 'axios';
import { parse, stringify } from "qs";
import { toast } from 'react-toastify';
/**
 * This is a function that takes in parameters for making HTTP requests using Axios, including handling
 * file uploads and error responses.
 * @returns The function `axiosBaseQuery` returns an asynchronous function that takes in an object with
 * properties `url`, `method`, `payload`, `params`, `fileUpload`, and `responseType`. The returned
 * function makes an HTTP request using Axios with the provided options and returns an object with a
 * `data` property that contains the response data. If an error occurs during the request, the function
 * catches the
 */
const axiosBaseQuery = ({ baseUrl = '' }) => async ({ url = "", method = "", payload = null, params = null, fileUpload = null,
  responseType = null, twofaLogin = false }) => {
  var submitParams = {};
  /* This code block is checking if the `params` object is not null, and if it is not null, it is
  iterating over the key-value pairs of the `params` object using `Object.entries(params)`. For
  each key-value pair, it is checking if the value is null, has a length of 0, or is an empty
  string. If any of these conditions are true, it skips to the next iteration using `continue`. If
  the value is not null, has a length greater than 0, and is not an empty string, it adds the
  key-value pair to the `submitParams` object using `submitParams[key] = value`. This code block
  is essentially filtering out any null or empty values from the `params` object and creating a
  new object `submitParams` with only the non-null and non-empty values. */
  if (params != null) {
    for (const [key, value] of Object.entries(params)) {
      if (value == null || value.length == 0 || value === "") {
        continue;
      }
      submitParams[key] = value;
    }
  }

  /* This code block is creating a new `FormData` object and populating it with data from the
  `payload` object, specifically any files that are included in the `payload` object. If
  `fileUpload` is truthy, meaning that a file is being uploaded, the code iterates over each file
  in the `payload.file` array and appends it to the `FormData` object using the `append()` method.
  Then, for each key in the `payload` object that is not "file", the code appends the key-value
  pair to the `FormData` object using the `append()` method. This `FormData` object is likely used
  later in the code to send the data as part of an HTTP request. */
  let formsData = new FormData();
  if (fileUpload) {
    payload?.file?.forEach((file) => {
      formsData.append("file", file);
    });
    for (let key in payload) {
      if (key != "file") {
        formsData.append(key, payload[key]);
      }
    }
  }

  /* `const options` is an object that contains various options for making an HTTP request using Axios. */
  const options = {
    method,
    url: baseUrl + url,
    responseType: responseType ?? "application/json; charset=utf-8",
    mode: 'no-cors',
    // crossDomain:true,
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: (!twofaLogin && `${localStorage.getItem('Admintoken')}`) ? `Bearer ${localStorage.getItem("Admintoken")}` : "",
      "Content-Type": fileUpload
        ? "multipart/form-data"
        : "application/json; charset=utf-8",
    },
    data: payload,
    params: submitParams,
    paramsSerializer: {
      encode: parse,
      serialize: stringify,
    },
  };

  try {
    const response = await axios(options);
    return {
      data: response.data
    };
  } catch (error) {
    if (error.response) {
      let err = JSON.parse(error.response.data)
      toast.error(err?.message)
      console.log(err?.message, 'errorerrorerrorerror')
      // console.log('Error 3', err);
    } else if (error.request) {
      // console.log('Error 2', error.request);
    } else {
      // console.log('Error 3', error.message);
    }

  }
};

export default axiosBaseQuery;
