import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const educationApi = createApi({
  reducerPath: 'educationApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: `${developmentApi}/api`,
  }),
  tagTypes:['getAllEducation','getExtraEducation'],
  endpoints: (builder) => ({
    addEducationAchieved: builder.mutation({
      query: ({ClientId, payload})=>({
        url:`/company/add-education-achieved?ClientId=${ClientId}`,
        method:'POST',
        payload:payload,
        fileUpload: true
      }),
      invalidatesTags: ['getAllEducation'],
      transformResponse:(response)=> JSON.parse(response)
    }),
    updateEducationAchieved: builder.mutation({
        query: ({id, payload})=>({
          url:`/company/update-education-achieved?id=${id}`,
          method:'PUT',
          payload:payload,
          fileUpload: true
        }),
        invalidatesTags: ['getAllEducation'],
        transformResponse:(response)=> JSON.parse(response)
      }),
      getAllEducation: builder.query({
        query: ({clientId})=>({
          url:`/company/get-all-education-achieved?clientId=${clientId}`,
          method:'GET',
        }),
        providesTags:["getAllEducation"],
        transformResponse:(response)=> JSON.parse(response)?.data
      }),
      getEducationById: builder.query({
        query: ({id})=>({
          url:`/company/get-education-achieved?id=${id}`,
          method:'GET',
        }),
        transformResponse:(response)=> JSON.parse(response)?.data
      }),
      deleteEducationAchieved: builder.mutation({
        query: ({id})=>({
          url:`/company/delete-education-achieved?id=${id}`,
          method:'DELETE',
        }),
        invalidatesTags:["getAllEducation"],
        transformResponse:(response)=> JSON.parse(response)
      }),
      addExtraEducation: builder.mutation({
        query: ({ClientId, payload})=>({
          url:`/company/add-extra-education-detail?ClientId=${ClientId}`,
          method:'POST',
          payload:payload,
          fileUpload: true
        }),
        invalidatesTags: ['getExtraEducation'],
        transformResponse:(response)=> JSON.parse(response)
      }),
      updateExtraEducation: builder.mutation({
        query: ({id, payload})=>({
          url:`/company/update-extra-education-detail?id=${id}`,
          method:'PUT',
          payload:payload,
          fileUpload: true
        }),
        invalidatesTags: ['getExtraEducation'],
        transformResponse:(response)=> JSON.parse(response)
      }),
      getExtraEducation: builder.query({
        query: ({id})=>({
          url:`/company/get-extra-education-detail?id=${id}`,
          method:'GET',
        }),
        providesTags:["getExtraEducation"],
        transformResponse:(response)=> JSON.parse(response)?.data
      }),
  }),
})

export const {useAddEducationAchievedMutation, useUpdateEducationAchievedMutation, useGetAllEducationQuery, useGetEducationByIdQuery,
useDeleteEducationAchievedMutation, useAddExtraEducationMutation, useUpdateExtraEducationMutation,useGetExtraEducationQuery}=educationApi;
