import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const mentalHealthEducationApi = createApi({
    reducerPath: 'mentalHealthEducationApi',
    refetchOnMountOrArgChange: true,
    keepUnusedDataFor: 0,
    refetchOnReconnect: true,
    baseQuery: AxiosBaseQuery({
        baseUrl: `${developmentApi}/api`,
    }),
    tagTypes: ['getEmploymentViews'],
    endpoints: (builder) => ({
        addEmploymentViews: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-employment-history-views?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getEmploymentViews'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateEmploymentViews: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-employment-history-views?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getEmploymentViews'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getEmploymentViews: builder.query({
            query: ({ id }) => ({
                url: `/company/get-employment-history-views?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['getEmploymentViews'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
    }),
})

export const { useAddEmploymentViewsMutation, useUpdateEmploymentViewsMutation, useGetEmploymentViewsQuery} = mentalHealthEducationApi;
