import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import palette from "../../theme/palette";

const customStyles = {
    dropdownIndicator: (base) => ({
        ...base,
        color: palette.text.light,
        flex: "1",
        width: "100%",
    }),
    indicatorSeparator: () => null,
    container: (base) => ({ ...base, flex: "1", width: "100%" }),
    menuPortal: base => ({ ...base, zIndex: 9999 })
};
const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
]
export default function CustomDatepicker({ marginBottom, value, setValue, i, disabled, DOBYear, dayHide, selectHeight, xsValue, isStart, isEnd }) {

    const [monthsOptions, setMonthsOptions] = useState();
    const [yearsOptions, setYearsOptions] = useState();
    const [daysOptions, setDaysOptions] = useState();


    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const years = []
        for (let i = currentYear + 5; i >= 1950; i--) {
            years?.push({ label: i, value: i })
        }
        setYearsOptions(years)
        const monthsName = months?.map((item, i) => {
            return { value: i + 1, label: item }
        })
        setMonthsOptions(monthsName)
        const days = new Date(value?.yearName?.value, parseInt(value?.monthName?.value), 0).getDate();
        const optionsDays = [];
        for (let d = 1; d <= days; d++) {
            optionsDays?.push({ value: d, label: d })
        }
        setDaysOptions(optionsDays)
    }, [value])
    return (
        <Box style={{ marginBottom: marginBottom ? marginBottom : 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={xsValue ? xsValue : 4} >
                    <Select
                        maxMenuHeight={selectHeight ? selectHeight : 250}
                        styles={customStyles}
                        options={yearsOptions}
                        classNamePrefix="standardSelect"
                        // required
                        isDisabled={disabled ? true : false}
                        placeholder={
                            <Typography variant="formLabel" component={'div'}>
                                {isStart ? "Start year" : isEnd ? "End year" : "YYYY"}
                            </Typography>
                        }
                        isSearchable={false}
                        isClearable={true}
                        name={"yearName"}
                        value={value?.yearName || null}
                        onChange={(e) => setValue({
                            ...value,
                            yearName: e
                        }, i)}
                    />
                </Grid>
                <Grid item xs={xsValue ? xsValue : 4}>
                    <Select
                        maxMenuHeight={selectHeight ? selectHeight : 250}
                        styles={customStyles}
                        options={monthsOptions}
                        isClearable={true}
                        classNamePrefix="standardSelect"
                        placeholder={
                            <Typography variant="formLabel" component={'div'}>
                                {isStart ? "Start month" : isEnd ? "End month" : "MM"}
                            </Typography>
                        }
                        isSearchable={false}
                        name={"monthName"}
                        isDisabled={disabled ? true : false}
                        value={value?.monthName || null}
                        onChange={(e) => setValue({
                            ...value,
                            monthName: e,
                        }, i)}
                    />
                </Grid>
                {!dayHide && <Grid item xs={xsValue ? xsValue : 4}  >
                    <Select
                        maxMenuHeight={selectHeight ? selectHeight : 250}
                        styles={customStyles}
                        options={daysOptions}
                        isClearable={true}
                        classNamePrefix="standardSelect"
                        isSearchable={false}
                        isDisabled={disabled ? true : false}
                        name={"dayName"}
                        placeholder={
                            <Typography variant="formLabel" component={'div'}>
                                DD
                            </Typography>
                        }
                        value={value?.dayName || null}
                        onChange={(e) => setValue({
                            ...value,
                            dayName: e
                        }, i)}
                    />
                </Grid>}

            </Grid>
        </Box>
    )
}