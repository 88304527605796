import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const employmentApi = createApi({
  reducerPath: 'employmentApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: `${developmentApi}/api`,
  }),
  tagTypes:['getAllEmploymentHistory','getEmploymentStatus'],
  endpoints: (builder) => ({
    addEmploymentStatus: builder.mutation({
      query: ({ClientId, payload})=>({
        url:`/company/add-current-employment-status?ClientId=${ClientId}`,
        method:'POST',
        payload:payload,
        fileUpload: true
      }),
      invalidatesTags:['getEmploymentStatus'],
      transformResponse:(response)=> JSON.parse(response)
    }),
    updateEmploymentStatus: builder.mutation({
        query: ({id, payload})=>({
          url:`/company/update-current-employment-status?id=${id}`,
          method:'PUT',
          payload:payload,
          fileUpload: true
        }),
        invalidatesTags:['getEmploymentStatus'],
        transformResponse:(response)=> JSON.parse(response)
      }),
      getEmploymentStatus: builder.query({
        query: ({id})=>({
          url:`/company/get-current-employment-status?id=${id}`,
          method:'GET',
        }),
        providesTags:['getEmploymentStatus'],
        transformResponse:(response)=> JSON.parse(response)?.data
      }),
      addEmploymentHistory: builder.mutation({
        query: ({ClientId, payload})=>({
          url:`/company/add-employment-history?ClientId=${ClientId}`,
          method:'POST',
          payload:payload,
          fileUpload: true
        }),
        invalidatesTags:['getAllEmploymentHistory'],
        transformResponse:(response)=> JSON.parse(response)
      }),
      updateEmploymentHistory: builder.mutation({
        query: ({id, payload})=>({
          url:`/company/update-employment-history?id=${id}`,
          method:'PUT',
          payload:payload,
          fileUpload: true
        }),
        invalidatesTags:['getAllEmploymentHistory'],
        transformResponse:(response)=> JSON.parse(response)
      }),
      getAllEmploymentHistory: builder.query({
        query: ({clientId})=>({
          url:`/company/get-all-employment-history?clientId=${clientId}`,
          method:'GET',
        }),
        providesTags:['getAllEmploymentHistory'],
        transformResponse:(response)=> JSON.parse(response)?.data
      }),
      getEmploymentHistoryById: builder.query({
        query: ({id})=>({
          url:`/company/get-employment-history?id=${id}`,
          method:'GET',
        }),
        transformResponse:(response)=> JSON.parse(response)?.data
      }),
      deleteEmploymentHistory: builder.mutation({
        query: ({id})=>({
          url:`/company/delete-employment-history?id=${id}`,
          method:'DELETE',
        }),
        invalidatesTags:['getAllEmploymentHistory'],
        transformResponse:(response)=> JSON.parse(response)
      }),
  }),
})

export const {useAddEmploymentStatusMutation, useUpdateEmploymentStatusMutation, useGetEmploymentStatusQuery, 
    useAddEmploymentHistoryMutation,useUpdateEmploymentHistoryMutation, useGetAllEmploymentHistoryQuery,useGetEmploymentHistoryByIdQuery, useDeleteEmploymentHistoryMutation}=employmentApi;
