import { Box, Button, Grid, TextField, Typography , CircularProgress} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import palette from "../../../../../theme/palette";
import { useForgotPasswordMutation } from "../../../../../store/services/authServices";
import { toast } from "react-toastify";

export default function ForgotPassword() {
    /* `const navigate= useNavigate();` is using the `useNavigate` hook from the `react-router-dom`
    library to get access to the `navigate` function. This function can be used to programmatically
    navigate to different routes in the application. It is commonly used in response to user
    actions, such as clicking a button or submitting a form. In this specific code, it is used to
    navigate to the `/forgotpasswordlink` route after a successful password reset request. */
    const navigate= useNavigate();
    /* `const [userForgotPassword, {isLoading}] = useForgotPasswordMutation();` is using the
    `useForgotPasswordMutation` hook from the `authServices` store service to get access to the
    `userForgotPassword` function and the `isLoading` boolean. */
    const [userForgotPassword, {isLoading}]= useForgotPasswordMutation();
   /* `const [userForgot, setUserForgot]= useState({userEmail:"", userError:"",})` is initializing a
   state variable `userForgot` using the `useState` hook. The state variable is an object with two
   properties: `userEmail` and `userError`. `userEmail` is initially set to an empty string, and
   `userError` is initially set to an empty string as well. The `setUserForgot` function is used to
   update the state of `userForgot`. */
    const [userForgot, setUserForgot]= useState({
        userEmail:"",
        userError:"",
    })

    /**
     * This is a function that updates the state of a user's forgotten password information based on
     * user input.
     * @param e - The parameter "e" is an event object that is passed as an argument to the
     * handleChange function. It represents the event that triggered the function, such as a change in
     * the input field value.
     */
    const handleChange = (e) => {
        setUserForgot({
            ...userForgot,
                [e.target.name]: e.target.value,
        });
    }
    /* This `useEffect` hook is checking if the `userForgot.userEmail` state variable is not an empty
    string. If it is not empty, it uses a regular expression to check if the email is valid. If the
    email is valid, it sets the `userError` state variable to an empty string, indicating that there
    is no error. If the email is not valid, it sets the `userError` state variable to a string
    indicating that the email is not valid. If the `userForgot.userEmail` state variable is empty,
    it sets the `userError` state variable to an empty string. This `useEffect` hook runs whenever
    the `userForgot.userEmail` state variable changes. */
    useEffect(()=>{
        if(userForgot?.userEmail !== ''){
            setUserForgot({
                ...userForgot,
                userError:(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(userForgot?.userEmail) ? "" : "Email is not valid")
            })
        }else{
            setUserForgot({
                ...userForgot,
                userError:""
            })
        }
    },[userForgot?.userEmail])

    /* `const EnableButton = userForgot?.userEmail && userForgot?.userError ==='';` is creating a
    boolean variable `EnableButton` that is true if `userForgot.userEmail` is not null or undefined
    and `userForgot.userError` is an empty string. It is used to disable the "Send Password Reset
    Link" button if either `userForgot.userEmail` is empty or `userForgot.userError` is not an empty
    string. */
    const EnableButton = userForgot?.userEmail && userForgot?.userError ==='';

    /**
     * This function handles the submission of a user's email for the purpose of resetting their
     * password and displays a success message if the request is successful.
     */
    const handleSubmit=(e)=>{
        e.preventDefault();
        const params={
            email:userForgot?.userEmail
        }
        userForgotPassword(params).unwrap()
        .then((response)=>{
            toast.success(response?.message)
            navigate("/forgotpasswordlink")
        })
        .catch((error)=>console.log(error?.message))
    }
    return (
        <form onSubmit={(e) => handleSubmit(e)}> 
        <Box sx={{ maxWidth: '500px' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} mb="20px"><Typography variant="h2" component="div">Forgot Your Password</Typography></Grid>
                <Grid item xs={12}>
                    <Typography variant="formLabel" component='div'>Enter your Email Address</Typography>
                    <TextField
                        error={userForgot?.userError ? true : false}
                        required
                        fullWidth
                        size="small"
                        name="userEmail"
                        autoComplete="off"
                        autoFocus={false}
                        helperText={<Typography variant="errorMessage">{userForgot?.userError}</Typography>}
                        value={userForgot?.userEmail}
                        onChange={(e) => handleChange(e)}
                    />
                </Grid>
                <Grid item xs={12} mt="30px">
                    <Button disabled={!EnableButton || isLoading} variant="contained" type="submit" fullWidth >Send Password Reset Link 
                    {isLoading &&  <CircularProgress size={20} sx={{ml:2, color: palette.secondary.main}} />} </Button>
                </Grid>
                <Grid item xs={12} mt="40px">
                    <Typography  component={'span'} variant="subtitle2" ><span className="pointerCursor" onClick={()=> navigate("/")} style={{textDecoration:"underline"}}>Click here</span><span style={{color:palette.text.main}}> to go back to Login!</span></Typography>
                </Grid>
            </Grid>
        </Box>
        </form>
    )
}