import { Box, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import Select from "react-select";
import palette from "../../../theme/palette";
import CustomTimePicker from "../../../components/CustomTimePicker";
import { meetingOptions, serviceTypeOptions } from "../../../constant";

const customStyles = {
    dropdownIndicator: (base) => ({
        ...base,
        color: palette.text.light,
        flex: "1",
        width: "100%",
    }),
    indicatorSeparator: () => null,
    container: (base) => ({ ...base, flex: "1", width: "100%" }),
};

export default function AddCaseNotesSection({ i, caseNoteUI, showTotalTime, setShowTotalTime, serviceGoalId, Assignments, meetingType, meetingWith, caseNotesInfo,
    pertinentInfo, interventionTime, travelTime, otherAssignment, documentationTime, totalTime, setSelectGoal, setSelectAssignment, setSelectServiceType
    , setSelectMeeting, setCaseNotes, setPartinentInformation, setInterventionTime, setTravelTime, setDocumentationTime, setTotalTime,
    onChange, goalsOptions, assignmentOptions, otherGoal }) {

    useEffect(() => {
        const totalHours = [interventionTime, travelTime, documentationTime]
            .map((time) => Number(time?.hours?.value || 0))
            .reduce((sum, hours) => sum + hours, 0);

        const totalMinutes = [interventionTime, travelTime, documentationTime]
            .map((time) => Number(time?.minutes?.value || 0))
            .reduce((sum, minutes) => sum + minutes, 0);

        const extraHours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;

        const finalHours = totalHours + extraHours;
        const finalMinutes = minutes;

        setShowTotalTime(`${finalHours}:${finalMinutes}`)
    }, [interventionTime, travelTime, documentationTime])

    const otherAssignmentField = Assignments && Assignments?.map(data => data?.label === "Other") || [];
    const otherGoalField = serviceGoalId && serviceGoalId?.map(data => data?.label === "Other") || [];
    return (
        <Box>
            <Grid container spacing={2}>
                <Grid xs={12} sm={12} md={12} lg={6}>
                    <Typography variant="formLabel" component={'div'}>{caseNoteUI ? 'Goal' : 'Please Select Goal'}</Typography>
                    <Select
                        styles={customStyles}
                        options={goalsOptions}
                        required
                        isMulti
                        classNamePrefix="standardSelect"
                        placeholder={
                            <Typography variant="formLabel">
                                Select Goal
                            </Typography>
                        }
                        isSearchable={false}
                        isClearable={true}
                        isDisabled={caseNoteUI ? caseNoteUI : false}
                        name="serviceGoalId"
                        value={serviceGoalId || null}
                        onChange={(e) => setSelectGoal(e, i)}
                    />
                </Grid>
                {otherGoalField.length > 0 && otherGoalField?.includes(true) && <Grid xs={6}>
                    <Typography variant="formLabel"
                        component={'div'}>{caseNoteUI ? 'Other Goal' : 'Enter goal'}</Typography>
                    <TextField fullWidth
                        required name="otherGoal"
                        inputProps={{ readOnly: caseNoteUI ? caseNoteUI : false }}
                        value={otherGoal || ""} onChange={(e) => onChange(e, i)}
                    />
                </Grid>}
                <Grid xs={12} sm={12} md={12} lg={6}>
                    <Typography variant="formLabel" component={'div'}>{caseNoteUI ? 'Assignments' : 'Select Assignments'}</Typography>
                    <Select
                        styles={customStyles}
                        options={assignmentOptions}
                        required
                        classNamePrefix="standardSelect"
                        placeholder={
                            <Typography variant="formLabel">
                                Select Assignment
                            </Typography>
                        }
                        isSearchable={false}
                        isMulti
                        isDisabled={caseNoteUI ? caseNoteUI : false}
                        name="Assignments"
                        value={Assignments || null}
                        onChange={(e) => setSelectAssignment(e, i)}
                    />
                </Grid>
                {otherAssignmentField.length > 0 && otherAssignmentField?.includes(true) && <Grid xs={6}>
                    <Typography variant="formLabel"
                        component={'div'}>{caseNoteUI ? 'Other Assignment' : 'Enter assignments'}</Typography>
                    <TextField fullWidth inputProps={{ readOnly: caseNoteUI ? caseNoteUI : false }}
                        required name="otherAssignment"
                        value={otherAssignment || ""} onChange={(e) => onChange(e, i)}
                    />
                </Grid>}
                <Grid xs={12} sm={12} md={12} lg={6}>
                    <Typography variant="formLabel" component={'div'}>Service Type</Typography>
                    <Select
                        styles={customStyles}
                        options={serviceTypeOptions}
                        required
                        classNamePrefix="standardSelect"
                        placeholder={
                            <Typography variant="formLabel">
                                Select Meeting Type
                            </Typography>
                        }
                        isSearchable={false}
                        isClearable={true}
                        isDisabled={caseNoteUI ? caseNoteUI : false}
                        name="meetingType"
                        value={meetingType || null}
                        onChange={(e) => setSelectServiceType(e, i)}
                    />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={6}>
                    <Typography variant="formLabel" component={'div'}>Meeting With</Typography>
                    <Select
                        styles={customStyles}
                        options={meetingOptions}
                        required
                        classNamePrefix="standardSelect"
                        placeholder={
                            <Typography variant="formLabel">
                                Select Meeting With
                            </Typography>
                        }
                        isSearchable={false}
                        isClearable={true}
                        isDisabled={caseNoteUI ? caseNoteUI : false}
                        name="meetingWith"
                        value={meetingWith || null}
                        onChange={(e) => setSelectMeeting(e, i)}
                    />
                </Grid>
                <Grid xs={12}>
                    <Typography variant="formLabel" component={'div'}>Case Notes</Typography>
                    <TextField fullWidth
                        multiline
                        required
                        variant="standard"
                        name="caseNotesInfo" inputProps={{ readOnly: caseNoteUI ? caseNoteUI : false }}
                        value={caseNotesInfo || ""}
                        onChange={(e) => onChange(e, i)}
                    />
                </Grid>
                <Grid xs={12}>
                    <Typography variant="formLabel" component={'div'}>Partinent Information</Typography>
                    <TextField fullWidth
                        multiline
                        variant="standard"
                        name="pertinentInfo" inputProps={{ readOnly: caseNoteUI ? caseNoteUI : false }}
                        value={pertinentInfo || ""}
                        onChange={(e) => onChange(e, i)}
                    />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={6}>
                    <Typography variant="formLabel" component={'div'}>Intervention Time</Typography>
                    <CustomTimePicker i={i}
                        disabled={caseNoteUI ? caseNoteUI : false}
                        setValue={setInterventionTime}
                        value={interventionTime} hide={true}
                        size={6}
                    />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={6}>
                    <Typography variant="formLabel" component={'div'}>Travel Time</Typography>
                    <CustomTimePicker i={i} disabled={caseNoteUI ? caseNoteUI : false}
                        setValue={setTravelTime}
                        value={travelTime} hide={true}
                        size={6}
                    />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={6}>
                    <Typography variant="formLabel" component={'div'}>Documentation Time</Typography>
                    <CustomTimePicker i={i} disabled={caseNoteUI ? caseNoteUI : false}
                        setValue={setDocumentationTime}
                        value={documentationTime} hide={true}
                        size={6}
                    />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={6}>
                    <Typography variant="formLabel" component={'div'}>Total Time</Typography>
                    <TextField fullWidth
                        name="totalTime" inputProps={{ readOnly: caseNoteUI ? caseNoteUI : false }}
                        value={showTotalTime || ""}
                        onChange={(e) => onChange(e, i)}
                        placeholder="Sum of enteries"
                    />
                </Grid>
            </Grid>
        </Box>
    )
}