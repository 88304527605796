import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const mentalHealthfamilyApi = createApi({
    reducerPath: 'mentalHealthfamilyApi',
    refetchOnMountOrArgChange: true,
    keepUnusedDataFor: 0,
    refetchOnReconnect: true,
    baseQuery: AxiosBaseQuery({
        baseUrl: `${developmentApi}/api`,
    }),
    tagTypes: ['getFamilySystem', 'getRaisedAge', 'getAllSibiling', 'getSignificantRelationship', 'getPerceiveLife'],
    endpoints: (builder) => ({
        addFamilySystem: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-family-system-relationship?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getFamilySystem'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateFamilySystem: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-family-system-relationship?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getFamilySystem'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getFamilySystem: builder.query({
            query: ({ id }) => ({
                url: `/company/get-family-system-relationship?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['getFamilySystem'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addRaisedAge: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-raised-age?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getRaisedAge'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateRaisedAge: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-raised-age?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getRaisedAge'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getRaisedAgeById: builder.query({
            query: ({ id }) => ({
                url: `/company/get-raised-age?id=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ['getRaisedAge'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getRaisedAge: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-all-raised-age?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getRaisedAge'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        DeleteRaisedAge: builder.mutation({
            query: ({ id }) => ({
                url: `/company/delete-raised-age?id=${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['getRaisedAge'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addNewSibiling: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-new-siblings?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getAllSibiling'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateNewSibiling: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-new-siblings?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getAllSibiling'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getSibilingById: builder.query({
            query: ({ id }) => ({
                url: `/company/get-new-siblings?id=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ['getAllSibiling'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getAllSibiling: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-all-new-siblings?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getAllSibiling'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        DeleteSibiling: builder.mutation({
            query: ({ id }) => ({
                url: `/company/delete-new-siblings?id=${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['getAllSibiling'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addSignificantRelationship: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-significant-relationship?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getSignificantRelationship'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateSignificantRelationship: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-significant-relationship?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getSignificantRelationship'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getSignificantRelationship: builder.query({
            query: ({ id }) => ({
                url: `/company/get-significant-relationships?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['getSignificantRelationship'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addPerceiveLife: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-perceive-life?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getPerceiveLife'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updatePerceiveLife: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-perceive-life?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getPerceiveLife'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getPerceiveLife: builder.query({
            query: ({ id }) => ({
                url: `/company/get-perceive-life?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['getPerceiveLife'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
    }),
})

export const { useAddFamilySystemMutation, useUpdateFamilySystemMutation, useGetFamilySystemQuery,
    useAddRaisedAgeMutation, useUpdateRaisedAgeMutation, useGetRaisedAgeByIdQuery, useGetRaisedAgeQuery, useDeleteRaisedAgeMutation, 
    useAddNewSibilingMutation, useUpdateNewSibilingMutation, useGetSibilingByIdQuery, useGetAllSibilingQuery, useDeleteSibilingMutation, 
    useAddSignificantRelationshipMutation, useUpdateSignificantRelationshipMutation, useGetSignificantRelationshipQuery, 
    useAddPerceiveLifeMutation, useUpdatePerceiveLifeMutation, useGetPerceiveLifeQuery } = mentalHealthfamilyApi;
