import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from 'react';
import Scrollbars from "react-custom-scrollbars-2";
import { useLocation, useNavigate } from "react-router-dom";

export default function PrivacyPolicy() {
    const navigate = useNavigate()
    const location= useLocation()
    return (
        <Box>
            <Grid xs={12} container justifyContent={'flex-start'} >
                <Typography variant="h1">Privacy Policy</Typography>
            </Grid>
            <Scrollbars autoHeight autoHeightMax={"calc(100vh - 200px)"} >
                {/* <Typography textAlign={'justify'} variant="body2" sx={{ pr: 2, lineHeight: 2 }}>The Master Comprehensive Assessment Report (“MCAR”), Addendum Elements (“Elements”), and any reports,
                    documents, exhibits, and information, related to or included in the MCAR and Elements are proprietary information
                    belonging to Good Steward Business and Benefits Consulting, LLC (“Good Steward”), and Good Steward reserves all right,
                    title, and interest in and to such materials. The terms, conditions, and information set forth MCAR, Elements, and any reports,
                    documents, exhibits, and information, related to or included in the MCAR and Elements are confidential to Good Steward and may not be
                    disclosed in any manner to any person other than the addressee, together with its officers, employees, and agents who are directly responsible for
                    evaluating the contents of these materials for the limited purpose intended. These materials may not be used in any manner other than for such limited purpose.
                    Any unauthorized disclosure, use, reproduction, or transmission is expressly prohibited without the prior written consent of Good Steward. </Typography> */}
            </Scrollbars>
            <Button sx={{ mt: 2 }} variant="contained" onClick={() => navigate("/register", {state: {data: location?.state, tabNumber: 1}})}>Back to Registration </Button>
        </Box>
    )
}