import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const mentalHealthThoughtApi = createApi({
    reducerPath: 'mentalHealthThoughtApi',
    refetchOnMountOrArgChange: true,
    keepUnusedDataFor: 0,
    refetchOnReconnect: true,
    baseQuery: AxiosBaseQuery({
        baseUrl: `${developmentApi}/api`,
    }),
    tagTypes: ['getOrientationPerson', 'getProverbsTask', 'getJudgmentTask'],
    endpoints: (builder) => ({
        addOrientationPerson: builder.mutation({
            query: ({ payload }) => ({
                url: `/company/add-Orientation-Person`,
                method: 'POST',
                payload: payload,
            }),
            invalidatesTags: ['getOrientationPerson'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateOrientationPerson: builder.mutation({
            query: ({ payload }) => ({
                url: `/company/update-Orientation-Person`,
                method: 'PUT',
                payload: payload,
            }),
            invalidatesTags: ['getOrientationPerson'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getOrientationPerson: builder.query({
            query: (clientid) => ({
                url: `/company/get-Orientation-Person/${clientid}`,
                method: 'GET',
            }),
            providesTags: ['getOrientationPerson'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addProverbsTask: builder.mutation({
            query: ({ payload }) => ({
                url: `/company/add-Proverbs-Task`,
                method: 'POST',
                payload: payload,
            }),
            invalidatesTags: ['getProverbsTask'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateProverbsTask: builder.mutation({
            query: ({ payload }) => ({
                url: `/company/update-Proverbs-Task`,
                method: 'PUT',
                payload: payload,
            }),
            invalidatesTags: ['getProverbsTask'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getProverbsTask: builder.query({
            query: (clientId) => ({
                url: `/company/get-Proverbs-Task/${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getProverbsTask'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addJudgmentTask: builder.mutation({
            query: ({ payload }) => ({
                url: `/company/add-Judgment-Task`,
                method: 'POST',
                payload: payload,
            }),
            invalidatesTags: ['getJudgmentTask'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateJudgmentTask: builder.mutation({
            query: ({ payload }) => ({
                url: `/company/update-Judgment-Task`,
                method: 'PUT',
                payload: payload,
            }),
            invalidatesTags: ['getJudgmentTask'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getJudgmentTask: builder.query({
            query: (clientId) => ({
                url: `/company/get-Judgment-Task/${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getJudgmentTask'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
    }),
})

export const { useAddOrientationPersonMutation, useUpdateOrientationPersonMutation, useGetOrientationPersonQuery, 
    useAddProverbsTaskMutation, useUpdateProverbsTaskMutation, useGetProverbsTaskQuery, 
    useAddJudgmentTaskMutation, useUpdateJudgmentTaskMutation, useGetJudgmentTaskQuery} = mentalHealthThoughtApi;
