import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const staffApi = createApi({
  reducerPath: 'staffApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: `${developmentApi}/api`,
  }),
  tagTypes:['getStaff'],
  endpoints: (builder) => ({
    addStaff: builder.mutation({
      query: ({ payload }) => ({
        url: `/company/add-staff-members`,
        method: 'POST',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags:['getStaff'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updateStaff: builder.mutation({
      query: ({id, payload }) => ({
        url: `/company/update-staff-memeber-info?id=${id}`,
        method: 'PUT',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags:['getStaff'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getStaff: builder.query({
      query: (params)=>({
        url:`/company/get-staff-members`,
        method:'GET',params:params
      }),
      providesTags:['getStaff'],
      transformResponse:(response)=> JSON.parse(response)?.data
    }),
    getStaffById: builder.query({
      query: ({id})=>({
        url:`/company/get-staff-member?id=${id}`,
        method:'GET',
      }),
      transformResponse:(response)=> JSON.parse(response)?.data
    }),
    StaffSetPassword: builder.mutation({
      query: ({payload})=>({
        url: `/Staff/SetPassword`,
        method:'POST',
        payload: payload
      }),
      transformResponse:(response)=> JSON.parse(response)
    }),
    staffResendInvite: builder.mutation({
      query: ({payload})=>({
        url: `/Staff/ResendInvite`,
        method:'POST',
        payload: payload
      }),
      transformResponse:(response)=> JSON.parse(response)
    }),
  }),
})

export const { useAddStaffMutation, useUpdateStaffMutation ,useGetStaffQuery, useStaffSetPasswordMutation , useGetStaffByIdQuery, useStaffResendInviteMutation } = staffApi;
