import { createTheme } from "@mui/material";
import palette from "./palette";
import typography from "./typography";
/* This code is creating a Material-UI theme using the `createTheme` function and exporting it as a
constant named `theme`. The theme includes customizations for various Material-UI components such as
`MuiButton`, `MuiTab`, `MuiTextField`, `MuiCheckbox`, `MuiTableHead`, `MuiTableCell`, `MuiAppBar`,
`MuiCardHeader`, and `MuiPagination`. These customizations include changes to the styles and colors
of these components. The customizations are defined in an object passed as the second argument to
`createTheme`, with each component having its own nested object of style overrides. The `palette`
and `typography` objects are imported from separate files and used to define the colors and
typography styles for the theme. */
export const theme = createTheme({
  typography,
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: palette.secondary.main,
          fontSize: "0.85rem",
          boxShadow: "none",
          textTransform: "none",
          fontWeight: 800,
          height: "40px",
          "&:hover": {
            backgroundColor: palette.secondary.main,
            boxShadow: "none",
          },
        },
        cancel: {
          backgroundColor: palette.text.light,
          fontSize: "1rem",
          boxShadow: "none",
          textTransform: "none",
          fontWeight: 800,
          height: "40px",
          color: palette.primary.main,
          "&:hover": {
            backgroundColor: palette.text.light,
            boxShadow: "none",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: "0.833rem",
          fontWeight: 700,
          color: palette.text.main,
          borderBottom: `1px solid ${palette.text.main}`,
          "&.Mui-selected": {
            color: palette.text.main,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: palette.primary.main,
        },
        indicator: {
          backgroundColor: palette.secondary.main,
          height: "4px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            borderRadius: "5px",
            height: "45px",
            "& .MuiOutlinedInput-notchedOutline": {
              border: `1px solid ${palette.primary.dark}`,
            },
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
            backgroundColor: palette.secondary.main,
        },
        bar:{
          backgroundColor: palette.secondary.main,
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: palette.primary.dark,
          "&.Mui-checked  ": {
            color: palette.active.main,
          },
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        head: {
          color: palette.primary.main,
          backgroundColor: palette.text.light,
          zIndex: 99
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: palette.primary.main,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: palette.primary.main,
          borderBottom: `1px solid ${palette.background.dark}`,
          boxShadow: "none"
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          backgroundColor: palette.text.light,
          padding: "11px 30px"
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          '& .MuiPaginationItem-root': {
            color: palette.text.dark,
            "&:hover": {
              backgroundColor: palette.secondary.main,
              boxShadow: "none",
              color: palette.primary.main,
              border: palette.secondary.main
            },
          },
        },
      }
    },
  },
});
