import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const referrialApi = createApi({
  reducerPath: 'referrialApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: `${developmentApi}/api`,
  }),
  tagTypes:['getRefferalDetails'],
  endpoints: (builder) => ({
    updateReferrialDetails: builder.mutation({
      query: ({id,payload}) => ({
        url: `/company/update-client-refferedby?id=${id}`,
        method: 'PUT',
        payload:payload
      }),
      invalidatesTags:['getRefferalDetails'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getReferrialDetails: builder.query({
        query: ({clientID}) => ({
          url: `/company/get-client-refferedby?clientID=${clientID}`,
          method: 'GET',
        }),
        providesTags:['getRefferalDetails'],
        transformResponse: (response) => JSON.parse(response)?.data
      }),
  }),
})

export const { useUpdateReferrialDetailsMutation, useGetReferrialDetailsQuery } = referrialApi;
