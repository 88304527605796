import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { agencyApi } from "./services/agencyServices";

import { authApi } from "./services/authServices";
import { benefitsApi } from "./services/benefitReportsServices";
import { benefitsComprehensiveApi } from "./services/BenefitsComprehensiveServices";
import { benefitsInerviewApi } from "./services/benefitsInterviewServices";
import { benefitsObservationApi } from "./services/BenefitsOservationServices";
import { benefitsReportApi } from "./services/BenefitsReportSummaryServices";
import { budgetInterviewApi } from "./services/budgetInterviewServices";
import { budgetPlannerApi } from "./services/budgetPlannerServices";
import { careerComprehensiveApi } from "./services/careerComprehensiveServices";
import { careerEducationApi } from "./services/careerEducationServices";
import { careerEmploymentApi } from "./services/careerEmploymentServices";
import { careerLegalApi } from "./services/careerLegalServices";
import { careerMedicalApi } from "./services/careerMedicalServices";
import { careerObservationApi } from "./services/careerobservationServices";
import { careerReportApi } from "./services/careerReportsServices";
import { careerSummaryApi } from "./services/careersummaryreportservices";
import { casenotesApi } from "./services/caseNotesServices";
import { clientInterviewApi } from "./services/clientInterviewServices";
import { clientApi } from "./services/clientServices";
import { companyApi } from "./services/companyServices";
import { comprehensiveReportApi } from "./services/comprehensiveReportServices";
import { comprehensiveApi } from "./services/comprehensiveServices";
import { departmentApi } from "./services/departmentServices";
import { educationApi } from "./services/educationServices";
import { employmentApi } from "./services/employmentServices";
import { financialApi } from "./services/financialServices";
import { homeAssessmentReportApi } from "./services/homeAssessmentReportServices";
import { homeObservationApi } from "./services/homeObservationServices";
import { homeReportApi } from "./services/homeReportServices";
import { houseHoldApi } from "./services/houseHoldServices";
import { interiorHomeApi } from "./services/interiorHomeServices";
import { legalApi } from "./services/legalServices";
import { medicalApi } from "./services/medicalServices";
import { observationApi } from "./services/observationServices";
import { physicalNeighborhoodApi } from "./services/PhysicalNeighborhoodServices";
import { reportApi } from "./services/reportServices";
import { staffApi } from "./services/staffServices";
import { summaryApi } from "./services/summaryServices";
import getPermission from "./permissions/permissionReducer"
import getUserData from "./userData/userDataReducer"
import getCasenoteData from "./caseNote/casenoteReducer";
import { releaseNoteApi } from "./services/releaseNoteServices";
import { referrialApi } from "./services/referrialServices";
import { mentalHealthfamilyApi } from "./services/MentalHealthfamilyServices";
import { mentalHealthMedicalApi } from "./services/MentalHealthMedicalServices";
import { mentalHealthTraumaApi } from "./services/mentalHealthTraumaServices";
import { mentalHealthEducationApi } from "./services/MentalHealthEducationServices";
import { mentalHealthDemographicApi } from "./services/mentalHealthDemographicServices";
import { mentalHealthCognitiveApi } from "./services/MentalCognitiveServices";
import { mentalHealthObservationApi } from "./services/MentalObservationServices";
import { mentalHealthComprehensiveApi } from "./services/MentalComprehensiveServices";
import { mentalHealthDiagnosticApi } from "./services/MentalDiagnosticServices";
import { mentalHealthThoughtApi } from "./services/MentalThoughtProcessServices";
import { mentalHealthSysmptomsApi } from "./services/MentalSymptomsServices";
import { comprehensiveAssessmentApi } from "./services/comprehensiveAssessmentServices";
import { mentalHealthReportApi } from "./services/mentalAssessmentReportServices";
import { comprehensiveActionApi } from "./services/ComprehensiveActionPlanServices";

/* This code is creating a Redux store using the `configureStore` function from the `@reduxjs/toolkit`
library. The store has a single reducer, which is the reducer generated by the `authApi` service.
The `middleware` option is used to add the middleware generated by the `authApi` service to the
store's middleware chain. Finally, the `store` object is exported so that it can be used in other
parts of the application. */
export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [companyApi.reducerPath]:companyApi.reducer,
    [departmentApi.reducerPath]:departmentApi.reducer,
    [staffApi.reducerPath]:staffApi.reducer,
    [clientApi.reducerPath]:clientApi.reducer,
    [houseHoldApi.reducerPath]:houseHoldApi.reducer,
    [educationApi?.reducerPath]:educationApi.reducer,
    [financialApi?.reducerPath]: financialApi.reducer,
    [employmentApi.reducerPath]:employmentApi.reducer,
    [legalApi.reducerPath]:legalApi.reducer,
    [observationApi.reducerPath]:observationApi.reducer,
    [summaryApi?.reducerPath]:summaryApi.reducer,
    [comprehensiveApi.reducerPath]:comprehensiveApi.reducer,
    [medicalApi.reducerPath]:medicalApi.reducer,
    [reportApi.reducerPath]:reportApi.reducer,
    [agencyApi.reducerPath]:agencyApi.reducer,
    [physicalNeighborhoodApi.reducerPath]:physicalNeighborhoodApi.reducer,
    [interiorHomeApi.reducerPath]:interiorHomeApi.reducer,
    [clientInterviewApi.reducerPath]:clientInterviewApi.reducer,
    [homeObservationApi.reducerPath]:homeObservationApi.reducer,
    [homeReportApi.reducerPath]:homeReportApi.reducer,
    [comprehensiveReportApi.reducerPath]:comprehensiveReportApi.reducer,
    [casenotesApi.reducerPath]:casenotesApi.reducer,
    [careerEmploymentApi.reducerPath]:careerEmploymentApi.reducer,
    [careerEducationApi.reducerPath]:careerEducationApi.reducer,
    [careerLegalApi.reducerPath]:careerLegalApi.reducer,
    [careerSummaryApi.reducerPath]:careerSummaryApi.reducer,
    [careerObservationApi.reducerPath]:careerObservationApi.reducer,
    [careerComprehensiveApi.reducerPath]:careerComprehensiveApi.reducer,
    [careerMedicalApi.reducerPath]:careerMedicalApi.reducer,
    [careerReportApi.reducerPath]:careerReportApi.reducer,
    [homeAssessmentReportApi.reducerPath]:homeAssessmentReportApi.reducer,
    [benefitsInerviewApi.reducerPath]:benefitsInerviewApi.reducer,
    [budgetInterviewApi.reducerPath]:budgetInterviewApi.reducer,
    [benefitsObservationApi.reducerPath]:benefitsObservationApi.reducer,
    [benefitsComprehensiveApi.reducerPath]:benefitsComprehensiveApi.reducer,
    [benefitsReportApi.reducerPath]:benefitsReportApi.reducer,
    [budgetPlannerApi.reducerPath]:budgetPlannerApi.reducer,
    getPermission,
    getUserData,
    getCasenoteData,
    [benefitsApi.reducerPath]:benefitsApi.reducer,
    [releaseNoteApi.reducerPath]:releaseNoteApi.reducer,
    [referrialApi.reducerPath]:referrialApi.reducer,
    [mentalHealthfamilyApi.reducerPath]:mentalHealthfamilyApi.reducer,
    [mentalHealthMedicalApi.reducerPath]:mentalHealthMedicalApi.reducer,
    [mentalHealthTraumaApi.reducerPath]:mentalHealthTraumaApi.reducer,
    [mentalHealthEducationApi.reducerPath]:mentalHealthEducationApi.reducer,
    [mentalHealthDemographicApi.reducerPath]:mentalHealthDemographicApi.reducer,
    [mentalHealthCognitiveApi.reducerPath]:mentalHealthCognitiveApi.reducer,
    [mentalHealthObservationApi.reducerPath]:mentalHealthObservationApi.reducer,
    [mentalHealthComprehensiveApi.reducerPath]:mentalHealthComprehensiveApi.reducer,
    [mentalHealthDiagnosticApi.reducerPath]:mentalHealthDiagnosticApi.reducer,
    [mentalHealthThoughtApi.reducerPath]:mentalHealthThoughtApi.reducer,
    [mentalHealthSysmptomsApi.reducerPath]:mentalHealthSysmptomsApi.reducer,
    [comprehensiveAssessmentApi.reducerPath]:comprehensiveAssessmentApi.reducer,
    [mentalHealthReportApi.reducerPath]:mentalHealthReportApi.reducer,
    [comprehensiveActionApi.reducerPath]:comprehensiveActionApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authApi.middleware,
    companyApi.middleware,departmentApi.middleware,
    staffApi.middleware,clientApi.middleware,houseHoldApi.middleware,
    educationApi.middleware,financialApi.middleware,employmentApi.middleware,
    legalApi.middleware,observationApi.middleware,summaryApi.middleware,comprehensiveApi.middleware,
    medicalApi.middleware,reportApi.middleware,agencyApi.middleware,physicalNeighborhoodApi.middleware,
    interiorHomeApi.middleware,clientInterviewApi.middleware,homeObservationApi.middleware,homeReportApi.middleware,
    comprehensiveReportApi.middleware,casenotesApi.middleware,careerEmploymentApi.middleware,careerEducationApi.middleware,
    careerLegalApi.middleware,careerSummaryApi.middleware,careerObservationApi.middleware,careerComprehensiveApi.middleware,
    careerMedicalApi.middleware,careerReportApi.middleware,homeAssessmentReportApi.middleware,
    benefitsInerviewApi.middleware,budgetInterviewApi.middleware,benefitsObservationApi.middleware,benefitsComprehensiveApi.middleware,
    benefitsReportApi.middleware,budgetPlannerApi.middleware,benefitsApi.middleware,releaseNoteApi.middleware,referrialApi.middleware,
    mentalHealthfamilyApi.middleware,mentalHealthMedicalApi.middleware,mentalHealthTraumaApi.middleware,
    mentalHealthEducationApi.middleware,mentalHealthDemographicApi.middleware,mentalHealthCognitiveApi.middleware,
    mentalHealthObservationApi.middleware,mentalHealthComprehensiveApi.middleware,
    mentalHealthDiagnosticApi.middleware,mentalHealthThoughtApi.middleware,mentalHealthSysmptomsApi.middleware,
    comprehensiveAssessmentApi.middleware,mentalHealthReportApi.middleware,
    comprehensiveActionApi.middleware,
    )
});

// export default store;
setupListeners(store.dispatch);
