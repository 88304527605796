import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const homeReportApi = createApi({
  reducerPath: 'homeReportApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: `${developmentApi}/api`,
  }),
  tagTypes: ['getHomeReport', 'getAllHomeCriticalConcern'],
  endpoints: (builder) => ({
    addHomeReport: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-home-report-summery?ClientId=${ClientId}`,
        method: 'POST',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ['getHomeReport'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updateHomeReport: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/update-home-report-summery?id=${id}`,
        method: 'PUT',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ['getHomeReport'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getHomeReport: builder.query({
      query: ({ clientId }) => ({
        url: `/company/get-home-report-summery?clientId=${clientId}`,
        method: 'GET',
      }),
      providesTags: ['getHomeReport'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    addHomeCriticalConcern: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-Home-Critical-Concerns-FollowUps?ClientId=${ClientId}`,
        method: 'POST',
        payload: payload,
      }),
      invalidatesTags: ['getAllHomeCriticalConcern'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updateHomeCriticalConcern: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/update-Home-Critical-Concerns-FollowUps?id=${id}`,
        method: 'PUT',
        payload: payload,
      }),
      invalidatesTags: ['getAllHomeCriticalConcern'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    getHomeCriticalConcernById: builder.query({
      query: ({ id }) => ({
        url: `/company/get-Home-Critical-Concerns-FollowUps?id=${id}`,
        method: 'GET',
      }),
      providesTags: ['getAllHomeCriticalConcern'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    getAllHomeCriticalConcern: builder.query({
      query: ({ clientId }) => ({
        url: `/company/get-Home-All-Critical-Concerns-FollowUps?clientId=${clientId}`,
        method: 'GET',
      }),
      providesTags: ['getAllHomeCriticalConcern'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    deleteHomeCriticalConcern: builder.mutation({
      query: ({ id }) => ({
        url: `/company/delete-Home-Critical-Concerns-FollowUps?id=${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['getAllHomeCriticalConcern'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
  }),
})

export const { useAddHomeReportMutation, useUpdateHomeReportMutation, useGetHomeReportQuery,
  useAddHomeCriticalConcernMutation, useUpdateHomeCriticalConcernMutation, useGetHomeCriticalConcernByIdQuery, useGetAllHomeCriticalConcernQuery, useDeleteHomeCriticalConcernMutation } = homeReportApi;
