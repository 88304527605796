import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const legalApi = createApi({
    reducerPath: 'legalApi',
    refetchOnMountOrArgChange: true,
    keepUnusedDataFor: 0,
    refetchOnReconnect: true,
    baseQuery: AxiosBaseQuery({
        baseUrl: `${developmentApi}/api`,
    }),
    tagTypes: ['getAllCriminalHistory', 'getLegalDetails'],
    endpoints: (builder) => ({
        addCriminalHistory: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-criminal-history?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getAllCriminalHistory'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateCriminalHistory: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-criminal-history?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getAllCriminalHistory'],
            transformResponse: (response) => JSON.parse(response)
        }),
        getAllCriminalHistory: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-all-criminal-history?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getAllCriminalHistory'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getCriminalHistoryById: builder.query({
            query: ({ id }) => ({
                url: `/company/get-criminal-history?id=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ['getAllCriminalHistory'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        deleteCriminalHistory: builder.mutation({
            query: ({ id }) => ({
                url: `/company/delete-criminal-history?id=${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['getAllCriminalHistory'],
            transformResponse: (response) => JSON.parse(response)
        }),
        addLegal: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-legal-background?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getLegalDetails'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateLegal: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-legal-background?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getLegalDetails'],
            transformResponse: (response) => JSON.parse(response)
        }),
        getLegal: builder.query({
            query: ({ id }) => ({
                url: `/company/get-legal-background?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['getLegalDetails'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
    }),
})

export const { useAddCriminalHistoryMutation, useUpdateCriminalHistoryMutation, useGetAllCriminalHistoryQuery, useGetCriminalHistoryByIdQuery, useDeleteCriminalHistoryMutation,
    useAddLegalMutation, useUpdateLegalMutation, useGetLegalQuery } = legalApi;
