/* This code is defining an object called `palette` which contains various color values for different
parts of a user interface. The `mode` property specifies whether the UI is in light or dark mode.
The other properties specify color values for primary, secondary, error, active, navbarProfile,
background, and edit button elements. These color values can be used in styling the UI elements of a
web application or website. The object is then exported using the `export default` statement, which
allows other modules to import and use this object. */
const palette = {
    mode: 'light',
    primary: {
        main: '#fff',
        dark: "#DED2D9"
    },
    text: {
        main: '#363636',
        light: '#4A5D66',
        dark: "#303030"
    },
    secondary: {
        main: '#EDA23E',
        light: '#FFF8EE',
    },
    error: {
        main: "#d32f2f"
    },
    active: {
        main: "#079F00"
    },
    navbarProfile: {
        main: "#F5F7F9"
    },
    background: {
        main: "#f5f5f5",
        dark: "#e4e4e4",
        light: '#4A5D66',
        fadeorange: '#FFEED7',
    },
    editBtn: {
        main: "#06B5FA"
    },
}
export default palette
