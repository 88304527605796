import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const benefitsObservationApi = createApi({
  reducerPath: 'benefitsOverviewApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: `${developmentApi}/api`,
  }),
  tagTypes:['getBenefitObservations'],
  endpoints: (builder) => ({
    addBenefitObservation: builder.mutation({
      query: ({ClientId, payload}) => ({
        url: `/company/add-budget-benefit-observation?ClientId=${ClientId}`,
        method: 'POST',
        fileUpload:true,
        payload:payload
      }),
      invalidatesTags:['getBenefitObservations'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updateBenefitObservation: builder.mutation({
      query: ({id, payload}) => ({
        url: `/company/update-budget-benefit-observation?id=${id}`,
        method: 'PUT',
        fileUpload:true,
        payload:payload
      }),
      invalidatesTags:['getBenefitObservations'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getBenefitObservation: builder.query({
      query: ({ id }) => ({
        url: `/company/get-budget-benefit-observation?id=${id}`,
        method: 'GET',
      }),
      providesTags:['getBenefitObservations'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
  }),
})

export const { useAddBenefitObservationMutation, useUpdateBenefitObservationMutation, useGetBenefitObservationQuery } = benefitsObservationApi;
