import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const homeAssessmentReportApi = createApi({
  reducerPath: 'homeAssessmentReportApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: `${developmentApi}/api`,
  }),
  endpoints: (builder) => ({
    getHomeSummaryReport: builder.query({
      query: ({clientId})=>({
        url:`/company/home-summery-report?clientId=${clientId}`,
        method:'GET',
      }),
      transformResponse:(response)=> JSON.parse(response)?.data
    }),
    getHomeDetailReport: builder.query({
      query: ({clientId})=>({
        url:`/company/home-detail-report?clientId=${clientId}`,
        method:'GET',
      }),
      transformResponse:(response)=> JSON.parse(response)?.data
    }),
    getAssessmentSection: builder.query({
      query: (clientId)=>({
        url:`/Client/GetAssessmentSectionConfig/${clientId}`,
        method:'GET',
      }),
      transformResponse:(response)=> JSON.parse(response)?.data
    }),
  }),
})

export const {useGetHomeSummaryReportQuery, useGetHomeDetailReportQuery, useGetAssessmentSectionQuery}=homeAssessmentReportApi;
