import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const departmentApi = createApi({
  reducerPath: 'departmentApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: `${developmentApi}/api`,
  }),
  tagTypes:['getDepartments'],
  endpoints: (builder) => ({
    getDepartments: builder.query({
      query: (params)=>({
        url:`/company/get-department`,
        method:'GET', params: params
      }),
      providesTags:['getDepartments'],
      transformResponse:(response)=> JSON.parse(response)?.data
    }),
    addDepartment: builder.mutation({
        query: ({payload})=>({
          url:`/company/add-department`,
          method:'POST',
          payload:payload
        }),
        invalidatesTags:['getDepartments'],
        transformResponse:(response)=> JSON.parse(response)
      }),
      updateDepartment: builder.mutation({
        query: ({id, payload})=>({
          url:`/company/update-department?id=${id}`,
          method:'PUT',
          payload:payload,
          fileUpload:true
        }),
        invalidatesTags: ['getDepartments'],
        transformResponse:(response)=> JSON.parse(response)
      }),
      getReportingList: builder.query({
        query: (params)=>({
          url:`/company/get-report-to-users`,
          method:'GET', params: params
        }),
        providesTags:['getDepartments'],
        transformResponse:(response)=> JSON.parse(response)?.data
      }),
  }),
})

export const {useGetDepartmentsQuery, useAddDepartmentMutation, useUpdateDepartmentMutation, useGetReportingListQuery}=departmentApi;
