import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const reportApi = createApi({
  reducerPath: 'reportApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: `${developmentApi}/api`,
  }),
  endpoints: (builder) => ({
    getSummaryReport: builder.query({
      query: ({clientId})=>({
        url:`/company/summery-report?clientId=${clientId}`,
        method:'GET',
      }),
      transformResponse:(response)=> JSON.parse(response)?.data
    }),
    getDetailedReport: builder.query({
        query: ({clientId})=>({
          url:`/company/detail-report?clientId=${clientId}`,
          method:'GET',
        }),
        transformResponse:(response)=> JSON.parse(response)?.data
      }),
  }),   
})

export const {useGetSummaryReportQuery, useGetDetailedReportQuery}=reportApi;
