import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const releaseNoteApi = createApi({
  reducerPath: 'releaseNoteApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: `${developmentApi}/api`,
  }),
  tagTypes:['getReleaseNoteByClient'],
  endpoints: (builder) => ({
    sendReleasenote: builder.mutation({
      query: ({payload}) => ({
        url: `/ReleaseNoteRequests/Send`,
        method: 'POST',
        payload:payload
      }),
      transformResponse: (response) => JSON.parse(response)
    }),
    getReleasenote: builder.query({
        query: ({companyId, clientid}) => ({
          url: `/ReleaseNoteRequests/${clientid}/${companyId}`,
          method: 'GET',
        }),
        transformResponse: (response) => JSON.parse(response)
      }),
      getReleasenotebyClient: builder.query({
        query: ({clientid}) => ({
          url: `/ReleaseNoteRequests/${clientid}`,
          method: 'GET',
        }),
        providesTags:['getReleaseNoteByClient'],
        transformResponse: (response) => JSON.parse(response)?.data
      }),
      submitReleasenote: builder.mutation({
        query: ({payload}) => ({
          url: `/ReleaseNoteRequests/SubmitResponse`,
          method: 'PUT',
          payload:payload
        }),
        invalidatesTags:['getReleaseNoteByClient'],
        transformResponse: (response) => JSON.parse(response)
      }),
  }),
})

export const { useSendReleasenoteMutation, useGetReleasenoteQuery, useGetReleasenotebyClientQuery, useSubmitReleasenoteMutation } = releaseNoteApi;
