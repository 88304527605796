import { Box, Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import closeIcon from "../../assets/Images/CloseIcon.svg";
import "./DropZone.css";
import { formatBytes } from "../../utils/util";
function DropZone({
  setFiles, files, btnText, icon, accept, width, preview, setFileError, data, fileSize, fileName, UploadFileSize, errorMsg }) {
  /* The line `const maxFileSize = "5242880";` is declaring a constant variable `maxFileSize` and
  assigning it a value of `"5242880"`. This value represents the maximum file size allowed in bytes.
  It is used later in the code to check if the size of the uploaded file exceeds this maximum limit. */
  const maxFileSize = UploadFileSize ? UploadFileSize : "5242880";
  const maxFiles = 1;
  /* `const onDrop` is a function that is created using the `useCallback` hook. It takes an array of
  `acceptedFiles` as an argument and sets it as the new value of `files` using the `setFiles`
  function. The `useCallback` hook is used to memoize the function and prevent unnecessary
  re-renders. The second argument `[files]` is an array of dependencies that tells React to recreate
  the function only if the `files` value changes. */
  const onDrop = useCallback(
    (acceptedFiles) => {
      // setFiles(acceptedFiles)
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    },
    [files]
  );

  /* `const { getRootProps, getInputProps } = useDropzone({...})` is using the `useDropzone` hook from
  the `react-dropzone` library to create a dropzone area for files to be uploaded. */
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    // maxSize: maxFileSize,
    accept: accept ? accept : {
      "image/png": [".png"],
      "image/jpg": [".jpg", ".jpeg"],
      "document/pdf": [".pdf"],
      "document/doc": [".doc", ".docx"],
    },
    maxFiles: maxFiles,
  });


  useEffect(() => {
    files?.length > 0 && files[0]?.size > maxFileSize ?
      setFileError(errorMsg ? errorMsg : "File size shouldn't exceed more than 5 MB") : setFileError("")
  }, [files])

  /**
   * This function removes a file from an array of files.
   * @param file - The `file` parameter is a variable that represents the file that needs to be removed
   * from the `files` array. It is used to find the index of the file in the array and then remove it
   * using the `splice` method.
   */
  const removeFile = (file) => () => {
    const filesDelete = [...files];
    filesDelete.splice(filesDelete.indexOf(file), 1);
    setFiles(filesDelete);
  };

  /* `const filesData` is a variable that is created to display the list of uploaded files. It uses the
  `files` array that is passed as a prop to the `DropZone` component and maps over it using the
  `map` function. For each file in the array, it creates an `li` element with a `key` attribute set
  to the `path` of the file. Inside the `li` element, it creates a `Grid` element with two child
  `Grid` elements. The first child `Grid` element contains a `Typography` component that displays
  the name of the file (`file.path`) and the second child `Grid` element contains another
  `Typography` component that displays the size of the file (`formatBytes(file.size)`). It also
  creates an `img` element with an `onClick` event that calls the `removeFile` function to remove
  the file from the `files` array. Finally, it returns the `filesData` variable to display the list
  of uploaded files. */

  const filesData = files?.length > 0 && files[0] !== null && files?.map((file) => (
    <li key={file?.path} style={{ marginRight: "10px" }}>
      <Grid className="uploadFile">
        <Grid>
          <Typography sx={{ fontSize: "13px", fontWeight: 600 }}>
            {file?.path || fileName || file?.name}
          </Typography>
          <Typography
            sx={{ fontSize: "12px", fontWeight: 600 }}
            className="uploadedFileSize"
          >
            {formatBytes(file?.size || fileSize)}
          </Typography>
        </Grid>
        <img
          src={closeIcon}
          onClick={removeFile(file)}
          className="removeUploadedFile"
          alt="Cancel"
        />
      </Grid>
    </li>
  ));
  return (
    <Box className="" sx={{ width: width ? width : 'auto' }}>
      <Grid className="">
        <Typography variant="formLabel" component={'div'}>
          {btnText ? btnText : ""}
        </Typography>
        <Grid {...getRootProps({ className: "dropzone uploadDocumentBody" })}>
          <img src={icon} alt="Browse" />
          <Grid >
            <input {...getInputProps()} />
          </Grid>
        </Grid>
      </Grid>
      {preview === true ? "" : <ul type="none" className="filesList">
        {filesData}
      </ul>}

    </Box>
  );
}

export default DropZone;
