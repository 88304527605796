import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const agencyApi = createApi({
  reducerPath: 'agencyApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: `${developmentApi}/api`,
  }),
  endpoints: (builder) => ({
    getAgencyList: builder.query({
      query: () => ({
        url: `/agency/get-all-agency-profile`,
        method: 'GET',
      }),
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    getDepartmentList: builder.query({
      query: ({ agencyId }) => ({
        url: `/agency/get-all-agency-department?agencyId=${agencyId}`,
        method: 'GET',
      }),
      transformResponse: (response) => JSON.parse(response)?.data
    }),
  }),
})

export const { useGetAgencyListQuery, useGetDepartmentListQuery } = agencyApi;
