import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Typography, Skeleton } from "@mui/material";
import palette from '../../../../../theme/palette';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DetailReportPopup from '../../ClientMCA/Components/DetailReportPopup';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { stringMatch } from '../../../../../utils/util';
import { toast } from 'react-toastify';

const header = [
    { key: "Goal Name", value: "GOAL_NAME", minWidth: 255, position: 'sticky', left: "0px", zIndex: 9999 },
    { key: "Intake Assigned To", value: "TNTAKE_ASSIGNED_TO" },
]

export default function ClientOtherGoalsTable({ data, isSuccess, isLoading, staffUserID, masterServiceId }) {
    const { id } = useParams();
    const navigate = useNavigate();
    const [filterData, setFilterData] = useState()
    const { permissionData } = useSelector((state) => state?.getPermission);
    const userPermission = Array.isArray(permissionData) ? permissionData.map(p => p.toLowerCase()) : permissionData?.length > 0 ? permissionData?.toLowerCase() : ''

    // useEffect(() => {
    //     if (((Array.isArray) && userPermission?.includes('company')) || userPermission === 'company') {
    //         setFilterData(data)
    //     }
    //     else if (((Array.isArray) && userPermission?.includes('manager')) || userPermission === 'manager') {
    //         setFilterData(data)
    //     }
    //     else {
    //         const dataFilter = data?.filter(item => item?.intakeAssignedTo === staffUserID)
    //         setFilterData(dataFilter)
    //     }
    // }, [data, isSuccess])

    useEffect(() => {
        if (userPermission === 'consultant' || userPermission === "consultant supervisor" || userPermission==="intern") {
            const dataFilter = data?.filter(item => stringMatch(item?.elementName) === "mentalhealthassessment" || stringMatch(item?.elementName) === "demographicassessment")
            setFilterData(dataFilter)
        }
        else {
            setFilterData(data)
        }
    }, [data, isSuccess])


    return (
        <Box container sx={{ width: "100%" }}>
            <TableContainer sx={{ maxWidth: `calc(100vw - 23rem)`, minWidth: '100%' }} >
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow >
                            {header?.map((data, i) => (
                                <TableCell sx={{ minWidth: data?.minWidth ?? 175, padding: '1rem', whiteSpace: 'nowrap', position: data?.position ?? 'none', left: data?.left, zIndex: data?.zIndex }}>{data?.key}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? [1, 2, 3, 4, 5, 6, 7]?.map((i) => (
                            <TableRow key={i}>
                                {header?.map((item) => (
                                    <TableCell><Skeleton variant='rectangular' sx={{ display: "block" }} /></TableCell>
                                ))}
                            </TableRow>
                        ))
                            : <>
                                {filterData?.length > 0 ?
                                    <>
                                        {isSuccess && filterData?.map((row) => (
                                            <TableRow
                                                key={row.name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                
                                                    <TableCell component="th" scope="row" style={{ position: 'sticky', left: "0px", background: palette.primary.main }}>
                                                        <Typography
                                                            variant='subtitle1' component={'span'} borderBottom='1px solid'
                                                            className='pointerCursor' color={palette.editBtn.main}>{row?.elementName}</Typography>
                                                    </TableCell>
                                                <TableCell component="th" scope="row"><Typography variant='subtitle1'>{row?.intakeAssignedToName || "N/A"}</Typography></TableCell>

                                               
                                            </TableRow>
                                        ))}
                                    </> :
                                    isSuccess && filterData?.length === 0 &&
                                    <TableRow><TableCell align='center' colSpan={header?.length}>{'No Records'}</TableCell></TableRow>
                                }
                            </>}

                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}