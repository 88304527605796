import { GET_CASENOTE_DATA } from "./casenoteDataType"

const initialState = {
  casenoteData: [],
}

export default function getCasenoteData(state = initialState, action) {
  if (action.type === GET_CASENOTE_DATA) {
    return { ...state, casenoteData: action.data }
  }
  return state
}
