import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const comprehensiveReportApi = createApi({
  reducerPath: 'comprehensiveReportApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: `${developmentApi}/api`,
  }),
  tagTypes: ['getComprehensiveReport', 'getRecommendations'],
  endpoints: (builder) => ({
    addComprehensiveReport: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-home-comprehensive-report?ClientId=${ClientId}`,
        method: 'POST',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ['getComprehensiveReport'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updateComprehensiveReport: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/update-home-comprehensive-report?id=${id}`,
        method: 'PUT',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ['getComprehensiveReport'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getComprehensiveReport: builder.query({
      query: ({ clientId }) => ({
        url: `/company/get-home-comprehensive-report?clientId=${clientId}`,
        method: 'GET',
      }),
      providesTags: ['getComprehensiveReport'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    addRecommendation: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-home-assessment-recommendation?ClientId=${ClientId}`,
        method: 'POST',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ['getRecommendations'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updateRecommendation: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/update-home-assessment-recommendation?id=${id}`,
        method: 'PUT',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ['getRecommendations'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getAllRecommendations: builder.query({
      query: ({ clientId }) => ({
        url: `/company/get-all-home-assessment-recommendation?clientId=${clientId}`,
        method: 'GET',
      }),
      providesTags: ['getRecommendations'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    getRecommendations: builder.query({
      query: ({ id }) => ({
        url: `/company/get-home-assessment-recommendation?id=${id}`,
        method: 'GET',
      }),
      invalidatesTags: ['getRecommendations'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    deleteRecommendation: builder.mutation({
      query: ({ id }) => ({
        url: `/company/delete-home-assessment-recommendation?id=${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['getRecommendations'],
      transformResponse: (response) => JSON.parse(response)
    }),
  }),
})

export const { useAddComprehensiveReportMutation, useUpdateComprehensiveReportMutation, useGetComprehensiveReportQuery, 
  useAddRecommendationMutation, useUpdateRecommendationMutation, useGetAllRecommendationsQuery, useGetRecommendationsQuery, useDeleteRecommendationMutation } = comprehensiveReportApi;
