import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import FirstRegisterForm from "./components/FirstRegisterForm/index.js";
import "./Register.css"
import SecondRegisterForm from "./components/SecondRegisterForm/";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useLocation } from "react-router-dom";

/**
 * This is a functional component for rendering a tab panel with conditional rendering of its children
 * based on the selected tab index.
 * @param props - props is an object that contains all the properties passed to the TabPanel component.
 * @returns A functional component named `TabPanel` is being returned. It takes in `props` as a
 * parameter and destructures `children`, `value`, `index`, and `other` from it. It returns a `div`
 * element with `role="tabpanel"`, `hidden` attribute set to `true` if `value` is not equal to `index`,
 * and `id` and `
 */
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: '20px 0' }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

/* `TabPanel.propTypes` is defining the expected types of the props passed to the `TabPanel` component.
In this case, it is specifying that `children` should be a `node`, `index` should be a `number` and
is required, and `value` should be a `number` and is also required. This helps to ensure that the
component is used correctly and can help catch errors during development. */
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

/**
 * The function returns an object with accessibility properties for a tab and its corresponding panel.
 * @param index - The index parameter is a number that represents the index of a tab or tab panel in a
 * tabbed interface. It is used to generate unique IDs and ARIA attributes for each tab and tab panel
 * to ensure accessibility for users with disabilities.
 * @returns The function `a11yProps` is returning an object with two properties: `id` and
 * `aria-controls`. The values of these properties are generated based on the `index` parameter passed
 * to the function.
 */
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export default function Register() {
    const location=useLocation()
    const tabNumber=location?.state?.tabNumber
    const dataForm= location?.state?.data?.data
    const w9form= location?.state?.data?.w9
    const logoPic= location?.state?.data?.logo
    const [value, setValue] = useState(0);



    /**
     * The function `handleChange` sets a new value for a variable called `value` based on the input
     * `newValue`.
     * @param event - The event parameter is an object that represents the event that triggered the
     * handleChange function. It contains information about the event, such as the type of event, the
     * target element, and any data associated with the event. In this case, it is likely an event
     * related to a change in a user interface element
     * @param newValue - The `newValue` parameter is the new value that is being passed to the
     * `handleChange` function. It is used to update the state of the component or perform any other
     * necessary actions based on the new value. In this case, it is being used to update the `value`
     * state variable.
     */
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [userLogo, setUserlogo] = useState([]);
    const [userWq, setUserWq] = useState([]);
    const [logoFileError, setLogoFileError] = useState("");
    const [w9FileError, setW9FileError] = useState("");
    /* The code is initializing a state variable called `formData` using the `useState` hook. The
    initial state of `formData` is an object with two properties: `values` and `error`. */
    const [formData, setFormData] = useState({
        values: {
            secondaryContactCheck: false,
            userAgreeCheck: false,
            RePassword:"",
            userPassword:""
        },
        error: {
            companyNameError: '',
            emailAddressError: '',
            mobileNumberError:"",
            secondaryMobileNumberError:"",
            SecondaryOfficePhoneNumberError:"",
            officeNumberError: "",
            displayNameError: "",
            einNumberError: "",
            zipCodeError: "",
            userPasswordError: "",
            RePasswordError: "",
        }
    });

    useEffect(()=>{
        tabNumber && 
        setValue(tabNumber)
        setFormData({
            ...formData,
            values:dataForm
        })
        setUserWq(w9form)
        setUserlogo(logoPic)
    },[tabNumber])
    return (
        <Grid container sx={{ maxWidth: '520px', pt:"2.4rem" }}>
            <Grid sx={{mb:'1.5rem'}}><Typography variant="h1">Register Your Company with Collab Set Go</Typography></Grid>
            <Box sx={{pr:"30px"}}>
            <Grid container justifyContent={"center"} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} aria-label="Auth Tabs" variant="fullWidth" className="w-100" onChange={handleChange}>
                    <Tab label="Step 1" {...a11yProps(0)} />
                    <Tab label="Step 2" {...a11yProps(1)} />
                </Tabs>
            </Grid>
            <TabPanel value={value} index={0}>
                <FirstRegisterForm formData={formData}
                    setFormData={setFormData} setLogoFileError={setLogoFileError} logoFileError={logoFileError}
                    setUserlogo={setUserlogo} w9FileError={w9FileError} setW9FileError={setW9FileError}
                    userLogo={userLogo} userWq={userWq} setUserWq={setUserWq} handleFormChange={handleChange} setValue={setValue} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <SecondRegisterForm w9FileError={w9FileError} setW9FileError={setW9FileError} setLogoFileError={setLogoFileError} logoFileError={logoFileError} handleFormChange={handleChange} setValue={setValue} formData={formData} userLogo={userLogo} userWq={userWq} setFormData={setFormData} />
            </TabPanel>
            </Box>
        </Grid>
    )
}