import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { useLocation } from "react-router-dom";
import palette from "../../../theme/palette";
import moment from "moment";
import {
  getCaseNoteData,
  getTime,
  getTotalTime,
  totalTimeCalculator,
  getFilterParams,
} from "../../../utils/util";
import html2pdf from "html2pdf.js/dist/html2pdf.js";
import goodstewardLogo from "../../../assets/Images/GoodstewardLogo.png";
import { useGetReferrialDetailsQuery } from "../../../store/services/referrialServices";
import { useSelector } from "react-redux";
import ReportHeader from "../ReportHeader";

export default function CasenotesExport() {
  const location = useLocation();
  const [clientId, setClientId] = useState();

  const [showTotalTime, setShowTotalTime] = useState();

  const [showInterventionTime, setShowInterventionTime] = useState();
  const [showTravelTime, setShowTravelTime] = useState();
  const [showDocumentTime, setShowDocumentTime] = useState();
  const [clientDetails, setClientDetails] = useState({ values: {} });
  const data = getCaseNoteData("CaseNotFilter");
  const filterData = getFilterParams("filterParams");
  const parseData = JSON.parse(data);
  const dataCaseNote = parseData;
  const [isExporting, setIsExporting] = useState(false);

  const reportTemplateRef = useRef(null);

  const { data: referrialDetailData, isSuccess: onSuccess } =
    useGetReferrialDetailsQuery(
      { clientID: filterData.clientId },
      { skip: !filterData.clientId }
    );

  const [caseNotesDataArray, setCaseNotesDataArray] = useState([]);

  useEffect(() => {
    if (
      dataCaseNote &&
      dataCaseNote.length > 0 &&
      !clientDetails.values.client
    ) {
      const formattedCaseNotesData = dataCaseNote.map((row) => ({
        staffName: row?.staff,
        meetingWith: row?.notes?.map((item) => item?.meetingWithName),
        startDate: moment(row?.startDate).format("MMMM DD, YYYY"),
        caseNotesInfo: row?.notes?.map((item) => item?.caseNotesInfo),
        totalTime: totalTimeCalculator(row?.notes?.map((item) => item)),
        interventionTime: row?.notes?.map((item) =>
          getTime(item?.interventionTime)
        ),
        travelTime: row?.notes?.map((item) => getTime(item?.travelTime)),
        documentationTime: row?.notes?.map((item) =>
          getTime(item?.documentationTime)
        ),
        billableStartDate: moment(row?.startDate).format("MMMM DD, YYYY"),
        billableEndDate: moment(row?.startDate).format("MMMM DD, YYYY"),
      }));

      const findOldestDate = (data) =>
        data?.reduce(
          (oldest, item) =>
            !oldest || new Date(item.startDate) > new Date(oldest.startDate)
              ? item
              : oldest,
          null
        )?.startDate;

      const billableEndDatebillableEndDate = findOldestDate(JSON.parse(data));

      setClientDetails({
        ...clientDetails,
        values: {
          ...clientDetails?.values,
          client: formattedCaseNotesData[0]?.staffName,
          billableStartDate: formattedCaseNotesData[0]?.billableStartDate,
          billableEndDate:
            moment(billableEndDatebillableEndDate).format("MMMM DD, YYYY") ||
            "N/A",
        },
      });
      setClientId(formattedCaseNotesData[0]?.clientId);

      setCaseNotesDataArray(formattedCaseNotesData);
    }
  }, [dataCaseNote, clientDetails]);

  useEffect(() => {
    const resultTotalTime = getTotalTime(caseNotesDataArray, "totalTime");
    const resultInterventionTime = getTotalTime(
      caseNotesDataArray,
      "interventionTime"
    );
    const resultTravelTime = getTotalTime(caseNotesDataArray, "travelTime");
    const resultDocTime = getTotalTime(caseNotesDataArray, "documentationTime");
    setShowTotalTime(resultTotalTime);
    setShowInterventionTime(resultInterventionTime);
    setShowDocumentTime(resultDocTime);
    setShowTravelTime(resultTravelTime);
  }, [caseNotesDataArray]);

  function download() {
    setIsExporting(true);
    var element = document.getElementById("element-to-print");
    html2pdf()
      .from(element)
      .set({
        filename: "Casenotes" + ".pdf",
        pagebreak: { mode: ["avoid-all", "css", "legacy"] },
        image: { type: "jpeg", quality: 1 },
        enableLinks: true,
        jsPDF: {
          orientation: "portrait",
          unit: "pt",
          format: "a2",
        },
      })
      .save()
      .then(() => {
        setIsExporting(false);
      });
  }

  return (
    <Box className="casenoteExportTopContainer" ref={reportTemplateRef}>
      <Grid container justifyContent={"flex-end"}>
        <Button variant="contained" onClick={download} disabled={isExporting}>
          Generate PDF
          {isExporting && (
            <CircularProgress
              color="primary"
              disableShrink
              size={20}
              sx={{ ml: 1 }}
            />
          )}
        </Button>
      </Grid>
      <Box id="element-to-print" p={2}>
        <ReportHeader data={referrialDetailData} />
        <div class="html2pdf__page-break"></div>
        <Grid container spacing={2} justifyContent={"center"}>
          <img src={goodstewardLogo} alt="companyLogo" />
        </Grid>
        <Box border={`2px solid ${palette.background.dark}`} p={1} mt={4}>
          <Grid container spacing={2}>
            <Grid
              xs={12}
              md={12}
              sm={12}
              lg={12}
              borderBottom={`2px solid ${palette.background.dark}`}
            >
              <Grid container justifyContent={"center"}>
                <Typography
                  variant="subtitle2"
                  color={palette.text.dark}
                  fontSize="18px"
                  fontWeight={700}
                >
                  Report
                </Typography>
              </Grid>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={6}
              lg={3}
              borderBottom={`2px solid ${palette.background.dark}`}
              borderRight={`2px solid ${palette.background.dark}`}
            >
              <Typography
                variant="subtitle2"
                color={palette.text.dark}
                fontSize="15px"
                fontWeight={600}
                component="div"
              >
                Consumer Name & Participation ID
              </Typography>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={6}
              lg={3}
              borderBottom={`2px solid ${palette.background.dark}`}
              borderRight={`2px solid ${palette.background.dark}`}
            >
              <Typography
                variant="subtitle2"
                color={palette.text.dark}
                fontSize="15px"
                fontWeight={400}
                component="div"
              >
                {referrialDetailData?.clientProfile
                  ? `${referrialDetailData?.clientProfile.firstName} ${
                      referrialDetailData?.clientProfile.lastName
                    } ${
                      referrialDetailData.agencyClientId
                        ? " - " + referrialDetailData.agencyClientId
                        : " "
                    } `
                  : "N/A"}
              </Typography>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={6}
              lg={3}
              borderBottom={`2px solid ${palette.background.dark}`}
              borderRight={`2px solid ${palette.background.dark}`}
            >
              <Typography
                variant="subtitle2"
                color={palette.text.dark}
                fontSize="15px"
                fontWeight={600}
                component="div"
              >
                Billable Month
              </Typography>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={6}
              lg={3}
              borderBottom={`2px solid ${palette.background.dark}`}
              sx={{
                borderRight: {
                  md: `2px solid ${palette.background.dark}`,
                  xs: "none",
                  sm: "none",
                  lg: "none",
                },
              }}
            >
              <Typography
                variant="subtitle2"
                color={palette.text.dark}
                fontSize="15px"
                fontWeight={400}
                component="div"
              >{`${
                moment(filterData?.from).format("MMMM DD, YYYY") || "N/A"
              } - ${
                moment(filterData?.to).format("MMMM DD, YYYY") || "N/A"
              }`}</Typography>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={6}
              lg={3}
              borderBottom={`2px solid ${palette.background.dark}`}
              borderRight={`2px solid ${palette.background.dark}`}
            >
              <Typography
                variant="subtitle2"
                color={palette.text.dark}
                fontSize="15px"
                fontWeight={600}
                component="div"
              >
                Agency
              </Typography>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={6}
              lg={3}
              borderBottom={`2px solid ${palette.background.dark}`}
              borderRight={`2px solid ${palette.background.dark}`}
            >
              <Typography
                variant="subtitle2"
                color={palette.text.dark}
                fontSize="15px"
                fontWeight={400}
                component="div"
              >
                {referrialDetailData?.referredByName
                  ? referrialDetailData?.referredByName
                  : "N/A"}
              </Typography>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={6}
              lg={3}
              borderBottom={`2px solid ${palette.background.dark}`}
              borderRight={`2px solid ${palette.background.dark}`}
            >
              <Typography
                variant="subtitle2"
                color={palette.text.dark}
                fontSize="15px"
                fontWeight={600}
                component="div"
              >
                Total Monthly Hours Billed
              </Typography>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={6}
              lg={3}
              borderBottom={`2px solid ${palette.background.dark}`}
              sx={{
                borderRight: {
                  md: `2px solid ${palette.background.dark}`,
                  xs: "none",
                  sm: "none",
                  lg: "none",
                },
              }}
            >
              <Typography
                variant="subtitle2"
                color={palette.text.dark}
                fontSize="15px"
                fontWeight={400}
                component="div"
              >
                {showTotalTime}
              </Typography>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={6}
              lg={3}
              sx={{
                borderBottom: {
                  md: `2px solid ${palette.background.dark}`,
                  xs: "none",
                  sm: "none",
                  lg: "none",
                },
              }}
              borderRight={`2px solid ${palette.background.dark}`}
            >
              <Typography
                variant="subtitle2"
                color={palette.text.dark}
                fontSize="15px"
                fontWeight={600}
                component="div"
              >
                Agency Case Manager
              </Typography>
            </Grid>
            <Grid xs={12} md={4} sm={6} lg={3}>
              <Typography
                variant="subtitle2"
                color={palette.text.dark}
                fontSize="15px"
                fontWeight={400}
                component="div"
              >
                {referrialDetailData?.agencyWorker
                  ? referrialDetailData?.agencyWorker
                  : "N/A"}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box border={`2px solid ${palette.background.dark}`} p={1} mt={4}>
          <Grid container spacing={2}>
            <Grid
              xs={12}
              md={12}
              sm={12}
              lg={12}
              borderBottom={`2px solid ${palette.background.dark}`}
            >
              <Grid container justifyContent={"center"}>
                <Typography
                  variant="subtitle2"
                  color={palette.text.dark}
                  fontSize="18px"
                  fontWeight={700}
                >
                  Case Notes
                </Typography>
              </Grid>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={6}
              lg={1}
              borderBottom={`2px solid ${palette.background.dark}`}
              borderRight={`2px solid ${palette.background.dark}`}
            >
              <Typography
                variant="subtitle2"
                wrap
                color={palette.text.dark}
                fontSize="15px"
                fontWeight={600}
                component="div"
              >
                Date of Serv.
              </Typography>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={6}
              lg={1}
              borderBottom={`2px solid ${palette.background.dark}`}
              borderRight={`2px solid ${palette.background.dark}`}
            >
              <Typography
                variant="subtitle2"
                color={palette.text.dark}
                fontSize="15px"
                fontWeight={600}
                component="div"
              >
                Staff Mem.
              </Typography>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={6}
              lg={1}
              borderBottom={`2px solid ${palette.background.dark}`}
              borderRight={`2px solid ${palette.background.dark}`}
            >
              <Typography
                variant="subtitle2"
                whiteSpace="break-spaces"
                color={palette.text.dark}
                fontSize="15px"
                fontWeight={600}
                component="div"
              >
                Meeting With
              </Typography>
            </Grid>
            <Grid
              xs={12}
              md={8}
              sm={6}
              lg={5}
              borderBottom={`2px solid ${palette.background.dark}`}
              borderRight={`2px solid ${palette.background.dark}`}
            >
              <Typography
                variant="subtitle2"
                color={palette.text.dark}
                fontSize="15px"
                fontWeight={600}
                component="div"
              >
                Case Note
              </Typography>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={6}
              lg={1}
              borderBottom={`2px solid ${palette.background.dark}`}
              borderRight={`2px solid ${palette.background.dark}`}
            >
              <Typography
                variant="subtitle2"
                color={palette.text.dark}
                fontSize="15px"
                fontWeight={600}
                component="div"
              >
                Interv. Time
              </Typography>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={6}
              lg={1}
              borderBottom={`2px solid ${palette.background.dark}`}
              borderRight={`2px solid ${palette.background.dark}`}
            >
              <Typography
                variant="subtitle2"
                color={palette.text.dark}
                fontSize="15px"
                fontWeight={600}
                component="div"
              >
                Travel Time
              </Typography>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={6}
              lg={1}
              borderBottom={`2px solid ${palette.background.dark}`}
              sx={{
                borderRight: {
                  md: `2px solid ${palette.background.dark}`,
                  xs: "none",
                  sm: `2px solid ${palette.background.dark}`,
                  lg: `2px solid ${palette.background.dark}`,
                },
              }}
            >
              <Typography
                variant="subtitle2"
                color={palette.text.dark}
                fontSize="15px"
                fontWeight={600}
                component="div"
              >
                Doc. Time
              </Typography>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={6}
              lg={1}
              sx={{
                borderBottom: {
                  md: `2px solid ${palette.background.dark}`,
                  xs: "none",
                  sm: "none",
                  lg: `2px solid ${palette.background.dark}`,
                },
                borderRight: {
                  md: `2px solid ${palette.background.dark}`,
                  xs: "none",
                  sm: "none",
                  lg: "none",
                },
              }}
            >
              <Typography
                variant="subtitle2"
                color={palette.text.dark}
                fontSize="15px"
                fontWeight={600}
                component="div"
              >
                Total Time
              </Typography>
            </Grid>
            {caseNotesDataArray?.map((row, i) => (
              <>
                <Grid
                  xs={12}
                  md={4}
                  sm={6}
                  lg={1}
                  borderBottom={`2px solid ${palette.background.dark}`}
                  borderRight={`2px solid ${palette.background.dark}`}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{ wordWrap: "break-word" }}
                    color={palette.text.dark}
                    fontSize="15px"
                    fontWeight={400}
                    component="div"
                  >
                    {moment(row?.startDate).format("MMMM DD, YYYY") || "N/A"}
                  </Typography>
                </Grid>
                <Grid
                  xs={12}
                  md={4}
                  sm={6}
                  lg={1}
                  borderBottom={`2px solid ${palette.background.dark}`}
                  borderRight={`2px solid ${palette.background.dark}`}
                >
                  <Typography
                    variant="subtitle2"
                    color={palette.text.dark}
                    fontSize="15px"
                    fontWeight={400}
                    sx={{ wordWrap: "break-word" }}
                    component="div"
                  >
                    {row?.staffName || "N/A"}
                  </Typography>
                </Grid>
                <Grid
                  xs={12}
                  md={4}
                  sm={6}
                  lg={1}
                  borderBottom={`2px solid ${palette.background.dark}`}
                  borderRight={`2px solid ${palette.background.dark}`}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{ wordWrap: "break-word" }}
                    color={palette.text.dark}
                    fontSize="15px"
                    fontWeight={400}
                    component="div"
                  >
                    {row?.meetingWith || "N/A"}
                  </Typography>
                </Grid>
                <Grid
                  xs={12}
                  md={8}
                  sm={6}
                  lg={5}
                  borderBottom={`2px solid ${palette.background.dark}`}
                  borderRight={`2px solid ${palette.background.dark}`}
                >
                  <Typography
                    variant="subtitle2"
                    color={palette.text.dark}
                    fontSize="15px"
                    fontWeight={400}
                    sx={{ wordWrap: "break-word" }}
                    component="div"
                  >
                    {row?.caseNotesInfo || "N/A"}
                  </Typography>
                </Grid>
                <Grid
                  xs={12}
                  md={4}
                  sm={6}
                  lg={1}
                  borderBottom={`2px solid ${palette.background.dark}`}
                  borderRight={`2px solid ${palette.background.dark}`}
                >
                  <Typography
                    variant="subtitle2"
                    color={palette.text.dark}
                    fontSize="15px"
                    fontWeight={400}
                    sx={{ wordWrap: "break-word" }}
                    component="div"
                  >
                    {row?.interventionTime?.map(
                      (item) => item?.hour + ":" + item?.minute
                    )}
                  </Typography>
                </Grid>
                <Grid
                  xs={12}
                  md={4}
                  sm={6}
                  lg={1}
                  borderBottom={`2px solid ${palette.background.dark}`}
                  borderRight={`2px solid ${palette.background.dark}`}
                >
                  <Typography
                    variant="subtitle2"
                    color={palette.text.dark}
                    fontSize="15px"
                    fontWeight={400}
                    sx={{ wordWrap: "break-word" }}
                    component="div"
                  >
                    {row?.travelTime?.map(
                      (item) => item?.hour + ":" + item?.minute
                    )}
                  </Typography>
                </Grid>
                <Grid
                  xs={12}
                  md={4}
                  sm={6}
                  lg={1}
                  borderBottom={`2px solid ${palette.background.dark}`}
                  sx={{
                    borderRight: {
                      md: `2px solid ${palette.background.dark}`,
                      xs: "none",
                      sm: `2px solid ${palette.background.dark}`,
                      lg: `2px solid ${palette.background.dark}`,
                    },
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    color={palette.text.dark}
                    fontSize="15px"
                    fontWeight={400}
                    sx={{ wordWrap: "break-word" }}
                    component="div"
                  >
                    {row?.documentationTime?.map(
                      (item) => item?.hour + ":" + item?.minute
                    )}
                  </Typography>
                </Grid>
                <Grid
                  xs={12}
                  md={4}
                  sm={6}
                  lg={1}
                  sx={{
                    borderBottom: {
                      md: `2px solid ${palette.background.dark}`,
                      xs: `2px solid ${palette.background.dark}`,
                      sm: `2px solid ${palette.background.dark}`,
                      lg: `2px solid ${palette.background.dark}`,
                    },
                    borderRight: {
                      md: `2px solid ${palette.background.dark}`,
                      xs: "none",
                      sm: "none",
                      lg: "none",
                    },
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    color={palette.text.dark}
                    fontSize="15px"
                    fontWeight={400}
                    sx={{ wordWrap: "break-word" }}
                    component="div"
                  >
                    {row?.totalTime?.map(
                      (item) => item?.hour + ":" + item?.minute
                    )}
                  </Typography>
                </Grid>
              </>
            ))}

            <Grid
              xs={12}
              md={8}
              sm={4}
              lg={8}
              sx={{
                borderRight: `2px solid ${palette.background.dark}`,
              }}
            >
              <Typography
                variant="subtitle2"
                color={palette.text.dark}
                fontSize="15px"
                fontWeight={600}
                component="div"
              >
                General SubTotal
              </Typography>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={2}
              lg={1}
              sx={{
                borderRight: `2px solid ${palette.background.dark}`,
              }}
              textAlign="left"
            >
              <Typography
                sx={{ wordWrap: "break-word" }}
                textAlign="left"
                variant="subtitle2"
                color={palette.text.dark}
                fontSize="15px"
                fontWeight={400}
                component="div"
              >
                {showInterventionTime}
              </Typography>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={2}
              lg={1}
              sx={{
                borderRight: `2px solid ${palette.background.dark}`,
              }}
            >
              <Typography
                sx={{ wordWrap: "break-word" }}
                align="left"
                variant="subtitle2"
                color={palette.text.dark}
                fontSize="15px"
                fontWeight={400}
                component="div"
              >
                {showTravelTime}
              </Typography>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={2}
              lg={1}
              sx={{
                borderRight: `2px solid ${palette.background.dark}`,
              }}
            >
              <Typography
                sx={{ wordWrap: "break-word" }}
                align="left"
                variant="subtitle2"
                color={palette.text.dark}
                fontSize="15px"
                fontWeight={400}
                component="div"
              >
                {showDocumentTime}
              </Typography>
            </Grid>
            <Grid xs={12} md={4} sm={2} lg={1}>
              <Typography
                sx={{ wordWrap: "break-word" }}
                align="left"
                variant="subtitle2"
                color={palette.text.dark}
                fontSize="15px"
                fontWeight={400}
                component="div"
              >
                {showTotalTime}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
