import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const careerMedicalApi = createApi({
  reducerPath: 'careerMedicalApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: `${developmentApi}/api`,
  }),
  tagTypes: ['getsideEffect','getphysicaldemands', 'getActivity','getDifficultySense','getMediAdditional', 'getMedicalHistory', 'getMentalHealth','getComprehensive'],
  endpoints: (builder) => ({
    addSideEffects: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-side-effects?ClientId=${ClientId}`,
        method: 'POST',
        fileUpload: true,
        payload: payload
      }),
      invalidatesTags: ['getsideEffect'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updateSideEffects: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/update-side-effects?id=${id}`,
        method: 'PUT',
        fileUpload: true,
        payload: payload,
      }),
      invalidatesTags: ['getsideEffect'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getSideEffects: builder.query({
      query: ({ id }) => ({
        url: `/company/get-side-effects?id=${id}`,
        method: 'GET',
      }),
      providesTags: ['getsideEffect'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    addPhysicalDemands: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-physical-demand?ClientId=${ClientId}`,
        method: 'POST',
        fileUpload: true,
        payload: payload
      }),
      invalidatesTags: ['getphysicaldemands'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updatePhysicalDemands: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/update-physical-demand?id=${id}`,
        method: 'PUT',
        fileUpload: true,
        payload: payload
      }),
      invalidatesTags: ['getphysicaldemands'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getPhysicalDemands: builder.query({
      query: ({ id }) => ({
        url: `/company/get-physical-demand?id=${id}`,
        method: 'GET',
      }),
      providesTags: ['getphysicaldemands'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    addActivity: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-activities-daily-living?ClientId=${ClientId}`,
        method: 'POST',
        fileUpload: true,
        payload: payload
      }),
      invalidatesTags: ['getActivity'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updateActivity: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/update-activities-daily-living?id=${id}`,
        method: 'PUT',
        fileUpload: true,
        payload: payload
      }),
      invalidatesTags: ['getActivity'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getActivity: builder.query({
      query: ({ id }) => ({
        url: `/company/get-activities-daily-living?id=${id}`,
        method: 'GET',
      }),
      providesTags: ['getActivity'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    addDifficultySenses: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-difficulty-with-senses?ClientId=${ClientId}`,
        method: 'POST',
        fileUpload: true,
        payload: payload
      }),
      invalidatesTags: ['getDifficultySense'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updateDifficultySenses: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/update-difficulty-with-senses?id=${id}`,
        method: 'PUT',
        fileUpload: true,
        payload: payload
      }),
      invalidatesTags: ['getDifficultySense'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getDifficultySenses: builder.query({
      query: ({ id }) => ({
        url: `/company/get-difficulty-with-senses?id=${id}`,
        method: 'GET',
      }),
      providesTags: ['getDifficultySense'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    addMedAdditional: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-medical-additional-info?ClientId=${ClientId}`,
        method: 'POST',
        fileUpload: true,
        payload: payload
      }),
      invalidatesTags: ['getMediAdditional'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updateMedAdditional: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/update-medical-additional-info?id=${id}`,
        method: 'PUT',
        fileUpload: true,
        payload: payload
      }),
      invalidatesTags: ['getMediAdditional'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getMedAdditional: builder.query({
      query: ({ id }) => ({
        url: `/company/get-medical-additional-info?id=${id}`,
        method: 'GET',
      }),
      providesTags: ['getMediAdditional'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    addMedicalHistoryCareer: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-medical-history-career?ClientId=${ClientId}`,
        method: 'POST',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ['getMedicalHistory'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updateMedicalHistoryCareer: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/update-medical-history-career?id=${id}`,
        method: 'PUT',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ['getMedicalHistory'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getMedicalHistoryCareer: builder.query({
      query: ({ id }) => ({
        url: `/company/get-medical-history-career?id=${id}`,
        method: 'GET',
      }),
      providesTags: ['getMedicalHistory'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    addMentalHealth: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-mental-health-career?ClientId=${ClientId}`,
        method: 'POST',
        fileUpload: true,
        payload: payload
      }),
      invalidatesTags: ['getMentalHealth'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updateMentalHealth: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/update-mental-health-career?id=${id}`,
        method: 'PUT',
         fileUpload: true,
        payload: payload
      }),
      invalidatesTags: ['getMentalHealth'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getMentalHealth: builder.query({
      query: ({ id }) => ({
        url: `/company/get-mental-health-career?id=${id}`,
        method: 'GET',
      }),
      providesTags: ['getMentalHealth'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
   
    updateSubstanceAbuse:  builder.mutation({
      query: ({ clientId, payload }) => ({
        url: `/company/update-Substance-Abuse-MHS`,
        method: 'PUT',
        payload: payload
      }),
      invalidatesTags: ['getSubstanceAbuse'],
      transformResponse: (response) => JSON.parse(response)
    }),
    addSubstanceAbuse: builder.mutation({
      query: ({ clientId, payload }) => ({
        url: `/company/add-Substance-Abuse-MHS`,
        method: 'POST',
        payload: payload
      }),
      invalidatesTags: ['getSubstanceAbuse'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getSubstanceAbuse: builder.query({
      query: ( clientId ) => ({
        url: `/company/get-Substance-Abuse-MHS/${clientId}`,
        method: 'GET',
      }),
      providesTags: ['getSubstanceAbuse'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),

     
     addComprehensive: builder.mutation({
      query: ({ClientId, payload})=>({
        url:`/company/add-report-comment-mhs?ClientId=${ClientId}`,
        method:'POST',
        fileUpload:true,
        payload:payload
      }),
      invalidatesTags:['getComprehensive'],
      transformResponse:(response)=> JSON.parse(response)?.data
    }),
    updateComprehensive: builder.mutation({
      query: ({id, payload})=>({
        url:`/company/update-report-comment-mhs?id=${id}`,
        method:'PUT',
        fileUpload:true,
        payload:payload
      }),
      invalidatesTags:['getComprehensive'],
      transformResponse:(response)=> JSON.parse(response)?.data
    }),
    getComprehensive: builder.query({
        query: ({id})=>({
          url:`/company/get-report-comment-mhs?id=${id}`,
          method:'GET',
        }),
        providesTags:['getComprehensive'],
        transformResponse:(response)=> JSON.parse(response)?.data
      }),


  }),
})

export const { useAddSideEffectsMutation, useUpdateSideEffectsMutation, useGetSideEffectsQuery ,
    useAddPhysicalDemandsMutation, useUpdatePhysicalDemandsMutation, useGetPhysicalDemandsQuery,
    useAddActivityMutation, useUpdateActivityMutation , useGetActivityQuery, 
    useAddDifficultySensesMutation, useUpdateDifficultySensesMutation, useGetDifficultySensesQuery, 
    useAddMedAdditionalMutation , useUpdateMedAdditionalMutation, useGetMedAdditionalQuery ,
    useAddMedicalHistoryCareerMutation, useUpdateMedicalHistoryCareerMutation, useGetMedicalHistoryCareerQuery ,
    useAddMentalHealthMutation, useUpdateMentalHealthMutation, useGetMentalHealthQuery,
    useAddComprehensiveMutation, useUpdateComprehensiveMutation,useGetComprehensiveQuery,
    useAddSubstanceAbuseMutation, useUpdateSubstanceAbuseMutation, useGetSubstanceAbuseQuery} = careerMedicalApi;
