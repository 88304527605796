import { Box, Grid, Typography } from "@mui/material";
import React from 'react';
import palette from "../../theme/palette";

export default function ConsultantDashboard() {
    return (
        <Box sx={{ backgroundColor: palette.primary.main }} p={10}>
            <Grid container justifyContent={'center'}><Typography variant="h1">Consultant Dashboard Under Construction</Typography></Grid>
        </Box>

    )
}