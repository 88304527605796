import {
    Box, Button, Grid, TextField, Typography, IconButton,
    InputAdornment, CircularProgress
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useStaffSetPasswordMutation } from "../../../../store/services/staffServices";
import palette from "../../../../theme/palette";

export default function StaffSetPassword() {
    /* This line of code is parsing the query parameters from the current URL and storing them in an
    array called `queryStringValues`. */
    const queryStringValues = Object.keys(JSON.parse(JSON.stringify(getQueryParams(window.location.href))))
    /* `const [resetPassword, {isLoading}] = useResetPasswordMutation({});` is using the
    `useResetPasswordMutation` hook from the `authServices` store service to create a
    `resetPassword` function and a `isLoading` boolean variable. The `resetPassword` function is
    used to send a request to the server to reset the user's password, while the `isLoading`
    variable is used to track whether the request is currently being processed or not. The
    `useResetPasswordMutation` hook is likely using the `createAsyncThunk` function from the
    `redux-toolkit` library to handle the asynchronous nature of the request and manage the state of
    the request in the Redux store. */
    const [setPassword, { isLoading }] = useStaffSetPasswordMutation({});
    const navigate = useNavigate();

    /**
     * The function extracts query parameters from a URL and returns them as an object.
     * @param url - The URL from which we want to extract query parameters.
     * @returns an object containing the query parameters parsed from the input URL.
     */
    function getQueryParams(url) {
        const paramArr = url.slice(url.indexOf('?') + 1)?.split('&');
        const params = {};
        paramArr.map(param => {
            const [key, val] = param?.split('?');
            params[key] = decodeURIComponent(val);
        })
        return params;
    }
    /* `const [userResetPassword, setUserResetPassword] = useState({...})` is creating a state variable
    called `userResetPassword` and a function to update that state called `setUserResetPassword`.
    The initial state of `userResetPassword` is an object with four properties: `newPassword`,
    `confirmPassword`, `newPasswordError`, and `confirmPasswordError`, all of which are initially
    set to empty strings. This state is used to store the values entered by the user in the "New
    Password" and "Confirm New Password" fields, as well as any errors that may occur during
    validation of those fields. The `useState` hook is a built-in React hook that allows functional
    components to have state variables. */
    const [userResetPassword, setUserResetPassword] = useState({
        values: { newPassword: '', confirmPassword: '' }, error: { newPasswordError: "", confirmPasswordError: "" }
    })
    /* `const [showPassword, setShowPassword] = useState({ password: false, confirmPassword: false })`
    is creating a state variable called `showPassword` and a function to update that state called
    `setShowPassword`. The initial state of `showPassword` is an object with two properties:
    `password` and `confirmPassword`, both of which are initially set to `false`. This state is used
    to toggle the visibility of the password and confirm password fields. When the user clicks on
    the eye icon next to the password or confirm password field, the `handleClickShowPassword`
    function is called, which updates the `showPassword` state to toggle the visibility of the
    corresponding field. If `password` is `true`, the password field is displayed in plain text,
    otherwise it is displayed as a password field. The same applies to the `confirmPassword` field. */
    const [showPassword, setShowPassword] = useState({
        password: false,
        confirmPassword: false,
    });
    /**
     * The function toggles the visibility of either the user password or the confirm password based on
     * the parameter passed.
     * @param param - The parameter `param` is a string that is used to determine which password field
     * to toggle the visibility of. It can have two possible values: "userPassword" or "RePassword".
     */
    const handleClickShowPassword = (param) => {
        param === "userPassword" &&
            setShowPassword({
                password: !showPassword.password,
                confirmPassword: showPassword.confirmPassword,
            });
        param === "RePassword" &&
            setShowPassword({
                password: showPassword.password,
                confirmPassword: !showPassword.confirmPassword,
            });
    };
    /**
     * The handleChange function updates the state of userResetPassword object with the new value of
     * the input field.
     * @param e - The parameter "e" is an event object that is passed as an argument to the
     * handleChange function. It represents the event that triggered the function, such as a change in
     * the input field value.
     */
    const handleChange = (e) => {
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()\-=^]).{8,20}$/;
        let { name, value } = e.target;
        if (e.target.name === "newPassword") {
            setUserResetPassword({
                ...userResetPassword,
                values: {
                    ...userResetPassword?.values,
                    [name]: value,
                },
                error: {
                    ...userResetPassword?.error,
                    newPasswordError: (passwordRegex.test(e.target.value) ? "" : "Password should have 8-20 characters, 1 digit, 1 uppercase letter, 1 lowercase letter, 1 special character (!@#$%&*()-+=^), no white space."),
                },
            });
        }
        else {
            setUserResetPassword({
                ...userResetPassword,
                values: {
                    ...userResetPassword?.values,
                    [name]: value,
                },
            });
        }
    };

    /* The above code is a React `useEffect` hook that is triggered whenever the
    `userResetPassword.newPassword` or `userResetPassword.confirmPassword` values change. It checks
    if the `newPassword` value matches a specific password regex pattern and sets an error message
    if it doesn't. It also checks if the `confirmPassword` value matches the `newPassword` value and
    sets an error message if it doesn't. If either of these values are empty, it clears the error
    messages. This code is likely used for validating a user's password reset form. */
    useEffect(() => {
        if (userResetPassword?.values?.confirmPassword !== '') {
            setUserResetPassword({
                ...userResetPassword,
                error: {
                    ...userResetPassword?.error,
                    confirmPasswordError: (userResetPassword?.values?.newPassword === userResetPassword?.values?.confirmPassword ? "" : "Confirm Password not matched")
                }
            })
        }
        else {
            setUserResetPassword({
                ...userResetPassword,
                error: {
                    ...userResetPassword?.error,
                    confirmPasswordError: ""
                }
            })
        }
    }, [userResetPassword?.values?.newPassword, userResetPassword?.values?.confirmPassword])

    /* The above code is checking if certain conditions are met in order to enable a button. It is
    using optional chaining (`?.`) to access properties of an object `userResetPassword`. The
    conditions that need to be met are:
    - `newPassword` property of `userResetPassword` is truthy
    - `confirmPassword` property of `userResetPassword` is truthy
    - `confirmPasswordError` property of `userResetPassword` is an empty string
    - `newPasswordError` property of `userResetPassword` is an empty string */
    const EnableButton = userResetPassword?.values?.newPassword &&
        userResetPassword?.values?.confirmPassword &&
        userResetPassword?.error?.confirmPasswordError === '' &&
        userResetPassword?.error?.newPasswordError === ''

    /**
     * This function handles the submission of a reset password form and sends a request to the server
     * to reset the user's password.
     */
    const handleSubmit = () => {
        const payload = {
            password: userResetPassword?.values?.newPassword,
            confirmPassword: userResetPassword?.values?.confirmPassword,
            email: queryStringValues[1],
            token: queryStringValues[0]
        }
        setPassword({ payload }).unwrap()
            .then((response) => {
                toast.success(response?.message)
                navigate("/")
            })
            .catch((error) => console.log(error))
    }
    return (
        <Box sx={{ maxWidth: '500px' }}>
            <Grid container spacing={2}>
                <Grid item lg={12} pb="20px">
                    <Typography variant="h2" component="div">Set Password</Typography>
                </Grid>
                <Grid item lg={12}>
                    <Typography variant="formLabel" component='div'>New Password</Typography>
                    <TextField
                        error={userResetPassword?.values?.newPassword !== '' && userResetPassword?.error?.newPasswordError ? true : false}
                        type={showPassword?.password ? 'text' : 'password'}
                        fullWidth
                        id="outlined-error-helper-text"
                        size="small"
                        name="newPassword"
                        helperText={<Typography variant="errorMessage">{userResetPassword?.values?.newPassword !== '' && userResetPassword?.error?.newPasswordError ? userResetPassword?.error?.newPasswordError : ""}</Typography>}
                        value={
                            userResetPassword?.values?.newPassword
                                ? userResetPassword?.values?.newPassword
                                : ""
                        }
                        autoComplete="new-password"
                        onChange={(e) => handleChange(e)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => handleClickShowPassword("userPassword")}
                                    >
                                        {showPassword?.password ? (
                                            <Visibility />
                                        ) : (
                                            <VisibilityOff />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item lg={12}>
                    <Typography variant="formLabel" component='div'>Confirm New Password</Typography>
                    <TextField
                        error={userResetPassword?.error?.confirmPasswordError ? true : false}
                        type={showPassword?.confirmPassword ? 'text' : 'password'}
                        fullWidth
                        id="outlined-error-helper-text"
                        size="small"
                        name="confirmPassword"
                        helperText={<Typography variant="errorMessage">{userResetPassword?.error?.confirmPasswordError || ""}</Typography>}
                        value={
                            userResetPassword?.values?.confirmPassword
                                ? userResetPassword?.values?.confirmPassword
                                : ""
                        }
                        autoComplete="new-password"
                        onChange={(e) => handleChange(e)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => handleClickShowPassword("RePassword")}
                                    >
                                        {showPassword?.confirmPassword ? (
                                            <Visibility />
                                        ) : (
                                            <VisibilityOff />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item lg={12} mt="30px">
                    <Button disabled={!EnableButton || isLoading} variant="contained" fullWidth onClick={() => handleSubmit()}>Submit
                        {isLoading && <CircularProgress size={20} sx={{ ml: 2, color: palette.secondary.main }} />}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}