import { Box, Button, Grid, Typography, CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Email from "../../../../../assets/Images/Email.png"
import { useLazyResendConfirmEmailQuery, useConfirmEmailQuery } from "../../../../../store/services/authServices";
import palette from "../../../../../theme/palette";
import { toast } from "react-toastify";
import ThankYouModal from "../ThankYouModal";
import { Co2Sharp } from "@mui/icons-material";
import { maskedEmail } from "../../../../../utils/util";

export default function EmailVerifyScreen() {
  /* `const location = useLocation();` is using the `useLocation` hook from the `react-router-dom`
  library to get the current location object. This location object contains information about the
  current URL, including the pathname, search query parameters, and hash. It is commonly used in
  React applications that use client-side routing to determine which components to render based on
  the current URL. */
  const location = useLocation();
  /* `const queryStringValues =
  Object.keys(JSON.parse(JSON.stringify(getQueryParams(window.location.href))))` is extracting the
  query string parameters from the current URL and storing them in an array called
  `queryStringValues`. */
  const queryStringValues = Object.keys(JSON.parse(JSON.stringify(getQueryParams(window.location.href))))



  /* `const token = queryStringValues[0]` is extracting the first query string parameter from the
  current URL and storing it in a variable called `token`. */
  const token = queryStringValues[0]
  const email = queryStringValues[1]
  const Useremail = location?.state?.data;




  /* `const [userResendEmail, {isLoading}] = useLazyResendConfirmEmailQuery();` is using the
  `useLazyResendConfirmEmailQuery` hook from the `authServices` module to create a `userResendEmail`
  function and a `isLoading` boolean variable. The `userResendEmail` function is a function that can
  be called to resend the confirmation email to the user's email address. The `isLoading` variable
  is a boolean that indicates whether the `userResendEmail` function is currently loading or not.
  This hook is likely used to handle the logic for resending the confirmation email to the user. */
  const [userResendEmail, { isLoading }] = useLazyResendConfirmEmailQuery();

  /* `const { data, isSuccess} = useConfirmEmailQuery({token: token, email: email}, { skip:(!token &&
  !email) || email == undefined })` is using the `useConfirmEmailQuery` hook from the `authServices`
  module to create a `data` object and an `isSuccess` boolean variable. The `useConfirmEmailQuery`
  hook is likely used to handle the logic for confirming the user's email address based on the token
  and email parameters passed to it. The `data` object likely contains the response data from the
  API call made by the `useConfirmEmailQuery` hook, while the `isSuccess` boolean variable indicates
  whether the API call was successful or not. The `skip` option in the second parameter of the
  `useConfirmEmailQuery` hook is likely used to skip the API call if the `token` and `email`
  parameters are not present or are undefined. */
  const { data, isSuccess } = useConfirmEmailQuery({ token: token, email: email }, { skip: (!token && !email) || email == undefined })

  /**
   * This function extracts query parameters from a URL and returns them as an object.
   * @param url - The `url` parameter is a string representing a URL that may contain query parameters.
   * @returns an object containing the query parameters parsed from the input URL.
   */
  function getQueryParams(url) {
    const paramArr = url.slice(url.indexOf('?') + 1).split('&');
    const params = {};
    paramArr.map(param => {
      const [key, val] = param.split('?');
      params[key] = decodeURIComponent(val);
    })
    return params;
  }
  /* The `useEffect` hook is used to perform side effects in a React functional component. In this
  case, the `useEffect` hook is being used to set the `open` state variable to `true` when the
  component mounts and when the `isSuccess` variable changes. */
  useEffect(() => {
    isSuccess &&
      setOpen(true)
  }, [isSuccess])

  const [open, setOpen] = useState(false);
  const [seconds, setSeconds] = useState(60);
  /* The `useEffect` hook is used to perform side effects in a React functional component. In this
  case, the `useEffect` hook is being used to set up a timer that decrements the `seconds` state
  variable by 1 every second, as long as `seconds` is greater than 0. The `interval` variable is
  used to store the ID of the timer, which is returned by the `setInterval` function. The `return`
  statement in the `useEffect` hook is used to clear the timer by calling the `clearInterval`
  function with the `interval` ID as an argument. The `seconds` state variable is passed as a
  dependency to the `useEffect` hook, which means that the hook will be re-run whenever `seconds`
  changes. */
  useEffect(() => {
    let interval = null;
    if (seconds > 0) {
      interval = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [seconds]);

  const EnableButtonResend = seconds == 0;

  /**
   * This function handles the resend email feature for a user and displays a success message upon
   * completion.
   */
  const handleResendEmail = () => {
    const params = location?.state?.data === undefined ? email : location?.state?.data;
    userResendEmail(params).unwrap()
      .then((response) => {
        toast.success(response?.message)
        setSeconds(60)
      })
      .catch((error) => console.log(error?.message))
  }

  return (
    <Box>
      <Grid container maxWidth={'500px'}>
        <Grid item lg={12} pb="20px"><img src={Email} alt="Email" /></Grid>
        <Grid item lg={12} pb="30px"><Typography variant="h2">Verify Your Account</Typography></Grid>
        <Grid item lg={12} pb="25px">
          <Typography variant="subtitle1">{`Thank you for registering!  We've just sent a verification link 
          to ${maskedEmail(Useremail)}. You have 15 minutes to verify your email.  
          If you don't receive it within a few minutes, you can use the Resend Link button below to try again.  Welcome aboard!`}
          </Typography>
        </Grid>
        <Grid item lg={12} pt="20px">
          <Button disabled={!EnableButtonResend || isLoading} variant="contained" fullWidth onClick={() => handleResendEmail()}>{EnableButtonResend ? "Resend Link" : `Resend Link in ${seconds} sec`}
            {isLoading && <CircularProgress size={20} sx={{ ml: 2, color: palette.secondary.main }} />} </Button>
        </Grid>
      </Grid>
      {open && <ThankYouModal open={open} setOpen={setOpen} />}
    </Box>
  )
}