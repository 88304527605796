import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const budgetPlannerApi = createApi({
  reducerPath: 'budgetPlannerApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: `${developmentApi}/api`,
  }),
  tagTypes:['getMonetary','getNonMonetary', 'getHouseholdExpense', 'getTransportationExpense', 
  'getMedicalHealthExpense', 'getPersonalExpense', 'getCharitySaving', 'getPetExpense', 'getDebtExpense','getChildExpense'],
  endpoints: (builder) => ({
    addMonetary: builder.mutation({
        query: ({ ClientId, payload }) => ({
            url: `/company/add-monetary-resources?ClientId=${ClientId}`,
            method: 'POST',
            payload: payload,
            // fileUpload: true
        }),
        invalidatesTags: ['getMonetary'],
        transformResponse: (response) => JSON.parse(response)
    }),
    updateMonetary: builder.mutation({
        query: ({ id, payload }) => ({
            url: `/company/update-monetary-resources?id=${id}`,
            method: 'PUT',
            payload: payload,
            // fileUpload: true
        }),
        invalidatesTags: ['getMonetary'],
        transformResponse: (response) => JSON.parse(response)
    }),
    getMonetary: builder.query({
        query: ({ id }) => ({
            url: `/company/get-monetary-resources?id=${id}`,
            method: 'GET',
        }),
        providesTags: ['getMonetary'],
        transformResponse: (response) => JSON.parse(response)?.data
    }),
    addNonMonetary: builder.mutation({
        query: ({ ClientId, payload }) => ({
            url: `/company/add-non-monetary-resources?ClientId=${ClientId}`,
            method: 'POST',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags: ['getNonMonetary'],
        transformResponse: (response) => JSON.parse(response)
    }),
    updateNonMonetary: builder.mutation({
        query: ({ id, payload }) => ({
            url: `/company/update-non-monetary-resources?id=${id}`,
            method: 'PUT',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags: ['getNonMonetary'],
        transformResponse: (response) => JSON.parse(response)
    }),
    getNonMonetary: builder.query({
        query: ({ id }) => ({
            url: `/company/get-non-monetary-resources?id=${id}`,
            method: 'GET',
        }),
        providesTags: ['getNonMonetary'],
        transformResponse: (response) => JSON.parse(response)?.data
    }),
    addHouseholdExpense: builder.mutation({
        query: ({ ClientId, payload }) => ({
            url: `/company/add-houseHold-expenses?ClientId=${ClientId}`,
            method: 'POST',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags: ['getHouseholdExpense'],
        transformResponse: (response) => JSON.parse(response)
    }),
    updateHouseholdExpense: builder.mutation({
        query: ({ id, payload }) => ({
            url: `/company/update-houseHold-expenses?id=${id}`,
            method: 'PUT',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags: ['getHouseholdExpense'],
        transformResponse: (response) => JSON.parse(response)
    }),
    getHouseholdExpense: builder.query({
        query: ({ id }) => ({
            url: `/company/get-houseHold-expenses?id=${id}`,
            method: 'GET',
        }),
        providesTags: ['getHouseholdExpense'],
        transformResponse: (response) => JSON.parse(response)?.data
    }),
    addTransportationExpense: builder.mutation({
        query: ({ ClientId, payload }) => ({
            url: `/company/add-transportation-expenses?ClientId=${ClientId}`,
            method: 'POST',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags: ['getTransportationExpense'],
        transformResponse: (response) => JSON.parse(response)
    }),
    updateTransportationExpense: builder.mutation({
        query: ({ id, payload }) => ({
            url: `/company/update-transportation-expenses?id=${id}`,
            method: 'PUT',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags: ['getTransportationExpense'],
        transformResponse: (response) => JSON.parse(response)
    }),
    getTransportationExpense: builder.query({
        query: ({ id }) => ({
            url: `/company/get-transportation-expenses?id=${id}`,
            method: 'GET',
        }),
        providesTags: ['getTransportationExpense'],
        transformResponse: (response) => JSON.parse(response)?.data
    }),
    addMedicalHealthExpense: builder.mutation({
        query: ({ ClientId, payload }) => ({
            url: `/company/add-medical-health-expenses-bba?ClientId=${ClientId}`,
            method: 'POST',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags: ['getMedicalHealthExpense'],
        transformResponse: (response) => JSON.parse(response)
    }),
    updateMedicalHealthExpense: builder.mutation({
        query: ({ id, payload }) => ({
            url: `/company/update-medical-health-expenses-bba?id=${id}`,
            method: 'PUT',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags: ['getMedicalHealthExpense'],
        transformResponse: (response) => JSON.parse(response)
    }),
    getMedicalHealthExpense: builder.query({
        query: ({ id }) => ({
            url: `/company/get-medical-health-expenses-bba?id=${id}`,
            method: 'GET',
        }),
        providesTags: ['getMedicalHealthExpense'],
        transformResponse: (response) => JSON.parse(response)?.data
    }),
    addPersonalExpense: builder.mutation({
        query: ({ ClientId, payload }) => ({
            url: `/company/add-personal-expenses-bba?ClientId=${ClientId}`,
            method: 'POST',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags: ['getPersonalExpense'],
        transformResponse: (response) => JSON.parse(response)
    }),
    updatePersonalExpense: builder.mutation({
        query: ({ id, payload }) => ({
            url: `/company/update-personal-expenses-bba?id=${id}`,
            method: 'PUT',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags: ['getPersonalExpense'],
        transformResponse: (response) => JSON.parse(response)
    }),
    getPersonalExpense: builder.query({
        query: ({ id }) => ({
            url: `/company/get-personal-expenses-bba?id=${id}`,
            method: 'GET',
        }),
        providesTags: ['getPersonalExpense'],
        transformResponse: (response) => JSON.parse(response)?.data
    }),
    addCharitySaving: builder.mutation({
        query: ({ ClientId, payload }) => ({
            url: `/company/add-charity-savings-expenses?ClientId=${ClientId}`,
            method: 'POST',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags: ['getCharitySaving'],
        transformResponse: (response) => JSON.parse(response)
    }),
    updateCharitySaving: builder.mutation({
        query: ({ id, payload }) => ({
            url: `/company/update-charity-savings-expenses?id=${id}`,
            method: 'PUT',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags: ['getCharitySaving'],
        transformResponse: (response) => JSON.parse(response)
    }),
    getCharitySaving: builder.query({
        query: ({ id }) => ({
            url: `/company/get-charity-savings-expenses?id=${id}`,
            method: 'GET',
        }),
        providesTags: ['getCharitySaving'],
        transformResponse: (response) => JSON.parse(response)?.data
    }),
    addPetExpense: builder.mutation({
        query: ({ ClientId, payload }) => ({
            url: `/company/add-pet-expenses?ClientId=${ClientId}`,
            method: 'POST',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags: ['getPetExpense'],
        transformResponse: (response) => JSON.parse(response)
    }),
    updatePetExpense: builder.mutation({
        query: ({ id, payload }) => ({
            url: `/company/update-pet-expenses?id=${id}`,
            method: 'PUT',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags: ['getPetExpense'],
        transformResponse: (response) => JSON.parse(response)
    }),
    getPetExpense: builder.query({
        query: ({ id }) => ({
            url: `/company/get-pet-expenses?id=${id}`,
            method: 'GET',
        }),
        providesTags: ['getPetExpense'],
        transformResponse: (response) => JSON.parse(response)?.data
    }),
    addDebtExpense: builder.mutation({
        query: ({ ClientId, payload }) => ({
            url: `/company/add-debt-bba?ClientId=${ClientId}`,
            method: 'POST',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags: ['getDebtExpense'],
        transformResponse: (response) => JSON.parse(response)
    }),
    updateDebtExpense: builder.mutation({
        query: ({ id, payload }) => ({
            url: `/company/update-debt-bba?id=${id}`,
            method: 'PUT',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags: ['getDebtExpense'],
        transformResponse: (response) => JSON.parse(response)
    }),
    getDebtExpense: builder.query({
        query: ({ id }) => ({
            url: `/company/get-debt-bba?id=${id}`,
            method: 'GET',
        }),
        providesTags: ['getDebtExpense'],
        transformResponse: (response) => JSON.parse(response)?.data
    }),
    addChildExpense: builder.mutation({
        query: ({ ClientId, payload }) => ({
            url: `/company/add-child-expenses-bba?ClientId=${ClientId}`,
            method: 'POST',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags: ['getChildExpense'],
        transformResponse: (response) => JSON.parse(response)
    }),
    updateChildExpense: builder.mutation({
        query: ({ id, payload }) => ({
            url: `/company/update-child-expenses-bba?id=${id}`,
            method: 'PUT',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags: ['getChildExpense'],
        transformResponse: (response) => JSON.parse(response)
    }),
    getChildExpense: builder.query({
        query: ({ id }) => ({
            url: `/company/get-child-expenses-bba?id=${id}`,
            method: 'GET',
        }),
        providesTags: ['getChildExpense'],
        transformResponse: (response) => JSON.parse(response)?.data
    }),
  }),
})

export const { useAddMonetaryMutation, useUpdateMonetaryMutation, useGetMonetaryQuery,
    useAddNonMonetaryMutation, useUpdateNonMonetaryMutation, useGetNonMonetaryQuery, 
    useAddHouseholdExpenseMutation, useUpdateHouseholdExpenseMutation, useGetHouseholdExpenseQuery,
    useAddTransportationExpenseMutation, useUpdateTransportationExpenseMutation, useGetTransportationExpenseQuery, 
    useAddMedicalHealthExpenseMutation, useUpdateMedicalHealthExpenseMutation, useGetMedicalHealthExpenseQuery, 
    useAddPersonalExpenseMutation, useUpdatePersonalExpenseMutation, useGetPersonalExpenseQuery,
    useAddCharitySavingMutation, useUpdateCharitySavingMutation, useGetCharitySavingQuery ,
    useAddPetExpenseMutation, useUpdatePetExpenseMutation, useGetPetExpenseQuery,
    useAddDebtExpenseMutation, useUpdateDebtExpenseMutation, useGetDebtExpenseQuery,
    useAddChildExpenseMutation, useUpdateChildExpenseMutation, useGetChildExpenseQuery  } = budgetPlannerApi;
