import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const mentalHealthSysmptomsApi = createApi({
    reducerPath: 'mentalHealthSysmptomsApi',
    refetchOnMountOrArgChange: true,
    keepUnusedDataFor: 0,
    refetchOnReconnect: true,
    baseQuery: AxiosBaseQuery({
        baseUrl: `${developmentApi}/api`,
    }),
    tagTypes: ['getHallucinations', 'getSuicidal', 'getMemory'],
    endpoints: (builder) => ({
        addHallucinations: builder.mutation({
            query: ({ payload }) => ({
                url: `/company/add-Hallucinations`,
                method: 'POST',
                payload: payload,
            }),
            invalidatesTags: ['getHallucinations'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateHallucinations: builder.mutation({
            query: ({ payload }) => ({
                url: `/company/update-Hallucinations`,
                method: 'PUT',
                payload: payload,
            }),
            invalidatesTags: ['getHallucinations'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getHallucinations: builder.query({
            query: (clientId) => ({
                url: `/company/get-Hallucinations/${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getHallucinations'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addSuicidal: builder.mutation({
            query: ({ payload }) => ({
                url: `/company/add-Suicidal-Homicidal`,
                method: 'POST',
                payload: payload,
            }),
            invalidatesTags: ['getSuicidal'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateSuicidal: builder.mutation({
            query: ({ payload }) => ({
                url: `/company/update-Suicidal-Homicidal`,
                method: 'PUT',
                payload: payload,
            }),
            invalidatesTags: ['getSuicidal'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getSuicidal: builder.query({
            query: (clientId) => ({
                url: `/company/get-Suicidal-Homicidal/${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getSuicidal'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addMemory: builder.mutation({
            query: ({ payload }) => ({
                url: `/company/add-Memory-Review`,
                method: 'POST',
                payload: payload,
            }),
            invalidatesTags: ['getMemory'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateMemory: builder.mutation({
            query: ({ payload }) => ({
                url: `/company/update-Memory-Review`,
                method: 'PUT',
                payload: payload,
            }),
            invalidatesTags: ['getMemory'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getMemory: builder.query({
            query: (clientId) => ({
                url: `/company/get-Memory-Review/${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getMemory'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
    }),
})

export const {useAddHallucinationsMutation, useUpdateHallucinationsMutation, useGetHallucinationsQuery, 
    useAddSuicidalMutation, useUpdateSuicidalMutation, useGetSuicidalQuery,
    useAddMemoryMutation, useUpdateMemoryMutation, useGetMemoryQuery} = mentalHealthSysmptomsApi;
