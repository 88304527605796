import { createTheme } from "@mui/material";
import palette from "./palette";
/* This code defines a typography object that contains various styles for different text elements such
as headings, labels, and body text. The styles include font size, font weight, line height, color,
and other properties. The `createTheme()` function is used to define breakpoints for responsive
design, which are used in some of the styles for the headings. The `palette` object is imported to
provide the color values used in the styles. The typography object can be used in a Material UI
theme to apply these styles to text elements throughout a React application. */
const theme = createTheme();
const typography = {
    h1: {
        fontSize: '2rem',
        fontWeight: 600,
        lineHeight: 1.3,
        color: palette.text.main,
        [theme.breakpoints.down("lg")]: {
            fontSize: "1.8rem",
        },
        [theme.breakpoints.down("md")]: {
            fontSize: "1.6rem",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "1.4rem",
        }
    },
    h2: {
        fontSize: '2rem',
        fontWeight: 600,
        lineHeight: 1.3,
        color: palette.text.main,
        '@media (max-width:800px)': {
            fontSize: '1.4rem',
        },
    },
    h3: {
        fontSize: '2rem',
        fontWeight: 600,
        lineHeight: 1.3,
        fontFamily: 'Inter',
        color: palette.secondary.main
    },
    h4: {
        fontSize: '1.1rem',
        fontWeight: 700,
        lineHeight: 1.3,
        color: palette.secondary.main
    },
    h5: {
        fontSize: '1.3rem',
        fontWeight: 400,
        lineHeight: 1.3,
        color: palette.text.light
    },
    formLabel: {
        fontSize: '0.83em',
        fontWeight: 500,
        lineHeight: 1.3,
        color: palette.text.main,
        marginBottom: "5px",
    },
    formLabelSmall: {
        fontSize: '0.83em',
        fontWeight: 700,
        lineHeight: 1.3,
        color: palette.text.main,
        marginBottom: "5px",
    },
    body2: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 400,
        color: palette.text.light
    },
    body1: {
        lineHeight: 1.3,
        fontSize: '1rem',
        fontWeight: 400,
        color: palette.text.main
    },
    subHeading: {
        fontSize: '1.35rem',
        fontWeight: 600,
        lineHeight: 1.3,
        color: palette.text.main,
        '@media (max-width:800px)': {
            fontSize: '1.1rem',
        },
    },
    reportSubHeading: {
        fontSize: '30px',
        fontWeight: 700,
        lineHeight: 1.5,
        color: palette.text.light,
        '@media (max-width:800px)': {
            fontSize: '1.5rem',
        },
    },
    errorMessage: {
        fontFamily: 'Inter',
        fontSize: '0.6rem',
        fontWeight: 400,
        color: palette.error.main,
        margin: 0,
        padding: 0
    },
    subtitle2: {
        fontSize: '1rem',
        fontWeight: 500,
        color: palette.secondary.main
    },
    subtitle1: {
        fontSize: '1rem',
        fontWeight: 500,
        color: palette.text.light
    },
    active:{
        fontSize:'1rem',
        fontWeight:500,
        color: palette.active.main
    },
    fontWeight: {
        bold: {
            fontWeight: 700
        },
        semiBold: {
            fontWeight: 600
        },
        medium: {
            fontWeight: 500
        },
        regular: {
            fontWeight: 400
        },
        light: {
            fontWeight: 300
        },
    },
    fontFamily: [
        "Inter",
        'sans-serif'
    ].join(','),
}
export default typography;
