import { Grid } from "@mui/material";
import React from 'react';
import BannerImage from "../../assets/Images/BannerImage.jpg";

export default function LandingPage() {
    return (
        <Grid>
            <Grid>
                <img src={BannerImage} alt="BannerImage" width={"100%"} height={"250px"} />
            </Grid>
            <Grid>
                xs=4
            </Grid>
        </Grid>
    )
}