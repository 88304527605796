import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const careerReportApi = createApi({
  reducerPath: 'careerReportApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: `${developmentApi}/api`,
  }),
  endpoints: (builder) => ({
    getCareerSummaryReport: builder.query({
      query: ({clientId})=>({
        url:`/company/career-summery-report?clientId=${clientId}`,
        method:'GET',
      }),
      transformResponse:(response)=> JSON.parse(response)?.data
    }),
    getCareerDetailReport: builder.query({
      query: ({clientId})=>({
        url:`/company/career-detail-report?clientId=${clientId}`,
        method:'GET',
      }),
      transformResponse:(response)=> JSON.parse(response)?.data
    }),
  }),
})

export const {useGetCareerSummaryReportQuery, useGetCareerDetailReportQuery}=careerReportApi;
