import React, { lazy } from "react";
import CasenotesExport from "../pages/CaseNotes/CasenotesExport";
import CaseNoteView from "../pages/CaseNotes/CaseNoteView";
import EditCaseNotes from "../pages/CaseNotes/EditCaseNotes";
import ClientOtherGoals from "../pages/Client/components/ClientOtherGoals";
import ConsultantDashboard from "../pages/ConsultantDashboard";
import ConsultantSupervisorDashboard from "../pages/ConsultantSupervisorDashboard";
import InternDashboard from "../pages/InternDashboard";
const ManageDepartment = lazy(() => import('../pages/ManageDepartment'));
const Profile = lazy(() => import('../pages/Profile'));
const SideBar = lazy(() => import("../components/SideBar"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const Client = lazy(() => import("../pages/Client"));
const ManageStaffAccounts = lazy(() => import("../pages/ManageStaffAccount"));
const Subscription = lazy(() => import("../pages/Profile/components/Subscription"));
const Configuration = lazy(() => import("../pages/Profile/components/Configuration"));
const ChangePassword = lazy(() => import("../pages/Profile/components/ChangePassword/Index"));
const AddDepartment = lazy(() => import("../pages/ManageDepartment/components/AddDepartment"));
const AddStaff = lazy(() => import("../pages/ManageStaffAccount/components/AddStaff"));
const AddClient = lazy(() => import("../pages/Client/components/AddClient"));
const SupportingDocument = lazy(() => import("../pages/Client/components/SupportingDocument"));
const SupportTeam = lazy(() => import("../pages/Client/components/SupportTeam"));
const EditDepartment = lazy(() => import("../pages/ManageDepartment/components/EditDepartment"));
const EditStaff = lazy(() => import("../pages/ManageStaffAccount/components/EditStaff"));
const ClientDetails = lazy(() => import("../pages/Client/components/ClientDetails"));
const EditClient = lazy(() => import("../pages/Client/components/EditClient"));
const DemographicElement = lazy(() => import("../pages/Client/components/ClientMCA/Components/DemographicElement"));
const MCASummaryReport = lazy(() => import("../pages/Client/components/ClientMCA/Components/MCASummaryReport"));
const MCADetailedReport = lazy(() => import("../pages/Client/components/ClientMCA/Components/MCADetailedReport"));
const CaseNotes = lazy(() => import("../pages/CaseNotes"));
const AddCaseNotes = lazy(() => import("../pages/CaseNotes/AddCaseNotes"));
const HomeAssessment = lazy(() => import("../pages/Client/components/ClientMCA/Components/HomeAssessment"));
const MentalHealthAssessment = lazy(() => import("../pages/Client/components/ClientMCA/Components/MentalHealthAssessment"));
const CareerAssessment = lazy(() => import("../pages/Client/components/ClientMCA/Components/CareerAssessment"));
const BenefitsAssessment = lazy(() => import("../pages/Client/components/ClientMCA/Components/BenefitsAssessment"));
const CareerVoucherReport = lazy(() => import("../pages/Client/components/ClientMCA/Components/CareerVoucherReport"));
const CareerAssessmentReport = lazy(() => import("../pages/Client/components/ClientMCA/Components/CareerAssessmentReport"));
const HomeAssessmentVoucher = lazy(() => import("../pages/Client/components/ClientMCA/Components/HomeAssessmentVoucher"));
const HomeAssessmentReport = lazy(() => import("../pages/Client/components/ClientMCA/Components/HomeAssessmentReport"));
const ClientDashboard = lazy(() => import("../pages/ClientDashboard"));
const BenefitsAssessmentVoucher = lazy(() => import("../pages/Client/components/ClientMCA/Components/BenefitsAssessmentVoucher"));
const BenefitsAssessmentDetailReport = lazy(() => import("../pages/Client/components/ClientMCA/Components/BenefitsAssessmentDetailReport"));
const ManagerDashboard = lazy(() => import("../pages/ManagerDashboard"));
const SupervisorDashboard = lazy(() => import("../pages/SupervisorDashboard"));
const AccountantDashboard = lazy(() => import("../pages/AccountantDashboard"));
const StaffDashboard = lazy(() => import("../pages/StaffDashboard"));
const ServiceStaffDashboard = lazy(() => import("../pages/ServiceStaffDashboard"));
const OfficeStaffDashboard = lazy(() => import("../pages/OfficeStaffDashboard"));
const ProfessionalWriterDashboard = lazy(() => import("../pages/ProfessionalWriterDashboard"));
const ReleaseNotes = lazy(() => import("../pages/ReleaseNotes"));
const ClientMCA = lazy(() => import("../pages/Client/components/ClientMCA"));
const ComprehensiveReportIntake = lazy(() => import("../pages/Client/components/ClientMCA/Components/ComprehensiveReportIntake"));
const MentalHealthVoucherReport = lazy(() => import("../pages/Client/components/ClientMCA/Components/MentalHealthVoucherReport"));
const MentalHealthDetailedReport = lazy(() => import("../pages/Client/components/ClientMCA/Components/MentalHealthDetailedReport"));
const MasterComprehensiveReport = lazy(() => import("../pages/Client/components/ClientMCA/Components/MasterComprehensiveReport"));
const Page404 = lazy(() => import("../pages/404Page"));
/**
 * The function defines the routing for different pages in a web application using React Router.
 * @returns A component called `Routing` is being returned. It contains a set of `Route` components
 * that define the different paths and corresponding components to be rendered when those paths are
 * accessed. The paths are divided into two categories: public routes and private routes. Public routes
 * are accessible to anyone, while private routes require authentication. The private routes are
 * wrapped in a `` component that checks if the user
 */

const authRoutes = [
  {
    path: "/client-dashboard",
    element: (<><SideBar><ClientDashboard /></SideBar></>),
    role: ["client"],
  },
  {
    path: "/release-notes",
    element: (<><SideBar><ReleaseNotes /></SideBar></>),
    role: ["client"],
  },
  {
    path: "/supervisor-dashboard",
    element: (<><SideBar><SupervisorDashboard /></SideBar></>),
    role: ["supervisor"],
  },
  {
    path: "/intern-dashboard",
    element: (<><SideBar><InternDashboard /></SideBar></>),
    role: ["intern"],
  },
  {
    path: "/consultant-dashboard",
    element: (<><SideBar><ConsultantDashboard /></SideBar></>),
    role: ["consultant"],
  },
  {
    path: "/consultant-supervisor-dashboard",
    element: (<><SideBar><ConsultantSupervisorDashboard /></SideBar></>),
    role: ["consultant supervisor"],
  },
  {
    path: "/accountant-dashboard",
    element: (<><SideBar><AccountantDashboard /></SideBar></>),
    role: ["accountant"],
  },
  {
    path: "/staff-dashboard",
    element: (<><SideBar><StaffDashboard /></SideBar></>),
    role: ["staff"],
  },
  {
    path: "/service-staff-dashboard",
    element: (<><SideBar><ServiceStaffDashboard /></SideBar></>),
    role: ["service staff"],
  },
  {
    path: "/office-staff-dashboard",
    element: (<><SideBar><OfficeStaffDashboard /></SideBar></>),
    role: ["office staff"],
  },
  {
    path: "/professional-writer-dashboard",
    element: (<><SideBar><ProfessionalWriterDashboard /></SideBar></>),
    role: ["professional writer"],
  },
  {
    path: "/manager-dashboard",
    element: (<><SideBar><ManagerDashboard /></SideBar></>),
    role: ["manager"],
  },
  {
    path: "/managedepartment",
    element: (
      <>
        <SideBar>
          <ManageDepartment />
        </SideBar>
      </>
    ),
    role: ["company"],
  },
  {
    path: "/managedepartment/adddepartment",
    element: (
      <>
        <SideBar>
          <AddDepartment />
        </SideBar>
      </>
    ),
    role: ["company"],
  },
  {
    path: "/managedepartment/editdepartment/:id",
    element: (
      <>
        <SideBar>
          <EditDepartment />
        </SideBar>
      </>
    ),
    role: ["company"],
  },
  {
    path: "/profile",
    element: (
      <SideBar>
        <Profile />
      </SideBar>
    ),
    role: ["company", "supervisor", "consultant", 'intern', "consultant supervisor", "office staff", "manager", "service staff", "accountant", "professional writer", "client"],
  },
  {
    path: "/client/clientdetails/mca/:id",
    element: (
      <SideBar>
        <ClientMCA />
      </SideBar>
    ),
    role: ["company", "supervisor", "consultant", 'intern', "consultant supervisor", "office staff", "manager", "service staff", "accountant", "professional writer", "client"],
  },
  {
    path: "/client/clientdetails/othergoals/:id",
    element: (
      <SideBar>
        <ClientOtherGoals />
      </SideBar>
    ),
    role: ["company", "supervisor", "consultant", 'intern', "consultant supervisor", "office staff", "manager", "service staff", "accountant", "professional writer", "client"],
  },
  {
    path: "/changepassword",
    element: (
      <>
        <SideBar>
          <ChangePassword />
        </SideBar>
      </>
    ),
    role: ["company", "supervisor", "consultant", 'intern', "consultant supervisor", "office staff", "manager", "service staff", "accountant", "professional writer", "client"],
  },
  {
    path: "/subscription",
    element: (
      <>
        <SideBar>
          <Subscription />
        </SideBar>
      </>
    ),
    role: ["company", "supervisor", "consultant", 'intern', "consultant supervisor", "office staff", "manager", "service staff", "accountant", "professional writer", "client"],
  },
  {
    path: "/configuration",
    element: (
      <>
        <SideBar>
          <Configuration />
        </SideBar>
      </>
    ),
    role: ["company", "supervisor", "consultant", 'intern', "consultant supervisor", "office staff", "manager", "service staff", "accountant", "professional writer", "client"],
  },
  {
    path: "/dashboard",
    element: (
      <>
        <SideBar>
          <Dashboard />
        </SideBar>
      </>
    ),
    role: ["company"],
  },
  {
    path: "/client",
    element: (
      <>
        <SideBar>
          <Client />
        </SideBar>
      </>
    ),
    role: ["company", "supervisor", "consultant", 'intern', "consultant supervisor", "office staff", "manager", "service staff", "accountant", "professional writer"],
  },
  {
    path: "/managestaffaccounts",
    element: (
      <>
        <SideBar>
          <ManageStaffAccounts />
        </SideBar>
      </>
    ),
    role: ["company", "supervisor", "consultant", 'intern', "consultant supervisor", "manager"],
  },
  {
    path: "/managestaffaccounts/addstaff",
    element: (
      <>
        <SideBar>
          <AddStaff />
        </SideBar>
      </>
    ),
    role: ["company", "supervisor", "consultant", 'intern', "consultant supervisor", "manager"],
  },
  {
    path: "/managestaffaccounts/Editstaff/:id",
    element: (
      <>
        <SideBar>
          <EditStaff />
        </SideBar>
      </>
    ),
    role: ["company", "supervisor", "consultant", 'intern', "consultant supervisor", "manager"],
  },
  {
    path: "/client/addclient",
    element: (
      <>
        <SideBar>
          <AddClient />
        </SideBar>
      </>
    ),
    role: ["company", "supervisor", "consultant", 'intern', "consultant supervisor", "office staff", "manager", "service staff", 'professional writer'],
  },
  {
    path: "/client/editclient/:id",
    element: (
      <>
        <SideBar>
          <EditClient />
        </SideBar>
      </>
    ),
    role: ["company", "supervisor", "consultant", 'intern', "consultant supervisor", "office staff", "manager", "service staff", 'professional writer'],
  },
  {
    path: "/client/clientdetails",
    element: (
      <>
        <SideBar>
          <ClientDetails />
        </SideBar>
      </>
    ),
    role: ["company", "supervisor", "consultant", 'intern', "consultant supervisor", "office staff", "manager", "service staff", 'professional writer'],
  },
  {
    path: "/client/clientdetails/supportingdocuments",
    element: (
      <>
        <SideBar>
          <SupportingDocument />
        </SideBar>
      </>
    ),
    role: ["company"],
  },
  {
    path: "/client/clientdetails/supportteam",
    element: (
      <>
        <SideBar>
          <SupportTeam />
        </SideBar>
      </>
    ),
    role: ["company"],
  },
  {
    path: "/client/clientdetails/mcademographic",
    element: (
      <>
        <SideBar>
          <DemographicElement />
        </SideBar>
      </>
    ),
    role: ["company", "supervisor", "consultant", 'intern', "consultant supervisor", "office staff", "manager", "service staff", 'professional writer'],
  },
  {
    path: "/client/clientdetails/homeassessment",
    element: (
      <>
        <SideBar>
          <HomeAssessment />
        </SideBar>
      </>
    ),
    role: ["company", "supervisor", "consultant", 'intern', "consultant supervisor", "office staff", "manager", "service staff", 'professional writer'],
  },
  {
    path: "/client/clientdetails/mentalhealthassessment",
    element: (
      <>
        <SideBar>
          <MentalHealthAssessment />
        </SideBar>
      </>
    ),
    role: ["company", "supervisor", "consultant", 'intern', "consultant supervisor", "office staff", "manager", "service staff", 'professional writer'],
  },
  {
    path: "/client/clientdetails/careerassessment",
    element: (
      <>
        <SideBar>
          <CareerAssessment />
        </SideBar>
      </>
    ),
    role: ["company", "supervisor", "consultant", 'intern', "consultant supervisor", "office staff", "manager", "service staff", 'professional writer'],
  },
  {
    path: "/client/clientdetails/benefitsassessment",
    element: (
      <>
        <SideBar>
          <BenefitsAssessment />
        </SideBar>
      </>
    ),
    role: ["company", "supervisor", "consultant", 'intern', "consultant supervisor", "office staff", "manager", "service staff", 'professional writer'],
  },
  {
    path: "/client/clientdetails/comprehensivereport",
    element: (
      <>
        <SideBar>
          <ComprehensiveReportIntake />
        </SideBar>
      </>
    ),
    role: ["company", "supervisor", "consultant", 'intern', "consultant supervisor", "office staff", "manager", "service staff", 'professional writer'],
  },
  {
    path: "/client/clientdetails/mcasummaryreport/:id",
    element: (
      <>
        <MCASummaryReport />
      </>
    ),
    role: ["company", "supervisor", "consultant", 'intern', "consultant supervisor", "office staff", "manager", "service staff", 'professional writer'],
  },
  {
    path: "/client/clientdetails/mcadetailedreport/:id",
    element: (
      <>
        <MCADetailedReport />
      </>
    ),
    role: ["company", "supervisor", "consultant", 'intern', "consultant supervisor", "office staff", "manager", "service staff", 'professional writer'],
  },
  {
    path: "/client/clientdetails/careervoucherreport/:id",
    element: (
      <>
        <CareerVoucherReport />
      </>
    ),
    role: ["company", "supervisor", "consultant", 'intern', "consultant supervisor", "office staff", "manager", "service staff", 'professional writer'],
  },
  {
    path: "/client/clientdetails/careerassessmentreport/:id",
    element: (
      <>
        <CareerAssessmentReport />
      </>
    ),
    role: ["company", "supervisor", "consultant", 'intern', "consultant supervisor", "office staff", "manager", "service staff", 'professional writer'],
  },
  {
    path: "/client/clientdetails/homeassessmentvoucher/:id",
    element: (
      <>
        <HomeAssessmentVoucher />
      </>
    ),
    role: ["company", "supervisor", "consultant", 'intern', "consultant supervisor", "office staff", "manager", "service staff", 'professional writer'],
  },
  {
    path: "/client/clientdetails/homeassessmentreport/:id",
    element: (
      <>
        <HomeAssessmentReport />
      </>
    ),
    role: ["company", "supervisor", "consultant", 'intern', "consultant supervisor", "office staff", "manager", "service staff", 'professional writer'],
  },
  {
    path: "/casenotes",
    element: (
      <>
        <SideBar>
          <CaseNotes />
        </SideBar>
      </>
    ),
    role: ["company", "supervisor", "consultant", 'intern', "consultant supervisor", "office staff", "manager", "service staff", 'professional writer', 'accountant'],
  },
  {
    path: "/casenotes/addcasenotes",
    element: <SideBar><AddCaseNotes /></SideBar>,
    role: ["company", "supervisor", "consultant", 'intern', "consultant supervisor", "office staff", "manager", "service staff", 'professional writer', 'accountant'],
  },
  {
    path: "/casenotes/editcasenotes/:id",
    element: <SideBar><EditCaseNotes /></SideBar>,
    role: ["company", "supervisor", "consultant", 'intern', "consultant supervisor", "office staff", "manager", "service staff", 'professional writer', 'accountant'],
  },
  {
    path: "/casenotes/casenotesdetail/:id",
    element:
      <CaseNoteView />
    ,
    role: ["company", "supervisor", "consultant", 'intern', "consultant supervisor", "office staff", "manager", "service staff", 'professional writer', 'accountant'],
  },
  {
    path: "/casenotes/casenotesexport",
    element:
      <CasenotesExport />
    ,
    role: ["company", "supervisor", "consultant", 'intern', "consultant supervisor", "office staff", "manager", "service staff", 'professional writer', 'accountant'],
  },
  {
    path: "/client/clientdetails/benefitbudgetdetailedreport/:id",
    element: <BenefitsAssessmentDetailReport />,
    role: ["company", "supervisor", "office staff", "manager", "service staff", 'professional writer'],
  },
  {
    path: "/client/clientdetails/benefitbudgetvoucher/:id",
    element: <BenefitsAssessmentVoucher />,
    role: ["company", "supervisor", "office staff", "manager", "service staff", 'professional writer'],
  },
  {
    path: "/client/clientdetails/homeassessmentreport/:id",
    element: <HomeAssessmentReport />,
    role: ["company", "supervisor", "office staff", "manager", "service staff", 'professional writer'],
  },
  {
    path: "/client/clientdetails/homeassessmentvoucher/:id",
    element: <HomeAssessmentVoucher />,
    role: ["company", "supervisor", "office staff", "manager", "service staff", 'professional writer'],
  },

  {
    path: "/client/clientdetails/mentalhealthvoucher/:id",
    element: <MentalHealthVoucherReport />,
    role: ["company", "supervisor", "consultant", 'intern', "consultant supervisor", "office staff", "manager", "service staff", 'professional writer'],
  },
  {
    path: "/client/clientdetails/mentalhealthdetailedreport/:id",
    element: <MentalHealthDetailedReport />,
    role: ["company", "supervisor", "consultant", 'intern', "consultant supervisor", "office staff", "manager", "service staff", 'professional writer'],
  },

  {
    path: "/client/clientdetails/careerassessmentreport/:id",
    element: <CareerAssessmentReport />,
    role: ["company", "supervisor", "office staff", "manager", "service staff", 'professional writer'],
  },
  {
    path: "/client/clientdetails/careervoucherreport/:id",
    element: <CareerVoucherReport />,
    role: ["company", "supervisor", "office staff", "manager", "service staff", 'professional writer'],
  },
  {
    path: "/client/clientdetails/mcadetailedreport/:id",
    element: <MCADetailedReport />,
    role: ["company", "supervisor", "office staff", "manager", "service staff", 'professional writer'],
  },
  {
    path: "/client/clientdetails/mcasummaryreport/:id",
    element: <MCASummaryReport />,
    role: ["company", "supervisor", "office staff", "manager", "service staff", 'professional writer'],
  },

  {
    path: "/client/clientdetails/mastercomprehensivereport/:id",
    element: <MasterComprehensiveReport />,
    role: ["company", "supervisor", "office staff", "manager", "service staff", 'professional writer'],
  },

  {
    path: "*",
    element: <Page404 />,
    role: ["company", "supervisor", "consultant", 'intern', "consultant supervisor", "office staff", "manager", "service staff", 'professional writer'],
  },

];

export { authRoutes };
