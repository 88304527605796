import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

/* This code is creating an API using the `createApi` function from the `@reduxjs/toolkit/query/react`
library. The API is named `authApi` and has several endpoints defined using the `builder` object
passed to the `endpoints` function. Each endpoint is defined as a mutation or query with a specific
`query` object that contains the URL, method, and payload or parameters for the request. The
`transformResponse` function is also defined for each endpoint to parse the response data. The
`baseQuery` object is also defined using the `AxiosBaseQuery` function and includes the base URL for
the API. This API can be used in a React application to make HTTP requests to the server. */
export const authApi = createApi({
  reducerPath: 'authApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: `${developmentApi}/api`,
  }),
  endpoints: (builder) => ({
    register: builder.mutation({
      query: ({ payload }) => ({
        url: `/Register`,
        method: 'POST',
        payload: payload,
        // responseType: 'blob',
        fileUpload: true
      }),
      transformResponse: (response) => JSON.parse(response)
    }),
    login: builder.mutation({
      query: ({ payload }) => ({
        url: "/Login",
        method: "POST",
        payload: payload,
      }),
      transformResponse: (response) => JSON.parse(response)
    }),
    otpVerify: builder.mutation({
      query: ({payload}) => ({
        url: `/login-2FA`,
        method: "POST",
        payload:payload,
        twofaLogin:true
      }),
      transformResponse:(response)=> JSON.parse(response)
    }),
    resendConfirmEmail: builder.query({
      query: (params) => ({
        url: `/resend-confirm-email?email=${params}`,
        method: "GET",
      }),
      transformResponse:(response)=> JSON.parse(response)
    }),
    resendOtp: builder.query({
      query: (params) => ({
        url: `/resend-token?email=${params}`,
        method: "GET"
      }),
      transformResponse: (response)=> JSON.parse(response)
    }),
    forgotPassword: builder.mutation({
      query: (params) => ({
        url: "/forget-password",
        method: "POST",
        params: params,
      }),
      transformResponse:(response)=> JSON.parse(response)
    }),
    confirmEmail: builder.query({
      query: ({token,email})=>({
        url:`/confirmemail/?token=${token}&email=${email}`,
        method:'GET',
        // params:params
      }),
      transformResponse:(response)=> JSON.parse(response)
    }),
    resetPassword: builder.mutation({
      query: ({payload})=>({
        url: `/reset-password`,
        method:'POST',
        payload: payload
      }),
      transformResponse:(response)=> JSON.parse(response)
    }),
    logoutUser: builder.mutation({
      query: ()=>({
        url: `/logout`,
        method:'POST',
      }),
      transformResponse:(response)=> JSON.parse(response)
    })
  }),
})

/* This line is exporting several hooks that can be used in a React component to interact with the API
endpoints defined in the `authApi` object. Each hook corresponds to a specific endpoint and can be
used to make a request to that endpoint and retrieve the response data. The naming convention for
the hooks is `use{EndpointName}{Query/Mutation}`, where `EndpointName` is the name of the endpoint
and `Query/Mutation` indicates whether the endpoint is a query or a mutation. These hooks are
generated automatically by the `createApi` function and can be used to simplify the process of
making API requests in a React application. */
export const { useRegisterMutation, useLoginMutation, useOtpVerifyMutation, useLazyResendConfirmEmailQuery, 
  useLazyResendOtpQuery, useForgotPasswordMutation, useConfirmEmailQuery, useResetPasswordMutation, useLogoutUserMutation} = authApi;