import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const mentalHealthTraumaApi = createApi({
    reducerPath: 'mentalHealthTraumaApi',
    refetchOnMountOrArgChange: true,
    keepUnusedDataFor: 0,
    refetchOnReconnect: true,
    baseQuery: AxiosBaseQuery({
        baseUrl: `${developmentApi}/api`,
    }),
    tagTypes: ['getNeglect', 'getEmotionalAbuse', 'getPhysicalAbuse', 'getSexualAbuse', 'getOtherTrauma'],
    endpoints: (builder) => ({
        addNeglect: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-neglect?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getNeglect'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateNeglect: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-neglect?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getNeglect'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getNeglect: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-neglect?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getNeglect'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addEmotionalAbuse: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-emotional-abuse?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getEmotionalAbuse'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateEmotionalAbuse: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-emotional-abuse?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getEmotionalAbuse'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getEmotionalAbuse: builder.query({
            query: ({ id }) => ({
                url: `/company/get-emotional-abuse?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['getEmotionalAbuse'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addPhysicalAbuse: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-physical-abuse?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getPhysicalAbuse'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updatePhysicalAbuse: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-physical-abuse?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getPhysicalAbuse'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getPhysicalAbuse: builder.query({
            query: ({ id }) => ({
                url: `/company/get-physical-abuse?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['getPhysicalAbuse'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addSexualAbuse: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-sexual-abuse?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getSexualAbuse'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateSexualAbuse: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-sexual-abuse?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getSexualAbuse'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getSexualAbuse: builder.query({
            query: ({ id }) => ({
                url: `/company/get-sexual-abuse?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['getSexualAbuse'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addOtherTrauma: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-other-trauma?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getOtherTrauma'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateOtherTrauma: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-other-trauma?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getOtherTrauma'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getOtherTrauma: builder.query({
            query: ({ id }) => ({
                url: `/company/get-other-trauma?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['getOtherTrauma'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
    }),
})

export const { useAddNeglectMutation, useUpdateNeglectMutation, useGetNeglectQuery,
    useAddEmotionalAbuseMutation, useUpdateEmotionalAbuseMutation, useGetEmotionalAbuseQuery, 
    useAddPhysicalAbuseMutation, useUpdatePhysicalAbuseMutation, useGetPhysicalAbuseQuery, 
    useAddSexualAbuseMutation, useUpdateSexualAbuseMutation, useGetSexualAbuseQuery, 
    useAddOtherTraumaMutation, useUpdateOtherTraumaMutation, useGetOtherTraumaQuery } = mentalHealthTraumaApi;
