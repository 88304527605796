import { Button, Grid, Typography, CircularProgress, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import OtpInput from 'react-otp-input';
import { useLocation, useNavigate } from "react-router-dom";
import palette from "../../../../../theme/palette";
import timer from "../../../../../assets/Images/timer.svg"
import { useOtpVerifyMutation, useLazyResendOtpQuery } from "../../../../../store/services/authServices";
import { toast } from "react-toastify";
import { maskedEmail, parseJwt, setUserId } from "../../../../../utils/util";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import getPermission from "../../../../../store/permissions/permissionAction";

export default function OtpScreen() {
  const dispatch = useDispatch();
  const { permissionData } = useSelector((state) => state?.getPermission);
  const userPermission = Array.isArray(permissionData) ? permissionData.map(p => p.toLowerCase()) : permissionData?.length > 0 ? permissionData?.toLowerCase() : ''
  const location = useLocation();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [timeRemaining, setTimeRemaining] = useState(900);
  const userEmail = location?.state?.data?.email
  /* `const [userOtpVerify, {isLoading}] = useOtpVerifyMutation({});` is using the
  `useOtpVerifyMutation` hook from the `authServices` store service to create a `userOtpVerify`
  function and a `isLoading` boolean variable. The `userOtpVerify` function is used to send a
  request to verify the OTP entered by the user, and the `isLoading` variable is used to track
  whether the request is currently being processed. The empty object passed as an argument to
  `useOtpVerifyMutation` is used to configure the behavior of the mutation. */
  const [userOtpVerify, { isLoading, isSuccess }] = useOtpVerifyMutation({});
  /* `const [userResendOtp, {isLoading: isloading}]=useLazyResendOtpQuery();` is using the
  `useLazyResendOtpQuery` hook from the `authServices` store service to create a `userResendOtp`
  function and a `isLoading` boolean variable. The `userResendOtp` function is used to send a
  request to resend the OTP to the user's email, and the `isLoading` variable is used to track
  whether the request is currently being processed. The `isloading` variable is used to rename the
  `isLoading` variable to `isloading` for convenience. The `useLazyResendOtpQuery` hook is used to
  create a lazy query that will only be executed when the `userResendOtp` function is called. */
  const [userResendOtp, { isLoading: isloading }] = useLazyResendOtpQuery();
  /* `const inputStyle` is an object that defines the styling properties for the OTP input field. It
  sets the width, height, margin, font size, border radius, and border color of the input field. The
  `palette.primary.dark` value is used to set the color of the border. This object is used to style
  the `OtpInput` component in the `return` statement of the `OtpScreen` component. */
  const inputStyle = {
    width: '3.5rem',
    height: '3rem',
    margin: '0.5rem 1rem 0.5rem 0rem',
    fontSize: '1rem',
    borderRadius: '4px',
    border: `1px solid ${palette.primary.dark} `,
  };
  const EnableButton = otp?.length === 6;
  /* The `useEffect` hook is used to update the `seconds` state variable every second. It creates an
  interval that decrements the `seconds` variable by 1 every second, as long as `seconds` is greater
  than 0. The interval is stored in the `interval` variable, which is returned by the cleanup
  function at the end of the `useEffect` hook. This cleanup function clears the interval when the
  component unmounts or when the `seconds` variable changes. This code is used to implement a
  countdown timer that counts down from 60 seconds to 0 seconds. */

  const EnableResendOTp = timeRemaining === 0;


  useEffect(() => {
    const timer = setInterval(() => {
      if (timeRemaining > 0) {
        setTimeRemaining(prevTime => prevTime - 1);
      } else {
        clearInterval(timer);
        // Handle countdown completion here, like showing a message or triggering an action
      }
    }, 1000); // Update every 1 second

    return () => {
      clearInterval(timer); // Clean up the timer when the component unmounts
    };
  }, [timeRemaining]);

  const formatTime = time => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };
  /**
   * The function handles the verification of a user's OTP code and navigates to the dashboard upon
   * successful verification.
   */
  const handleVerifyOtp = (e) => {
    e.preventDefault();
    // axios.post('https://10.20.1.212:7155/api/login-2FA', {
    //   email:location?.state?.data?.email,
    //   code : otp,
    //   password:location?.state?.data?.password
    // },
    // {
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Access-Control-Allow-Origin' : '*',
    //   }
    // })
    // .then((response) => {
    //       toast.success(response?.message)
    //       navigate("/dashboard")
    //       setUserId(response?.token)
    //     })
    //     .catch((error) => console.log(error))

    const payload = {
      email: location?.state?.data?.email,
      code: otp,
      password: location?.state?.data?.password
    }
    userOtpVerify({ payload }).unwrap()
      .then((response) => {
        toast.success(response?.message)
        setUserId(response?.token)
        const tokenInfo = parseJwt(response?.token)
        // const userRole = tokenInfo['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']?.toLowerCase()
        dispatch(getPermission(tokenInfo["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]));
      })
      .catch((error) => { console.log(error?.message) })
  };

  useEffect(() => {
    if (isSuccess && userPermission?.length > 0) {
      ((Array.isArray(userPermission) && userPermission?.includes('company')) || userPermission === 'company') ? navigate("/dashboard") :
        userPermission === 'manager' ? navigate("/manager-dashboard") :
          userPermission === 'professional writer' ? navigate("/professional-writer-dashboard") :
            userPermission === 'service staff' ? navigate("/service-staff-dashboard") :
              userPermission === 'accountant' ? navigate("/accountant-dashboard") :
                userPermission === 'office staff' ? navigate("/office-staff-dashboard") :
                  userPermission === 'supervisor' ? navigate("/supervisor-dashboard") :
                  userPermission === 'intern' ? navigate("/intern-dashboard") :
                    userPermission === 'consultant' ? navigate("/consultant-dashboard") :
                      userPermission === 'consultant supervisor' ? navigate("/consultant-supervisor-dashboard") :
                        userPermission === 'staff' ? navigate("/staff-dashboard") :
                          userPermission === 'client' ? navigate("/client-dashboard") :
                            navigate("/")
    }
  }, [isSuccess, userPermission])

  /**
   * The function handles the resend of an OTP code and displays a success message while resetting the
   * timer.
   */
  const handleResendOtp = () => {
    const params = location?.state?.data?.email
    setOtp('')
    userResendOtp(params).unwrap()
      .then((response) => {
        toast.success(response?.message)
        setTimeRemaining(900)
      })
      .catch((error) => console.log(error?.message))
  }
  return (

    <Box sx={{ maxWidth: "420px" }}>
      <form onSubmit={(e) => handleVerifyOtp(e)}>
        <Grid container spacing={2}>
          <Grid item lg={12} pb="20px"><Typography variant="h2" component="div">2 Factor Authentication</Typography></Grid>
          <Grid item lg={12} mb="10px"><Typography variant="formLabel" component='div'>{`Enter the 6 digit code sent to your email ${maskedEmail(userEmail)}`}</Typography></Grid>
          <Grid item lg={12}>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderInput={(props) => <input {...props} />}
              inputStyle={inputStyle}
              shouldAutoFocus="true"
              inputType="number"
            /></Grid>
          <Grid item lg={6} sx={{ alignItems: "center", display: "flex" }}><img src={timer} alt="timer" /><Typography pl="10px" variant="body2">{formatTime(timeRemaining)}</Typography></Grid>
          <Grid item lg={6} sx={{ display: "flex", justifyContent: "flex-end" }}><Button sx={{ color: palette.secondary.main }} type="text" disabled={!EnableResendOTp || isloading} onClick={handleResendOtp}>Resend OTP
            {isloading && <CircularProgress size={20} sx={{ ml: 2, color: palette.secondary.main }} />}</Button>
          </Grid>
          <TextField sx={{ opacity: 0, visibility: 'hidden', height: 0 }} />
          <Grid item lg={12} mt="45px">
            <Button disabled={!EnableButton || isLoading} type='submit' variant="contained" fullWidth>Submit
              {isLoading && <CircularProgress size={20} sx={{ ml: 2, color: palette.secondary.main }} />}</Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}