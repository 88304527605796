import { Box, Typography } from "@mui/material";
import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import palette from "../../../../theme/palette";
import { useGetServiceElementStatusQuery } from "../../../../store/services/mentalAssessmentReportServices";
import { useSelector } from "react-redux";
import { useGetClientByQuery } from "../../../../store/services/clientServices";
import ClientOtherGoalsTable from "./ClientOtherGoalsTable";

export default function ClientOtherGoals() {
    const { userData } = useSelector((state) => state?.getUserData);
    const location = useLocation()
    const masterServiceId = location?.state?.serviceId
    const staffUserID = userData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier']
    const { id } = useParams()
    const clientId = id
    const { data, isSuccess, isLoading } = useGetServiceElementStatusQuery({ masterServiceId, clientId })
    const { data: clientData } = useGetClientByQuery({ id: clientId }, { skip: !clientId })
    const navigate = useNavigate()
    return (
        <Box >
            <Grid container spacing={2}>
                <Grid xs={12} sm={8} md={8} lg={6} justifyContent={"flex-start"}>
                    <Typography variant="h1">List of Goals - {clientData?.firstName} {clientData?.lastName}</Typography>
                </Grid>
                <Grid xs={12} sm={4} md={4} lg={6}>
                    <Grid container justifyContent={'flex-end'}>
                        <Typography variant='subtitle1' fontWeight={'bold'}
                            color={palette.secondary.main}
                            onClick={() => navigate('/client/clientdetails', { state: { data: id, tabName: 2 } })}>
                            <span style={{ borderBottom: "2px solid", cursor: "pointer" }}>Back</span>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid xs={12}>
                    <ClientOtherGoalsTable
                        staffUserID={staffUserID}
                        data={data}
                        masterServiceId={masterServiceId}
                        isLoading={isLoading}
                        isSuccess={isSuccess} />
                </Grid>
            </Grid>
        </Box>
    )
}