import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Grid,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import palette from "../../../theme/palette";
import { useLoginMutation } from "../../../store/services/authServices";
import { toast } from "react-toastify";
import axios from "axios";
import { parseJwt, setUserId } from "../../../utils/util";
import getPermission from "../../../store/permissions/permissionAction";
import { useDispatch } from "react-redux";
import { developmentApi } from "../../../config";
export default function Login() {
  const apiUrl = axios.create({
    baseURL: developmentApi,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /* This line of code is initializing a state variable called `userProfile` using the `useState` hook.
  The initial state of `userProfile` is an object with two properties: `values` and `error`. */
  const [userProfile, setUserProfile] = useState({
    values: { email: "", password: "", check: false },
    error: { emailError: "" },
  });
  /* This line of code is initializing a state variable called `showPassword` using the `useState`
  hook. The initial state of `showPassword` is `false`. This state variable is used to toggle the
  visibility of the password input field. When the user clicks on the eye icon, the
  `setShowPassword` function is called to update the state of `showPassword` to the opposite of its
  current value, which will toggle the visibility of the password input field. */
  const [showPassword, setShowPassword] = useState(false);
  /* `const [userLogin,{isLoading}] = useLoginMutation({});` is using the `useLoginMutation` hook from
  an authentication service to initialize two variables using array destructuring. */
  const [userLogin, { isLoading }] = useLoginMutation({});
  /**
   * The function toggles the value of a boolean variable called showPassword.
   */
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  /**
   * The function updates the user profile values based on the input change event.
   * @param e - The parameter `e` is an event object that is passed to the `changeHandlar` function. It
   * contains information about the event that triggered the function, such as the target element, the
   * type of event, and any data associated with the event. In this case, the function is using the
   */
  const changeHandler = (e) => {
    let { name, value } = e.target;
    if (e.target.type === "checkbox") {
      name = e.target.name;
      value = e.target.checked;
    }
    setUserProfile({
      ...userProfile,
      values: {
        ...userProfile?.values,
        [name]: value,
      },
    });
  };
  /* `const loginButtonEnable` is a boolean variable that is being initialized with a condition. The
  condition checks if the `email` and `password` values in the `userProfile` state object are truthy
  and if the `emailError` property in the `error` object of the `userProfile` state object is an
  empty string. If all of these conditions are true, then `loginButtonEnable` will be set to `true`,
  which means that the login button will be enabled. Otherwise, `loginButtonEnable` will be set to
  `false`, which means that the login button will be disabled. */
  const loginButtonEnable =
    userProfile?.values?.email &&
    userProfile?.values?.password &&
    userProfile?.error?.emailError === "";

  /* The `useEffect` hook is used to perform side effects in a functional component. In this case, the
  `useEffect` hook is being used to validate the email input field in the login form. */
  useEffect(() => {
    if (userProfile?.values?.email) {
      setUserProfile({
        ...userProfile,
        error: {
          ...userProfile?.error,
          emailError: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(
            userProfile?.values?.email
          )
            ? ""
            : "Email is not valid",
        },
      });
    } else {
      setUserProfile({
        ...userProfile,
        error: {
          ...userProfile?.error,
          emailError: "",
        },
      });
    }
  }, [userProfile?.values?.email]);

  const handlevalidateSpace = (e) => {
    if (e.keyCode === 32) {
      e.preventDefault();
    }
  };

  /**
   * The function handles user login by sending a payload containing email and password to the server,
   * unwrapping the response, displaying a success message, and navigating to the OTP verification
   * page.
   * @param e - The "e" parameter is an event object that is passed to the function when the login form
   * is submitted. It is used to prevent the default form submission behavior and handle the form
   * submission manually.
   */
  const handleLogin = (e) => {
    e.preventDefault();
    const payload = {
      email: userProfile?.values?.email,
      password: userProfile?.values?.password,
    };

    apiUrl
      .post(`/api/Login`, {
        email: userProfile?.values?.email,
        password: userProfile?.values?.password,
      })
      .then((response) => {

        const is2Faenabled = response?.headers["x-collabsetgo-2fa"];
        if (is2Faenabled === 'False') {
   
          setUserId(response?.data?.token)
          const tokenInfo = parseJwt(response?.data?.token)
          const userPermission = tokenInfo["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]?.toLowerCase()
     
          dispatch(getPermission(userPermission));
          ((Array.isArray(userPermission) && userPermission?.includes('company')) || userPermission === 'company') ? navigate("/dashboard") :
          userPermission === 'manager' ? navigate("/manager-dashboard") :
          userPermission === 'professional writer' ? navigate("/professional-writer-dashboard") :
          userPermission === 'service staff' ? navigate("/service-staff-dashboard") :
          userPermission === 'accountant' ? navigate("/accountant-dashboard") :
          userPermission === 'office staff' ? navigate("/office-staff-dashboard") :
          userPermission === 'supervisor' ? navigate("/supervisor-dashboard") :
          userPermission === 'intern' ? navigate("/intern-dashboard") :
          userPermission === 'consultant' ? navigate("/consultant-dashboard") :
          userPermission === 'consultant supervisor' ? navigate("/consultant-supervisor-dashboard") :
          userPermission === 'staff' ? navigate("/staff-dashboard") :
          userPermission === 'client' ? navigate("/client-dashboard") :
           navigate("/")
        } else {
        
          navigate("/otpverify", { state: { data: userProfile?.values } });
        }
        
      
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      })
  };
  return (
    <form onSubmit={(e) => handleLogin(e)}>
      <Grid
        container
        spacing={2}
        justifyContent="flex-start"
        sx={{
          maxWidth: "500px",
        }}
      >
        <Grid item xs={12} pb="20px">
          <Typography align="center" variant="h2" component="div">
            Login
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="formLabel" component="div">
            Enter email address
          </Typography>
          <TextField
            error={userProfile?.error?.emailError ? true : false}
            required
            fullWidth
            size="small"
            name="email"
            helperText={userProfile?.error?.emailError}
            value={userProfile?.values?.email}
            onChange={(e) => changeHandler(e)}
            autoComplete="off"
            autoFocus
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="formLabel" component="div">
            Enter password
          </Typography>
          <TextField
            size="small"
            type={showPassword ? "text" : "password"}
            required
            fullWidth
            name="password"
            value={userProfile?.values?.password}
            onChange={(e) => changeHandler(e)}
            autoComplete="new-password"
            onKeyDown={handlevalidateSpace}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} container justifyContent={"flex-end"} pb="20px">
          <Typography
            variant="subtitle2"
            sx={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => navigate("/forgotpassword")}
          >
            Forgot Password
          </Typography>
        </Grid>
        {/* <Grid item lg={12} paddingRight={20} margin={0}>
          <FormControlLabel
            control={<Checkbox name="check" value={userProfile?.check} onChange={(e) => changeHandler(e)} color="primary" />}
            label="Remember me"
          />
        </Grid> */}
        <Grid item xs={12}>
          <Button
            disabled={!loginButtonEnable || isLoading}
            type="submit"
            fullWidth
            variant="contained"
          >
            Login
            {isLoading && (
              <CircularProgress
                size={20}
                sx={{ ml: 2, color: palette.secondary.main }}
              />
            )}
          </Button>
        </Grid>
        <Grid item xs={12} display="flex" mt="45px" alignItems={"center"}>
          <Typography variant="body1" sx={{ mr: "8px" }}>
            {" "}
            New to application?
          </Typography>
          <Typography
            variant="subtitle2"
            onClick={() => navigate("/register")}
            sx={{ cursor: "pointer" }}
          >
            <span style={{ textDecoration: "underline" }}> Click here</span>{" "}
            <span style={{ color: palette.text.main, textDecoration: "none" }}>
              to Register
            </span>
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
}
