import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const companyApi = createApi({
  reducerPath: 'companyApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: `${developmentApi}/api`,
  }),
  tagTypes:['companyProfile'],
  endpoints: (builder) => ({
    companyProfile: builder.query({
      query: ()=>({
        url:`/company/company-profile`,
        method:'GET',
      }),
      providesTags:['companyProfile'],
      transformResponse:(response)=> JSON.parse(response)?.data
    }),
    companyUpdate: builder.mutation({
      query: ({id, payload})=>({
        url:`/company/update-company-profile?id=${id}`,
        method:'PUT',
        payload:payload,
        fileUpload: true
      }),
      invalidatesTags: ['companyProfile'],
      transformResponse:(response)=> JSON.parse(response)
    }),
    companyChangePassword: builder.mutation({
      query: ({payload})=>({
        url:`/company/change-password`,
        method:'POST',
        payload:payload,
      }),
      transformResponse:(response)=> JSON.parse(response)
    }),
    mcaNotApplicable: builder.mutation({
      query: ({payload})=>({
        url:`/Client/UpdateAssessmentSectionConfig`,
        method:'POST',
        payload:payload,
      }),
      transformResponse:(response)=> JSON.parse(response)
    }),
    getClientNotApplicable: builder.query({
      query: ({id})=>({
        url:`/company/get-manage-mca-sections-applicable?clientId=${id}`,
        method:'GET'
      }),
      transformResponse:(response)=> JSON.parse(response)?.data
    }),
  
  }),
})

export const {useCompanyProfileQuery, useCompanyUpdateMutation, useCompanyChangePasswordMutation, useMcaNotApplicableMutation,useGetClientNotApplicableQuery
}=companyApi;
