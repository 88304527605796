import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const houseHoldApi = createApi({
  reducerPath: 'houseHoldApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: `${developmentApi}/api`,
  }),
  tagTypes: ["getMembersByclient", 'getAllChildren', 'getGeneralDetails', 'getEthnicityDetails'],
  endpoints: (builder) => ({
    addHouseHoldMember: builder.mutation({
      query: ({ clientId, payload }) => ({
        url: `/company/add-household-members?ClientId=${clientId}`,
        method: 'POST',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ["getMembersByclient"],
      transformResponse: (response) => JSON.parse(response)
    }),
    getMemberByClient: builder.query({
      query: ({ id }) => ({
        url: `/company/get-all-household-members?clientId=${id}`,
        method: 'GET',
      }),
      providesTags: ["getMembersByclient"],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    EditHouseHoldMember: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/update-household-member?id=${id}`,
        method: 'PUT',
        payload: payload,
      }),
      invalidatesTags: ["getMembersByclient"],
      transformResponse: (response) => JSON.parse(response)
    }),
    deleteHouseMember: builder.mutation({
      query: ({ id }) => ({
        url: `/company/delete-household-member?id=${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ["getMembersByclient"],
      transformResponse: (response) => JSON.parse(response)
    }),
    getHouseMemberById: builder.query({
      query: ({ id }) => ({
        url: `/company/get-household-member?id=${id}`,
        method: 'GET',
      }),
      invalidatesTags: ["getMembersByclient"],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    addChildren: builder.mutation({
      query: ({ clientId, payload }) => ({
        url: `/company/add-children-not-in-home?ClientId=${clientId}`,
        method: 'POST',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ["getAllChildren"],
      transformResponse: (response) => JSON.parse(response)
    }),
    editChildren: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/update-children-not-in-home?id=${id}`,
        method: 'PUT',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ["getAllChildren"],
      transformResponse: (response) => JSON.parse(response)
    }),
    getChildrenById: builder.query({
      query: ({ id }) => ({
        url: `/company/get-children-not-in-home?id=${id}`,
        method: 'GET',
      }),
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    getAllChildren: builder.query({
      query: ({ clientId }) => ({
        url: `/company/get-all-children-not-in-home?clientId=${clientId}`,
        method: 'GET',
      }),
      providesTags: ["getAllChildren"],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    deleteChildren: builder.mutation({
      query: ({ id }) => ({
        url: `/company/delete-children-not-in-home?id=${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ["getAllChildren"],
      transformResponse: (response) => JSON.parse(response)
    }),
    getGeneralDetails: builder.query({
      query: ({ id }) => ({
        url: `/company/get-general-household-info?id=${id}`,
        method: 'GET',
      }),
      providesTags: ["getGeneralDetails"],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    addGeneralDetails: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-general-household-info?ClientId=${ClientId}`,
        method: 'POST',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ["getGeneralDetails"],
      transformResponse: (response) => JSON.parse(response)
    }),
    editGeneralDetails: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/update-general-household-info?id=${id}`,
        method: 'PUT',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ["getGeneralDetails"],
      transformResponse: (response) => JSON.parse(response)
    }),
    getEthnicityDetails: builder.query({
      query: ({ ClientId }) => ({
        url: `/company/get-Ethnicity-And-Gender?ClientId=${ClientId}`,
        method: 'GET',
      }),
      providesTags: ["getEthnicityDetails"],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    addEthnicityDetails: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-Ethnicity-And-Gender?ClientId=${ClientId}`,
        method: 'POST',
        payload: payload,
      }),
      invalidatesTags: ["getEthnicityDetails"],
      transformResponse: (response) => JSON.parse(response)
    }),
    editEthnicityDetails: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/update-Ethnicity-And-Gender?id=${id}`,
        method: 'PUT',
        payload: payload,
      }),
      invalidatesTags: ["getEthnicityDetails"],
      transformResponse: (response) => JSON.parse(response)
    }),
  }),
})

export const { useAddHouseHoldMemberMutation, useGetMemberByClientQuery, useEditHouseHoldMemberMutation,
  useGetHouseMemberByIdQuery, useAddChildrenMutation, useEditChildrenMutation, useGetChildrenByIdQuery, useGetAllChildrenQuery,
  useDeleteChildrenMutation, useDeleteHouseMemberMutation, useGetGeneralDetailsQuery, useAddGeneralDetailsMutation, useEditGeneralDetailsMutation,
  useGetEthnicityDetailsQuery, useAddEthnicityDetailsMutation, useEditEthnicityDetailsMutation } = houseHoldApi;
