import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const comprehensiveApi = createApi({
    reducerPath: 'comprehensiveApi',
    refetchOnMountOrArgChange: true,
    keepUnusedDataFor: 0,
    refetchOnReconnect: true,
    baseQuery: AxiosBaseQuery({
        baseUrl: `${developmentApi}/api`,
    }),
    tagTypes: ['getComprehensive', 'getAllFollowups'],
    endpoints: (builder) => ({
        addComprehensive: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-demographic-comprehensive-report?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getComprehensive'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateComprehensive: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-demographic-comprehensive-report?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getComprehensive'],
            transformResponse: (response) => JSON.parse(response)
        }),
        getComprehensive: builder.query({
            query: ({ id }) => ({
                url: `/company/get-demographic-comprehensive-report?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['getComprehensive'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addFollowUp: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-follow-ups?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getAllFollowups'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateFollowUp: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-follow-ups?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getAllFollowups'],
            transformResponse: (response) => JSON.parse(response)
        }),
        getFollowUpById: builder.query({
            query: ({ id }) => ({
                url: `/company/get-follow-ups?id=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ['getAllFollowups'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getAllFollow: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-all-follow-ups?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getAllFollowups'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        deleteFollowUp: builder.mutation({
            query: ({ id }) => ({
                url: `/company/delete-follow-ups?id=${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["getAllFollowups"],
            transformResponse: (response) => JSON.parse(response)
        }),
    }),
})

export const { useAddComprehensiveMutation, useUpdateComprehensiveMutation, useGetComprehensiveQuery,
    useAddFollowUpMutation, useUpdateFollowUpMutation, useGetFollowUpByIdQuery, useGetAllFollowQuery, useDeleteFollowUpMutation } = comprehensiveApi;
