import React, { Suspense, useEffect } from "react";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { parseJwt } from "./utils/util";
import { Routes, Redirect } from "react-router-dom";
import { BrowserRouter, Route } from "react-router-dom";
import OtpScreen from "./pages/Authentication/Register/components/OTPScreen";
import LandingPage from "./pages/LandingPage";
import Login from "./pages/Authentication/Login";
import Register from "./pages/Authentication/Register";
import AuthenicationOutlet from "./pages/Authentication";
import EmailVerfiyScreen from "./pages/Authentication/Register/components/EmailVerifyScreen";
import ForgotPassword from "./pages/Authentication/Login/components/ForgotPassword";
import ResetPassword from "./pages/Authentication/Login/components/ResetPassword";
import ForgotPasswordLink from "./pages/Authentication/Login/components/ForgotPasswordLink";
import TermsAndCondition from "./pages/Authentication/Register/components/TermsAndCondition";
import PrivacyPolicy from "./pages/Authentication/Register/components/PrivacyPolicy";
import ClientSetPassword from "./pages/Client/components/ClientSetPassword";
import StaffSetPassword from "./pages/ManageStaffAccount/components/StaffSetPassword";
import getPermission from "./store/permissions/permissionAction";
import { authRoutes } from "./routes";
import Page404 from "./pages/404Page";
import getUserData from "./store/userData/userDataAction";
import { LinearProgress } from "@mui/material";

/** 
 * The function returns a component that renders a BrowserRouter and a Routing component, with a
 * ToastContainer.
 * @returns The `App` component is being returned, which contains a `BrowserRouter` component and a
 * `Routing` component. Additionally, there is a `ToastContainer` component being rendered inside the
 * `div` with the class name "App". The `console.log` statement is also being executed, which logs the
 * value of the `developmentApi` variable and the string "developmentt" to the console.
 */
function App() {
  const dispatch = useDispatch();
  const { permissionData } = useSelector((state) => state?.getPermission);
  const userPermission = Array.isArray(permissionData) ? permissionData.map(p => p.toLowerCase()) : permissionData?.length > 0 ? permissionData?.toLowerCase() : ''
  const authToken = localStorage.getItem("Admintoken");
  useEffect(() => {
    if (authToken) {
      let tokenResponse = parseJwt(authToken);
      dispatch(getPermission(tokenResponse["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]));
      dispatch(getUserData(tokenResponse));
    }
  }, [authToken]);

  return (
    <div className="App">
      <ToastContainer />
      <Suspense fallback={<LinearProgress />}>
        <BrowserRouter basename="/">
          <Routes>
            <Route
              path="/landing"
              element={<><LandingPage /></>}
            ></Route>
            <Route
              path="/"
              element={
                <><AuthenicationOutlet /></>
              }
            >
              <Route path="/" element={<Login />}></Route>
              <Route path="/register" element={<Register />}></Route>
              <Route path="/emailverify" element={<EmailVerfiyScreen />}></Route>
              <Route path="/otpverify" element={<OtpScreen />}></Route>
              <Route path="/terms&condition"
                element={<TermsAndCondition />}
              ></Route>
              <Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>
              <Route path="/forgotpassword" element={<ForgotPassword />}></Route>
              <Route path="/forgetpassword/*" element={<ResetPassword />}></Route>
              <Route
                path="/forgotpasswordlink"
                element={<ForgotPasswordLink />}
              ></Route>
              <Route
                path="/confirmemail/*"
                element={<EmailVerfiyScreen />}
              ></Route>
              <Route
                path="/clientsetpassword/*"
                element={<ClientSetPassword />}
              ></Route>
              <Route
                path="/staffsetpassword/*"
                element={<StaffSetPassword />}
              ></Route>
            </Route>

            {(userPermission && userPermission?.length > 0) && authRoutes?.map((route, idx) => {
              if ((Array.isArray(route?.role) && route?.role?.some(val => userPermission?.includes(val))) || userPermission?.includes(route?.role)) {
                return <Route
                  path={route.path}
                  element={route.element}
                  key={idx}
                  exact={true}
                />
              }
              else {
                return <Route path="*" element={<Page404 />} />
              }
            }
            )}
          </Routes>
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;
