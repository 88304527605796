import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars-2";

export default function TermsAndCondition() {
    const navigate = useNavigate()
    const location= useLocation()
    return (
        <Box>
            <Grid xs={12} container justifyContent={'flex-start'} >
                <Typography variant="h1">Terms and Conditions</Typography>
            </Grid>
            <Scrollbars autoHeight autoHeightMax={"calc(100vh - 200px)"} >
                <Typography textAlign={'left'} variant="body2" sx={{ pr: 2, lineHeight: 2 }}>

                    The Good Steward Business and Benefits Consulting, LLC, and its affiliates (“Good Steward” or

                    “we” or “us”) own and operate collabsetgo.com and these Terms and Conditions document the

                    legally binding terms and conditions attached to the use of this Website. By using or accessing

                    collabsetgo.com in any way, viewing or browsing the Website, or adding your own content to the

                    site, you are agreeing to be bound by these Terms and Conditions. If you are entering into this

                    agreement on behalf of a company, organization or legal entity other than your own, you are

                    agreeing to these terms on their behalf. If you do not have the authority to do so, or do not agree to these terms and conditions for your own use,
                    do not use collabsetgo.com.
                    <br />



                    You agree not to use the Website for any unlawful or abusive purpose, or in any way that

                    interferes with the ability of Good Steward to provide access to the Website or to provide any

                    other service to our customers, or in any way damages our property or the property of others.

                    You are solely responsible for all content you transmit through the Website and assume and

                    accept all liability and risk associated with accessing or using our Website and the Internet. You

                    agree to provide information that is: (a) accurate and not misleading, and (b) free of damaging

                    content, including, without limitation, viruses, worms, trojan horses, or other programs that can

                    disable, damage, interfere with, alter, intercept, or otherwise, impact or affect a Website or any

                    Good Steward system, network, or service, or any Website user. Good Steward will make

                    reasonable attempts to provide uninterrupted service, but there may be instances where Websites are delayed,
                    impaired or impossible to access (collectively, &quot; Technical Issues&quot;). Should you experience any Technical Issues,
                    you are to promptly contact Good Steward.<br />



                    You are aware that any information provided through your access to the Website belongs solely

                    to Good Steward. Any attempt to sell, disburse, distribute, provide, copy, or lease any

                    information will violate the terms and conditions and will be subject to court proceedings,

                    criminal liability, or otherwise. In the event any information provided to you from the Website,

                    Good Steward, or from your use of the Website, is released from your possession, transferred,

                    sold, disbursed, distributed, copied, or etc., you shall inform Good Steward.<br/>



                    This Website is presented “as is” and Good Steward makes no expressed or implied

                    representations or warranties of any kind related to this Website or the materials contained

                    hereon. In no event shall Good Steward, nor any of its officers, directors, and employees, be

                    liable to you for anything arising out of or in any way connected with your use of this Website.

                    You hereby indemnify to the fullest extend Good Steward from and against any and all liabilities,

                    costs, demands, causes of action, damages and expenses (including attorney fees) arising out of or in any way related to your breach
                    of any of the provisions of these Terms and Conditions. </Typography>
            </Scrollbars>
            <Button sx={{ mt: 2 }} variant="contained" 
            onClick={() => navigate("/register", {state: {data: location?.state, tabNumber: 1}})}>
                Back to Registration</Button>
        </Box>
    )
}