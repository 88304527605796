import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const comprehensiveActionApi = createApi({
    reducerPath: 'comprehensiveActionApi',
    refetchOnMountOrArgChange: true,
    keepUnusedDataFor: 0,
    refetchOnReconnect: true,
    baseQuery: AxiosBaseQuery({
        baseUrl: `${developmentApi}/api`,
    }),
    tagTypes: ['getAllDemoCompCC', 'getAllHomeCompCC', 'getAllBudgetCompCC', 'getAllCareerCompCC', 'getAllMentalCompCC', 'getAllRecommendationMedicalComp', 'getAllRecommendationCommunityComp'],
    endpoints: (builder) => ({
        addDemoCompCC: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-Demographic-Comprehensive-Critical-Concerns?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
            }),
            invalidatesTags: ['getAllDemoCompCC'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateDemoCompCC: builder.mutation({
            query: ({ params, payload }) => ({
                url: `/company/update-Demographic-Comprehensive-Critical-Concerns`,
                method: 'PUT',
                payload: payload,
                params: params
            }),
            invalidatesTags: ['getAllDemoCompCC'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getDemoCompCC: builder.query({
            query: (params) => ({
                url: `/company/get-Demographic-Comprehensive-Critical-Concerns`,
                method: 'GET',
                params: params
            }),
            providesTags: ['getAllDemoCompCC'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getAllDemoCompCC: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-all-Demographic-Comprehensive-Critical-Concerns?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getAllDemoCompCC'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        deleteDemoCompCC: builder.mutation({
            query: (params) => ({
                url: `/company/delete-Demographic-Comprehensive-Critical-Concerns`,
                method: 'DELETE',
                params: params
            }),
            invalidatesTags: ['getAllDemoCompCC'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addHomeCompCC: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-Home-Comprehensive-Critical-Concerns?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
            }),
            invalidatesTags: ['getAllHomeCompCC'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateHomeCompCC: builder.mutation({
            query: ({ params, payload }) => ({
                url: `/company/update-Home-Comprehensive-Critical-Concerns`,
                method: 'PUT',
                payload: payload,
                params: params
            }),
            invalidatesTags: ['getAllHomeCompCC'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getHomeCompCC: builder.query({
            query: (params) => ({
                url: `/company/get-Home-Comprehensive-Critical-Concerns`,
                method: 'GET',
                params: params
            }),
            providesTags: ['getAllHomeCompCC'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getAllHomeCompCC: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-all-Home-Comprehensive-Critical-Concerns?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getAllHomeCompCC'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        deleteHomeCompCC: builder.mutation({
            query: (params) => ({
                url: `/company/delete-Home-Comprehensive-Critical-Concerns`,
                method: 'DELETE',
                params: params
            }),
            invalidatesTags: ['getAllHomeCompCC'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addBudgetCompCC: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-Budget-Comprehensive-Critical-Concerns?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
            }),
            invalidatesTags: ['getAllBudgetCompCC'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateBudgetCompCC: builder.mutation({
            query: ({ params, payload }) => ({
                url: `/company/update-Budget-Comprehensive-Critical-Concerns`,
                method: 'PUT',
                payload: payload,
                params: params
            }),
            invalidatesTags: ['getAllBudgetCompCC'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getBudgetCompCC: builder.query({
            query: (params) => ({
                url: `/company/get-Budget-Comprehensive-Critical-Concerns`,
                method: 'GET',
                params: params
            }),
            providesTags: ['getAllBudgetCompCC'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getAllBudgetCompCC: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-all-Budget-Comprehensive-Critical-Concerns?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getAllBudgetCompCC'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        deleteBudgetCompCC: builder.mutation({
            query: (params) => ({
                url: `/company/delete-Budget-Comprehensive-Critical-Concerns`,
                method: 'DELETE',
                params: params
            }),
            invalidatesTags: ['getAllBudgetCompCC'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addCareerCompCC: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-career-Comprehensive-Critical-Concerns?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
            }),
            invalidatesTags: ['getAllCareerCompCC'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateCareerCompCC: builder.mutation({
            query: ({ params, payload }) => ({
                url: `/company/update-career-Comprehensive-Critical-Concerns`,
                method: 'PUT',
                payload: payload,
                params: params
            }),
            invalidatesTags: ['getAllCareerCompCC'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getCareerCompCC: builder.query({
            query: (params) => ({
                url: `/company/get-career-Comprehensive-Critical-Concerns`,
                method: 'GET',
                params: params
            }),
            providesTags: ['getAllCareerCompCC'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getAllCareerCompCC: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-all-career-Comprehensive-Critical-Concerns?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getAllCareerCompCC'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        deleteCareerCompCC: builder.mutation({
            query: (params) => ({
                url: `/company/delete-career-Comprehensive-Critical-Concerns`,
                method: 'DELETE',
                params: params
            }),
            invalidatesTags: ['getAllCareerCompCC'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addMentalCompCC: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-mental-Comprehensive-Critical-Concerns?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
            }),
            invalidatesTags: ['getAllMentalCompCC'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateMentalCompCC: builder.mutation({
            query: ({ params, payload }) => ({
                url: `/company/update-mental-Comprehensive-Critical-Concerns`,
                method: 'PUT',
                payload: payload,
                params: params
            }),
            invalidatesTags: ['getAllMentalCompCC'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getMentalCompCC: builder.query({
            query: (params) => ({
                url: `/company/get-mental-Comprehensive-Critical-Concerns`,
                method: 'GET',
                params: params
            }),
            providesTags: ['getAllMentalCompCC'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getAllMentalCompCC: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-all-mental-Comprehensive-Critical-Concerns?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getAllMentalCompCC'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        deleteMentalCompCC: builder.mutation({
            query: (params) => ({
                url: `/company/delete-mental-Comprehensive-Critical-Concerns`,
                method: 'DELETE',
                params: params
            }),
            invalidatesTags: ['getAllMentalCompCC'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addRecommendationMedicalComp: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-Recommendations-Medical-Comprehensive?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
            }),
            invalidatesTags: ['getAllRecommendationMedicalComp'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateRecommendationMedicalComp: builder.mutation({
            query: ({ params, payload }) => ({
                url: `/company/update-Recommendations-Medical-Comprehensive`,
                method: 'PUT',
                payload: payload,
                params: params
            }),
            invalidatesTags: ['getAllRecommendationMedicalComp'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getRecommendationMedicalComp: builder.query({
            query: (params) => ({
                url: `/company/get-Recommendations-Medical-Comprehensive`,
                method: 'GET',
                params: params
            }),
            providesTags: ['getAllRecommendationMedicalComp'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getAllRecommendationMedicalComp: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-all-Recommendations-Medical-Comprehensive?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getAllRecommendationMedicalComp'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        deleteRecommendationMedicalComp: builder.mutation({
            query: (params) => ({
                url: `/company/delete-Recommendations-Medical-Comprehensive`,
                method: 'DELETE',
                params: params
            }),
            invalidatesTags: ['getAllRecommendationMedicalComp'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addRecommendationCommunityComp: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-Recommendations-community-Comprehensive?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
            }),
            invalidatesTags: ['getAllRecommendationCommunityComp'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateRecommendationCommunityComp: builder.mutation({
            query: ({ params, payload }) => ({
                url: `/company/update-Recommendations-community-Comprehensive`,
                method: 'PUT',
                payload: payload,
                params: params
            }),
            invalidatesTags: ['getAllRecommendationCommunityComp'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getRecommendationCommunityComp: builder.query({
            query: (params) => ({
                url: `/company/get-Recommendations-community-Comprehensive`,
                method: 'GET',
                params: params
            }),
            providesTags: ['getAllRecommendationCommunityComp'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getAllRecommendationCommunityComp: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-all-Recommendations-community-Comprehensive?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getAllRecommendationCommunityComp'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        deleteRecommendationCommunityComp: builder.mutation({
            query: (params) => ({
                url: `/company/delete-Recommendations-community-Comprehensive`,
                method: 'DELETE',
                params: params
            }),
            invalidatesTags: ['getAllRecommendationCommunityComp'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
    }),
})

export const { useAddDemoCompCCMutation, useUpdateDemoCompCCMutation, useGetDemoCompCCQuery, useGetAllDemoCompCCQuery, useDeleteDemoCompCCMutation,
    useAddHomeCompCCMutation, useUpdateHomeCompCCMutation, useGetHomeCompCCQuery, useGetAllHomeCompCCQuery, useDeleteHomeCompCCMutation,
    useAddBudgetCompCCMutation, useUpdateBudgetCompCCMutation, useGetBudgetCompCCQuery, useGetAllBudgetCompCCQuery, useDeleteBudgetCompCCMutation,
    useAddCareerCompCCMutation, useUpdateCareerCompCCMutation, useGetCareerCompCCQuery, useGetAllCareerCompCCQuery, useDeleteCareerCompCCMutation,
    useAddMentalCompCCMutation, useUpdateMentalCompCCMutation, useGetMentalCompCCQuery, useGetAllMentalCompCCQuery, useDeleteMentalCompCCMutation,
    useAddRecommendationMedicalCompMutation, useUpdateRecommendationMedicalCompMutation, useGetRecommendationMedicalCompQuery, useGetAllRecommendationMedicalCompQuery, useDeleteRecommendationMedicalCompMutation,
    useAddRecommendationCommunityCompMutation, useUpdateRecommendationCommunityCompMutation, useGetRecommendationCommunityCompQuery, useGetAllRecommendationCommunityCompQuery, useDeleteRecommendationCommunityCompMutation } = comprehensiveActionApi;
