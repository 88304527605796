import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const mentalHealthComprehensiveApi = createApi({
    reducerPath: 'mentalHealthComprehensiveApi',
    refetchOnMountOrArgChange: true,
    keepUnusedDataFor: 0,
    refetchOnReconnect: true,
    baseQuery: AxiosBaseQuery({
        baseUrl: `${developmentApi}/api`,
    }),
    tagTypes: ['getReportSummary', 'getReportCritical'],
    endpoints: (builder) => ({
        addReportSummary: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-report-summery-mental-health?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getReportSummary'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateReportSummary: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-report-summery-mental-health?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getReportSummary'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getReportSummary: builder.query({
            query: ({ id }) => ({
                url: `/company/get-report-summery-mental-health?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['getReportSummary'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addReportCritical: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-report-critical-concerns-mha?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getReportCritical'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateReportCritical: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-report-critical-concerns-mha?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getReportCritical'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getReportCritical: builder.query({
            query: ({ clientId }) => ({
                url: `/company/get-all-report-critical-concerns-mha?clientId=${clientId}`,
                method: 'GET',
            }),
            providesTags: ['getReportCritical'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getReportCriticalById: builder.query({
            query: ({ id }) => ({
                url: `/company/get-report-critical-concerns-mha?id=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ['getReportCritical'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        deleteReportCritical: builder.mutation({
            query: ({ id }) => ({
                url: `/company/delete-report-critical-concerns-mha?id=${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['getReportCritical'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
    }),
})

export const { useAddReportSummaryMutation, useUpdateReportSummaryMutation, useGetReportSummaryQuery,
    useAddReportCriticalMutation, useUpdateReportCriticalMutation, useGetReportCriticalQuery, useGetReportCriticalByIdQuery, useDeleteReportCriticalMutation} = mentalHealthComprehensiveApi;
