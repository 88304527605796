import { Box, Button, Grid, Typography } from "@mui/material";
import React ,{useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";

export default function ForgotPasswordLink(){
    const navigate= useNavigate();
    return (
        <Box sx={{ maxWidth: '500px'}}>
            <Grid container spacing={2}>
                <Grid item lg={12}>
                    <Typography variant="h2" component="div">Forgot your Password</Typography>
                </Grid>
                <Grid item lg={12} mb="10px"><Typography variant="formLabel" component='div' onClick={()=> navigate("/resetpassword")}>A password reset link has been sent to your email address. Please check your inbox to reset your password.</Typography></Grid>
            </Grid>
            <Grid item lg={12} mt="20px">
                <Button fullWidth variant="contained" onClick={()=> navigate("/")}>Go Back to Login</Button>
            </Grid>
        </Box>
    )
}