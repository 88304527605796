import { GET_PERMISSION } from "./permissionType"

const initialState = {
  permissionData: [],
}

export default function getPermission(state = initialState, action) {
  if (action.type === GET_PERMISSION) {
    return { ...state, permissionData: action.data }
  }
  return state
}
