import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const physicalNeighborhoodApi = createApi({
  reducerPath: 'physicalNeighborhoodApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: `${developmentApi}/api`,
  }),
  tagTypes:['getAmenity','getNeighborhoodCondition','getFrontCondition','getBackCondition','getResourceOutdoor','getDwelling'],
  endpoints: (builder) => ({
    addAmenity: builder.mutation({
        query: ({ ClientId, payload }) => ({
            url: `/company/add-neighborhood-amenity?ClientId=${ClientId}`,
            method: 'POST',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags:['getAmenity'],
        transformResponse: (response) => JSON.parse(response)
    }),
    updateAmenity: builder.mutation({
        query: ({ id, payload }) => ({
            url: `/company/update-neighborhood-amenity?id=${id}`,
            method: 'PUT',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags:['getAmenity'],
        transformResponse: (response) => JSON.parse(response)
    }),
    getAmenity: builder.query({
        query: ({ clientId }) => ({
            url: `/company/get-neighborhood-amenity?clientId=${clientId}`,
            method: 'GET',
        }),
        providesTags:['getAmenity'],
        transformResponse: (response) => JSON.parse(response)?.data
    }),
    addNeighborhoodCondition: builder.mutation({
        query: ({ ClientId, payload }) => ({
            url: `/company/add-neighborhood-condition?ClientId=${ClientId}`,
            method: 'POST',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags:['getNeighborhoodCondition'],
        transformResponse: (response) => JSON.parse(response)
    }),
    updateNeighborhoodCondition: builder.mutation({
        query: ({ id, payload }) => ({
            url: `/company/update-neighborhood-condition?id=${id}`,
            method: 'PUT',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags:['getNeighborhoodCondition'],
        transformResponse: (response) => JSON.parse(response)
    }),
    getNeighborhoodCondition: builder.query({
        query: ({ clientId }) => ({
            url: `/company/get-neighborhood-condition?clientId=${clientId}`,
            method: 'GET',
        }),
        providesTags:['getNeighborhoodCondition'],
        transformResponse: (response) => JSON.parse(response)?.data
    }),
    addFrontCondition: builder.mutation({
        query: ({ ClientId, payload }) => ({
            url: `/company/add-condition-front-home?ClientId=${ClientId}`,
            method: 'POST',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags:['getFrontCondition'],
        transformResponse: (response) => JSON.parse(response)
    }),
    updateFrontCondition: builder.mutation({
        query: ({ id, payload }) => ({
            url: `/company/update-condition-front-home?id=${id}`,
            method: 'PUT',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags:['getFrontCondition'],
        transformResponse: (response) => JSON.parse(response)
    }),
    getFrontCondition: builder.query({
        query: ({ clientId }) => ({
            url: `/company/get-condition-front-home?clientId=${clientId}`,
            method: 'GET',
        }),
        providesTags:['getFrontCondition'],
        transformResponse: (response) => JSON.parse(response)?.data
    }),
    addBackCondition: builder.mutation({
        query: ({ ClientId, payload }) => ({
            url: `/company/add-condition-back-home?ClientId=${ClientId}`,
            method: 'POST',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags:['getBackCondition'],
        transformResponse: (response) => JSON.parse(response)
    }),
    updateBackCondition: builder.mutation({
        query: ({ id, payload }) => ({
            url: `/company/update-condition-back-home?id=${id}`,
            method: 'PUT',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags:['getBackCondition'],
        transformResponse: (response) => JSON.parse(response)
    }),
    getBackCondition: builder.query({
        query: ({ clientId }) => ({
            url: `/company/get-condition-back-home?clientId=${clientId}`,
            method: 'GET',
        }),
        providesTags:['getBackCondition'],
        transformResponse: (response) => JSON.parse(response)?.data
    }),
    addResourceOutdoor: builder.mutation({
        query: ({ ClientId, payload }) => ({
            url: `/company/add-resource-outdoors-backyard?ClientId=${ClientId}`,
            method: 'POST',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags:['getResourceOutdoor'],
        transformResponse: (response) => JSON.parse(response)
    }),
    updateResourceOutdoor: builder.mutation({
        query: ({ id, payload }) => ({
            url: `/company/update-resource-outdoors-backyard?id=${id}`,
            method: 'PUT',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags:['getResourceOutdoor'],
        transformResponse: (response) => JSON.parse(response)
    }),
    getResourceOutdoor: builder.query({
        query: ({ clientId }) => ({
            url: `/company/get-resource-outdoors-backyard?clientId=${clientId}`,
            method: 'GET',
        }),
        providesTags:['getResourceOutdoor'],
        transformResponse: (response) => JSON.parse(response)?.data
    }),
    addDwelling: builder.mutation({
        query: ({ ClientId, payload }) => ({
            url: `/company/add-type-of-dwelling?ClientId=${ClientId}`,
            method: 'POST',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags:['getDwelling'],
        transformResponse: (response) => JSON.parse(response)
    }),
    updateDwelling: builder.mutation({
        query: ({ id, payload }) => ({
            url: `/company/update-type-of-dwelling?id=${id}`,
            method: 'PUT',
            payload: payload,
            fileUpload: true
        }),
        invalidatesTags:['getDwelling'],
        transformResponse: (response) => JSON.parse(response)
    }),
    getDwelling: builder.query({
        query: ({ clientId }) => ({
            url: `/company/get-type-of-dwelling?clientId=${clientId}`,
            method: 'GET',
        }),
        providesTags:['getDwelling'],
        transformResponse: (response) => JSON.parse(response)?.data
    }),
  }),
})

export const {useAddAmenityMutation, useUpdateAmenityMutation,useGetAmenityQuery,
 useAddNeighborhoodConditionMutation, useUpdateNeighborhoodConditionMutation, useGetNeighborhoodConditionQuery, 
 useAddFrontConditionMutation, useUpdateFrontConditionMutation, useGetFrontConditionQuery, 
 useAddBackConditionMutation, useUpdateBackConditionMutation, useGetBackConditionQuery,
 useAddResourceOutdoorMutation, useUpdateResourceOutdoorMutation, useGetResourceOutdoorQuery,
 useAddDwellingMutation, useUpdateDwellingMutation, useGetDwellingQuery}=physicalNeighborhoodApi;
