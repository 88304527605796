import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const budgetInterviewApi = createApi({
    reducerPath: 'budgetInterviewApi',
    refetchOnMountOrArgChange: true,
    keepUnusedDataFor: 0,
    refetchOnReconnect: true,
    baseQuery: AxiosBaseQuery({
        baseUrl: `${developmentApi}/api`,
    }),
    tagTypes: ['getFinancial', 'getAlimony', 'getEmploymentSupport', 'getBudgetSummary'],
    endpoints: (builder) => ({
        addFinancial: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-financial-detail?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getFinancial'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateFinancial: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-financial-detail?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getFinancial'],
            transformResponse: (response) => JSON.parse(response)
        }),
        getFinancial: builder.query({
            query: ({ id }) => ({
                url: `/company/get-financial-detail?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['getFinancial'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addAlimony: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-alimony?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getAlimony'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateAlimony: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-alimony?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getAlimony'],
            transformResponse: (response) => JSON.parse(response)
        }),
        getAlimony: builder.query({
            query: ({ id }) => ({
                url: `/company/get-alimony?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['getAlimony'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addEmploymentSupport: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-employment-supports-budget?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getEmploymentSupport'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateEmploymentSupport: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-employment-supports-budget?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getEmploymentSupport'],
            transformResponse: (response) => JSON.parse(response)
        }),
        getEmploymentSupport: builder.query({
            query: ({ id }) => ({
                url: `/company/get-employment-supports-budget?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['getEmploymentSupport'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        addBudgetSummary: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-budget-summery?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getBudgetSummary'],
            transformResponse: (response) => JSON.parse(response)
        }),
        updateBudgetSummary: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-budget-summery?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getBudgetSummary'],
            transformResponse: (response) => JSON.parse(response)
        }),
        getBudgetSummary: builder.query({
            query: ({ id }) => ({
                url: `/company/get-budget-summery?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['getBudgetSummary'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
    }),
})

export const { useAddFinancialMutation, useUpdateFinancialMutation, useGetFinancialQuery ,
    useAddAlimonyMutation, useUpdateAlimonyMutation, useGetAlimonyQuery ,
    useAddEmploymentSupportMutation, useUpdateEmploymentSupportMutation, useGetEmploymentSupportQuery,
    useAddBudgetSummaryMutation, useUpdateBudgetSummaryMutation, useGetBudgetSummaryQuery } = budgetInterviewApi;
