import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const interiorHomeApi = createApi({
  reducerPath: 'interiorHomeApiinteriorHomeApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: `${developmentApi}/api`,
  }),
  tagTypes: ['getHome', 'getLivingRoom', 'getDen', 'getKitchen', 'getBedroom', 'getBathroom', 'getAdditional'],
  endpoints: (builder) => ({
    addHome: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-internal-home-info?ClientId=${ClientId}`,
        method: 'POST',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ['getHome'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updateHome: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/update-internal-home-info?id=${id}`,
        method: 'PUT',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ['getHome'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getHome: builder.query({
      query: ({ clientId }) => ({
        url: `/company/get-internal-home-info?clientId=${clientId}`,
        method: 'GET',
      }),
      providesTags: ['getHome'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    addLivingRoom: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-living-room-info?ClientId=${ClientId}`,
        method: 'POST',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ['getLivingRoom'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updateLivingRoom: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/update-living-room-info?id=${id}`,
        method: 'PUT',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ['getLivingRoom'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getLivingRoom: builder.query({
      query: ({ clientId }) => ({
        url: `/company/get-living-room-info?clientId=${clientId}`,
        method: 'GET',
      }),
      providesTags: ['getLivingRoom'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    addDen: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-den-info?ClientId=${ClientId}`,
        method: 'POST',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ['getDen'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updateDen: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/update-den-info?id=${id}`,
        method: 'PUT',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ['getDen'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getDen: builder.query({
      query: ({ clientId }) => ({
        url: `/company/get-den-info?clientId=${clientId}`,
        method: 'GET',
      }),
      providesTags: ['getDen'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    addKitchen: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-kitchen-info?ClientId=${ClientId}`,
        method: 'POST',
        payload: payload,
      }),
      invalidatesTags: ['getKitchen'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updateKitchen: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/update-kitchen-info?id=${id}`,
        method: 'PUT',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ['getKitchen'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getKitchen: builder.query({
      query: ({ clientId }) => ({
        url: `/company/get-kitchen-info?clientId=${clientId}`,
        method: 'GET',
      }),
      providesTags: ['getKitchen'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    addBedroom: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-bedroom-info?ClientId=${ClientId}`,
        method: 'POST',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ['getBedroom'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updateBedroom: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/update-bedroom-info?id=${id}`,
        method: 'PUT',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ['getBedroom'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getAllBedroom: builder.query({
      query: ({ clientId }) => ({
        url: `/company/get-all-bedroom-info?clientId=${clientId}`,
        method: 'GET',
      }),
      providesTags: ['getBedroom'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    getBedroom: builder.query({
      query: ({ id }) => ({
        url: `/company/get-bedroom-info?id=${id}`,
        method: 'GET',
      }),
      invalidatesTags: ['getBedroom'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    deleteBedroom: builder.mutation({
      query: ({ id }) => ({
        url: `/company/delete-bedroom-info?id=${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['getBedroom'],
      transformResponse: (response) => JSON.parse(response)
    }),
    addBathroom: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-bathroom-info?ClientId=${ClientId}`,
        method: 'POST',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ['getBathroom'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updateBathroom: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/update-bathroom-info?id=${id}`,
        method: 'PUT',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ['getBathroom'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getAllBathroom: builder.query({
      query: ({ clientId }) => ({
        url: `/company/get-all-bathroom-info?clientId=${clientId}`,
        method: 'GET',
      }),
      providesTags: ['getBathroom'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    getBathroom: builder.query({
      query: ({ id }) => ({
        url: `/company/get-bathroom-info?id=${id}`,
        method: 'GET',
      }),
      invalidatesTags: ['getBathroom'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
    deleteBathroom: builder.mutation({
      query: ({ id }) => ({
        url: `/company/delete-bathroom-info?id=${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['getBathroom'],
      transformResponse: (response) => JSON.parse(response)
    }),
    addAdditional: builder.mutation({
      query: ({ ClientId, payload }) => ({
        url: `/company/add-additional-home-assessment-info?ClientId=${ClientId}`,
        method: 'POST',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ['getAdditional'],
      transformResponse: (response) => JSON.parse(response)
    }),
    updateAdditional: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/company/update-additional-home-assessment-info?id=${id}`,
        method: 'PUT',
        payload: payload,
        fileUpload: true
      }),
      invalidatesTags: ['getAdditional'],
      transformResponse: (response) => JSON.parse(response)
    }),
    getAdditional: builder.query({
      query: ({ clientId }) => ({
        url: `/company/get-additional-home-assessment-info?clientId=${clientId}`,
        method: 'GET',
      }),
      providesTags: ['getAdditional'],
      transformResponse: (response) => JSON.parse(response)?.data
    }),
  }),
})

export const { useAddHomeMutation, useUpdateHomeMutation, useGetHomeQuery,
  useAddLivingRoomMutation, useUpdateLivingRoomMutation, useGetLivingRoomQuery,
  useAddDenMutation, useUpdateDenMutation, useGetDenQuery,
  useAddKitchenMutation, useUpdateKitchenMutation, useGetKitchenQuery,
  useAddBedroomMutation, useUpdateBedroomMutation, useGetBedroomQuery,useGetAllBedroomQuery, useDeleteBedroomMutation,
  useAddBathroomMutation, useUpdateBathroomMutation, useGetBathroomQuery, useGetAllBathroomQuery, useDeleteBathroomMutation,
  useAddAdditionalMutation, useUpdateAdditionalMutation, useGetAdditionalQuery } = interiorHomeApi;
