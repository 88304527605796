import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosBaseQuery';
import { developmentApi } from '../../config';

export const mentalHealthDemographicApi = createApi({
    reducerPath: 'mentalHealthDemographicApi',
    refetchOnMountOrArgChange: true,
    keepUnusedDataFor: 0,
    refetchOnReconnect: true,
    baseQuery: AxiosBaseQuery({
        baseUrl: `${developmentApi}/api`,
    }),
    tagTypes: ['getDemographic'],
    endpoints: (builder) => ({
        addDemographic: builder.mutation({
            query: ({ ClientId, payload }) => ({
                url: `/company/add-demographic-mental-health?ClientId=${ClientId}`,
                method: 'POST',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getDemographic'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        updateDemographic: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/company/update-demographic-mental-health?id=${id}`,
                method: 'PUT',
                payload: payload,
                fileUpload: true
            }),
            invalidatesTags: ['getDemographic'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
        getDemographic: builder.query({
            query: ({ id }) => ({
                url: `/company/get-demographic-mental-health?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['getDemographic'],
            transformResponse: (response) => JSON.parse(response)?.data
        }),
    }),
})

export const { useAddDemographicMutation, useUpdateDemographicMutation, useGetDemographicQuery} = mentalHealthDemographicApi;
